import isArray from 'lodash/isArray';
import {
  CareTypeTranslation,
  CareTypeTranslationResponse,
} from '~/types/CareTypeTranslation';

export const parseCareTypeTranslation = (
  careTypeTranslation?: CareTypeTranslationResponse
): CareTypeTranslation[] => {
  const parsedContent: CareTypeTranslation[] = [];

  if (!isArray(careTypeTranslation?.results)) {
    return parsedContent;
  }

  careTypeTranslation?.results.forEach((content) => {
    parsedContent[content.careType.name] = content.copy;
  });

  return parsedContent;
};
