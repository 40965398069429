import { careTypeSeoTensing } from '@utils/careTypeSeoTensing';
import { getStateAbbreviation } from '@utils/UsStates';
import { SeoCarousel } from '@components/NextSeo';
import { Provider } from '~/contexts/Provider';
import { useTenantFunctions } from '~/contexts/TenantFunctionsContext';
import { useContext } from 'react';
import SiteContext from '~/contexts/SiteContext';
import { Domains } from '~/types/Domains';
import { SearchCarouselItemList } from '@components/NextSeo/SeoCarousel';

interface Props {
  city?: string;
  county?: string;
  state?: string;
  careType: string;
  results: Provider[];
  totalCount: number;
}

const SearchSeoCarousel = ({
  city,
  county,
  state,
  careType,
  results,
  totalCount,
}: Props) => {
  const siteContext = useContext(SiteContext);
  const { getProviderDetailsPath } = useTenantFunctions();
  const domain = siteContext.site?.path;
  const itemListElements = results.map(
    (provider, index): SearchCarouselItemList => {
      const path = getProviderDetailsPath(provider);
      const data: SearchCarouselItemList = {
        '@type': 'ListItem',
        position: index + 1,
        url: `https://www.${domain}${path}`,
      };

      if (
        (domain === Domains.CaringDomains.LIVE ||
          domain === Domains.CaringDomains.STG_DXP) &&
        provider.images?.[0]
      ) {
        data.image = {
          '@type': 'ImageObject',
          name: provider.name,
          url: provider.images?.[0] ?? '',
        };
      }

      return data;
    }
  );

  let name: string = '';

  if (
    domain === Domains.CaringDomains.LIVE ||
    domain === Domains.CaringDomains.STG_DXP
  ) {
    name = `List of ${totalCount} ${careTypeSeoTensing(
      careType,
      totalCount
    )} near ${city || county || ''}`;

    if (state) {
      name += `, ${getStateAbbreviation(state) || state}`;
    }
  }

  return (
    <SeoCarousel
      numberOfItems={totalCount ?? 0}
      data={itemListElements ?? []}
      name={name}
    />
  );
};

export default SearchSeoCarousel;
