import {
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form';
import { FieldErrorDetails, FieldConfig } from '../types';
import Input from './Input';
import Select from './Select';

export type FormFieldCommonProps = {
  entriesColorKey: string;
  error?: FieldErrorDetails;
  errorColorKey: string;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  trigger: UseFormTrigger<any>;
};

type Props = FieldConfig & FormFieldCommonProps;

const FormField: React.FC<Props> = (props) => {
  switch (props.type) {
    case 'input':
      return <Input {...props} />;
    case 'select':
      return <Select {...props} />;
    default:
      return null;
  }
};

export default FormField;
