import { useContext } from 'react';
import SiteContext from '~/contexts/SiteContext';
import { Domains } from '~/types/Domains';

interface SearchParamKeys {
  CARE_TYPE: string;
  KEYWORD: string;
}

const CARING_SEARCH_PARAM: SearchParamKeys = {
  CARE_TYPE: 'care-type',
  KEYWORD: 'keyword',
};

const SENIOR_HOMES_SEARCH_PARAM: SearchParamKeys = {
  CARE_TYPE: 'care-type',
  KEYWORD: 'keyword',
};

interface SearchParams {
  careType?: string;
  keyword?: string;
}

const useSearch = () => {
  const { site } = useContext(SiteContext);
  const domain = site?.path || Domains.CaringDomains.LIVE;
  const baseUrl = new URL(`https://www.${domain}`);

  const getSearchPagePath = () => {
    switch (domain) {
      case Domains.CaringDomains.LIVE:
        return '/local/search/';
      case Domains.SeniorHomesDomains.LIVE:
        return '/search/';
      default:
        return '/search/';
    }
  };

  const getSearchParamKeys = (): SearchParamKeys => {
    switch (domain) {
      case Domains.CaringDomains.LIVE:
        return CARING_SEARCH_PARAM;
      case Domains.SeniorHomesDomains.LIVE:
        return SENIOR_HOMES_SEARCH_PARAM;
      default:
        return SENIOR_HOMES_SEARCH_PARAM;
    }
  };

  const buildSearchUrl = ({ careType, keyword }: SearchParams): string => {
    const searchParams = new URLSearchParams();
    const { CARE_TYPE, KEYWORD } = getSearchParamKeys();

    if (keyword) {
      searchParams.set(KEYWORD, keyword);
    }

    if (careType) {
      searchParams.set(CARE_TYPE, careType);
    }

    const url = new URL(getSearchPagePath(), baseUrl);

    url.search = searchParams.toString();

    return url.toString();
  };

  return {
    buildSearchUrl,
  };
};

export default useSearch;
