import Button from '@components/Button';
import {
  ElementActions,
  ElementNames,
  ElementTypes,
} from '@components/Analytics/events/ElementClicked';
import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import { Box, Icon, useBreakpointValue, AspectRatio } from '@chakra-ui/react';

import { useToast } from '@chakra-ui/toast';
import { navigateToQuery } from '@components/FacetedSearch/navigation';
import { SEARCH_PARAM } from '@constants/search-params';
import { handleShare } from '@utils/share';
import { getSearchParamsFromWindow } from '@utils/url';
import { useRouter } from 'next/router';
import { MdClose, MdIosShare } from 'react-icons/md';
import { useEffect } from 'react';

const GOOGLE_EMBED_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_EMBED_API_KEY || '';

const StreetViewModal: React.FC<{
  coordinates: { latitude: number; longitude: number };
  modalSidebar?: React.ReactNode;
  isStreetViewModalOpen: boolean;
  setIsStreetViewModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  coordinates,
  modalSidebar,
  isStreetViewModalOpen,
  setIsStreetViewModalOpen,
}) => {
  const toast = useToast();
  const shouldShowSidebar = useBreakpointValue({ base: false, lg: true });
  const sharedUrl = typeof window !== 'undefined' ? window.location.href : '';
  const router = useRouter();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has(SEARCH_PARAM.STREET_VIEW)) {
      setIsStreetViewModalOpen(true);
    }
  }, [setIsStreetViewModalOpen]);

  const onCloseModal = () => {
    setIsStreetViewModalOpen(false);
    const searchParams = getSearchParamsFromWindow();
    searchParams.delete(SEARCH_PARAM.STREET_VIEW);
    navigateToQuery(router, searchParams);
  };

  return (
    <Modal
      isCentered
      isOpen={isStreetViewModalOpen}
      size={{ base: 'lg', lg: '4xl' }}
      trapFocus={false}
      onClose={onCloseModal}
    >
      <ModalOverlay />
      <ModalContent
        containerProps={{
          width: {
            base: '100vw',
            lg: modalSidebar ? '70vw' : '100vw',
            '3xl': modalSidebar ? '80vw' : '100vw',
          },
        }}
      >
        <AspectRatio maxW="100%" ratio={6 / 4}>
          <Box position="relative">
            <Box
              position="absolute"
              display="flex"
              gap="2"
              left="160px"
              top="10px"
            >
              <Button
                colorScheme="gray"
                title="Close street view"
                onClick={onCloseModal}
                elementAction={ElementActions.CLOSE_MODAL}
                elementName={ElementNames.STREET_VIEW}
                elementType={ElementTypes.BUTTON}
              >
                <Icon as={MdClose} />
              </Button>
              <Button
                colorScheme="primary"
                title="Share street view"
                elementAction={ElementActions.SHARE}
                elementName={ElementNames.VIEW_GALLERY}
                elementType={ElementTypes.BUTTON}
                onClick={() => handleShare(sharedUrl, toast)}
              >
                <Icon as={MdIosShare} />
              </Button>
            </Box>
            <iframe
              width="100%"
              height="100%"
              loading="lazy"
              allowFullScreen
              allow="accelerometer; gyroscope;"
              src={`https://www.google.com/maps/embed/v1/streetview?key=${GOOGLE_EMBED_API_KEY}&location=${coordinates.latitude},${coordinates.longitude}&radius=150`}
            />
          </Box>
        </AspectRatio>
      </ModalContent>
      {shouldShowSidebar && modalSidebar && (
        <Box
          position="fixed"
          top={0}
          bottom={0}
          right={0}
          zIndex="popover"
          maxWidth={{
            base: '30vw',
            '3xl': '20vw',
          }}
        >
          {modalSidebar}
        </Box>
      )}
    </Modal>
  );
};

export default StreetViewModal;
