import { PAGE_NUMBER_PARAM } from '@components/Search/EnhancedSearch/EnhancedSearch';
import { getPathnameFromWindow, getSearchParamsFromWindow } from '@utils/url';
import set from 'lodash/set';
import { NextRouter } from 'next/router';
import { parse, stringify } from 'qs';
import {
  FacetedSearchParsedParams,
  PARAM_NAMES,
  QUERY_KEY_MAP,
} from './search-params';

const ARRAY_FORMAT = 'comma';
const SCROLL_OPTION = false;
const SHALLOW_OPTION = true;
const INITIAL_PAGE = 0;
const PAGE_INCREMENT = 1;

const createURL = (pathname: string, searchParams: URLSearchParams) => {
  const queryString = searchParams.toString();
  return queryString ? `${pathname}?${queryString}` : pathname;
};

export const navigateToQuery = (
  router: NextRouter,
  searchParams: URLSearchParams
) => {
  const pathname = getPathnameFromWindow();

  router.push(createURL(pathname, searchParams), undefined, {
    scroll: SCROLL_OPTION,
    shallow: SHALLOW_OPTION,
  });
};

export const navigateToInitialSearch = (router: NextRouter) => {
  const currentSearchParams = getSearchParamsFromWindow();
  const searchBarParams: Array<keyof FacetedSearchParsedParams> = [
    'keyword',
    'careType',
  ];
  const sidebarParams = PARAM_NAMES.filter(
    (paramName) => !searchBarParams.includes(paramName)
  );

  sidebarParams.forEach((paramName) => {
    const searchParamName = QUERY_KEY_MAP[paramName] ?? paramName;
    currentSearchParams.delete(searchParamName);
  });

  navigateToQuery(router, currentSearchParams);
};

export const navigateToSearchQuery = (
  router: NextRouter,
  queryKey: string,
  queryValue: unknown
) => {
  const currentSearchParams = getSearchParamsFromWindow();
  const parsedQuery = parse(currentSearchParams.toString());

  const mappedParamName = QUERY_KEY_MAP[queryKey] ?? queryKey;

  set(parsedQuery, mappedParamName, queryValue);
  set(parsedQuery, PAGE_NUMBER_PARAM, undefined);

  const updatedSearchParams = new URLSearchParams(
    stringify(parsedQuery, { arrayFormat: ARRAY_FORMAT })
  );

  navigateToQuery(router, updatedSearchParams);
};

export const navigateToSearchPage = (router: NextRouter, page: number) => {
  const currentSearchParams = getSearchParamsFromWindow();
  const navigateToInitialPage = page === INITIAL_PAGE;

  if (navigateToInitialPage) {
    currentSearchParams.delete(PAGE_NUMBER_PARAM);
  } else {
    currentSearchParams.set(PAGE_NUMBER_PARAM, String(page + PAGE_INCREMENT));
  }

  navigateToQuery(router, currentSearchParams);
};
