import { Icon } from '@chakra-ui/icon';
import { Box, Heading, Flex, Text } from '@chakra-ui/layout';
import { Tag } from '@chakra-ui/tag';
import ReviewStars from '@components/ReviewStars';
import { safeFormat } from '@utils/dates';
import { useContext } from 'react';
import { MdStar } from 'react-icons/md';
import SiteContext from '~/contexts/SiteContext';

const ProviderReviewsStars = ({
  domain,
  rating,
  color = 'tertiary',
  colorRange = '400',
}) => {
  switch (domain) {
    case 'caring.com':
      return (
        <>
          <Heading
            as="p"
            size="sm"
            aria-label={`${rating} star rating`}
            color={`${color}.${colorRange}`}
          >
            {rating}
          </Heading>
          <Box alignSelf="center" lineHeight={'0'}>
            <ReviewStars
              color={`${color}.${colorRange}`}
              rating={rating}
              size="5"
            />
          </Box>
        </>
      );
    default:
      return (
        <>
          <Icon
            as={MdStar}
            boxSize="4"
            color="accent.500"
            role="presentation"
          />
          <Heading
            as="p"
            size="xs"
            color="accent.500"
            aria-label={`${rating} star rating`}
          >
            {rating}
          </Heading>
        </>
      );
  }
};

const showCareTypeLabel = (domain: string): boolean => {
  switch (domain) {
    case 'caring.com':
      return true;
    default:
      return false;
  }
};

const getReviewTitleColor = (
  domain: string
): { reviewTitleBackgroundColor: string; reviewTitleTextColor: string } => {
  switch (domain) {
    case 'caring.com':
      return {
        reviewTitleBackgroundColor: 'gray.200',
        reviewTitleTextColor: 'gray.800',
      };
    default:
      return {
        reviewTitleBackgroundColor: 'primary.600',
        reviewTitleTextColor: 'white',
      };
  }
};

const ProviderReviewsCardHeader = ({
  author,
  createdAt,
  rating,
  title,
  subtitle,
  serviceCategoryName,
  ratingColor,
  ratingColorRange,
  tagsColor = 'tertiary',
  tagsColorRange = '600',
}: {
  author: string;
  rating: number;
  createdAt?: string;
  title: string;
  subtitle?: string;
  serviceCategoryName?: string;
  tagsColor?: string;
  tagsColorRange?: string;
  ratingColor?: string;
  ratingColorRange?: string;
}) => {
  const siteContext = useContext(SiteContext);
  const domain = siteContext.site?.path || '';
  const date = createdAt ? safeFormat(createdAt, 'MMMM d, yyy') : '';

  const careTypeLabel = showCareTypeLabel(domain);
  const { reviewTitleBackgroundColor, reviewTitleTextColor } =
    getReviewTitleColor(domain);

  return (
    <>
      {careTypeLabel && serviceCategoryName && (
        <Text
          mb={2}
          as="label"
          fontSize="xs"
          fontWeight={400}
          color={`${tagsColor}.${tagsColorRange}`}
          textTransform="uppercase"
        >
          {serviceCategoryName}
        </Text>
      )}
      <Heading as="p" size="md" color="gray.800">
        {author}
      </Heading>
      <Flex alignItems="center">
        <ProviderReviewsStars
          domain={domain}
          rating={rating}
          color={ratingColor}
          colorRange={ratingColorRange}
        />
        {date && (
          <>
            <Text role="separator" fontSize="xs" px={2}>
              |
            </Text>
            <Text fontSize="xs" color="gray.800">
              {date}
            </Text>
          </>
        )}
      </Flex>
      <Tag
        mt={3}
        mb={4}
        bg={reviewTitleBackgroundColor}
        color={reviewTitleTextColor}
        fontSize="xs"
        width="fit-content"
      >
        {title}
      </Tag>
      {subtitle && (
        <Text as="label" fontSize="sm" fontWeight={600} color="gray.800">
          {subtitle}
        </Text>
      )}
    </>
  );
};
export default ProviderReviewsCardHeader;
