import withHydrationOnDemand from 'react-hydration-on-demand';
import { WhyCaring } from '@components/WhyCaring';

interface Props {
  title: string;
  bgColor: string;
  bgColorRange: string;
  itemsBgColor: string;
  itemsBgColorRange: string;
  iconsTextColor: string;
  iconsTextColorRange: string;
  iconsBgColor: string;
  iconsBgColorRange: string;
  firstItemTitle: string;
  firstItemDescription: string;
  firstItemIcon: string;
  secondItemTitle: string;
  secondItemDescription: string;
  secondItemIcon: string;
  thirdItemTitle: string;
  thirdItemDescription: string;
  thirdItemIcon: string;
  fourthItemTitle: string;
  fourthItemDescription: string;
  fourthItemIcon: string;
}

const WhyCaringWrapper = ({
  title,
  bgColor = 'secondary',
  bgColorRange = '50',
  itemsBgColor = 'white',
  itemsBgColorRange = '50',
  iconsTextColor = 'accent',
  iconsTextColorRange = '400',
  iconsBgColor = 'accent',
  iconsBgColorRange = '50',
  firstItemTitle,
  firstItemDescription,
  firstItemIcon,
  secondItemTitle,
  secondItemDescription,
  secondItemIcon,
  thirdItemTitle,
  thirdItemDescription,
  thirdItemIcon,
  fourthItemTitle,
  fourthItemDescription,
  fourthItemIcon,
}: Props): JSX.Element => {
  const bg = { color: bgColor, range: bgColorRange };
  const itemsBg = { color: itemsBgColor, itemsBgColorRange: itemsBgColorRange };
  const iconsColor = { color: iconsTextColor, range: iconsTextColorRange };
  const iconsBg = { color: iconsBgColor, range: iconsBgColorRange };
  const firstItem = {
    title: firstItemTitle,
    description: firstItemDescription,
    icon: firstItemIcon,
  };
  const secondItem = {
    title: secondItemTitle,
    description: secondItemDescription,
    icon: secondItemIcon,
  };
  const thirdItem = {
    title: thirdItemTitle,
    description: thirdItemDescription,
    icon: thirdItemIcon,
  };
  const fourthItem = {
    title: fourthItemTitle,
    description: fourthItemDescription,
    icon: fourthItemIcon,
  };

  return (
    <WhyCaring
      title={title}
      bg={bg}
      itemsBg={itemsBg}
      iconColor={iconsColor}
      iconsBg={iconsBg}
      firstItem={firstItem}
      secondItem={secondItem}
      thirdItem={thirdItem}
      fourthItem={fourthItem}
    />
  );
};

export default withHydrationOnDemand({
  on: ['idle', 'visible'],
  initialVisible: true,
})(WhyCaringWrapper);
