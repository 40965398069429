import { ButtonProps, IconButton } from '@chakra-ui/button';
import { Icon } from '@chakra-ui/icon';
import { MouseEventHandler } from 'react';
import { LuChevronLeft, LuChevronRight } from 'react-icons/lu';
import { ScrollDirection } from './constants';

const ariaLabel: { [key in ScrollDirection]: string } = {
  left: 'Scroll left',
  right: 'Scroll right',
};

const buttonOffset = '-20px';

const icons: { [key in ScrollDirection]: React.ReactElement } = {
  left: <Icon as={LuChevronLeft} boxSize={6} />,
  right: <Icon as={LuChevronRight} boxSize={6} />,
};

const styles: { [key in ScrollDirection]: ButtonProps } = {
  left: {
    left: buttonOffset,
  },
  right: {
    right: buttonOffset,
  },
};

interface ArrowButtonProps {
  direction: ScrollDirection;
  isDisabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const ArrowButton: React.FC<ArrowButtonProps> = ({
  isDisabled,
  direction,
  onClick,
}) => {
  return (
    <IconButton
      aria-label={ariaLabel[direction]}
      border="1px"
      borderColor="gray.200"
      colorScheme="white"
      icon={icons[direction]}
      isDisabled={isDisabled}
      isRound={true}
      position="absolute"
      shadow="lg"
      variant="outline"
      {...styles[direction]}
      onClick={onClick}
    />
  );
};

export default ArrowButton;
