import { Button, ButtonProps } from '@chakra-ui/button';
import { Text } from '@chakra-ui/layout';
import { MdTouchApp } from 'react-icons/md';

interface PromotionButtonProps extends ButtonProps {
  label: string;
  subLabel: string;
  colorScheme: string;
}

const PromotionButton: React.FC<PromotionButtonProps> = ({
  label,
  subLabel,
  colorScheme = 'primary',
  ...rest
}) => {
  return (
    <Button
      borderRadius="50px"
      boxShadow="md"
      colorScheme={`${colorScheme}`}
      height="34px"
      size="xs"
      {...rest}
    >
      <MdTouchApp size={23} />
      <Text as="span">
        {label}{' '}
        <Text as="span" fontWeight={400}>
          {subLabel}
        </Text>
      </Text>
    </Button>
  );
};

export default PromotionButton;
