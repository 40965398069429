import { Icon } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { ButtonProps } from '@chakra-ui/button';
import Button from '@components/Button';
import {
  ElementActions,
  ElementNames,
  ElementTypes,
} from '@components/Analytics/events/ElementClicked';

const WhyCaringToggleButton = ({
  onClick,
  expanded,
  ...rest
}: { expanded: boolean } & ButtonProps) => {
  return (
    <Button
      variant="outline"
      colorScheme="primary"
      size="sm"
      width={{ base: 'full', sm: 'auto' }}
      mt={{ base: 5, sm: 0 }}
      onClick={onClick}
      rightIcon={
        expanded ? (
          <Icon as={ChevronUpIcon} w="14px" h="14px" />
        ) : (
          <Icon as={ChevronDownIcon} w="14px" h="14px" />
        )
      }
      elementAction={expanded ? ElementActions.COLLAPSE : ElementActions.EXPAND}
      elementType={ElementTypes.BUTTON}
      elementName={ElementNames.WHY_CARING_BUTTON}
      {...rest}
    >
      {expanded ? 'Read Less' : 'Read More'}
    </Button>
  );
};

export default WhyCaringToggleButton;
