import { ModularMonolithClient } from '@services/modular-monolith/client';
import {
  FacetedSearchRequestQuery,
  GetProvidersResponse,
} from '@services/modular-monolith/types/search.type';
import {
  AmenityCategoryNode,
  AMENITY_CATEGORIES,
  AMENITY_CATEGORIES_BY_CARE_TYPE,
  CareType,
  isAmenityCategory,
} from '@utils/faceted-search';
import mergeWith from 'lodash/mergeWith';
import { Domain } from '~/types/Domains';
import { AmenityCategory } from '../EnhancedSearch/constants';

export const isValidCareTypeForFacetedSearch = (
  careType: string
): careType is CareType => {
  return Object.values(CareType).includes(careType as CareType);
};

export const getAmenitiesByCategory = (amenityCategory: string): string[] => {
  if (isAmenityCategory(amenityCategory)) {
    return AMENITY_CATEGORIES[amenityCategory].amenities;
  }

  return [];
};

const addOptionalNumbers = (objValue?: number, srcValue?: number) => {
  return (objValue ?? 0) + (srcValue ?? 0);
};

const mergeAmenitiesCount = (
  inRegionCount: Record<string, number>,
  nearbyCount: Record<string, number>
): Record<string, number> => {
  const mergedAmenitiesCount = {};
  return mergeWith(
    mergedAmenitiesCount,
    inRegionCount,
    nearbyCount,
    addOptionalNumbers
  );
};

const getAmenityCounts = (
  response: GetProvidersResponse
): Record<string, number> => {
  const inRegionFacets =
    response.inRegionFacets?.['amenities.amenityType.name'] ?? {};
  const nearbyFacets =
    response.nearbyFacets?.['amenities.amenityType.name'] ?? {};
  return mergeAmenitiesCount(inRegionFacets, nearbyFacets);
};

const getAmenityCategoriesByCareType = (
  careType: string
): AmenityCategory[] => {
  if (isValidCareTypeForFacetedSearch(careType)) {
    return AMENITY_CATEGORIES_BY_CARE_TYPE[careType];
  }

  return [];
};

export const getMaxAmenityCount = (
  amenities: string[],
  amenitiesCount: Record<string, number>
): number => {
  let count = 0;
  for (const amenity of amenities) {
    const currentCount = amenitiesCount[amenity] ?? 0;
    // Get the max count of all amenities in the category
    // e.g. if there are 2 amenities in the category, one with 5 and one with 10, the count should be 10
    // This is because one provider can have multiple amenities in the same category
    // e.g. a provider can have both 'Swimming Pool' and 'Hot Tub Spa' amenities
    // and both of these amenities are in the 'Pool / Hot Tub' category
    // so the count for the 'Pool / Hot Tub' category should be 1
    count = Math.max(count, currentCount);
  }
  return count;
};

export interface AmenityCategoryNodeWithCount extends AmenityCategoryNode {
  count: number;
}

const mergeAmenityCategoriesWithCounts = (
  amenityCategories: AmenityCategory[],
  amenitiesCount: Record<string, number>
): AmenityCategoryNodeWithCount[] => {
  return amenityCategories.map((amenityCategorySlug) => {
    const amenityCategory = AMENITY_CATEGORIES[amenityCategorySlug];
    const amenities = amenityCategory.amenities;
    const count = getMaxAmenityCount(amenities, amenitiesCount);

    return {
      ...amenityCategory,
      count,
    };
  });
};

export const getAmenityCategoriesFromResponse = (
  response: GetProvidersResponse,
  careType: string
): AmenityCategoryNodeWithCount[] => {
  const amenitiesCount = getAmenityCounts(response);
  const amenityCategories = getAmenityCategoriesByCareType(careType);
  const amenityCategoriesWithCounts = mergeAmenityCategoriesWithCounts(
    amenityCategories,
    amenitiesCount
  );

  return amenityCategoriesWithCounts.filter(
    (amenityCategory) => amenityCategory.count > 0
  );
};
export const fetchAmenityCategoryCounts = async (
  searchParams: FacetedSearchRequestQuery
): Promise<AmenityCategoryNodeWithCount[]> => {
  const { domain } = searchParams.criteria;
  const careType = searchParams.criteria.careType ?? '';
  const hasInvalidCareType = !isValidCareTypeForFacetedSearch(careType);

  if (hasInvalidCareType) {
    return [];
  }

  // Remove amenities from searchParams to get the count of amenities categories
  const updatedSearchParams: FacetedSearchRequestQuery = {
    ...searchParams,
    criteria: {
      ...searchParams.criteria,
      amenityCategory: [],
    },
    page: 0,
  };

  const modularMonolithClient = new ModularMonolithClient(domain as Domain);
  const providers = await modularMonolithClient.searchProvidersByFacets(
    updatedSearchParams
  );

  return getAmenityCategoriesFromResponse(providers, careType);
};
