import { Grid, HStack, Icon, Text, Stack } from '@chakra-ui/react';
import { STRING_TO_ICON_CLASS } from '@components/RenderIcon';

import { Medicares } from '~/contexts/Provider';

const medicareListData = {
  federalProviderNumber: {
    label: 'Medicare Provider Number',
    type: 'string',
  },
  ownershipType: { label: 'Ownership Type', type: 'text' },
  ratingDate: { label: 'Rating Date', type: 'text' },
  frequencyBeginCare: {
    label: 'Timeliness of providing service',
    type: 'percent',
  },
  frequencyDrugTeaching: {
    label: 'Taught patient or caregivers about their drugs',
    type: 'percent',
  },
  frequencyFallCheck: {
    label: "Checked patient's risk of falling",
    type: 'percent',
  },
  frequencyDepressionCheck: {
    label: 'Checked patient for depression',
    type: 'percent',
  },
  frequencyFluShot: { label: 'Checked for flu vaccination', type: 'percent' },
  frequencyPneumoniaShot: {
    label: 'Checked for pneumonia shot',
    type: 'percent',
  },
  frequencyDiabetesFootCare: {
    label:
      'Provided diabetes specific care including getting doctors orders, giving foot care, and teatching patients about foot care',
    type: 'percent',
  },
  frequencyPainMoving: {
    label: 'How often patients had less pain moving around',
    type: 'percent',
  },
  frequencyAdmittedToHospital: {
    label: 'How often home health patients had to be admitted to the hospital',
    type: 'percent',
  },
  frequencyUrgentCareNotReadmitted: {
    label:
      'How often patients receiving home health care needed urgent, unplanned care in the ER without being admitted',
    type: 'percent',
  },
  frequencyReadmitted: {
    label:
      'How often home health patients, who have had a recent hospital stay, had to be re-admitted to the hospital',
    type: 'text',
  },
  frequencyHospitalRecentVisitCareNotReadmitted: {
    label:
      'How often home health patients, who have had a recent hospital stay, received care in the hospital emergency room without being re-admitted to the hospital',
    type: 'text',
  },
  frequencyHospitalCareNotReadmitted: {
    label:
      'How often home health patients received care in the hospital emergency room without being re-admitted to the hospital',
    type: 'text',
  },
  improvementWalkingOrMovingAround: {
    label: 'Walking or moving around',
    type: 'percent',
  },
  improvementGettingInAndOutOfBed: {
    label: 'Getting in and out of bed',
    type: 'percent',
  },
  improvementBathing: { label: 'Bathing', type: 'percent' },
  improvementBreathing: { label: 'Breathing', type: 'percent' },
  improvementWounds: {
    label: 'Wounds/healing after an operation',
    type: 'percent',
  },
  improvementMedicationCompliance: {
    label: 'Medication compliance',
    type: 'percent',
  },
};

interface Props {
  medicareReviews: Medicares;
}

const MedicareReviewsList: React.FC<Props> = ({ medicareReviews }) => {
  const listData = Object.keys(medicareListData)
    .map((key) => ({
      label: medicareListData[key].label,
      value:
        key === 'ratingDate'
          ? new Date(medicareReviews[key] ?? '').toLocaleDateString('en-US')
          : medicareReviews[key],
      type: medicareListData[key].type,
    }))
    .filter(
      (item) =>
        item.value !== null &&
        item.value !== undefined &&
        (item.type === 'text' || item.type === 'percent')
    );

  return (
    <HStack>
      <Grid as="ul" templateColumns="1">
        {listData.map(({ label, value, type }) => (
          <Stack direction="row" key={`${label}-${value}`}>
            {
              <Icon
                as={STRING_TO_ICON_CLASS['MdCheck']}
                mr={0}
                boxSize="6"
                height="27px"
              />
            }
            <Text as="span">
              {label}:{' '}
              <strong>{type === 'percent' ? `${value}%` : value}</strong>
            </Text>
          </Stack>
        ))}
      </Grid>
    </HStack>
  );
};

export default MedicareReviewsList;
