import { ArrowDownIcon } from '@chakra-ui/icons';
import { Box, Text } from '@chakra-ui/layout';

type ItemProps = {
  title: string;
  activeHeading: boolean;
};

type ItemButtonProps = {
  linkText: string;
};

export const TableOfContentsItem: React.FC<ItemProps> = ({
  title,
  activeHeading,
}) => {
  return (
    <Box
      bgColor="white"
      borderBottom="4px solid"
      borderColor={activeHeading ? 'blue.400' : 'gray.400'}
      height={12}
      width={228}
      cursor={'pointer'}
    >
      <Text
        fontSize="xs"
        lineHeight="16px"
        color={activeHeading ? 'blue.400' : 'gray.400'}
        noOfLines={2}
      >
        {title.toUpperCase()}
      </Text>
    </Box>
  );
};

export const TableOfContentsButton: React.FC<ItemButtonProps> = ({
  linkText,
}) => (
  <Box
    px={2}
    py={1.5}
    mb="1"
    mt="1"
    border="2px"
    borderRadius="md"
    borderColor="gray.300"
    scrollSnapAlign="start"
  >
    <Text
      fontSize="xs"
      lineHeight="16px"
      fontWeight="bold"
      noOfLines={2}
      color="black"
      display="flex"
      alignItems="center"
    >
      <ArrowDownIcon mr={2} />
      {linkText}
    </Text>
  </Box>
);
