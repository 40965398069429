import { HStack, Icon } from '@chakra-ui/react';
import Heading from '@components/Heading';
import Container from '@components/LayoutStructure/Container';
import { STRING_TO_ICON_CLASS } from '@components/RenderIcon';
import ProviderContext from '~/contexts/Provider';
import { useContext } from 'react';
import MedicareReviewsList from './MedicareReviewsList';
import MedicareReviewsBarChart from './MedicareReviewsBarChart';
import MedicareReviewsLink from './MedicareReviewsLink';
import { mockModMonProvider } from '@mocks/modmon.mock';
import {
  DeviceVisibility,
  useResponsiveDisplay,
} from '@hooks/useResponsiveDisplay';

const MedicareReviews = ({
  title,
  deviceVisibility,
  templateId,
}: {
  title?: string;
  deviceVisibility?: DeviceVisibility;
  templateId?: string;
}) => {
  const inPlasmic = templateId != '' ? mockModMonProvider : null;
  const provider = useContext(ProviderContext)?.provider || inPlasmic;
  const isHidden = useResponsiveDisplay(deviceVisibility);
  if (isHidden) {
    return <></>;
  }
  if (!provider) return null;
  const medicareReviews = provider.services?.find(
    (service) => service?.medicares?.length > 0
  )?.medicares[0];

  if (medicareReviews === undefined) return null;
  return (
    <Container>
      {title ? (
        <Heading
          headingElement="h2"
          title={title}
          withContainer={false}
          mb={{ base: 6, lg: 8 }}
        />
      ) : (
        <HStack paddingBottom={4}>
          <Icon
            as={STRING_TO_ICON_CLASS['MdMedicalServices']}
            boxSize={'24px'}
          />
          <Heading
            headingElement="h3"
            headingSize="md"
            title="Medicare"
            withContainer={false}
          />
        </HStack>
      )}
      <MedicareReviewsBarChart medicareReviews={medicareReviews} />
      <MedicareReviewsLink
        careType={medicareReviews.localResourceType}
        federalProviderNumber={medicareReviews?.federalProviderNumber}
        state={medicareReviews.state}
      />
      <MedicareReviewsList medicareReviews={medicareReviews} />
    </Container>
  );
};

export default MedicareReviews;
