import { Grid, GridItem, Heading, VStack } from '@chakra-ui/layout';
import { PageContext } from '@components/LayoutStructure/EditablePage';
import useInquiryFormSubmitted from '@hooks/use-inquiry-form-submitted';
import useQueryState from '@hooks/use-query-state';
import { AlgoliaService } from '@services/algolia/service';
import isArray from 'lodash/isArray';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { useContext, useEffect, useState } from 'react';
import 'setimmediate';
import { EditableAreaType, Metadata } from 'types/Magnolia';
import { HeadingElements } from '~/@types/heading';
import {
  DEFAULT_HITS_PER_PAGE,
  TWENTY_FIVE_MILES_IN_METERS,
} from '~/constants';
import { useTenantFunctions } from '~/contexts/TenantFunctionsContext';
import { Domain } from '~/types/Domains';
import { getCareTypeFromURL } from '~/utils/getCareTypeFromURL';
import {
  getDefaultKeywords,
  RenderResults,
  updateQueryParamPage,
} from './Search.utils';
import SearchBar from './SearchBar';
import SearchPagination from './SearchPagination';
import SearchResultSkeleton from './SearchResultSkeleton';
import { Provider } from '~/contexts/Provider';

import ShowMore from './ShowMore';
import { getCareTypeLabel } from './utils';
import SearchSeoCarousel from '@components/Search/SearchSeoCarousel';
const GuidedSearchBanner = dynamic(
  () => import('@components/GuidedSearch/GuidedSearchBanner')
);
const ColumnContainer = dynamic(
  () => import('@components/LayoutStructure/ColumnContainer')
);

export enum SearchStates {
  NO_SEARCH_INPUT = 0,
  INPUT_WITH_RESULTS = 1,
  INPUT_WITH_NO_RESULTS = 2,
}

export const SEARCH_KEYWORD_PARAM = 'keyword';
export const PAGE_NUMBER_PARAM = 'listing-page';

enum OrderByOptionsFromMagnolia {
  TEXT_RELEVANCE = 'provider_poc_dev',
  REVIEWS_DESC = 'provider_poc_dev_average_rating_desc',
  REVIEWS_ASC = 'provider_poc_dev_average_rating_asc',
  DISTANCE_FROM_SEARCH = 'provider_poc_dev_geo',
}

// FIXME move this call to ModMon. We should not be calling Algolia from DXP
export const doAlgoliaSearch = async ({
  keyword,
  careType,
  hitsPerPage,
  page,
  orderBy,
  domain = '',
  latitude,
  longitude,
}) => {
  const indexesThatUseDistanceFromIP = [
    OrderByOptionsFromMagnolia.DISTANCE_FROM_SEARCH,
  ];

  const algoliaSettings: any = {
    ...(careType && { tagFilters: [getCareTypeLabel(careType)] }),
    hitsPerPage,
    page: page,
    aroundLatLngViaIP: indexesThatUseDistanceFromIP.includes(orderBy),
  };

  if (latitude && longitude) {
    algoliaSettings.aroundLatLng = `${latitude},${longitude}`;
    algoliaSettings.aroundRadius = TWENTY_FIVE_MILES_IN_METERS;
  }

  const algoliaService = new AlgoliaService(domain as Domain);

  return algoliaService.getProviders(algoliaSettings, keyword, orderBy);
};

export interface SearchComponentDialogProps {
  countOfTiles?: string;
  orderBy?: string;
  state?: string;
  city?: string;
  county?: string;
  latitude?: string;
  longitude?: string;
  title?: string;
  countOfTilesPerRow?: string;
  titleSize: HeadingElements;
  data: SearchData;
  displayGuidedSearchBanner?: boolean;
  guidedSearchInquiryId?: string;
  fullWidthTile?: boolean;
  displayBadges?: boolean;
  displayToggleMap?: boolean;
  hideSearchButton?: boolean;
  displayTotal?: boolean;
  blurCosts?: boolean;
  genericBlock1: EditableAreaType;
  genericBlock2: EditableAreaType;
  metadata: Metadata;
  visibleLimit?: number;
  dontOpenInNewTab: boolean;
}

export interface SearchData {
  displayGeoComparison: Boolean;
  results: Provider[];
  resultCount: number;
  searchState: SearchStates;
}

export interface HandleSearchParams {
  keyword?: string | string[];
  careType?: string | string[];
  countOfTiles?: number;
  page?: number;
  pageNearBy?: number;
  searchIndex?: string;
}

export const getKeywordQueryParams = (keywords) => {
  if (isArray(keywords)) return keywords.join(' ');
  return keywords;
};
const Search: React.FC<SearchComponentDialogProps> = (props) => {
  const {
    orderBy,
    countOfTiles: countOfTilesFromProps,
    state,
    city,
    county,
    latitude,
    longitude,
    title,
    titleSize,
    fullWidthTile = false,
    displayBadges = false,
    displayGuidedSearchBanner = false,
    guidedSearchInquiryId,
    data,
    displayToggleMap,
    hideSearchButton = false,
    displayTotal,
    countOfTilesPerRow,
    blurCosts,
    genericBlock1,
    genericBlock2,
    metadata,
    visibleLimit = 0,
    dontOpenInNewTab,
  } = props;
  // We should fix the catalog or Algolia
  // https://caring.atlassian.net/browse/CME-893
  const alteredCounty = county?.replace('County', '').trim();
  const router = useRouter();
  const { query } = router;
  const queryPage = Number(query[PAGE_NUMBER_PARAM]);
  const queryKeyword =
    getKeywordQueryParams(query[SEARCH_KEYWORD_PARAM]) ?? ('' as string);

  const pageProps = useContext(PageContext);
  const params = pageProps?.context.params as ParsedUrlQuery;

  //  careType should come from props

  const careType = getCareTypeFromURL(params);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [resultCount, setResultCount] = useState<number>(data.resultCount);
  const [results, setResults] = useState<Provider[]>(data.results);
  const [searchState, setSearchState] = useState<SearchStates>(
    data.searchState
  );
  const [currentPage, setCurrentPage] = useState<number>(
    !isNaN(queryPage) ? queryPage - 1 : 0
  );
  const [showMoreLimit, setShowMoreLimit] = useState<number>(
    Number(visibleLimit)
  );
  const inquiryFormHasSubmitted = useInquiryFormSubmitted();
  const showPrice = blurCosts ? inquiryFormHasSubmitted : true;

  const shouldRenderSearchBar = !(state || city || state || alteredCounty);

  const [keyword, setKeyword] = useQueryState(
    'keyword',
    getDefaultKeywords({
      state,
      city,
      county: alteredCounty,
      queryKeyword,
    })
  );
  const [careTypeFromQuery] = useQueryState('careType');
  const { getProviderDetailsPath, getProviderDescription } =
    useTenantFunctions();

  // countOfTiles = DEFAULT_HITS_PER_PAGE ?
  const hitsPerPage = Number(countOfTilesFromProps) || DEFAULT_HITS_PER_PAGE;
  const howManyPages = Math.ceil(resultCount / hitsPerPage);
  const lat = parseFloat(latitude ?? '') || 0;
  const lng = parseFloat(longitude ?? '') || 0;

  const handleSearch = async ({
    keyword,
    careType,
    page = 0,
    pageNearBy = 0,
  }: HandleSearchParams) => {
    setIsLoading(true);

    const data = await doAlgoliaSearch({
      keyword,
      careType,
      hitsPerPage,
      page,
      orderBy,
      latitude: lat,
      longitude: lng,
    });

    // Fallback to the first page if the page number is greater than the total number of pages
    const currentPage = page;
    const totalPages = Math.max(0, data?.nbPages - 1); // pages are zero indexed
    if (currentPage > totalPages) {
      setCurrentPage(0);
      updateQueryParamPage(0, PAGE_NUMBER_PARAM, router);
      handleSearch({
        keyword: keyword as string,
        page: 0,
        careType: careType || (careTypeFromQuery as string),
      });
      return;
    }

    setResults(data?.hits as unknown as Provider[]);
    setResultCount(data?.nbHits);
    setSearchState(
      data?.hits?.length === 0
        ? SearchStates.INPUT_WITH_NO_RESULTS
        : SearchStates.INPUT_WITH_RESULTS
    );
    setIsLoading(false);
  };

  const handlePagination = (newPage: number) => {
    setCurrentPage(newPage);
    updateQueryParamPage(newPage, PAGE_NUMBER_PARAM, router);
    handleSearch({
      keyword: keyword as string,
      page: newPage,
      careType: careType || (careTypeFromQuery as string),
    });
    setImmediate(() => {
      document.getElementById('listings-app')?.scrollIntoView();
    });
  };

  const handleShowMore = (showMoreLimit: number) => {
    setShowMoreLimit(showMoreLimit);
  };

  // should probibly be removed
  useEffect(() => {
    const careTypeParam = isArray(careTypeFromQuery)
      ? careTypeFromQuery.join(',')
      : careTypeFromQuery;
    handleSearch({
      keyword: keyword as string,
      careType: careType || careTypeParam || undefined,
      page: currentPage,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const itemsPerRow = Number(countOfTilesPerRow) * 3 ?? 12;

  return (
    <ColumnContainer id="listings-app" sx={{ scrollMarginTop: '8' }}>
      <SearchSeoCarousel
        results={results ?? []}
        state={state}
        city={city}
        county={county}
        careType={careType as string}
        totalCount={resultCount}
      />
      <VStack alignItems={'left'} spacing="4">
        {title && (
          <Heading as={titleSize} size={{ base: 'xl', md: '2xl' }}>
            {title}
          </Heading>
        )}
        {shouldRenderSearchBar && (
          <SearchBar
            onChange={handleSearch}
            isLoading={isLoading}
            currentPage={currentPage}
            displayToggleMap={displayToggleMap}
            hideSearchButton={hideSearchButton}
            displayTotal={displayTotal}
            totalResults={resultCount}
            searchBarId={metadata?.['@id']}
            enablePredictiveSearch={false}
          />
        )}
        {displayGuidedSearchBanner && (
          <GuidedSearchBanner modalId={guidedSearchInquiryId} />
        )}
        {isLoading ? (
          <Grid
            templateColumns={
              fullWidthTile
                ? '1fr'
                : {
                    base: 'repeat(3, 1fr)',
                    sm: 'repeat(6, 1fr)',
                    md: `repeat(${itemsPerRow}, 1fr)`,
                  }
            }
            gap={6}
          >
            {new Array(6).fill(0).map((value, key) => (
              <GridItem colSpan={3} key={`${key}-${value}`}>
                <SearchResultSkeleton fullWidth={fullWidthTile} />
              </GridItem>
            ))}
          </Grid>
        ) : (
          <RenderResults
            results={results}
            searchState={searchState}
            itemsPerRow={itemsPerRow}
            fullWidthTile={fullWidthTile}
            displayBadges={displayBadges}
            showPrice={showPrice}
            genericBlock1={genericBlock1}
            genericBlock2={genericBlock2}
            metadata={metadata}
            currentPage={currentPage}
            getProviderDetailsPath={getProviderDetailsPath}
            getProviderDescription={getProviderDescription}
            showMoreLimit={howManyPages > 1 ? 0 : showMoreLimit}
            careType={careType || (careTypeFromQuery as string)}
            dontOpenInNewTab={dontOpenInNewTab}
          />
        )}
        {visibleLimit > 0 && howManyPages <= 1 && (
          <ShowMore
            limit={Number(visibleLimit)}
            currentLimit={showMoreLimit}
            setShowMore={handleShowMore}
            resultCount={resultCount}
          />
        )}
        <SearchPagination
          currentPage={currentPage}
          totalPages={howManyPages}
          setPage={handlePagination}
          baseUrl={pageProps?.page.canonical || ''}
          queryParamKey={PAGE_NUMBER_PARAM}
        />
      </VStack>
    </ColumnContainer>
  );
};

export default Search;
