import {
  Card,
  CardHeader,
  CardBody,
  CardBodyProps,
  CardProps,
} from '@chakra-ui/card';
import {
  Heading,
  HeadingProps,
  Link,
  LinkProps,
  List,
  ListItem,
  ListItemProps,
  ListProps,
} from '@chakra-ui/layout';
import { HeadingElements, HeadingSizes } from '~/@types/heading';

interface LinkData {
  text: string;
  url: string;
}

interface CardWithLinksProps {
  // The heading to display above the list of links.
  heading?: string;

  // The HTML element to use for the heading.
  headingElement?: HeadingElements;

  // The size of the heading.
  headingSize?: HeadingSizes;

  // The list of links to display.
  links: Array<LinkData>;

  // Props to pass to the Card component.
  cardProps?: CardProps;

  // Props to pass to the CardBody component.
  cardBodyProps?: CardBodyProps;

  // Props to pass to the heading component.
  headingProps?: HeadingProps;

  // Props to pass to the list component.
  listProps?: ListProps;

  // Props to pass to the list item component.
  listItemProps?: ListItemProps;

  // Props to pass to the link component.
  linkProps?: LinkProps;

  // A function that renders a custom link component.
  renderLink?: (link: LinkData, linkProps: LinkProps) => React.ReactNode;
}

// Renders a card that contains a heading and a list of links.
const CardWithLinks: React.FC<CardWithLinksProps> = ({
  heading,
  headingElement = 'h2',
  headingSize = 'md',
  links = [],
  cardProps = {},
  cardBodyProps = {},
  headingProps = {},
  listProps = {},
  listItemProps = {},
  linkProps = {},
  renderLink,
}) => {
  return (
    <Card bg="white" variant="filled" borderRadius="xl" {...cardProps}>
      {heading && (
        <CardHeader>
          <Heading as={headingElement} size={headingSize} {...headingProps}>
            {heading}
          </Heading>
        </CardHeader>
      )}
      <CardBody {...cardBodyProps}>
        <List spacing="2.5" {...listProps}>
          {links.map(({ text, url }, i) => {
            return (
              <ListItem
                key={`${i}-${url}`}
                display="flex"
                flexDirection="row"
                {...listItemProps}
              >
                {renderLink ? (
                  renderLink({ text, url }, linkProps)
                ) : (
                  <Link
                    href={url}
                    color="primary.700"
                    fontSize="sm"
                    {...linkProps}
                  >
                    {text}
                  </Link>
                )}
              </ListItem>
            );
          })}
        </List>
      </CardBody>
    </Card>
  );
};

export default CardWithLinks;
