import { useState } from 'react';
interface UseSwipeProps {
  onSwipeLeft: () => void;
  onSwipeRight: () => void;
  threshold?: number;
}

export const useSwipe = ({
  onSwipeLeft,
  onSwipeRight,
  threshold = 100,
}: UseSwipeProps) => {
  const [startX, setStartX] = useState(0);

  const handleTouchStart: React.TouchEventHandler<HTMLDivElement> = (e) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchEnd: React.TouchEventHandler<HTMLDivElement> = (e) => {
    const endX = e.changedTouches[0].clientX;
    const distanceX = endX - startX;
    if (distanceX > threshold) {
      onSwipeLeft();
    } else if (distanceX < -threshold) {
      onSwipeRight();
    }
    setStartX(0);
  };

  return {
    handleTouchStart,
    handleTouchEnd,
  };
};
