import { Box, Container, VStack } from '@chakra-ui/react';
import StepImage from '@components/Image/Image';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import withHydrationOnDemand from 'react-hydration-on-demand';
import SiteContext from '~/contexts/SiteContext';
import useClientIP from '~/hooks/useClientIP';
import useMultiStepFormEventTracking from '~/hooks/useMultiStepFormEventsTracking';
import useTracking from '~/hooks/useTracking';
import { Metadata } from '~/types/Magnolia';
import { Color } from '~/utils/getColor';
import {
  getDefaultValues,
  getHiddenValues,
  getPropsToDoNotSubmit,
  getResolver,
  parseValues,
  postMultiStepForm,
} from './MultiStepForm.utils';
import Step from './Step';
import StepPagination from './StepPagination';
import { HiddenField, MagnoliaMultiStepForm, StepConfig } from './types';

export interface MultiStepFormProps {
  data: {
    id?: string;
    pagination: {
      active?: boolean;
      color?: Color;
    };
    steps: StepConfig[];
    hiddenFields: HiddenField[];
  };
  errorMessages: MagnoliaMultiStepForm['errorMessages'];
  formType: MagnoliaMultiStepForm['formType'];
  metadata: Pick<Metadata, '@id'>;
  thankYou: MagnoliaMultiStepForm['thankYou'];
  className?: string;
  inPlasmicEditor?: boolean;
  plasmicEditorStep?: number;
}

export const MultiStepForm: React.FC<MultiStepFormProps> = ({
  data,
  errorMessages = {},
  formType,
  metadata,
  thankYou = {
    path: '/thank-you/',
  },
  className,
  inPlasmicEditor = false,
  plasmicEditorStep = 0,
}) => {
  const {
    errorOnSubmit = 'Something went wrong. Please try again.',
    validationError = 'Please review the previous steps, there is an error on at least one of them.',
  } = errorMessages;

  const clientIP = useClientIP();
  const { anonId, trackingNotes } = useTracking();
  const siteContext = useContext(SiteContext);
  const { formInstanceId, trackStepSubmitted, trackFormSuccessfullySubmitted } =
    useMultiStepFormEventTracking({
      metadata,
      formType,
      stepsConfig: data.steps,
    });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [formError, setFormError] = useState('');

  const defaultValues = useMemo(
    () => getDefaultValues(data.steps),
    [data.steps]
  );
  const hiddenValues = useMemo(
    () => getHiddenValues(data.hiddenFields),
    [data.hiddenFields]
  );
  const doNotSubmitPropNames = useMemo(
    () => getPropsToDoNotSubmit(data.steps, data.hiddenFields),
    [data.hiddenFields, data.steps]
  );
  const resolver = useMemo(() => getResolver(data.steps), [data.steps]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    watch,
    getValues,
    setValue,
  } = useForm({
    mode: 'onBlur',
    resolver,
    defaultValues,
  });

  const onStepChange = useCallback(
    (newStep: number) => {
      window.location.hash = `step-${newStep + 1}`;
      setCurrentStep(newStep);
    },
    [setCurrentStep]
  );

  // The HTML event used to detect back/forward changes (`hashchange`) does not
  // trigger when the hash changes from/to an empty hash, so we need to make sure
  // the page will always start with a hash
  useEffect(() => {
    if (inPlasmicEditor) {
      setCurrentStep(plasmicEditorStep);
      // We need to stop the execution when we are in the Plasmic editor since this will break it.
      return;
    }
    const initialUrl = new URL(document.location.href);
    initialUrl.hash = `step-1`;
    document.location.replace(initialUrl);
  }, [plasmicEditorStep, inPlasmicEditor]);

  useEffect(() => {
    const onHashChanged = () => {
      const match = window.location.hash.match(/^#step-(\d+)$/);
      if (match) {
        const newStep = Math.max(0, Number(match[1]) - 1);
        if (newStep < data.steps.length) {
          setCurrentStep(newStep);
        }
      }
    };

    window.addEventListener('hashchange', onHashChanged);
    return () => {
      window.removeEventListener('hashchange', onHashChanged);
    };
  }, [data.steps.length, onStepChange]);

  const onSubmit = handleSubmit(async (data) => {
    setIsSubmitting(true);

    const parsedHiddenValues = parseValues({
      originalValues: hiddenValues,
      values: {
        form: data,
        client: {
          ip: clientIP || '0.0.0.0',
          browser: navigator.userAgent,
          formInstanceId,
          anonId,
          trackingNotes,
        },
      },
    });

    const allData = {
      ...parsedHiddenValues,
      ...data,
    };

    const filteredData = { ...allData };
    for (let i = 0; i < doNotSubmitPropNames.length; i++) {
      const doNotSubmitProp = doNotSubmitPropNames[i];
      delete filteredData[doNotSubmitProp];
    }

    const domain = siteContext.site?.path || '';
    const response = await postMultiStepForm({
      ...filteredData,
      formId: metadata['@id'],
      path: document.location.pathname,
      domain,
    });

    if (response.success) {
      trackFormSuccessfullySubmitted(filteredData);
      setFormError('');
      if (thankYou.path) {
        window.location.assign(
          `${thankYou.path}?${new URLSearchParams(allData)}`
        );
      }
    } else {
      setFormError(errorOnSubmit);
      setIsSubmitting(false);
    }
  });

  return (
    <>
      <Container maxWidth="container.xl" className={className}>
        <VStack pb={16}>
          {data.steps.map((step, index) => (
            <Step
              key={index}
              headline={step.headline}
              subHeadline={step.subHeadline}
              active={index === currentStep}
              watchFormValues={watch}
              testId={`step-${index + 1}`}
              errors={errors}
              formError={formError}
              showErrorsOfOtherSteps={index === data.steps.length - 1}
              errorMessageOnValidationError={validationError}
              form={step.form}
              register={register}
              setValue={setValue}
              trigger={trigger}
              isSubmitting={isSubmitting}
              onSubmit={() => {
                if (Object.keys(errors).length > 0) {
                  return;
                }

                trackStepSubmitted(currentStep, getValues);
                if (currentStep === data.steps.length - 1) {
                  onSubmit();
                } else {
                  onStepChange(currentStep + 1);
                }
              }}
            />
          ))}
          {data.pagination.active && (
            <StepPagination
              currentStep={currentStep}
              totalSteps={data.steps.length}
              setCurrentStep={onStepChange}
              color={data.pagination.color}
            />
          )}
        </VStack>
      </Container>
      {data.steps.map(
        (step, index) =>
          step.image && (
            <Box
              key={index}
              display={index === currentStep ? 'block' : 'none'}
              as="div"
            >
              <StepImage {...step.image} />
            </Box>
          )
      )}
    </>
  );
};

export default withHydrationOnDemand({ on: ['visible'] })(MultiStepForm);
