import {
  locationComparisonViewed,
  locationComparisonViewedFields,
} from './eventContracts';
import {
  SessionContextType,
  useSessionContext,
} from '~/contexts/SessionContext';
import segmentEvents from '~/config/segment-events';

interface FormSubmissionType {
  sessionContext: SessionContextType;
  data: locationComparisonViewedFields[];
}

const locationComparisonView = ({
  sessionContext,
  data,
}: FormSubmissionType) => {
  const LocationsViewed: locationComparisonViewed = {
    locations: JSON.stringify(data),
    page_session_id: sessionContext.pageSessionId,
    session_id: sessionContext.sessionId,
  };
  window.tracking.track(
    segmentEvents.LOCATION_COMPARISON_VIEWED,
    LocationsViewed
  );
};

const useLocationComparisonViewed = () => {
  const sessionContext = useSessionContext();
  return (data: locationComparisonViewedFields[]) => {
    return locationComparisonView({ sessionContext, data });
  };
};

export default useLocationComparisonViewed;
