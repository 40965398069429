export const templateId = '816f9588-143c-49bc-9950-c553f8b481d4';

export const themeSpacingDescription =
  'Follows ChakraUI Spacing <a href="https://chakra-ui.com/docs/styled-system/theme#spacing" target="_blank">https://chakra-ui.com/docs/styled-system/theme#spacing</a>';

export const selectColor = [
  'primary',
  'secondary',
  'tertiary',
  'accent',
  'background',
  'success',
  'error',
  'info',
  'link',
  'graphic',
  'blue',
  'orange',
  'green',
  'green',
  'red',
  'yellow',
  'purple',
  'cyan',
  'teal',
  'gray',
  'white',
  'black',
];

export const selectColorRange = [
  '50',
  '100',
  '200',
  '300',
  '400',
  '500',
  '600',
  '700',
  '800',
  '900',
];

export const selectIcon = [
  { value: 'MdAccessible', label: 'Accessible' },
  { value: 'MdAddShoppingCart', label: 'Add Shopping Cart' },
  { value: 'MdAirplanemodeActive', label: 'Airplanemode Active' },
  { value: 'MdAirplanemodeActive', label: 'Airplanemode Active' },
  { value: 'MdArrowForward', label: 'Arrow Forward' },
  { value: 'MdBuild', label: 'Build' },
  { value: 'MdCardTravel', label: 'Card Travel' },
  { value: 'MdChat', label: 'Chat' },
  { value: 'MdChatBubble', label: 'Chat Bubble' },
  { value: 'MdDirectionsBike', label: 'Directions Bike' },
  { value: 'MdDirectionsBus', label: 'Directions Bus' },
  { value: 'MdDirectionsCarFilled', label: 'Directions Car Filled' },
  { value: 'MdDirectionsSubway', label: 'Directions Subway' },
  { value: 'MdDirectionsWalk', label: 'Directions Walk' },
  { value: 'MdEventSeat', label: 'Event Seat' },
  { value: 'MdFace', label: 'Face' },
  { value: 'FaFacebookF', label: 'Facebook' },
  { value: 'MdFavorite', label: 'Favorite' },
  { value: 'MdHail', label: 'Hail' },
  { value: 'MdLightbulb', label: 'Lightbulb' },
  { value: 'FaLinkedinIn', label: 'LinkedIn' },
  { value: 'MdLocalBar', label: 'Local Bar' },
  { value: 'MdLocalCafe', label: 'Local Cafe' },
  { value: 'MdLocalDining', label: 'Local Dining' },
  { value: 'MdLocalFlorist', label: 'Local Florist' },
  { value: 'MdLocalGasStation', label: 'Local Gas Station' },
  { value: 'MdLocalHospital', label: 'Local Hospital' },
  { value: 'MdLocalHotel', label: 'Local Hotel' },
  { value: 'MdLocalParking', label: 'Local Parking' },
  { value: 'MdLogin', label: 'Login' },
  { value: 'MdMovie', label: 'Movie' },
  { value: 'MdOutlinedFlag', label: 'Outlined Flag' },
  { value: 'MdOpenInNew', label: 'Open in New' },
  { value: 'MdPark', label: 'Park' },
  { value: 'MdPets', label: 'Pets' },
  { value: 'MdPhone', label: 'Phone' },
  { value: 'MdPushPin', label: 'Push Pin' },
  { value: 'MdSavings', label: 'Savings' },
  { value: 'MdSearch', label: 'Search' },
  { value: 'MdShield', label: 'Shield' },
  { value: 'MdTheaterComedy', label: 'Theater Comedy' },
  { value: 'FaTwitter', label: 'Twitter' },
  { value: 'MdVolunteerActivism', label: 'Volunteer Activism' },
  { value: 'MdWaves', label: 'Waves' },
  { value: 'MdWeekend', label: 'Weekend' },
  { value: 'MdWheelchairPickup', label: 'WheelchairPickup' },
  { value: 'MdAssignmentTurnedIn', label: 'AssignmentTurnedIn' },
  { value: 'MdOtherHouses', label: 'Other Houses' },
  { value: 'MdMedication', label: 'Medication' },
  { value: 'MdWbTwilight', label: 'WbTwilight' },
  { value: 'MdChecklistRtl', label: 'ChecklistRtl' },
  { value: 'MdEmojiPeople', label: 'EmojiPeople' },
  { value: 'MdWc', label: 'MdWc' },
  { value: 'MdOutlineAttachMoney', label: 'MdOutlineAttachMoney' },
  { value: 'MdOutlineBed', label: 'MdOutlineBed' },
  { value: 'MdCreditCard', label: 'MdCreditCard' },
];

const baseCtaProps = {
  state: {
    type: 'choice',
    displayName: 'CTA State',
    options: [
      { value: 'solid', label: 'Solid' },
      { value: 'outline', label: 'Outline' },
      { value: 'ghost', label: 'Ghost' },
    ],
    defaultValue: 'solid',
  },
  alignment: {
    type: 'choice',
    displayName: 'Button Alignment',
    options: [
      { value: 'left', label: 'Left' },
      { value: 'center', label: 'Center' },
      { value: 'right', label: 'Right' },
    ],
    defaultValue: 'left',
  },
  width: {
    type: 'choice',
    displayName: 'Button Width',
    options: [
      { value: 'fit-content', label: 'Fit Content' },
      { value: 'xs', label: 'XS' },
      { value: 'sm', label: 'SM' },
      { value: 'md', label: 'MD' },
      { value: 'lg', label: 'LG' },
      { value: 'xl', label: 'XL' },
      { value: '100%', label: '100%' },
    ],
    defaultValue: 'fit-content',
  },
  size: {
    type: 'choice',
    displayName: 'Button Size',
    options: [
      { value: 'xs', label: 'XS' },
      { value: 'sm', label: 'SM' },
      { value: 'md', label: 'MD' },
      { value: 'lg', label: 'LG' },
      { value: 'xl', label: 'XL' },
    ],
    defaultValue: 'lg',
  },
  text: 'string',
  icon: {
    displayName: 'Icon',
    type: 'choice',
    options: selectIcon,
  },
  bgColor: {
    displayName: 'Background Color',
    type: 'choice',
    options: selectColor,
    defaultValue: 'primary',
  },
  textColor: {
    displayName: 'Text Color',
    type: 'choice',
    options: selectColor,
    defaultValue: 'white',
  },
  ignoreMaxWidth: {
    type: 'boolean',
    defaultValue: 'true',
    description: 'Ignore container max width',
  },
};

export const newCtaProps = (propName?: string) => {
  return {
    ...baseCtaProps,
    actionBehavior: {
      type: 'object',
      fields: {
        field: {
          type: 'choice',
          displayName: 'CTA Action Behavior',
          options: [
            {
              value: 'openInquiry',
              label: 'Open an inquiry form within a modal',
            },
            { value: 'openLink', label: 'Follow URL' },
            { value: 'openReviewModal', label: 'Open Review Modal' },
          ],
          defaultValue: 'openInquiry',
        },
        inquiryId: {
          type: 'string',
          displayName: 'Inquiry Form ID',
          hidden: (props) =>
            !propName
              ? props.actionBehavior.field !== 'openInquiry'
              : props[propName].actionBehavior.field !== 'openInquiry',
          description: 'Inquiry Form that will be opened in a modal.',
        },
        url: {
          type: 'string',
          hidden: (props) =>
            !propName
              ? props.actionBehavior.field !== 'openLink'
              : props[propName].actionBehavior.field !== 'openLink',
        },
        behavior: {
          type: 'choice',
          options: [
            { value: '_blank', label: 'Open in a new tab' },
            {
              value: '_self',
              label: 'Open in the same frame as it was clicked',
            },
            { value: '_top', label: 'Open in the full body of the window' },
          ],
          hidden: (props) =>
            !propName
              ? props.actionBehavior.field !== 'openLink'
              : props[propName].actionBehavior.field != 'openLink',
        },
        rel: {
          type: 'choice',
          options: ['external', 'nofollow', 'noopener', 'noreferrer', 'opener'],
          multiSelect: true,
          hidden: (props) =>
            !propName
              ? props.actionBehavior.field !== 'openLink'
              : props[propName].actionBehavior.field !== 'openLink',
        },
      },
    },
    type: {
      type: 'choice',
      options: [
        { value: 'tel', label: 'Phone' },
        { value: 'mailto', label: 'E-mail' },
        { value: 'sms', label: 'Text message' },
        { value: '', label: 'None above' },
      ],
      hidden: (props) =>
        !propName
          ? props.actionBehavior.field !== 'openLink'
          : props[propName].actionBehavior.field !== 'openLink',
    },
  } as any;
};

export const legacyCtaProps = {
  ...baseCtaProps,
  isInquiry: {
    type: 'boolean',
    displayName: 'Open an inquiry form within a modal',
    defaultValue: true,
  },
  inquiryId: {
    type: 'string',
    displayName: 'Inquiry Form ID',
    description: 'Inquiry Form that will be opened in a modal.',
    hidden: (props) => !props.isInquiry,
  },
  url: {
    type: 'string',
    hidden: (props) => props.isInquiry,
  },
  behavior: {
    type: 'choice',
    options: [
      { value: '_blank', label: 'Open in a new tab' },
      { value: '_self', label: 'Open in the same frame as it was clicked' },
      { value: '_parent', label: 'Open in the parent frame' },
      { value: '_top', label: 'Open in the full body of the window' },
    ],
    hidden: (props) => props.isInquiry,
  },
  rel: {
    type: 'choice',
    options: ['external', 'nofollow', 'noopener', 'noreferrer', 'opener'],
    multiSelect: true,
    hidden: (props) => props.isInquiry,
  },
  type: {
    type: 'choice',
    options: [
      { value: 'tel', label: 'Phone' },
      { value: 'mailto', label: 'E-mail' },
      { value: 'sms', label: 'Text message' },
      { value: '', label: 'None above' },
    ],
    hidden: (props) => props.isInquiry,
  },
} as any;

export const chakraSize = [
  { value: 0, label: '0' },
  { value: '1px', label: '1px' },
  { value: 0.5, label: '0.125rem' },
  { value: 1, label: '0.25rem' },
  { value: 1.5, label: '0.375rem' },
  { value: 2, label: '0.5rem' },
  { value: 2.5, label: '0.625rem' },
  { value: 3, label: '0.75rem' },
  { value: 3.5, label: '0.875rem' },
  { value: 4, label: '1rem' },
  { value: 5, label: '1.25rem' },
  { value: 6, label: '1.5rem' },
  { value: 7, label: '1.75rem' },
  { value: 8, label: '2rem' },
  { value: 9, label: '2.25rem' },
  { value: 10, label: '2.5rem' },
  { value: 12, label: '3rem' },
  { value: 14, label: '3.5rem' },
  { value: 16, label: '4rem' },
  { value: 20, label: '5rem' },
  { value: 24, label: '6rem' },
  { value: 28, label: '7rem' },
  { value: 32, label: '8rem' },
  { value: 36, label: '9rem' },
  { value: 40, label: '10rem' },
  { value: 44, label: '11rem' },
  { value: 48, label: '12rem' },
  { value: 52, label: '13rem' },
  { value: 56, label: '14rem' },
  { value: 60, label: '15rem' },
  { value: 64, label: '16rem' },
  { value: 72, label: '18rem' },
  { value: 80, label: '20rem' },
  { value: 96, label: '24rem' },
];

export const deviceVisibilityProps = {
  type: 'choice',
  defaultValue: '',
  options: [
    { value: '', label: 'All devices' },
    { value: 'mobile', label: 'Mobile only' },
    { value: 'desktop', label: 'Desktop only' },
  ],
} as any;

export const baseInquiryFormProps = {
  title: {
    type: 'string',
    defaultValue: 'Get costs',
  },
  rollUpType: {
    displayName: 'Rollup Type',
    type: 'choice',
    options: [
      { value: 'senior-care', label: 'Senior Care' },
      { value: 'senior-living', label: 'Senior Living' },
    ],
  },
  relationshipLabel: {
    displayName: 'Relationship Label',
    type: 'string',
    defaultValue: 'Who are you looking for?',
  },
  nameLabel: {
    displayName: 'Name Label',
    type: 'string',
    defaultValue: 'Full Name',
  },
  phoneLabel: {
    displayName: 'Phone Label',
    type: 'string',
    defaultValue: 'Phone',
  },
  emailLabel: {
    displayName: 'Email Label',
    type: 'string',
    defaultValue: 'Email',
  },
  ctaText: {
    type: 'string',
  },
  ctaColorScheme: {
    type: 'choice',
    options: selectColor,
  },
  ctaAction: {
    displayName: 'CTA Action',
    type: 'choice',
    options: ['request-info', 'display-cost-data'],
  },
  thankYouMessage: {
    displayName: 'Thank you message',
    type: 'string',
  },
  legalDisclosure: {
    displayName: 'Legal disclosure',
    type: 'string',
  },
  bgColor: {
    displayName: 'Background Color',
    type: 'choice',
    options: selectColor,
    defaultValue: 'graphic',
  },
  bgColorRange: {
    displayName: 'Background Color Range',
    type: 'choice',
    options: selectColorRange,
    defaultValue: '50',
  },
  textColor: {
    displayName: 'Text Color',
    type: 'choice',
    options: selectColor,
  },
  textColorRange: {
    displayName: 'Text Color Range',
    type: 'choice',
    options: selectColorRange,
  },
  linkColor: {
    displayName: 'Disclaimer Link Color',
    type: 'choice',
    options: selectColor,
  },
  linkColorRange: {
    displayName: 'Disclaimer Link Color Range',
    type: 'choice',
    options: selectColorRange,
  },
  bgBorderRadius: {
    displayName: 'Border radius',
    type: 'string',
    defaultValue: '10',
  },
  marginBottom: {
    displayName: 'Margin Bottom',
    type: 'string',
  },
  showPayingWithMedicaid: {
    displayName: 'Show "Paying with Medicaid" checkbox',
    type: 'boolean',
    defaultValue: true,
  },
} as any;

export const inquiryFormProps = {
  formId: {
    displayName: 'ID',
    type: 'string',
    description:
      'This ID is used to identify the inquiry form in the page. It must be unique.',
    required: true,
  },
  visibility: deviceVisibilityProps,
  display: {
    type: 'choice',
    options: [
      { value: 'vertical', label: 'Vertical' },
      { value: 'horizontal', label: 'Horizontal' },
      { value: 'full-page-modal', label: 'Full Page Modal' },
      { value: 'fit-content-modal', label: 'Fit to Content Modal' },
    ],
  },
  templateId: {
    type: 'string',
    defaultValue: templateId,
  },
  ...baseInquiryFormProps,
  legalDisclosure: {
    displayName: 'Legal disclosure',
    type: 'string',
  },
} as any;
