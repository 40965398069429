import { Grid } from '@chakra-ui/react';
import ProgressChart from '@components/Charts/ProgressChart';
import { formatRating } from '@utils/number';
import { Medicares } from '~/contexts/Provider';

interface Props {
  medicareReviews: Medicares;
}

const medicareBarData = {
  healthInspectionRating: { label: 'Health' },
  overallRating: { label: 'Overall' },
  qmRating: { label: 'Quality' },
  staffingRating: { label: 'Staff' },
};

const MedicareReviewsBarChart: React.FC<Props> = ({ medicareReviews }) => {
  const barData = Object.keys(medicareBarData)
    .map((key) => ({
      label: medicareBarData[key].label,
      value: medicareReviews[key],
    }))
    .filter((item) => item.value !== null);

  return (
    <Grid
      as="ul"
      templateColumns="1"
      rowGap={4}
      columnGap={10}
      paddingBottom={4}
    >
      {barData.map(({ label, value }) => (
        <ProgressChart
          as="li"
          key={`${value}-${label}`}
          id={`progress-label-${label}`}
          ariaLabel={`${String(
            formatRating(value)
          )} medicare rating for ${label}`}
          label={label}
          value={(100 * (value - 0)) / (5 - 0)}
          displayValue={String(formatRating(value))}
          color={'black'}
        />
      ))}
    </Grid>
  );
};

export default MedicareReviewsBarChart;
