import { ModularMonolithClient } from '@services/modular-monolith/client';
import {
  GetProvidersFilters,
  GetProvidersResponse,
} from '@services/modular-monolith/types/search.type';
import inBrowser from '@utils/inBrowser';
import { Domain } from '~/types/Domains';

interface GetProvidersParams {
  domain?: Domain;
  filters: GetProvidersFilters;
  apiVersion: string;
}

export async function getProviders({
  domain,
  filters,
  apiVersion,
}: GetProvidersParams): Promise<GetProvidersResponse> {
  if (inBrowser()) {
    try {
      const response = await fetch('/api/enhanced-search', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(filters),
      });

      if (!response.ok) {
        throw new Error('getProviders: Server error.');
      }

      return await response.json();
    } catch (error) {
      console.error('getProviders: Error getting data.', error);
      return {
        listId: '',
        queryId: '',
        totalRegionItems: 0,
        totalNearbyItems: 0,
        totalItems: 0,
        totalPages: 0,
        results: [],
      };
    }
  }

  const modMonClient = new ModularMonolithClient(domain);
  const paginatedProviders = await modMonClient.getProviders(filters, {
    apiVersion,
  });

  return paginatedProviders;
}
