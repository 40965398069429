import {
  getCustomHeadings,
  PageContext,
} from '@components/LayoutStructure/EditablePage';
import { useContext } from 'react';
import { Text } from '@chakra-ui/layout';
import { createID } from '@utils/strings';
import { TableOfContentsButton } from '@components/TableOfContents/TableOfContentsItem';
import Container from '@components/LayoutStructure/Container';
import useElementClicked, {
  ElementActions,
  ElementNames,
  ElementTypes,
} from '@components/Analytics/events/ElementClicked';
import StoryContext from '~/contexts/StoryContext';
import useTranslation from '@hooks/use-translation';
import SiteContext from '~/contexts/SiteContext';
import { Domain } from '~/types/Domains';
import ProviderContext from '~/contexts/Provider';
import {
  DeviceVisibility,
  useResponsiveDisplay,
} from '@hooks/useResponsiveDisplay';

interface CustomTableOfContentsProps {
  title?: string;
  deviceVisibility?: DeviceVisibility;
}

const CustomTableOfContents: React.FC<CustomTableOfContentsProps> = ({
  title = 'Jump to:',
  deviceVisibility,
}) => {
  const { provider } = useContext(ProviderContext) || {};
  const status = 2;
  const elementClicked = useElementClicked();
  const pageContext = useContext(PageContext);
  const storyContext = useContext(StoryContext);
  const siteContext = useContext(SiteContext);
  const domain = siteContext.site?.path as Domain;
  const { t } = useTranslation();

  const isHidden = useResponsiveDisplay(deviceVisibility);
  if (isHidden) {
    return <></>;
  }

  const pageHeadings = getCustomHeadings(
    pageContext,
    storyContext,
    provider ?? null,
    status,
    domain,
    t
  );

  return (
    <Container
      gap={3}
      my={4}
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      scrollPadding="16px"
      backgroundColor="white"
      scrollSnapType="x mandatory"
      noHorizontalPadding={true}
    >
      <Text
        as="p"
        fontSize="sm"
        fontWeight="bold"
        width={{ base: '100%', md: 'auto' }}
      >
        {title}
      </Text>
      {pageHeadings?.map(({ title, linkText }, index) => (
        <a
          onClick={() => {
            elementClicked({
              element: {
                type: ElementTypes.LINK,
                action: ElementActions.JUMP_LINK,
                name: ElementNames.TABLE_OF_CONTENTS_BUTTON,
                text: linkText,
                color: 'white',
                textColor: 'black',
              },
              destinationUrl: `#${createID(title)}`,
            });
          }}
          href={`#${createID(title)}`}
          key={`${title}-${index}`}
        >
          <TableOfContentsButton linkText={linkText} />
        </a>
      ))}
    </Container>
  );
};

export default CustomTableOfContents;
