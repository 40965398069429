import { ButtonProps, IconButtonProps } from '@chakra-ui/react';

export const getStylesForDisabledNavigationButton = (
  domain: string
): Omit<IconButtonProps, 'aria-label'> => {
  switch (domain) {
    case 'seniorhomes.com':
      return {
        background: 'primary.300',
        _hover: { _disabled: { background: 'primary.300' } },
        color: 'primary.100',
      };
    case 'caring.com':
      return {
        background: '#9FAFBC',
        _hover: { _disabled: { background: '#9FAFBC' } },
        color: 'gray.50',
      };
    default:
      return {};
  }
};

export const getStylesForEnabledNavigationButton = (
  domain: string
): Omit<IconButtonProps, 'aria-label'> => {
  switch (domain) {
    case 'seniorhomes.com':
      return {
        background: 'white',
        color: 'gray.800',
        borderColor: 'primary.100',
        borderWidth: '1px',
      };
    case 'caring.com':
      return {
        background: 'white',
        color: 'gray.800',
        borderColor: 'gray.50',
        borderWidth: '1px',
      };
    default:
      return {};
  }
};

export const getStylesForPageButton = (domain: string): ButtonProps => {
  switch (domain) {
    case 'seniorhomes.com':
      return {
        background: 'white',
        _hover: { background: 'gray.200' },
        color: 'primary.800',
        borderColor: 'primary.100',
        borderWidth: '1px',
      };
    case 'caring.com':
      return {
        background: 'white',
        _hover: { background: 'gray.200' },
        color: 'gray.800',
        borderColor: 'gray.50',
        borderWidth: '1px',
      };
    default:
      return {};
  }
};

export const getStylesForActivePageButton = (domain: string): ButtonProps => {
  switch (domain) {
    case 'seniorhomes.com':
      return {
        background: 'link.50',
        _hover: { background: 'link.100' },
        color: 'link.900',
        borderColor: 'link.900',
        borderWidth: '1px',
      };
    case 'caring.com':
      return {
        background: '#E6F5FF',
        _hover: { background: '#B8E2FF' },
        color: '#001E33',
        borderColor: '#001E33',
        borderWidth: '1px',
      };
    default:
      return {};
  }
};
