import { Button, PropsOf, Text } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Resolver } from 'react-hook-form';
import { z as zod, ZodType } from 'zod';
import { maskToRegex } from '~/hooks/use-react-input-mask';
import { getColor } from '~/utils/getColor';
import { Parser } from '~/utils/parser';
import { ParserProps } from '~/utils/parser/index.types';
import { getSearchParamsFromWindow } from '~/utils/url';
import { getUTMParameters } from '~/utils/utmParameters';
import {
  FieldConfig,
  HiddenField,
  MagnoliaFormConfig,
  MagnoliaTextWithSizeAndStyle,
  StepConfig,
} from './types';

export const getDefaultValues = (steps: StepConfig[]): Record<string, any> => {
  const fields = steps.map((step) => step.form.fields).flat();
  const defaultValues = Object.fromEntries(
    fields
      .filter((field) => field.defaultValue !== undefined)
      .map((field) => [field.name, field.defaultValue])
  );

  return defaultValues;
};

export const getHiddenValues = (
  fields: HiddenField[]
): Record<string, string> => {
  const defaultValues = Object.fromEntries(
    fields.map((field) => [field.name, field.defaultValue])
  );

  return defaultValues;
};

export const getPropsToDoNotSubmit = (
  steps: StepConfig[],
  hiddenFields: HiddenField[]
): string[] => {
  const visibleFields = steps.map((step) => step.form.fields).flat();
  const doNotSubmitProps = [...visibleFields, ...hiddenFields]
    .filter(({ doNotSubmit }) => !!doNotSubmit)
    .map(({ name }) => name);

  return doNotSubmitProps;
};

export const parseValues = ({
  originalValues,
  values,
}: {
  originalValues: Record<string, string>;
  values: ParserProps['values'];
}): Record<string, string> => {
  const utmParams = getUTMParameters();
  const searchParams = Object.fromEntries(getSearchParamsFromWindow());
  const vars = {
    ...values,
    url: {
      ...utmParams,
      ...searchParams,
    },
  };

  const parsedEntries = Object.keys(originalValues).map((key) => {
    const value = Parser({
      source: originalValues[key],
      values: vars,
      strip: true,
    });
    return [key, value];
  });
  return Object.fromEntries(parsedEntries);
};

export const getResolver = (steps: StepConfig[]): Resolver => {
  const fields = steps.map((step) => step.form.fields).flat();

  const fieldsSchemas = Object.fromEntries(
    fields.map((field) => [field.name, getSchemaForField(field)])
  );
  const FormSchema = zod.object(fieldsSchemas);

  return zodResolver(FormSchema);
};

const PHONE_NUMBER_MASK = '(999) 999-9999';
const getSchemaForField = (field: FieldConfig): ZodType => {
  let schema = zod.string({ required_error: field.isRequiredErrorMessage });

  switch (field.type) {
    case 'input': {
      schema = schema.max(50, 'Value is too long');
      // Add type-specific validations
      switch (field.inputType) {
        case 'email':
          schema = schema.email(field.typeErrorMessage);
          break;
        case 'phone':
          schema = schema.regex(
            maskToRegex(field.typeMask || PHONE_NUMBER_MASK),
            field.typeErrorMessage
          );
          break;
      }
      if (field.isRequired) {
        return schema.min(1, field.isRequiredErrorMessage);
      } else {
        // Allow optional entries with specific formats (like email and phone number)
        // to accept empty values
        return schema.optional().or(zod.literal(''));
      }
    }
    case 'select':
      if (field.isRequired) {
        return schema.min(1, field.isRequiredErrorMessage);
      } else {
        return schema.optional();
      }
  }
};

const defaultTextFormat: Omit<
  MagnoliaTextWithSizeAndStyle['textFormatting'],
  'field'
> = {
  sizeOnMobile: 'md',
  sizeOnDesktop: 'lg',
};

export const getTextElementProps = (
  textFormatting?: MagnoliaTextWithSizeAndStyle['textFormatting']
): PropsOf<typeof Text> => {
  const { color, element, sizeOnDesktop, sizeOnMobile, styles } =
    textFormatting?.field === 'custom' ? textFormatting : defaultTextFormat;

  const textStyles: PropsOf<typeof Text> = {};
  if (element) {
    textStyles.as = element;
  }
  if (color) {
    textStyles.color = getColor(color.color, color.colorRange);
  }
  if (sizeOnMobile && sizeOnDesktop) {
    textStyles.fontSize = { base: sizeOnMobile, md: sizeOnDesktop };
  }
  if (styles?.italic) {
    textStyles.fontStyle = 'italic';
  }
  if (styles?.bold) {
    textStyles.fontWeight = 700;
  }

  return textStyles;
};

const defaultCtaFormat: Omit<MagnoliaFormConfig['cta']['formatting'], 'field'> =
  {
    color: 'secondary',
  };

export const getCtaButtonProps = (
  ctaFormatting?: MagnoliaFormConfig['cta']['formatting']
): PropsOf<typeof Button> => {
  const { color, size, variant } =
    ctaFormatting?.field === 'custom' ? ctaFormatting : defaultCtaFormat;

  const ctaStyles: PropsOf<typeof Button> = {};
  if (color) {
    ctaStyles.colorScheme = color;
  }
  if (size) {
    ctaStyles.size = size;
  }
  if (variant) {
    ctaStyles.variant = variant;
  }

  return ctaStyles;
};

export const postMultiStepForm = async (payload: {
  [key: string]: any;
  formId: string;
  path: string;
  domain: string;
}): Promise<{
  success: boolean;
  error?: string;
}> => {
  try {
    const response = await fetch('/api/multi-step-form', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      return { success: true };
    }

    const responseBody = await response.json();

    return {
      success: false,
      error: responseBody.message,
    };
  } catch (error) {
    return {
      success: false,
      error: 'An error occurred while processing the request',
    };
  }
};
