import React, { Fragment, useEffect, useState } from 'react';
import { Box, Text } from '@chakra-ui/layout';
import { Provider } from '~/contexts/Provider';
import {
  Stack,
  IconButton,
  Button as ChakraButton,
  Modal,
  ModalContent,
  ModalOverlay,
  VStack,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  AccordionIcon,
  useBreakpointValue,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
} from '@chakra-ui/react';
import { MdCancel, MdIosShare } from 'react-icons/md';
import Button from '@components/Button';
import {
  ElementActions,
  ElementNames,
  ElementTypes,
} from '@components/Analytics/events/ElementClicked';
import styled from '@emotion/styled';
import CommunityComparisonContainer, {
  CommunityComparisonContainerProps,
} from './CommunityComparisonContainer';
import { NextRouter } from 'next/router';
import { handleShare } from '@utils/share';
import { useToast } from '@chakra-ui/toast';
import { updateQueryParams } from '@components/Search/Search.utils';
import useLocationComparisonViewed from '@components/Analytics/events/LocationComparisonViewed';

export interface Community extends Provider {
  index: number;
}

export interface CommunityComparisonBannerProps
  extends CommunityComparisonContainerProps {
  providers: Community[];
  providerTitleColor?: string;
  providerTitleColorRange?: string;
  displayBadges?: boolean;
  displayViewCommunityButton?: boolean;
  viewCommunityButtonText?: string;
  displayRequestInfoButton?: boolean;
  requestInfoButtonText?: string;
  inquiryId?: string;
  viewCommunityButtonColorScheme?: string;
  ratingStarsColor?: string;
  ratingStarsColorRange?: string;
  openCompareModal: boolean;
  router: NextRouter;
  onCancel: (provider: Community) => void;
}

const AccordionContainer = styled(Box)`
  flexdirection: row;
  .chakra-collapse {
    width: 100%;
  }
`;

export const ComparisonErrorDialog = ({ isOpen, onClose }) => (
  <Modal isOpen={isOpen} onClose={onClose} size="sm">
    <ModalOverlay />
    <ModalContent paddingTop="48px" paddingX="24px" paddingBottom="24px">
      <VStack alignItems="center">
        <Text fontWeight={700} fontSize={24}>
          Too many selections
        </Text>
        <Text fontWeight={400} fontSize="sm" maxW={200} align="center">
          You can only compare 4 communities at a time.
        </Text>
        <Box>
          <ChakraButton onClick={onClose} colorScheme="primary" mt={4}>
            Continue
          </ChakraButton>
        </Box>
      </VStack>
    </ModalContent>
  </Modal>
);

export const CommunityComparisonModal = ({
  isOpen,
  onClose,
  providers,
  modalId = '',
  queryId = '',
  listId = '',
  onClickShare,
  ratingStarsColor,
  providerTitleColor,
  sliderSettings = {},
  displayBadges = false,
  ratingStarsColorRange,
  providerTitleColorRange,
  viewCommunityButtonText,
  displayRequestInfoButton,
  displayViewCommunityButton,
  viewCommunityButtonColorScheme,
}) => (
  <Modal isOpen={isOpen} onClose={onClose} size="lg">
    <ModalOverlay />
    <ModalContent
      margin="auto"
      maxW={1600}
      padding={{ base: '24px, 32px', lg: '24px 96px' }}
    >
      <ModalHeader>
        <Box
          display="flex"
          alignItems="center"
          onClick={onClickShare}
          _hover={{ cursor: 'pointer' }}
        >
          <MdIosShare size="24px" />
          <Text
            ml={2}
            textDecoration="underline"
            fontSize="sm"
            fontWeight="400"
            color="gray.700"
          >
            Share Results
          </Text>
        </Box>
      </ModalHeader>
      <ModalCloseButton onClick={onClose} />
      <ModalBody>
        <CommunityComparisonContainer
          listId={listId}
          modalId={modalId}
          queryId={queryId}
          providers={providers}
          displayBadges={displayBadges}
          displayCurrentProvider={false}
          ratingStarsColor={ratingStarsColor}
          customSliderSettings={sliderSettings}
          providerTitleColor={providerTitleColor}
          ratingStarsColorRange={ratingStarsColorRange}
          viewCommunityButtonText={viewCommunityButtonText}
          providerTitleColorRange={providerTitleColorRange}
          displayRequestInfoButton={displayRequestInfoButton}
          displayViewCommunityButton={displayViewCommunityButton}
          viewCommunityButtonColorScheme={viewCommunityButtonColorScheme}
        />
      </ModalBody>
    </ModalContent>
  </Modal>
);

export const CommunityComparisonBanner: React.FC<
  CommunityComparisonBannerProps
> = ({
  router,
  providers = [],
  modalId = '',
  queryId = '',
  listId = '',
  ratingStarsColor,
  providerTitleColor,
  onCancel,
  displayBadges = false,
  ratingStarsColorRange,
  providerTitleColorRange,
  viewCommunityButtonText,
  openCompareModal = false,
  displayRequestInfoButton,
  displayViewCommunityButton,
  viewCommunityButtonColorScheme,
}) => {
  const toast = useToast();
  const locationsViewed = useLocationComparisonViewed();
  const defaultIndex = useBreakpointValue({ base: -1, lg: 0 });
  const [index, setIndex] = useState(defaultIndex);
  const [openComparisonModal, setOpenComparisonModal] =
    useState(openCompareModal);
  const disableAccordion = useBreakpointValue({ base: false, lg: true });

  const sliderSettings = {
    dots: true,
    infinite: false,
    slidesToShow: providers.length,
  };

  useEffect(() => {
    setIndex(defaultIndex);
  }, [defaultIndex]);

  const handleIndexChange = (newIndex: number) => {
    setIndex(newIndex);
  };

  const handleOpenModal = () => {
    const selectedCommunities = providers
      .map((provider) => provider.id)
      .join(',');
    updateQueryParams(
      {
        selectedCommunities,
        isModalOpen: (!openComparisonModal).toString(),
      },
      router
    );
    setOpenComparisonModal(!openComparisonModal);
  };

  const onClickShare = () => {
    const sharedUrl = typeof window !== 'undefined' ? window.location.href : '';
    handleShare(sharedUrl, toast);
  };

  const CompareButton = ({ display, testId }) => (
    <Button
      size="lg"
      minW={125}
      variant="solid"
      display={display}
      onClick={() => {
        const locations = providers.map((loc) => ({
          index: loc.index,
          location_id: loc.locationId,
        }));
        handleOpenModal();
        locationsViewed(locations);
      }}
      data-testid={testId}
      colorScheme="primary"
      isDisabled={providers.length < 2}
      elementType={ElementTypes.BUTTON}
      elementAction={ElementActions.OPEN_MODAL}
      elementName={ElementNames.COMPARE_PROVIDERS}
    >
      Compare
    </Button>
  );

  return (
    <Fragment>
      <Accordion allowToggle index={index} onChange={handleIndexChange}>
        <AccordionItem isDisabled={disableAccordion} opacity={1}>
          <Box
            left={0}
            py="10px"
            px="32px"
            right={0}
            bottom={0}
            display="flex"
            zIndex={1000}
            position="fixed"
            alignItems="center"
            justifyContent="center"
            backgroundColor="primary.50"
            flexDirection={{ base: 'column', lg: 'column' }}
          >
            <Box
              width="full"
              display="flex"
              alignItems="center"
              order={{ base: 1, lg: 0 }}
              justifyContent="space-between"
              mt={{ base: index != -1 ? 2 : 0, lg: 0 }}
            >
              <AccordionButton
                style={{ opacity: 1 }}
                disabled={disableAccordion}
                data-testid="accordionButton"
                width={{ base: 'auto', lg: 'full' }}
                textAlign={{ base: 'start', lg: 'center' }}
                justifyContent={{ base: 'flex-start', lg: 'center' }}
              >
                <Text color="#0F3233" fontWeight={700} fontSize={16}>
                  {providers.length == 1
                    ? 'Add another community to compare'
                    : `Compare ${providers.length} of 4`}
                </Text>
                {providers.length > 1 && (
                  <AccordionIcon display={{ base: 'block', lg: 'none' }} />
                )}
              </AccordionButton>
              <CompareButton
                testId="compareMobile"
                display={{ base: 'block', lg: 'none' }}
              />
            </Box>
            <AccordionContainer
              mt={4}
              gap={4}
              display="flex"
              alignItems="center"
              order={{ base: 0, lg: 1 }}
              width={{ base: 'full', lg: 'auto' }}
              direction={{ base: 'column', lg: 'row' }}
            >
              <AccordionPanel padding={0} width="full">
                <Stack
                  w={{ base: 'full', lg: 'auto' }}
                  direction={{ base: 'column', lg: 'row' }}
                >
                  {providers?.map((el, index) => (
                    <Box
                      key={el.id}
                      paddingY={2}
                      paddingX={3}
                      display="flex"
                      borderWidth={2}
                      borderRadius="md"
                      borderStyle="solid"
                      alignItems="center"
                      borderColor="gray.400"
                      backgroundColor="white"
                      data-testid={`remove${index}`}
                      justifyContent="space-between"
                      w={{ base: 'full', lg: 'auto' }}
                      maxW={{ base: 'full', lg: 270 }}
                    >
                      <VStack alignItems="flex-start">
                        <Text
                          fontSize={16}
                          noOfLines={1}
                          color="#0F3233"
                          fontWeight={700}
                        >
                          {el.name}
                        </Text>
                        <Text
                          as="span"
                          fontSize={16}
                          noOfLines={1}
                          color="#0F3233"
                          fontWeight={400}
                          style={{ margin: 0 }}
                        >
                          {el.address?.city}, {el.address?.state}
                        </Text>
                      </VStack>
                      <IconButton
                        p={0}
                        minW="auto"
                        name="remove"
                        display="flex"
                        fontSize="24px"
                        color="gray.800"
                        variant="unstyled"
                        aria-label="Cancel"
                        icon={<MdCancel />}
                        onClick={() => onCancel(el)}
                      />
                    </Box>
                  ))}
                </Stack>
              </AccordionPanel>
              <CompareButton
                testId="compareDesktop"
                display={{ base: 'none', lg: 'block' }}
              />
            </AccordionContainer>
          </Box>
        </AccordionItem>
      </Accordion>
      <CommunityComparisonModal
        listId={listId}
        modalId={modalId}
        queryId={queryId}
        providers={providers}
        onClose={handleOpenModal}
        onClickShare={onClickShare}
        isOpen={openComparisonModal}
        displayBadges={displayBadges}
        sliderSettings={sliderSettings}
        ratingStarsColor={ratingStarsColor}
        providerTitleColor={providerTitleColor}
        ratingStarsColorRange={ratingStarsColorRange}
        viewCommunityButtonText={viewCommunityButtonText}
        providerTitleColorRange={providerTitleColorRange}
        displayRequestInfoButton={displayRequestInfoButton}
        displayViewCommunityButton={displayViewCommunityButton}
        viewCommunityButtonColorScheme={viewCommunityButtonColorScheme}
      />
    </Fragment>
  );
};
