import { Grid } from '@chakra-ui/layout';

type NearbyGridProps = {
  children: React.ReactNode;
  align?: string;
};

const NearbyGrid = ({ children, align = 'center' }: NearbyGridProps) => {
  return (
    <Grid
      display="flex"
      flexWrap="wrap"
      justifyContent={align}
      templateColumns={{
        base: 'repeat(3, 1fr)',
        sm: 'repeat(6, 1fr)',
        md: 'repeat(12, 1fr)',
      }}
      gap={6}
    >
      {children}
    </Grid>
  );
};

export default NearbyGrid;
