import { useMediaQuery } from '@chakra-ui/media-query';
import { useMemo } from 'react';

type Breakpoint = {
  maxWidth: string;
  maxHeight: string;
  maxPageWidth: string;
};

export type BreakpointsFromMagnolia = {
  [key: string]: Breakpoint;
};

export type BreakpointsFromMagnoliaResponse = {
  maxWidth: string;
  maxHeight: string;
};

export const useBreakpointsFromMagnolia = (
  params?: BreakpointsFromMagnolia & {
    '@nodes': string[];
  }
): BreakpointsFromMagnoliaResponse => {
  const breakpoints = useMemo(
    () =>
      (params?.['@nodes'] || []).map(
        (node) => `(max-width: ${params?.[node].maxPageWidth})`
      ),
    [params]
  );

  const booleanArrayBreakpoints = useMediaQuery(breakpoints);

  const validBreakpointIndex = booleanArrayBreakpoints.findIndex(
    (breakpoint) => breakpoint
  );

  if (validBreakpointIndex === -1)
    return { maxWidth: '100%', maxHeight: '100%' };

  const nodes = params?.['@nodes'] || [];

  const nodeIndex = nodes?.[validBreakpointIndex];
  const activeBreakpoint = params?.[nodeIndex];

  return {
    maxWidth: activeBreakpoint?.maxWidth || '100%',
    maxHeight: activeBreakpoint?.maxHeight || '100%',
  };
};
