import { BoxProps } from '@chakra-ui/layout';
import Button, { ButtonProps } from '@components/Button';
import Container from '@components/LayoutStructure/Container';
import { StringToIconKeys } from '@components/RenderIcon';
import { DEFAULT_BUTTON_ALIGNMENT, DEFAULT_CTA_BEHAVIOR } from '@utils/buttons';
import { filterNonReactAttributes } from '@utils/filterNonReactAttributes';
import { useModalDispatch } from '~/contexts/ModalContext';
import {
  ElementActions,
  ElementNames,
  ElementTypes,
} from '@components/Analytics/events/ElementClicked';
import SiteContext from '~/contexts/SiteContext';
import { mockModMonProvider } from '@mocks/modmon.mock';
import { useContext } from 'react';
import ProviderContext from '~/contexts/Provider';
import { ParseMagnoliaPage } from '@utils/parser/magnolia';
import {
  DeviceVisibility,
  useResponsiveDisplay,
} from '@hooks/useResponsiveDisplay';

export interface CallToActionProps extends Omit<BoxProps, 'rel'> {
  text?: string;
  textColor?: string;
  bgColor?: string;
  isInquiry?: boolean;
  inquiryId?: string;
  type?: 'tel' | 'mailto' | 'sms';
  state?: 'solid' | 'outline' | 'ghost';
  behavior?: '_blank' | '_self' | '_parent' | '_top';
  rel?: Array<'external' | 'nofollow' | 'noopener' | 'noreferrer' | 'opener'>;
  url?: string;
  icon?: StringToIconKeys;
  alignment?: 'left' | 'center' | 'right';
  width?: 'fit-content' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '100%';
  size?: ButtonProps['size'];
  buttonProps?: ButtonProps;
  trackingName: ElementNames;
  templateId?: string;
  className?: string;
  mobileWidth?: 'fit-content' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '100%';
  deviceVisibility?: DeviceVisibility;
}

const CallToAction = ({
  state,
  text,
  icon,
  bgColor,
  textColor,
  url,
  rel,
  behavior,
  isInquiry,
  inquiryId,
  type,
  alignment,
  width,
  size = 'lg',
  buttonProps,
  trackingName = ElementNames.GENERIC_BUTTON,
  templateId,
  className,
  mobileWidth,
  deviceVisibility,
  ...boxProps
}: CallToActionProps): React.ReactElement => {
  const { showModal } = useModalDispatch();
  const siteContext = useContext(SiteContext);
  const openModal = (inquiryId) => {
    showModal(inquiryId);
  };

  const inPlasmic = templateId !== '' ? mockModMonProvider : null;
  const provider = useContext(ProviderContext)?.provider || inPlasmic;

  const isHidden = useResponsiveDisplay(deviceVisibility);
  if (isHidden) {
    return <></>;
  }

  const parsedBoxProps = filterNonReactAttributes(boxProps);

  const parsed = {
    url: url,
  };

  ParseMagnoliaPage({
    source: parsed,
    values: { provider: provider || {} },
    strip: true,
  });

  return (
    <Container
      display="flex"
      justifyContent={alignment || DEFAULT_BUTTON_ALIGNMENT}
      className={className}
      {...parsedBoxProps}
    >
      {isInquiry ? (
        <Button
          colorScheme={bgColor}
          leftIcon={icon}
          variant={state}
          width={{ base: mobileWidth ?? 'lg', sm: width }}
          size={size}
          fontSize={16}
          {...buttonProps}
          elementAction={ElementActions.OPEN_MODAL}
          elementType={ElementTypes.BUTTON}
          elementName={trackingName}
          onClick={() => openModal(inquiryId)}
        >
          {text}
        </Button>
      ) : (
        parsed.url && (
          <Button
            as="a"
            href={`${type ? `${type}:` : ''}${parsed.url}`}
            target={behavior || DEFAULT_CTA_BEHAVIOR}
            rel={rel?.join(' ') || ''}
            colorScheme={bgColor}
            leftIcon={icon}
            variant={state}
            width={{ base: mobileWidth ?? 'lg', sm: width }}
            {...buttonProps}
            elementAction={
              parsed.url?.startsWith('/') ||
              siteContext.site?.domains.map(
                (domain) => domain.indexOf(parsed.url || '') >= 0
              )
                ? ElementActions.INTERNAL_LINK
                : ElementActions.EXTERNAL_LINK
            }
            elementType={(type as ElementTypes) || ElementTypes}
            elementName={trackingName}
            destinationUrl={parsed.url}
            onClick={() => openModal(inquiryId)}
          >
            {text}
          </Button>
        )
      )}
    </Container>
  );
};

export default CallToAction;
