import { IconButton } from '@chakra-ui/button';
import { Card, CardBody, CardFooter, CardHeader } from '@chakra-ui/card';
import { Box, Link, Text } from '@chakra-ui/layout';
import { MdClose } from 'react-icons/md';

interface PromotionBannerProps {
  title: string;
  subheader: string;
  content: string;
  link: string;
  onClose: () => void;
  openInNewTab?: boolean;
  colorScheme: string;
}

const PromotionBanner: React.FC<PromotionBannerProps> = ({
  title,
  subheader,
  content,
  link,
  onClose,
  openInNewTab = false,
  colorScheme = 'primary',
}) => {
  return (
    <Card
      bg={`${colorScheme}.100`}
      borderRadius="md"
      h="full"
      p={4}
      size="sm"
      variant="filled"
      w="full"
    >
      <CardHeader
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Box>
          <Text
            color="black"
            fontSize={{ base: 'sm', md: 'md' }}
            fontWeight={700}
          >
            {title}
          </Text>
          <Text
            color="blackAlpha.600"
            fontSize={{ base: '10px', md: 'xs' }}
            fontWeight={700}
          >
            {subheader}
          </Text>
        </Box>

        {/* Close button */}
        <IconButton
          aria-label="Close"
          bg="white"
          boxShadow="md"
          colorScheme={`${colorScheme}`}
          height="34px"
          icon={<MdClose size={23} />}
          isRound={true}
          mr="-5px"
          mt="-5px"
          size="sm"
          variant="ghost"
          width="34px"
          zIndex={1}
          onClick={onClose}
        />
      </CardHeader>

      <CardBody>
        <Text color="black" fontSize={{ base: 'xs', md: 'sm' }} noOfLines={4}>
          {content}
        </Text>
      </CardBody>

      <CardFooter>
        <Link
          href={link}
          color={`${colorScheme}.500`}
          fontSize="xs"
          isExternal={openInNewTab}
          fontWeight={700}
        >
          LEARN MORE
        </Link>
      </CardFooter>
    </Card>
  );
};

export default PromotionBanner;
