import { getMapFeature } from '@hooks/use-query-map-feature';
import { MapFeature } from '~/types/MapFeature';

export interface PinMarker {
  pinColor: string;
  icon: string;
  lat: number;
  lng: number;
}

export const getPinMarkers = async (
  centralizedPin: PinMarker,
  features: MapFeature[] | null
): Promise<PinMarker[]> => {
  let pinMarkers: PinMarker[] = [];

  if (centralizedPin) {
    pinMarkers.push(centralizedPin);
  }

  if (features) {
    const promisesGetFeature = features.map(async (feature) => {
      const queryKey = [
        '',
        { lat: centralizedPin.lat, lng: centralizedPin.lng },
      ];

      return getMapFeature({ queryKey }, feature.keywords);
    });

    const resolvedPromises = await Promise.allSettled(promisesGetFeature);

    resolvedPromises.forEach((promise, index) => {
      if (promise.status === 'fulfilled') {
        const places = promise.value;

        if (places) {
          places.forEach((place) => {
            pinMarkers.push({
              lat: place.geometry.location.lat,
              lng: place.geometry.location.lng,
              icon: features[index].icon,
              pinColor: features[index].pinColor,
            });
          });
        }
      }
    });
  }

  return pinMarkers;
};
