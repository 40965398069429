import CommunityComparison from '@components/CommunityComparison/CommunityComparison';
import { CommunityComparisonProps } from '@components/CommunityComparison/CommunityComparison';

const CommunityComparisonWrapper = ({
  communities,
  state,
  city,
  county,
  latitude,
  longitude,
  careType,
  data,
  inquiryId = '',
  displayBadges,
  providerTitleColor,
  providerTitleColorRange,
  viewCommunityButtonText,
  displayRequestInfoButton,
  displayViewCommunityButton,
  ratingStarsColor = 'accent',
  ratingStarsColorRange = '500',
  viewCommunityButtonColorScheme,
}: CommunityComparisonProps): JSX.Element => {
  return (
    <CommunityComparison
      communities={communities}
      inquiryId={inquiryId}
      displayBadges={displayBadges}
      providerTitleColor={providerTitleColor}
      providerTitleColorRange={providerTitleColorRange}
      viewCommunityButtonText={viewCommunityButtonText}
      displayRequestInfoButton={displayRequestInfoButton}
      data={data}
      displayViewCommunityButton={displayViewCommunityButton}
      ratingStarsColor={ratingStarsColor}
      ratingStarsColorRange={ratingStarsColorRange}
      viewCommunityButtonColorScheme={viewCommunityButtonColorScheme}
    />
  );
};

export default CommunityComparisonWrapper;
