import startCase from 'lodash/startCase';

export const careTypesSeoTensing: Record<
  string,
  { singular: string; plural: string }
> = {
  'adult-day-care': {
    singular: 'Adult Day Care Service',
    plural: 'Adult Day Care Services',
  },
  'assisted-living': {
    singular: 'Assisted Living Community',
    plural: 'Assisted Living Communities',
  },
  'care-homes': {
    singular: 'Home Care Agency',
    plural: 'Home Care Agencies',
  },
  'continuing-care': {
    singular: 'Continuing Care Retirement Community',
    plural: 'Continuing Care Retirement Communities',
  },
  'continuing-care-retirement-communities': {
    singular: 'Continuing Care Retirement Community',
    plural: 'Continuing Care Retirement Communities',
  },
  'geriatric-care-managers': {
    singular: 'Geriatric Care Manager',
    plural: 'Geriatric Care Managers',
  },
  'home-care': {
    singular: 'Home Care Agency',
    plural: 'Home Care Agencies',
  },
  'home-health-agencies': {
    singular: 'Home Health Agency',
    plural: 'Home Health Agencies',
  },
  hospices: {
    singular: 'Hospice Service',
    plural: 'Hospice Services',
  },
  'in-home-care': {
    singular: 'Home Care Agency',
    plural: 'Home Care Agencies',
  },
  'independent-living': {
    singular: 'Independent Living Community',
    plural: 'Independent Living Communities',
  },
  'memory-care': {
    singular: 'Memory Care Facility',
    plural: 'Memory Care Facilities',
  },
  'memory-care-facilities': {
    singular: 'Memory Care Facility',
    plural: 'Memory Care Facilities',
  },
  'nursing-homes': {
    singular: 'Nursing Home',
    plural: 'Nursing Homes',
  },
  'retirement-communities': {
    singular: 'Continuing Care Retirement Community',
    plural: 'Continuing Care Retirement Communities',
  },
  '55-communities': {
    singular: '55+ Community',
    plural: '55+ Communities',
  },
  '55-plus': {
    singular: '55+ Community',
    plural: '55+ Communities',
  },
  '55-plus-communities': {
    singular: '55+ Community',
    plural: '55+ Communities',
  },
  'senior-living': {
    singular: 'Senior Living Facility',
    plural: 'Senior Living Facilities',
  },
  'senior-care': {
    singular: 'Senior Care Service',
    plural: 'Senior Care Services',
  },
};

export const careTypeSeoTensing = (
  careType: string,
  quantity?: number
): string => {
  const careTypeTensing = careTypesSeoTensing[careType];
  if (!careTypeTensing) {
    return `${startCase(careType)} ${
      quantity && quantity > 1 ? 'Facilities' : 'Facility'
    }`;
  }

  return quantity && quantity > 1
    ? careTypeTensing.plural
    : careTypeTensing.singular;
};
