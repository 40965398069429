import { Button, ButtonProps } from '@chakra-ui/button';
import { Grid, GridItem } from '@chakra-ui/layout';
import { Show } from '@chakra-ui/media-query';
import { ComponentWithAs } from '@chakra-ui/system';
import { SEARCH_PARAM } from '@constants/search-params';
import Image from 'next/image';
import SinglePhotoGallery from './SinglePhotoGallery';
import { Photo } from './types';
import { getAreaForItem, getTemplateAreas } from './utils';

const MAX_PHOTO_COUNT = 4;

const TEMPLATE_AREAS = [
  // One photo
  `"A A A A A A"
   "A A A A A A"
   "A A A A A A"
   "A A A A A A"
   "A A A A A A"
   "A A A A A A"`,
  // Two photos
  `"A A A A B B"
   "A A A A B B"
   "A A A A B B"
   "A A A A B B"
   "A A A A B B"
   "A A A A B B"`,
  // Three photos
  `"A A A A B B"
   "A A A A B B"
   "A A A A B B"
   "A A A A C C"
   "A A A A C C"
   "A A A A C C"`,
  // Four photos
  `"A A A A B C"
   "A A A A B C"
   "A A A A D D"
   "A A A A D D"
   "A A A A D D"
   "A A A A D D"`,
];

interface SeniorHomesPhotoGalleryProps {
  photos: Array<Photo>;
  setGalleryIndex: React.Dispatch<React.SetStateAction<null | number>>;
}

const ShowAllPhotosButton: ComponentWithAs<'button', ButtonProps> = (props) => {
  return (
    <Button colorScheme="primary" {...props}>
      View all photos
    </Button>
  );
};

const PhotoGallery: React.FC<SeniorHomesPhotoGalleryProps> = ({
  photos,
  setGalleryIndex,
}) => {
  const photoCount = photos.length;
  const templateAreas = getTemplateAreas(photoCount, TEMPLATE_AREAS);
  const photosToDisplay = photos.slice(0, MAX_PHOTO_COUNT);

  return (
    <Grid
      gap={6}
      height="md"
      templateAreas={templateAreas}
      templateColumns={'repeat(6, 1fr)'}
      templateRows={'repeat(6, 1fr)'}
    >
      {photosToDisplay.map((photo, index) => {
        const shouldDisplayShowAllButton = index === 0;

        return (
          <GridItem
            key={index}
            area={getAreaForItem(index, TEMPLATE_AREAS)}
            alignItems="center"
            display="flex"
            justifyContent="center"
            overflow="hidden"
            position="relative"
            rounded="8"
            cursor="pointer"
            onClick={() => {
              window.history.replaceState(
                window.history.state,
                '',
                `?${SEARCH_PARAM.PHOTO_INDEX}=${index + 1}`
              );
              setGalleryIndex(index + 1);
            }}
          >
            <Image
              alt={photo.alt}
              fill
              priority
              sizes="(max-width: 992px) 100vw, 850px"
              src={photo.url}
              style={{
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />

            {shouldDisplayShowAllButton ? (
              <ShowAllPhotosButton
                as="div"
                position="absolute"
                left="16px"
                bottom="16px"
              />
            ) : null}
          </GridItem>
        );
      })}
    </Grid>
  );
};

const SeniorHomesPhotoGallery: React.FC<SeniorHomesPhotoGalleryProps> = ({
  photos,
  setGalleryIndex,
}) => {
  return (
    <>
      {/* Mobile Only */}
      <Show below="lg">
        <SinglePhotoGallery photos={photos} setGalleryIndex={setGalleryIndex} />
      </Show>

      {/* Desktop Only */}
      <Show above="lg">
        <PhotoGallery photos={photos} setGalleryIndex={setGalleryIndex} />
      </Show>
    </>
  );
};

export default SeniorHomesPhotoGallery;
