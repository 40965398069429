import { Alert, AlertDescription, AlertTitle } from '@chakra-ui/alert';
import { CloseButton } from '@chakra-ui/close-button';
import { useDisclosure } from '@chakra-ui/hooks';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { Text } from '@chakra-ui/react';
import Button from '@components/Button';
import { getActivePromotion } from '@utils/getActivePromotion';
import dynamic from 'next/dynamic';
import { useContext } from 'react';
import ProviderContext, { Provider } from '~/contexts/Provider';

import {
  ElementActions,
  ElementNames,
  ElementTypes,
} from '@components/Analytics/events/ElementClicked';
import { Box } from '@chakra-ui/layout';
const Container = dynamic(
  () => import('@components/LayoutStructure/Container')
);

interface PromotionProps {
  provider?: Provider | null | undefined;
  colorScheme?: string | null | undefined;
}

const getTruncatedText = (text: string, length: number) => {
  return text.length > length ? text.slice(0, length).concat('...') : text;
};

const Promotion = (props: PromotionProps) => {
  const { isOpen: isVisible, onClose } = useDisclosure({ defaultIsOpen: true });
  const { isOpen, onOpen, onClose: onModalClose } = useDisclosure();

  const providerCtx = useContext(ProviderContext)?.provider;

  let provider = props.provider;

  if (!props.provider && providerCtx) {
    provider = providerCtx;
  }

  if (!provider) return null;

  const colorScheme = props.colorScheme ? props.colorScheme : 'info';
  const activePromotion = getActivePromotion(provider.promotions ?? []);
  if (!activePromotion || !activePromotion.externalPromotionText) return null;

  const mobileText = getTruncatedText(
    activePromotion.externalPromotionText,
    75
  );
  const desktopText = getTruncatedText(
    activePromotion.externalPromotionText,
    125
  );

  return activePromotion && isVisible ? (
    <Alert
      mb={8}
      mt={{ base: 0, md: 2 }}
      px={0}
      backgroundColor={`${colorScheme}.100`}
    >
      <Container
        pr={{ base: 8, md: 0 }}
        display="grid"
        gridTemplateAreas={{
          base: `
          "text close"
          "cta cta"
          `,
          md: '"text cta close"',
        }}
        gridTemplateColumns={{
          base: 'auto min-content',
          md: 'auto 100px min-content',
        }}
        gap="12px 24px"
        alignItems="center"
        color={`${colorScheme}.900`}
        flexWrap="wrap"
      >
        <Box width="full" gridArea="text">
          <AlertTitle whiteSpace="nowrap" display="inline">
            🎉 PROMOTION
          </AlertTitle>
          <AlertDescription display="inline">
            <Text display={{ base: 'inline', md: 'none' }} mb={4}>
              {mobileText}
            </Text>
            <Text display={{ base: 'none', md: 'inline' }} mb={0}>
              {desktopText}
            </Text>
          </AlertDescription>
        </Box>
        <Button
          gridArea="cta"
          width={{ base: '100%', md: 99 }}
          minWidth={99}
          height={8}
          colorScheme={colorScheme}
          variant="solid"
          fontSize={14}
          onClick={onOpen}
          elementAction={ElementActions.OPEN_MODAL}
          elementName={ElementNames.GENERIC_BUTTON}
          elementType={ElementTypes.BUTTON}
        >
          Read More
        </Button>
        <CloseButton
          alignSelf={{
            base: 'flex-start',
            md: 'center',
          }}
          gridArea="close"
          onClick={onClose}
        />
      </Container>
      <Modal isOpen={isOpen} onClose={onModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Promotion</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{activePromotion.externalPromotionText}</ModalBody>
          <ModalFooter>
            <Button
              colorScheme={colorScheme}
              mr={3}
              onClick={onModalClose}
              elementAction={ElementActions.CLOSE_MODAL}
              elementName={ElementNames.GENERIC_BUTTON}
              elementType={ElementTypes.BUTTON}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Alert>
  ) : (
    <></>
  );
};

export default Promotion;
