import { Box, VStack } from '@chakra-ui/layout';
import { Collapse } from '@chakra-ui/transition';
import Button from '@components/Button';
import Container from '@components/LayoutStructure/Container';
import { Section } from '@components/Sections';
import { EditableArea } from '@magnolia/react-editor';
import { isEmptyText } from '@utils/isEmptyText';
import { truncateText } from '@utils/truncateText';
import { useRef, useState } from 'react';
import withHydrationOnDemand from 'react-hydration-on-demand';
import { HeadingElements } from '~/@types/heading';
import { EditableAreaType, Metadata } from '~/types/Magnolia';
import {
  ElementActions,
  ElementNames,
  ElementTypes,
} from '@components/Analytics/events/ElementClicked';
import { isValidElement } from 'react';
import { ParseMagnoliaPage } from '@utils/parser/magnolia';
import { mockModMonProvider } from '@mocks/modmon.mock';
import { useContext } from 'react';
import ProviderContext from '~/contexts/Provider';
import {
  DeviceVisibility,
  useResponsiveDisplay,
} from '@hooks/useResponsiveDisplay';

const MAX_TEXT_LENGTH = 250;

interface CollapsibleSectionProps {
  title: string;
  text: string;
  metadata: Metadata;
  headingElement?: HeadingElements;
  textAlignment?: 'left' | 'center' | 'right';
  titleAlignment?: 'left' | 'center' | 'right';
  collapsibleComponents?: EditableAreaType;
  nonCollapsibleComponents?: EditableAreaType;
  templateId?: string;
  className?: string;
  deviceVisibility?: DeviceVisibility;
}

const CollapsibleSectionComponent = ({
  title,
  text,
  metadata,
  headingElement = 'h2',
  textAlignment = 'left',
  titleAlignment = 'left',
  collapsibleComponents,
  nonCollapsibleComponents,
  templateId,
  className,
  deviceVisibility,
}: CollapsibleSectionProps): React.ReactElement => {
  const [viewFewerDetails, setViewFewerDetails] = useState(true);
  const collapsibleSectionRef = useRef<HTMLDivElement>(null);
  const inPlasmic = templateId !== '' ? mockModMonProvider : null;
  const provider = useContext(ProviderContext)?.provider || inPlasmic;

  const isHidden = useResponsiveDisplay(deviceVisibility);
  if (isHidden) {
    return <></>;
  }

  const scrollToSection = () => {
    collapsibleSectionRef.current?.scrollIntoView();
  };

  const parsed = {
    title: title,
    content: text,
  };

  ParseMagnoliaPage({
    source: parsed,
    values: { provider: provider || {} },
    strip: true,
  });

  const emptyText = isEmptyText(parsed.content);
  if (!parsed.title && emptyText) return <></>;

  const shouldReduceIntro =
    !emptyText && parsed.content.length > MAX_TEXT_LENGTH;
  const { textStart, textEnd } = shouldReduceIntro
    ? truncateText(parsed.content, MAX_TEXT_LENGTH)
    : { textStart: '', textEnd: '' };
  const content = shouldReduceIntro
    ? textStart +
      (viewFewerDetails ? textEnd : `... <div class="hidden">${textEnd}</div>`)
    : parsed.content;

  return (
    <Container className={`collapsible-container ${className}`}>
      <VStack spacing={4} ref={collapsibleSectionRef} marginBottom={4}>
        <Section
          title={parsed.title}
          headingElement={headingElement}
          richText={content}
          textAlignment={textAlignment}
          titleAlignment={titleAlignment}
        />
      </VStack>
      <Collapse in={viewFewerDetails}>
        <>
          {isValidElement(collapsibleComponents)
            ? collapsibleComponents
            : collapsibleComponents && (
                <EditableArea
                  content={collapsibleComponents}
                  parentTemplateId={metadata['mgnl:template']}
                />
              )}
        </>
      </Collapse>
      <Box marginTop={viewFewerDetails ? 0 : 8}>
        <>
          {isValidElement(nonCollapsibleComponents)
            ? nonCollapsibleComponents
            : nonCollapsibleComponents && (
                <EditableArea
                  content={nonCollapsibleComponents}
                  parentTemplateId={metadata['mgnl:template']}
                />
              )}
        </>
      </Box>
      <Button
        variant="outline"
        width="100%"
        colorScheme="primary"
        paddingY={3}
        paddingX={6}
        marginBottom={4}
        size="lg"
        fontSize={16}
        onClick={() => {
          setViewFewerDetails(!viewFewerDetails);
          viewFewerDetails && scrollToSection();
        }}
        elementAction={
          viewFewerDetails ? ElementActions.COLLAPSE : ElementActions.EXPAND
        }
        elementName={
          viewFewerDetails
            ? ElementNames.VIEW_FEWER_DETAILS
            : ElementNames.VIEW_MORE_DETAILS
        }
        elementType={ElementTypes.BUTTON}
      >
        {viewFewerDetails ? 'View fewer details' : 'View more details'}
      </Button>
    </Container>
  );
};

const CollapsibleSection: React.FC<CollapsibleSectionProps> =
  withHydrationOnDemand({
    on: ['idle', 'visible'],
    initialVisible: true,
  })(CollapsibleSectionComponent);

export default CollapsibleSection;
