import { Box } from '@chakra-ui/react';
import { useTheme } from '@chakra-ui/system';
import {
  BreakpointsFromMagnolia,
  useBreakpointsFromMagnolia,
} from '@hooks/useBreakpointsFromMagnolia';
import NextImage from 'next/image';
import { IMAGE_DOMAINS } from '~/constants';
import { MagnoliaImage, Metadata } from '~/types/Magnolia';

import DAMImage from './DAMImage';
import { Link } from '@chakra-ui/layout';
import {
  DeviceVisibility,
  useResponsiveDisplay,
} from '@hooks/useResponsiveDisplay';

interface ImageProps {
  switchable: {
    field: string;
    image?: MagnoliaImage;
    metadata?: Metadata;
    imageAlt?: string;
    imageUrl?: string;
  };
  preloadImage?: boolean;
  maxWidth?: string;
  breakpoints?: BreakpointsFromMagnolia & {
    '@nodes': string[];
  };
  linkProperties?: {
    url?: string;
    type?: 'tel' | 'mailto' | 'sms';
    target?: '_blank' | '_self' | '_parent' | '_top';
    rel?: ['external' | 'nofollow' | 'noopener' | 'noreferrer' | 'opener'];
  };
  imageBorderRadius?: string;
  desktopImageBorderRadius?: string;
  deviceVisibility?: DeviceVisibility;
}
const Image = ({
  switchable,
  breakpoints,
  preloadImage = false,
  imageBorderRadius,
  desktopImageBorderRadius,
  linkProperties = {},
  deviceVisibility,
}: ImageProps): React.ReactElement => {
  const theme = useTheme();
  const { field, image, imageAlt, imageUrl } = switchable;
  const hasImage = image || (imageAlt && imageUrl);
  const { maxHeight, maxWidth } = useBreakpointsFromMagnolia(breakpoints);
  const isHidden = useResponsiveDisplay(deviceVisibility);
  if (isHidden) {
    return <></>;
  }
  if (!hasImage) return <></>;
  const hasDAMImage = image && field === 'damChooser';
  const hasExternalSourceImage = field === 'externalSource' && imageUrl;
  if (
    hasExternalSourceImage &&
    !IMAGE_DOMAINS.some((url) => imageUrl.includes(url))
  ) {
    console.error('Invalid host in image url');
    return <></>;
  }

  const getBoxProps = () => {
    if (imageBorderRadius) {
      return {
        lineHeight: 0,
        borderRadius: {
          base: imageBorderRadius,
          md: desktopImageBorderRadius
            ? desktopImageBorderRadius
            : imageBorderRadius,
        },
        overflow: 'hidden',
      };
    }
  };

  const getLinkProps = () => {
    const { url, rel, target, type } = linkProperties;
    if (url) {
      return {
        rel: rel?.join(' ') || '',
        target,
        href: (type ? `${type}:` : '') + url,
      };
    }
    return {};
  };

  const element = linkProperties?.url ? Link : 'div';

  return (
    <Box display="block" as={element} {...getLinkProps()} {...getBoxProps()}>
      {hasDAMImage && (
        <DAMImage
          src={image}
          priority={preloadImage}
          style={{
            width: '100%',
            objectFit: 'cover',
            maxWidth: maxWidth,
            maxHeight: maxHeight,
          }}
        />
      )}
      {hasExternalSourceImage && (
        <NextImage
          height={500}
          width={500}
          src={imageUrl}
          alt={imageAlt ?? ''}
          sizes={`(min-width: ${theme.breakpoints['lg']}) 40vw,
              60vw`}
          priority={preloadImage}
          style={{
            width: '100%',
            objectFit: 'cover',
            maxWidth: maxWidth,
            maxHeight: maxHeight,
            padding: imageBorderRadius
              ? '0'
              : `${theme.space[8]} ${theme.space[4]}`,
          }}
        />
      )}
    </Box>
  );
};

export default Image;
