import React, { useContext, useEffect } from 'react';
import dynamic from 'next/dynamic';
import orderBy from 'lodash/orderBy';
import { Domains } from '~/types/Domains';
import { Section } from '@components/Sections';
import { Stack } from '@chakra-ui/layout';
import SiteContext from '~/contexts/SiteContext';
import ProviderContext from '~/contexts/Provider';
import { NearbyProps } from '@components/NearbyCare/types';
import withHydrationOnDemand from 'react-hydration-on-demand';
import { ProvidersNearMeQueryResponse } from '@hooks/provider';
import { providerIsEnhancedAndNotSuppressed } from '~/utils/providers';
import CommunityComparisonContainer from './CommunityComparisonContainer';
import {
  DeviceVisibility,
  useResponsiveDisplay,
} from '@hooks/useResponsiveDisplay';

const Container = dynamic(
  () => import('@components/LayoutStructure/Container')
);

export type FetchResponse = {
  nearbyProviders: ProvidersNearMeQueryResponse | {};
};

export interface CommunityComparisonProps {
  communities: NearbyProps;
  state: string;
  city: string;
  county: string;
  latitude?: string;
  longitude?: string;
  careType: string | { name: string };
  data: {
    nearbyProviders: ProvidersNearMeQueryResponse;
  };
  providerTitleColor?: string;
  providerTitleColorRange?: string;
  displayBadges?: boolean;
  displayViewCommunityButton?: boolean;
  viewCommunityButtonText?: string;
  displayRequestInfoButton?: boolean;
  requestInfoButtonText?: string;
  inquiryId?: string;
  viewCommunityButtonColorScheme?: string;
  ratingStarsColor?: string;
  ratingStarsColorRange?: string;
  deviceVisibility?: DeviceVisibility;
}

const CommunityComparison: React.FC<CommunityComparisonProps> = ({
  communities,
  inquiryId = '',
  displayBadges,
  providerTitleColor,
  providerTitleColorRange,
  viewCommunityButtonText,
  displayRequestInfoButton,
  data: { nearbyProviders },
  displayViewCommunityButton,
  ratingStarsColor = 'accent',
  ratingStarsColorRange = '500',
  viewCommunityButtonColorScheme,
  deviceVisibility,
}) => {
  const { site } = useContext(SiteContext);
  const isCaringWebSite = site?.path === Domains.CaringDomains.LIVE;
  const provider = useContext(ProviderContext)?.provider;
  const isHidden = useResponsiveDisplay(deviceVisibility);

  useEffect(() => {
    const id = window.location.hash.substring(1);
    if (id.startsWith('provider-comparison-')) {
      document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  if (isHidden || !provider) {
    return <></>;
  }

  const isEnhanced = providerIsEnhancedAndNotSuppressed(provider);
  const isBasicListing = isCaringWebSite && !isEnhanced;
  const displayNearbyProviderListing = !isCaringWebSite || isBasicListing;

  const nearbyCommunities =
    orderBy(
      [provider, ...nearbyProviders?.results],
      ['bayesian_average'],
      ['desc']
    ) || [];

  if (nearbyProviders?.results?.length === 0 || !displayNearbyProviderListing)
    return <></>;

  return (
    <Container py="16" id={`provider-comparison-${provider.id}`}>
      <Stack spacing="8" align="stretch">
        <Section
          headingElement={communities.headingElement}
          richText={communities.content}
          title={communities.heading}
        />
        {nearbyCommunities.length > 0 && (
          <CommunityComparisonContainer
            modalId={inquiryId}
            providers={nearbyCommunities}
            displayBadges={displayBadges}
            ratingStarsColor={ratingStarsColor}
            providerTitleColor={providerTitleColor}
            ratingStarsColorRange={ratingStarsColorRange}
            viewCommunityButtonText={viewCommunityButtonText}
            providerTitleColorRange={providerTitleColorRange}
            displayRequestInfoButton={displayRequestInfoButton}
            enableTrailingSlash={communities.enableTrailingSlash}
            displayViewCommunityButton={displayViewCommunityButton}
            viewCommunityButtonColorScheme={viewCommunityButtonColorScheme}
          />
        )}
      </Stack>
    </Container>
  );
};

export default withHydrationOnDemand({
  on: ['idle', 'visible'],
  initialVisible: true,
})(CommunityComparison);
