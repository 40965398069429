import clamp from 'lodash/clamp';
import { AREA_NAMES } from './constants';

const getSafeIndex = (index: number, templates: Array<string>) => {
  const lowerBound = 0;
  const upperBound = templates.length - 1;

  return clamp(index, lowerBound, upperBound);
};

export const getTemplateAreas = (
  photoCount: number,
  templates: Array<string>
) => {
  const index = getSafeIndex(photoCount - 1, templates);

  return templates[index];
};

export const getAreaForItem = (index: number, templates: Array<string>) => {
  return AREA_NAMES[getSafeIndex(index, templates)];
};
