export const mockNearbyGeos = {
  encodedId:
    'BAh7CEkiCGdpZAY6BkVUSSIqZ2lkOi8vZGlyZWN0b3J5L0xvY2FsUmVzb3VyY2UvMTIyMDE2MgY7AFRJIgxwdXJwb3NlBjsAVEkiDGRlZmF1bHQGOwBUSSIPZXhwaXJlc19hdAY7AFRJIh0yMDI0LTA0LTEyVDIwOjAwOjE5LjM1NFoGOwBU--2b7b38bf4a3d70694689adb38fedf9ae9fce2092',
  nearbyCountiesSameType: {
    heading: 'Senior Living Communities near Bexar County, TX',
    links: [
      {
        text: 'Wilson County',
        url: '/senior-living/texas/wilson-county',
      },
      {
        text: 'Medina County',
        url: '/senior-living/texas/medina-county',
      },
      {
        text: 'Atascosa County',
        url: '/senior-living/texas/atascosa-county',
      },
      {
        text: 'Kendall County',
        url: '/senior-living/texas/kendall-county',
      },
      {
        text: 'Guadalupe County',
        url: '/senior-living/texas/guadalupe-county',
      },
      {
        text: 'Karnes County',
        url: '/senior-living/texas/karnes-county',
      },
    ],
  },
  nearbyCitiesSameType: {
    heading: 'Senior Living Communities near San Antonio, TX',
    links: [
      { text: 'Converse', url: '/senior-living/texas/converse' },
      { text: 'Schertz', url: '/senior-living/texas/schertz' },
      { text: 'Floresville', url: '/senior-living/texas/floresville' },
      {
        text: 'New Braunfels',
        url: '/senior-living/texas/new-braunfels',
      },
      { text: 'Boerne', url: '/senior-living/texas/boerne' },
      { text: 'Pleasanton', url: '/senior-living/texas/pleasanton' },
    ],
  },
  otherTypesHere: {
    heading: 'Other Senior Care in San Antonio, TX',
    links: [
      {
        text: 'All Assisted Living',
        url: '/senior-living/assisted-living/texas/san-antonio',
      },
      {
        text: 'All Independent Living',
        url: '/senior-living/independent-living/texas/san-antonio',
      },
      {
        text: 'All Home Care',
        url: '/senior-care/in-home-care/texas/san-antonio',
      },
      {
        text: 'All Nursing Homes',
        url: '/senior-living/nursing-homes/texas/san-antonio',
      },
      {
        text: 'All Home Health Agencies',
        url: '/senior-care/home-health-agencies/texas/san-antonio',
      },
      { text: 'All Senior Care', url: '/senior-care/texas/san-antonio' },
    ],
  },
  otherTypesNearby: {
    heading: 'More Senior Care near San Antonio, TX',
    links: [
      {
        text: 'Assisted Living in Austin, Texas',
        url: '/senior-living/assisted-living/texas/austin',
      },
      {
        text: 'Assisted Living in Round Rock, Texas',
        url: '/senior-living/assisted-living/texas/round-rock',
      },
      {
        text: 'Memory Care in Austin, Texas',
        url: '/senior-living/memory-care-facilities/texas/austin',
      },
      {
        text: 'Memory Care in Cedar Park, Texas',
        url: '/senior-living/memory-care-facilities/texas/cedar-park',
      },
      {
        text: 'Independent Living in Austin, Texas',
        url: '/senior-living/independent-living/texas/austin',
      },
      {
        text: 'Senior Care in Austin, Texas',
        url: '/senior-care/texas/austin',
      },
    ],
  },
};
