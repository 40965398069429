import { useEffect, useState } from 'react';
import Button from '@components/Button';
import { Grid, GridItem } from '@chakra-ui/layout';
import { Show } from '@chakra-ui/media-query';
import { Icon } from '@chakra-ui/icon';
import { SEARCH_PARAM } from '@constants/search-params';
import Image from 'next/image';
import { BsGrid3X3Gap } from 'react-icons/bs';
import { Md360 } from 'react-icons/md';
import SinglePhotoGallery from './SinglePhotoGallery';
import { Photo } from './types';
import { getAreaForItem, getTemplateAreas } from './utils';
import {
  ElementActions,
  ElementNames,
  ElementTypes,
} from '@components/Analytics/events/ElementClicked';

const MAX_PHOTO_COUNT = 5;

const TEMPLATE_AREAS = [
  // One photo
  `"A A A"
   "A A A"
   "A A A"`,
  // Two photos
  `"A A A"
   "A A A"
   "B B B"`,
  // Three photos
  `"A A B"
   "A A B"
   "C C C"`,
  // Four photos
  `"A A B"
   "A A B"
   "C C D"`,
  // Five photos
  `"A A B"
   "A A B"
   "C D E"`,
  // Six photos
  `"A A B"
   "A A C"
   "D E F"`,
];

interface CaringPhotoGalleryProps {
  photos: Array<Photo>;
  setIsStreetViewModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setGalleryIndex: React.Dispatch<React.SetStateAction<null | number>>;
}

const ShowStreetViewButton = (props) => {
  return (
    <Button
      color="white"
      leftIcon={<Icon as={Md360} boxSize={6} />}
      pos={'absolute'}
      variant="outline"
      _hover={{ backgroundColor: 'gray.600' }}
      elementAction={ElementActions.OPEN_MODAL}
      elementName={ElementNames.STREET_VIEW}
      elementType={ElementTypes.BUTTON}
      {...props}
    >
      Street view
    </Button>
  );
};

const ShowAllPhotosButton = (props) => {
  return (
    <Button
      color="white"
      leftIcon={<BsGrid3X3Gap />}
      pos={'absolute'}
      variant="outline"
      _hover={{ backgroundColor: 'gray.600' }}
      elementAction={ElementActions.OPEN_MODAL}
      elementName={ElementNames.VIEW_GALLERY}
      elementType={ElementTypes.BUTTON}
      {...props}
    >
      Show all photos
    </Button>
  );
};

const PhotoGallery: React.FC<CaringPhotoGalleryProps> = ({
  photos,
  setIsStreetViewModalOpen,
  setGalleryIndex,
}) => {
  const [streetViewImage, setStreetViewImage] = useState<Photo>({
    url: '',
    alt: 'streetview',
  });

  useEffect(() => {
    const loadImage = async (path: string) => {
      try {
        const image = await import(`~/assets/streetview/${path}`);
        setStreetViewImage({ url: image.default, alt: 'streetview' });
      } catch (error) {
        console.error('Error loading image:', error);
      }
    };
    switch (photos.length) {
      case 2:
        loadImage('streetview_tall.jpg');
        break;
      case 3:
        loadImage('streetview_wide.jpg');
        break;
      default:
        loadImage('streetview_small.jpg');
    }
  }, [photos.length]);
  const slicedPhotoCount = photos.slice(0, MAX_PHOTO_COUNT).length;
  const photosToDisplay = photos.slice(0, MAX_PHOTO_COUNT);
  const hasMultiplePhotos = photosToDisplay.length > 1;
  const templateAreas = getTemplateAreas(
    hasMultiplePhotos ? slicedPhotoCount + 1 : slicedPhotoCount,
    TEMPLATE_AREAS
  );

  const streetViewIndex = slicedPhotoCount - 1;
  const photosWithStreetView = [...photosToDisplay];

  hasMultiplePhotos
    ? photosWithStreetView.splice(streetViewIndex, 0, streetViewImage)
    : photosWithStreetView;

  return (
    <Grid
      gap={3}
      height="xl"
      templateAreas={templateAreas}
      templateColumns={'repeat(3, 1fr)'}
      templateRows={'repeat(3, 1fr)'}
    >
      {photosWithStreetView.map((photo, index) => {
        const isLastPhoto = index === photosWithStreetView.length - 1;
        const shouldDisplayShowAllButton = isLastPhoto && hasMultiplePhotos;
        const isStreetView = index === streetViewIndex;

        if (isStreetView && hasMultiplePhotos) {
          return (
            <GridItem
              key={index}
              area={getAreaForItem(index, TEMPLATE_AREAS)}
              alignItems="center"
              bg="gray.900"
              display="flex"
              justifyContent="center"
              overflow="hidden"
              position="relative"
              rounded="8"
              cursor="pointer"
              onClick={() => {
                window.history.replaceState(
                  window.history.state,
                  '',
                  `?${SEARCH_PARAM.STREET_VIEW}=true`
                );
                setIsStreetViewModalOpen(true);
              }}
            >
              {photo.url && (
                <Image
                  alt={photo.alt}
                  fill
                  sizes="(max-width: 992px) 100vw, 850px"
                  src={photo.url}
                  quality={50}
                  style={{
                    objectFit: 'cover',
                    objectPosition: 'center',
                    opacity: 0.3,
                  }}
                />
              )}
              <ShowStreetViewButton />
            </GridItem>
          );
        }

        return (
          <GridItem
            key={index}
            area={getAreaForItem(index, TEMPLATE_AREAS)}
            alignItems="center"
            bg={shouldDisplayShowAllButton ? 'gray.900' : 'gray.100'}
            display="flex"
            justifyContent="center"
            overflow="hidden"
            position="relative"
            rounded="8"
            cursor="pointer"
            onClick={() => {
              const galleryIndex = index > streetViewIndex ? index : index + 1;
              if (hasMultiplePhotos) {
                window.history.replaceState(
                  window.history.state,
                  '',
                  `?${SEARCH_PARAM.PHOTO_INDEX}=${galleryIndex}`
                );
                setGalleryIndex(galleryIndex);
              }
            }}
          >
            <Image
              alt={photo.alt}
              fill
              priority
              sizes="(max-width: 992px) 100vw, 850px"
              src={photo.url}
              style={{
                objectFit: 'cover',
                objectPosition: 'center',
                opacity: shouldDisplayShowAllButton ? 0.3 : 1,
              }}
            />
            {!hasMultiplePhotos && (
              <ShowStreetViewButton
                position="absolute"
                bottom={6}
                left={6}
                color="primary.500"
                borderColor="primary.500"
                borderWidth={2}
                backgroundColor="white"
                _hover={{ backgroundColor: 'primary.100' }}
                onClick={() => {
                  window.history.replaceState(
                    window.history.state,
                    '',
                    `?${SEARCH_PARAM.STREET_VIEW}=true`
                  );
                  setIsStreetViewModalOpen(true);
                }}
              />
            )}

            {shouldDisplayShowAllButton ? <ShowAllPhotosButton /> : null}
          </GridItem>
        );
      })}
    </Grid>
  );
};

const CaringPhotoGallery: React.FC<CaringPhotoGalleryProps> = ({
  photos,
  setIsStreetViewModalOpen,
  setGalleryIndex,
}) => {
  return (
    <>
      {/* Mobile Only */}
      <Show below="lg">
        <SinglePhotoGallery
          photos={photos}
          setIsStreetViewModalOpen={setIsStreetViewModalOpen}
          setGalleryIndex={setGalleryIndex}
        />
      </Show>

      {/* Desktop Only */}
      <Show above="lg">
        <PhotoGallery
          photos={photos}
          setIsStreetViewModalOpen={setIsStreetViewModalOpen}
          setGalleryIndex={setGalleryIndex}
        />
      </Show>
    </>
  );
};

export default CaringPhotoGallery;
