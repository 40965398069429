import MagnoliaImage from '@components/Image/Image';

export const MIN_TEXT_LENGTH = 250;

interface Props {
  imageAlt?: string;
  imageUrl?: string;
  preloadImage?: boolean;
  url?: string;
  type?: 'tel' | 'mailto' | 'sms';
  target?: '_blank' | '_self' | '_parent' | '_top';
  rel?: ['external' | 'nofollow' | 'noopener' | 'noreferrer' | 'opener'];
  imageBorderRadius?: string;
  desktopImageBorderRadius?: string;
}

const ImageWrapper = ({
  imageAlt,
  imageUrl,
  preloadImage = false,
  url,
  type,
  target,
  rel,
  imageBorderRadius,
  desktopImageBorderRadius,
}: Props): JSX.Element => {
  const switchable = {
    field: 'externalSource',
    imageAlt: imageAlt,
    imageUrl: imageUrl,
  };

  const linkProperties = {
    url: url,
    type: type,
    target: target,
    rel: rel,
  };

  return (
    <MagnoliaImage
      switchable={switchable}
      preloadImage={preloadImage}
      imageBorderRadius={imageBorderRadius}
      desktopImageBorderRadius={desktopImageBorderRadius}
      linkProperties={linkProperties}
    />
  );
};

export default ImageWrapper;
