import {
  forwardRef,
  useContext,
  useRef,
  useState,
  useEffect,
  useMemo,
} from 'react';
import dynamic from 'next/dynamic';
import Heading from '@components/Heading';
import SiteContext from '~/contexts/SiteContext';
import { HeadingElements } from '~/@types/heading';
import ProviderReviewsList from './ProviderReviewsList';
import ProviderReviewsHeader from './ProviderReviewsHeader';
import withHydrationOnDemand from 'react-hydration-on-demand';
import { Grid, GridItem, Stack, Text } from '@chakra-ui/layout';
import ProviderContext, { ParametricReviewData } from '~/contexts/Provider';
import { Metadata } from '~/types/Magnolia';
import { getProviderWebsiteDetailsVariant } from '~/utils/getProviderWebsiteDetailsVariant';
import { ElementActions } from '@components/Analytics/events/ElementClicked';
import { mockModMonProvider } from '@mocks/modmon.mock';
import { ParseMagnoliaPage } from '@utils/parser/magnolia';
import { useScrollToAnchor } from '@hooks/useScrollToAnchor';
import { applySearchFilter } from './ProviderReviewsSearch';
import { applyFilters, ReviewFilters } from './ProviderReviewsFilters';
import useDebounce from '@hooks/useDebounce';
import { Review } from '@services/modular-monolith/types/location.type';
import {
  DeviceVisibility,
  useResponsiveDisplay,
} from '@hooks/useResponsiveDisplay';

const Container = dynamic(
  () => import('@components/LayoutStructure/Container')
);
const ProviderDetailsWriteAReviewLink = dynamic(
  () => import('@components/ReviewWidget/WriteAReviewLink')
);
const ProviderReviewsSearch = dynamic(() => import('./ProviderReviewsSearch'));

const ReviewsHeading = (
  {
    domain,
    headingElement,
    title,
    providerName,
  }: {
    domain: string;
    headingElement: HeadingElements;
    title: string;
    providerName: string;
  },
  ref
) => {
  const headingText = title || `Reviews for ${providerName}`;
  switch (domain) {
    case 'caring.com':
      return (
        <Heading
          ref={ref}
          headingElement={headingElement}
          headingSize="lg"
          title={headingText}
          withContainer={false}
          mb={{ base: 6, lg: 8 }}
        />
      );
    default:
      return (
        <Heading
          ref={ref}
          headingElement={headingElement}
          headingSize="lg"
          withContainer={false}
          title={headingText}
          px={0}
          pt={8}
        />
      );
  }
};

const DomainHeading = forwardRef(ReviewsHeading);

const ReviewsContainer = ({ bgBorderRadius, domain, children }) => {
  switch (domain) {
    case 'caring.com':
      return (
        <Container
          bg="gray.50"
          id="all_reviews_section"
          ignoreMaxWidth={true}
          borderRadius={bgBorderRadius}
          py={{ base: 8, lg: 16 }}
        >
          <Container my={0}>{children}</Container>
        </Container>
      );
    default:
      return (
        <Container pb={8} bg="secondary.50" id="all_reviews_section">
          {children}
        </Container>
      );
  }
};

const ReviewsLayout = ({ domain, children }) => {
  switch (domain) {
    case 'caring.com':
      return (
        <Grid
          gridTemplateColumns={{ base: 'repeat(1,1fr)', lg: 'repeat(3,1fr)' }}
          columnGap={{ base: 0, md: 6 }}
          rowGap={6}
        >
          {children}
        </Grid>
      );
    default:
      return <>{children}</>;
  }
};

const resetAll = (setSearchValue, setFilters, setSortOrder) => {
  setSearchValue('');
  setFilters({
    selectedStars: [],
    selectedCareTypes: [],
    selectedRelationships: [],
  });
  setSortOrder('most_recent_review_desc');
};

export interface ReviewsProps {
  title: string;
  description?: string;
  includeAggregateReview?: boolean;
  headingElement?: HeadingElements;
  includeCountOfReview?: boolean;
  enableReviewSearch?: boolean;
  includeFallbackIfNoReviewAvailable?: boolean;
  includeParametricReview?: boolean;
  parametricReviewColor?: string;
  // I dont think this is being used
  parametricReviewColorRange?: string;
  headingReviewsStarsColor?: string;
  headingReviewsStarsColorRange?: string;
  cardReviewsStarsColor?: string;
  cardReviewsStarsColorRange?: string;
  cardCareTypeTagsColor?: string;
  cardCareTypeTagsColorRange?: string;
  deviceVisibility?: DeviceVisibility;
  displayBadges?: boolean;
  displayHomeCarePulseBadges?: boolean;
  orderBy: string;
  reviewGuidelinesURL?: string;
  contactUsURL?: string;
  bgBorderRadius?: string;
  defaultOpenModal?: boolean;
  metadata: Pick<Metadata, '@id'>;
  templateId?: string;
}

export function Reviews({
  title,
  headingElement = 'h2',
  description,
  includeAggregateReview,
  includeCountOfReview,
  enableReviewSearch = false,
  includeFallbackIfNoReviewAvailable,
  orderBy,
  includeParametricReview,
  parametricReviewColor,
  // I dont think this is being used
  parametricReviewColorRange,
  displayBadges,
  displayHomeCarePulseBadges,
  reviewGuidelinesURL,
  contactUsURL,
  bgBorderRadius,
  defaultOpenModal,
  headingReviewsStarsColor,
  headingReviewsStarsColorRange,
  cardReviewsStarsColor,
  cardReviewsStarsColorRange,
  cardCareTypeTagsColor,
  cardCareTypeTagsColorRange,
  deviceVisibility,
  metadata,
  templateId = '',
  ...parametricReviewData
}: ReviewsProps) {
  const inPlasmic = templateId != '' ? { provider: mockModMonProvider } : {};
  const { provider } = useContext(ProviderContext) || inPlasmic;
  const targetRef = useRef<HTMLHeadingElement>(null);
  const [reviews, setReviews] = useState(provider?.reviews ?? []);
  const originalReviews = useMemo(() => provider?.reviews ?? [], [provider]);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 200);
  const [filters, setFilters] = useState<ReviewFilters>({
    selectedStars: [],
    selectedCareTypes: [],
    selectedRelationships: [],
  });
  const [sortOrder, setSortOrder] = useState(orderBy);
  const siteContext = useContext(SiteContext);
  const hasReviews = originalReviews.length > 0;

  // workaround to fix issue with jumplink in chrome on redirects
  useScrollToAnchor(targetRef, title, provider != null);
  useEffect(() => {
    const searchedReviews: Review[] = applySearchFilter(
      originalReviews,
      debouncedSearchValue
    );

    const filteredReviews = applyFilters(
      searchedReviews,
      filters.selectedStars,
      filters.selectedCareTypes,
      filters.selectedRelationships
    );
    setReviews(filteredReviews);
  }, [debouncedSearchValue, filters, originalReviews]);

  const isHidden = useResponsiveDisplay(deviceVisibility);
  if (isHidden) {
    return <></>;
  }

  if (!provider) return <></>;

  const domain = siteContext.site?.path || '';
  const providerName = provider.name;

  const providerDetailsVariants = getProviderWebsiteDetailsVariant(
    provider,
    domain
  );

  if (!metadata) {
    metadata = {
      '@id': templateId,
    };
  }

  const parsed = {
    title: title,
    parametricReviewData: parametricReviewData,
  };

  ParseMagnoliaPage({
    source: parsed,
    values: { provider: provider || {} },
    strip: false,
  });

  return (
    <ReviewsContainer domain={domain} bgBorderRadius={bgBorderRadius}>
      {hasReviews ? (
        <>
          <DomainHeading
            ref={targetRef}
            domain={domain}
            headingElement={headingElement}
            title={parsed.title}
            providerName={providerName}
          />
          <ReviewsLayout domain={domain}>
            <ProviderReviewsHeader
              description={description}
              includeAggregateReview={includeAggregateReview}
              includeCountOfReview={includeCountOfReview}
              includeFallbackIfNoReviewAvailable={
                includeFallbackIfNoReviewAvailable
              }
              includeParametricReview={includeParametricReview}
              ratingColor={headingReviewsStarsColor}
              ratingColorRange={headingReviewsStarsColorRange}
              parametricReviewColor={parametricReviewColor}
              parametricReviewData={
                parsed.parametricReviewData as ParametricReviewData
              }
              providerName={providerName}
              caringStars={provider.caringStars || []}
              homeCarePulse={provider.homeCarePulse || []}
              displayBadges={displayBadges}
              displayHomeCarePulseBadges={displayHomeCarePulseBadges}
              averageRating={providerDetailsVariants?.averageRating || '1'}
              reviewCount={providerDetailsVariants?.ratingCount || 0}
              reviewGuidelinesURL={reviewGuidelinesURL}
              contactUsURL={contactUsURL}
              defaultOpenModal={defaultOpenModal}
              metadata={metadata}
            />
            <GridItem colSpan={2}>
              {enableReviewSearch && (
                <ProviderReviewsSearch
                  originalReviews={originalReviews}
                  reviewTotal={reviews.length}
                  searchValue={searchValue}
                  filters={filters}
                  sortOrder={sortOrder}
                  setSearchValue={setSearchValue}
                  setFilters={setFilters}
                  setSortOrder={setSortOrder}
                  resetAll={() =>
                    resetAll(setSearchValue, setFilters, setSortOrder)
                  }
                  metadataId={metadata['@id']}
                />
              )}

              <ProviderReviewsList
                title={title}
                reviews={reviews}
                orderBy={sortOrder}
                cardReviewsStarsColor={cardReviewsStarsColor}
                cardReviewsStarsColorRange={cardReviewsStarsColorRange}
                cardCareTypeTagsColor={cardCareTypeTagsColor}
                cardCareTypeTagsColorRange={cardCareTypeTagsColorRange}
              />
            </GridItem>
          </ReviewsLayout>
        </>
      ) : (
        <>
          <Stack spacing={4}>
            <ReviewsHeading
              domain={domain}
              headingElement={headingElement}
              title={title}
              providerName={providerName}
            />
            <ProviderReviewsHeader
              includeParametricReview={false}
              parametricReviewData={
                parametricReviewData as ParametricReviewData
              }
              reviewGuidelinesURL={reviewGuidelinesURL}
              contactUsURL={contactUsURL}
              providerName={providerName}
              caringStars={[]}
              homeCarePulse={[]}
              displayBadges={false}
              displayHomeCarePulseBadges={false}
              averageRating={'0'}
              reviewCount={0}
              ratingColor={headingReviewsStarsColor}
              ratingColorRange={headingReviewsStarsColorRange}
              metadata={metadata}
            />
            <Text fontSize="lg">{description}</Text>
          </Stack>
          <ProviderDetailsWriteAReviewLink
            providerName={providerName}
            text="Write a review"
            buttonSize="lg"
            defaultOpenModal={defaultOpenModal}
            reviewGuidelinesURL={reviewGuidelinesURL}
            contactUsURL={contactUsURL}
            metadata={metadata}
            elementAction={ElementActions.OPEN_MODAL}
          />
        </>
      )}
    </ReviewsContainer>
  );
}

const ReviewsWithOnDemandHydration = withHydrationOnDemand({
  on: ['visible'],
})(Reviews);

const ReviewsWithDefaultOpenModal = ({ ...props }: ReviewsProps) => {
  const [defaultOpenModal, setDefaultOpenModal] = useState(false);

  useEffect(() => {
    setDefaultOpenModal(window.location.hash === '#newreview');
  }, []);

  return (
    <ReviewsWithOnDemandHydration
      forceHydration={defaultOpenModal}
      defaultOpenModal={defaultOpenModal}
      {...props}
    />
  );
};

export default ReviewsWithDefaultOpenModal;
