import { LinkBox, VStack } from '@chakra-ui/layout';
import { Skeleton } from '@chakra-ui/skeleton';

interface Props {
  fullWidth?: boolean;
}

const SearchResultSkeleton = ({ fullWidth = false }: Props): JSX.Element => {
  return (
    <LinkBox
      as="article"
      maxW={fullWidth ? undefined : 'sm'}
      width={fullWidth ? '100%' : undefined}
      data-testid="search-result-skeleton"
    >
      <VStack
        alignItems={'left'}
        p="3"
        boxShadow="lg"
        rounded="md"
        minHeight={fullWidth ? 'xs' : 'md'}
        justifyContent="space-between"
      >
        <VStack alignItems="left">
          <Skeleton height="130" rounded="md" />
          <Skeleton height="24px" rounded="md" />
          <Skeleton height="24px" rounded="md" width="88%" />
          <Skeleton height="24px" rounded="md" width="44%" />
        </VStack>
        <Skeleton height="24px" rounded="md" width="88px" />
      </VStack>
    </LinkBox>
  );
};

export default SearchResultSkeleton;
