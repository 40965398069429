import { VStack } from '@chakra-ui/react';
import { useCallback } from 'react';
import { UseFormWatch } from 'react-hook-form';
import { Parser } from '~/utils/parser';
import FormCard, { FormCardProps } from './FormCard';
import HeadingWithSizeAndStyle from './HeadingWithSizeAndStyle';
import TextWithSizeAndStyle from './TextWithSizeAndStyle';
import { MagnoliaTextWithSizeAndStyle } from './types';

interface Props extends FormCardProps {
  headline: MagnoliaTextWithSizeAndStyle;
  subHeadline: MagnoliaTextWithSizeAndStyle;
  active: boolean;
  testId?: string;
  watchFormValues: UseFormWatch<any>;
}

const Step: React.FC<Props> = ({
  headline,
  subHeadline,
  active,
  testId,
  watchFormValues,
  ...formProps
}) => {
  const formValues = watchFormValues();
  const parseText = useCallback(
    (text?: string) => {
      return text
        ? Parser({
            source: text,
            values: { form: formValues },
            strip: true,
          })
        : undefined;
    },
    [formValues]
  );

  const headlineText = parseText(headline.text);
  const subHeadlineText = parseText(subHeadline.text);
  const formHeadlineText = parseText(formProps.form.headline);
  return (
    <VStack data-testid={testId} {...(active ? {} : { display: 'none' })}>
      <VStack mb="6" textAlign="center">
        <HeadingWithSizeAndStyle {...headline} text={headlineText} />
        <TextWithSizeAndStyle {...subHeadline} text={subHeadlineText} />
      </VStack>
      <FormCard
        {...formProps}
        form={{
          ...formProps.form,
          headline: formHeadlineText,
        }}
      />
    </VStack>
  );
};

export default Step;
