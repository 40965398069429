import { z } from 'zod';
import { DEFAULT_VALUES, DISPLAY_MODE } from './constants';
import {
  amenityCategory,
  border,
  boxShadow,
  careType,
  color,
  displayMode,
  facetedSearchOptions,
  headingAlignment,
  headingElement,
  headingSize,
  itemsPerPage,
  itemsPerRow,
  latitude,
  longitude,
  magnoliaNode,
  nullableBoolean,
  nullableNumber,
  nullableString,
  phoneNumber,
  providerPhoneNumber,
  radiusForSearch,
  readMoreBehavior,
} from './validator';

export const ComponentConfigSchema = z.object({
  amenityCategoryChips: z
    .object({
      amenityCategory: amenityCategory,
      basePath: nullableString,
      visible: nullableBoolean,
    })
    .catch({ amenityCategory: null, basePath: null, visible: null }),
  componentStyle: z
    .object({
      backgroundColor: color,
      borderRadius: nullableNumber,
      boxShadow: boxShadow,
    })
    .catch({ backgroundColor: null, borderRadius: null, boxShadow: null }),
  guidedSearch: z
    .object({ id: nullableString, visible: nullableBoolean })
    .catch({ id: null, visible: null }),
  heading: z
    .object({
      alignment: headingAlignment,
      content: nullableString,
      element: headingElement,
      size: headingSize,
    })
    .catch({ alignment: null, content: null, element: null, size: null }),
  mapView: z.object({ visible: nullableBoolean }).catch({ visible: null }),
  metadata: magnoliaNode,
  providerList: z
    .object({
      genericBlock1: magnoliaNode,
      genericBlock2: magnoliaNode,
      itemsPerRow: itemsPerRow,
      noData: z.object({ phoneNumber: phoneNumber }),
      providerCard: z.object({
        blurCosts: nullableBoolean,
        border: border,
        borderColor: color,
        heading: z.object({ color: color }),
        learnMoreButton: z.object({
          colorScheme: nullableString,
          content: nullableString,
          visible: nullableBoolean,
        }),
        phoneNumber: z.object({
          source: providerPhoneNumber,
          visible: nullableBoolean,
        }),
        ratingStars: z.object({ color: color }),
        readMoreButton: z.object({ behavior: readMoreBehavior }),
        requestInfoButton: z.object({
          colorScheme: nullableString,
          content: nullableString,
          formId: nullableString,
          visible: nullableBoolean,
        }),
      }),
    })
    .catch({
      genericBlock1: null,
      genericBlock2: null,
      itemsPerRow: DEFAULT_VALUES.ITEMS_PER_ROW,
      noData: { phoneNumber: null },
      providerCard: {
        blurCosts: null,
        border: null,
        borderColor: null,
        heading: { color: null },
        learnMoreButton: { colorScheme: null, content: null, visible: null },
        phoneNumber: { source: null, visible: null },
        ratingStars: { color: null },
        readMoreButton: { behavior: null },
        requestInfoButton: {
          colorScheme: null,
          content: null,
          formId: null,
          visible: null,
        },
      },
    }),
  searchBar: z
    .object({
      backgroundColor: color,
      careTypeSelect: z.object({ visible: nullableBoolean }),
      locationInput: z.object({
        placeholder: nullableString,
        readonly: nullableBoolean,
        visible: nullableBoolean,
      }),
      mapSwitch: z.object({ visible: nullableBoolean }),
      maxWidth: nullableNumber,
      submitButton: z.object({
        colorScheme: nullableString,
        visible: nullableBoolean,
      }),
      summary: z.object({ visible: nullableBoolean }),
      visible: nullableBoolean,
    })
    .catch({
      backgroundColor: null,
      careTypeSelect: { visible: null },
      locationInput: { placeholder: null, readonly: null, visible: null },
      mapSwitch: { visible: null },
      maxWidth: null,
      submitButton: { colorScheme: null, visible: null },
      summary: { visible: null },
      visible: null,
    }),
  searchOptions: z
    .object({
      amenityCategory: amenityCategory,
      careType: careType,
      city: nullableString,
      county: nullableString,
      displayMode: displayMode,
      itemsPerPage: itemsPerPage,
      latitude: latitude,
      longitude: longitude,
      radiusForSearch: radiusForSearch,
      state: nullableString,
    })
    .catch({
      amenityCategory: null,
      careType: null,
      city: null,
      county: null,
      displayMode: DISPLAY_MODE.LIST,
      itemsPerPage: DEFAULT_VALUES.ITEMS_PER_PAGE,
      latitude: 0,
      longitude: 0,
      radiusForSearch: DEFAULT_VALUES.RADIUS_FOR_SEARCH_IN_MILES,
      state: null,
    }),
  searchSidebar: z
    .object({ filters: facetedSearchOptions, visible: nullableBoolean })
    .catch({ filters: [], visible: null }),
});

export type ComponentConfig = z.infer<typeof ComponentConfigSchema>;

export const EnhancedSearchConfigParser = z
  .record(z.string(), z.unknown())
  .transform((config) => {
    return {
      amenityCategoryChips: {
        amenityCategory: config.facetedSearch
          ? config.facetedSearch['amenityCategory']
          : null,
        basePath: config.facetedSearch
          ? config.facetedSearch['basePath']
          : null,
        visible: config.facetedSearch ? config.facetedSearch['enabled'] : null,
      },
      componentStyle: {
        backgroundColor:
          config.bgColor && config.bgColorRange
            ? { color: config.bgColor, range: config.bgColorRange }
            : null,
        borderRadius: config.bgBorderRadius,
        boxShadow: config.boxShadow,
      },
      guidedSearch: {
        id: config.guidedSearchInquiryId,
        visible: config.displayGuidedSearchBanner,
      },
      heading: {
        alignment: config.titleAlignment,
        content: config.title,
        element: config.headingElement,
        size: { base: config.titleSize, desktop: config.desktopTitleSize },
      },
      mapView: { visible: config.mapView ? config.mapView['enabled'] : null },
      metadata: config.metadata,
      providerList: {
        genericBlock1: config.genericBlock1,
        genericBlock2: config.genericBlock2,
        itemsPerRow: config.countOfTilesPerRow,
        noData: {
          phoneNumber: config.filterAndSort
            ? config.filterAndSort['phoneNumber']
            : null,
        },
        providerCard: {
          blurCosts: config.blurCosts,
          border: config.tileBorder,
          borderColor: {
            color: config.tileBorderColor,
            range: config.tileBorderColorRange,
          },
          heading: {
            color: {
              color: config.providerTitleColor,
              range: config.providerTitleColorRange,
            },
          },
          learnMoreButton: {
            colorScheme: config.learnMoreButtonColorScheme,
            content: config.learnMoreButtonText,
            visible: config.displayLearnMoreButton,
          },
          phoneNumber: {
            source: config.providerPhoneNumberSource,
            visible: config.displayProviderPhoneNumber,
          },
          ratingStars: {
            color: {
              color: config.ratingStarsColor,
              range: config.ratingStarsColorRange,
            },
          },
          readMoreButton: { behavior: config.readMoreButton },
          requestInfoButton: {
            colorScheme: config.requestInfoButtonColorScheme,
            content: config.requestInfoButtonText,
            formId: config.infoButtonInquiryId,
            visible: config.displayRequestInfoButton,
          },
        },
      },
      searchBar: {
        backgroundColor: {
          color: config.searchBarBgColor,
          range: config.searchBarBgColorRange,
        },
        careTypeSelect: { visible: config.displayCareTypeFilter },
        locationInput: {
          placeholder: config.readOnlyLocationInputPlaceholder,
          readOnly: config.readOnlyLocationInput,
          visible: config.displayLocationInput,
        },
        mapSwitch: { visible: config.displayToggleMap },
        maxWidth: config.maxWidth,
        submitButton: {
          colorScheme: config.searchBarButtonColorScheme,
          visible:
            typeof config.hideSearchButton === 'boolean'
              ? !config.hideSearchButton
              : null,
        },
        summary: { visible: config.displayTotal },
        visible: config.displaySearchBar,
      },
      searchOptions: {
        amenityCategory: config.facetedSearch
          ? config.facetedSearch['amenityCategory']
          : null,
        careType: config.careType,
        city: config.city,
        county: config.county,
        displayMode: config.displayMode,
        itemsPerPage: config.countOfTiles,
        latitude: config.latitude,
        longitude: config.longitude,
        radiusForSearch: config.radiusForSearch,
        state: config.state,
      },
      searchSidebar: {
        filters: config.filterAndSort ? config.filterAndSort['filters'] : null,
        visible:
          config.filterAndSort && config.filterAndSort['filters']
            ? Boolean(config.filterAndSort && config.filterAndSort['filters'])
            : null,
      },
    };
  })
  .pipe(ComponentConfigSchema);

export type EnhancedSearchParsedConfig = z.infer<
  typeof EnhancedSearchConfigParser
>;

export const getDefaultComponentConfig = (): ComponentConfig => {
  return ComponentConfigSchema.parse({});
};

export const parseComponentConfig = (
  componentConfig: unknown
): ComponentConfig => {
  const result = EnhancedSearchConfigParser.safeParse(componentConfig);

  if (result.success) {
    return result.data;
  }

  return getDefaultComponentConfig();
};
