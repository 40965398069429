import Promotion from '@components/Alerts/Promotion';
import CallToAction from '@components/CallToAction/CallToAction';
import CollapsibleSection from '@components/CollapsibleSection/CollapsibleSection';
import ProviderFAQ from '@components/FAQ/FAQ';
import InquiryForm from '@components/InquiryForm';
import Map from '@components/Map/Map';
import MedicareReviews from '@components/MedicareReviews/MedicareReviews';
import Breadcrumb from '@components/Navigation/Breadcrumb';
import NearbyGeoPages from '@components/NearbyGeoPages';
import NewCallToAction from '@components/NewCallToAction/NewCallToAction';
import OfferingListing from '@components/OfferingListing/OfferingListing';
import PageTitleBanner from '@components/PageTitleBanner/PageTitleBanner';
import CommunityComparisonWrapper from '@components/Plasmic/CommunityComparison/CommunityComparisonWrapper';
import ExperimentViewed from '@components/Plasmic/ExperimentViewed/ExperimentViewed';
import Heading from '@components/Plasmic/Heading/Heading';
import ImageWrapper from '@components/Plasmic/Image/ImageWrapper';
import InfoRequest from '@components/Plasmic/InfoRequest/InfoRequest';
import NearbyCareWrapper from '@components/Plasmic/NearbyCare/NearbyCareWrapper';
import ProviderCareTypesWrapper from '@components/Plasmic/ProviderCareTypes/ProviderCareTypesWrapper';
import ProviderImagesGallery from '@components/Plasmic/ProviderImagesGallery/ProviderImagesGallery';
import RelatedArticlesWrapper from '@components/Plasmic/RelatedArticle/RelatedArticleWrapper';
import SectionTitleWrapper, {
  MIN_TEXT_LENGTH,
} from '@components/Plasmic/SectionTitle/SectionTitleWrapper';
import CustomTableOfContents from '@components/Plasmic/TableOfContents/CustomTableOfContents';
import WhyCaringWrapper from '@components/Plasmic/WhyCaring/WhyCaringWrapper';
import Reviews from '@components/ProviderDetailsReviews/Reviews';
import SubscriptionProviderCta from '@components/SubscriptionProviderCTA/SubscriptionProviderCTA';
import MultiStepFormWrapper from '@components/Plasmic/MultiStepForm/MultiStepFormWrapper';
import EmptyComponent from '@components/Plasmic/EmptyComponent/EmptyComponent';
import { renderSettingsDivider } from '@components/Plasmic/MultiStepForm/SettingsDivider';

import { mockNearbyGeos } from '@mocks/legacy.mock';
import { mockMagnoliaCatalog, mockMagnoliaPage } from '@mocks/magnolia.mock';
import { mockModMonProvider } from '@mocks/modmon.mock';
import { mockNearbyCare } from '@mocks/nearby.mock';
import { mockNextContext } from '@mocks/next.mock';
import { DataProvider, initPlasmicLoader } from '@plasmicapp/loader-nextjs';
import { ReactNode } from 'react';
import { PlasmicCanvasContext } from '@plasmicapp/loader-nextjs';
import { useContext } from 'react';
import { headingSizes } from '../config/headings';

const PLASMIC_PROJECT_ID = process.env.NEXT_PUBLIC_PLASMIC_PROJECT_ID || '';
const PLASMIC_API_TOKEN = process.env.NEXT_PUBLIC_PLASMIC_API_TOKEN || '';

import {
  baseInquiryFormProps,
  chakraSize,
  deviceVisibilityProps,
  inquiryFormProps,
  legacyCtaProps,
  newCtaProps,
  selectColor,
  selectColorRange,
  selectIcon,
  templateId,
  themeSpacingDescription,
} from './plasmicComponentDefaults';

export const PLASMIC = initPlasmicLoader({
  projects: [
    {
      id: PLASMIC_PROJECT_ID,
      token: PLASMIC_API_TOKEN,
      version:
        process.env.NEXT_PUBLIC_PLASMIC_VERSION === 'prod'
          ? 'prod'
          : process.env.NEXT_PUBLIC_PLASMIC_VERSION === 'stg'
          ? 'stg'
          : // If not production or staging, then just use the latest
            // published version, regardless of tags.
            undefined,
    },
  ],
  // In non-prod, we fetch the latest revisions, whether or not
  // they were published.
  preview: process.env.NODE_ENV !== 'production',
});

interface CanvasProviderProps {
  name?: string;
  data?: any;
  hidden?: boolean;
  advanced?: boolean;
  label?: string;
  children?: ReactNode;
  previewData?: any;
}

const LocationDataProvider = (props: CanvasProviderProps) => {
  const { name, data, hidden, advanced, label, children } = props;
  const inEditor = useContext(PlasmicCanvasContext);
  return DataProvider({
    name: name,
    data: inEditor ? mockModMonProvider : data,
    hidden: hidden,
    advanced: advanced,
    label: label,
    children: children,
  });
};

//
// Data Providers
//
PLASMIC.registerGlobalContext(LocationDataProvider, {
  name: 'LocationData',
  props: {
    name: {
      type: 'string',
      defaultValue: 'provider',
    },
    data: {
      type: 'object',
    },
  },
  providesData: true,
});

const CatalogDataProvider = (props: CanvasProviderProps) => {
  const { name, data, hidden, advanced, label, children } = props;
  const inEditor = useContext(PlasmicCanvasContext);
  return DataProvider({
    name: name,
    data: inEditor ? mockMagnoliaCatalog : data,
    hidden: hidden,
    advanced: advanced,
    label: label,
    children: children,
  });
};

PLASMIC.registerGlobalContext(CatalogDataProvider, {
  name: 'CatalogData',
  props: {
    name: {
      type: 'string',
      defaultValue: 'catalog',
    },
    data: {
      type: 'object',
    },
  },
  providesData: true,
});

const PageDataProvider = (props: CanvasProviderProps) => {
  const { name, data, hidden, advanced, label, children } = props;
  const inEditor = useContext(PlasmicCanvasContext);
  return DataProvider({
    name: name,
    data: inEditor ? mockMagnoliaPage : data,
    hidden: hidden,
    advanced: advanced,
    label: label,
    children: children,
  });
};

PLASMIC.registerGlobalContext(PageDataProvider, {
  name: 'PageData',
  props: {
    name: {
      type: 'string',
      defaultValue: 'page',
    },
    data: {
      type: 'object',
    },
  },
  providesData: true,
});

const ContextDataProvider = (props: CanvasProviderProps) => {
  const { name, data, hidden, advanced, label, children } = props;
  const inEditor = useContext(PlasmicCanvasContext);
  return DataProvider({
    name: name,
    data: inEditor ? mockNextContext : data,
    hidden: hidden,
    advanced: advanced,
    label: label,
    children: children,
  });
};

PLASMIC.registerGlobalContext(ContextDataProvider, {
  name: 'ContextData',
  props: {
    name: {
      type: 'string',
      defaultValue: 'context',
    },
    data: {
      type: 'object',
    },
  },
  providesData: true,
});

const NearbyGeoDataProvider = (props: CanvasProviderProps) => {
  const { name, data, hidden, advanced, label, children } = props;
  const inEditor = useContext(PlasmicCanvasContext);
  return DataProvider({
    name: name,
    data: inEditor ? mockNearbyGeos : data,
    hidden: hidden,
    advanced: advanced,
    label: label,
    children: children,
  });
};

PLASMIC.registerGlobalContext(NearbyGeoDataProvider, {
  name: 'NearbyGeoData',
  props: {
    name: {
      type: 'string',
      defaultValue: 'nearbyGeo',
    },
    data: {
      type: 'object',
    },
  },
  providesData: true,
});

const NearbyCareProvider = (props: CanvasProviderProps) => {
  const { name, data, hidden, advanced, label, children } = props;
  const inEditor = useContext(PlasmicCanvasContext);
  return DataProvider({
    name: name,
    data: inEditor ? mockNearbyCare : data,
    hidden: hidden,
    advanced: advanced,
    label: label,
    children: children,
  });
};

PLASMIC.registerGlobalContext(NearbyCareProvider, {
  name: 'NearbyCareData',
  props: {
    name: {
      type: 'string',
      defaultValue: 'nearbyCare',
    },
    data: {
      type: 'object',
    },
  },
  providesData: true,
});

const CommunityComparisonProvider = (props: CanvasProviderProps) => {
  const { name, data, hidden, advanced, label, children } = props;
  const inEditor = useContext(PlasmicCanvasContext);
  return DataProvider({
    name: name,
    data: inEditor ? mockNearbyCare : data,
    hidden: hidden,
    advanced: advanced,
    label: label,
    children: children,
  });
};

PLASMIC.registerGlobalContext(CommunityComparisonProvider, {
  name: 'CommunityComparisonData',
  props: {
    name: {
      type: 'string',
      defaultValue: 'communityComparison',
    },
    data: {
      type: 'object',
    },
  },
  providesData: true,
});

PLASMIC.registerGlobalContext(DataProvider, {
  name: 'MultiStepFormData',
  props: {
    name: {
      type: 'string',
      defaultValue: 'multiStepForm',
    },
    data: {
      type: 'object',
    },
  },
  providesData: true,
});

//
// Traits
//
PLASMIC.registerTrait('isLoggedIn', {
  type: 'boolean',
  label: 'Is user logged in',
});

PLASMIC.registerTrait('utm_campaign', {
  type: 'text',
  label: 'UTM Campaign',
});

PLASMIC.registerTrait('isEnhancedProvider', {
  type: 'boolean',
  label: 'Is an Enhanced Provider',
});

PLASMIC.registerTrait('isBasicProvider', {
  type: 'boolean',
  label: 'Is a Basic Provider',
});

// see https://en.wikipedia.org/wiki/HTTP_referer
PLASMIC.registerTrait('referer', {
  type: 'text',
  label: 'Referrer',
});

PLASMIC.registerTrait('providerCaretypes', {
  type: 'text',
  label: 'Provider Caretypes',
});
//
// Components
//
PLASMIC.registerComponent(PageTitleBanner, {
  name: 'PageTitleBanner',
  props: {
    provider: 'object',
    service: 'object',
    headingElement: {
      type: 'choice',
      options: Object.keys(headingSizes),
    },
    headingSize: {
      type: 'choice',
      options: Object.values(headingSizes),
    },
    phoneNumberPreText: 'string',
    phoneNumber: 'string',
    phoneNumberPostText: 'string',
    displayReviews: 'boolean',
    displayBadges: 'boolean',
    displayPricing: 'boolean',
    verifiedText: 'string',
    ratingColor: 'string',
    ratingColorRange: 'string',
    metadata: 'object',
    noHorizontalPadding: {
      type: 'boolean',
      defaultValue: true,
    },
    deviceVisibility: deviceVisibilityProps,
  },
});

PLASMIC.registerComponent(Promotion, {
  name: 'Promotion',
  props: {
    provider: 'object',
    colorScheme: {
      displayName: 'Color Scheme',
      type: 'choice',
      options: selectColor,
      defaultValue: 'info',
    },
  },
});

PLASMIC.registerComponent(Breadcrumb, {
  name: 'Breadcrumb',
  props: {
    noHorizontalPadding: {
      type: 'boolean',
      defaultValue: true,
    },
    deviceVisibility: deviceVisibilityProps,
  },
});

PLASMIC.registerComponent(CommunityComparisonWrapper, {
  name: 'CommunityComparison',
  props: {
    data: 'object',
    state: 'string',
    city: 'string',
    county: 'string',
    careType: {
      displayName: 'Page Care Type',
      type: 'string',
    },
    latitude: 'string',
    longitude: 'string',
    communities: {
      type: 'object',
      fields: {
        enable: {
          type: 'boolean',
          displayName: 'Enable Nearby Communities',
        },
        headingElement: {
          type: 'choice',
          options: Object.keys(headingSizes),
        },
        linkText: 'string',
        heading: 'string',
        content: 'string',
        enableTrailingSlash: 'boolean',
      },
    },
    // results tile
    providerTitleColor: {
      displayName: 'Title Color',
      type: 'choice',
      options: selectColor,
      defaultValue: 'black',
    },
    providerTitleColorRange: {
      displayName: 'Title Color Range',
      type: 'choice',
      options: selectColorRange,
      defaultValue: '500',
    },
    displayBadges: 'boolean',

    displayRequestInfoButton: {
      displayName: 'Display Get Pricing / Information Button',
      type: 'boolean',
    },
    inquiryId: {
      displayName: 'Inquiry Form ID',
      type: 'string',
    },
    displayViewCommunityButton: 'boolean',
    viewCommunityButtonText: 'string',
    viewCommunityButtonColorScheme: {
      displayName: 'View Community Button Color Scheme',
      type: 'choice',
      options: selectColor,
      defaultValue: 'secondary',
    },
    ratingStarsColor: {
      displayName: 'Rating (Stars) Color',
      type: 'choice',
      options: selectColor,
      defaultValue: 'info',
    },
    ratingStarsColorRange: {
      displayName: 'Rating (Stars) Color Range',
      type: 'choice',
      options: selectColorRange,
      defaultValue: '500',
    },
    deviceVisibility: deviceVisibilityProps,
  },
});

PLASMIC.registerComponent(CustomTableOfContents, {
  name: 'CustomTableOfContents',
  props: {
    title: 'string',
    deviceVisibility: deviceVisibilityProps,
  },
});

PLASMIC.registerComponent(Heading, {
  name: 'Heading',
  props: {
    title: 'string',
    withContainer: {
      type: 'boolean',
      defaultValue: false,
    },
    headingElement: {
      type: 'choice',
      options: Object.keys(headingSizes),
    },
    deviceVisibility: deviceVisibilityProps,
  },
});

PLASMIC.registerComponent(ProviderImagesGallery, {
  name: 'ProviderImagesGallery',
  props: {
    provider: 'object',
    templateId: {
      type: 'string',
      defaultValue: templateId,
    },
    deviceVisibility: deviceVisibilityProps,
    clickableOverlayColor: {
      displayName: 'Clickable Overlay color',
      type: 'choice',
      options: selectColor,
      defaultValue: 'primary',
    },
    clickableOverlayColorRange: {
      displayName: 'Clickable Overlay color range',
      type: 'choice',
      options: selectColorRange,
      defaultValue: '600',
    },
    openInquiryForm: {
      type: 'choice',
      options: [
        { value: 'true', label: 'True' },
        { value: 'false', label: 'False' },
      ],
    },
    ...baseInquiryFormProps,
  },
});

PLASMIC.registerComponent(InquiryForm, {
  name: 'InquiryForm',
  props: {
    ...inquiryFormProps,
  },
});

PLASMIC.registerComponent(InfoRequest, {
  name: 'InfoRequest',
  props: {
    deviceVisibility: deviceVisibilityProps,
    icon: {
      displayName: 'Icon',
      type: 'choice',
      options: selectIcon,
    },
    title: {
      type: 'string',
      defaultValue: 'Get cost information',
    },
    iconColor: {
      displayName: 'Icon Color',
      type: 'choice',
      options: selectColor,
    },
    iconColorRange: {
      displayName: 'Icon Color Range',
      type: 'choice',
      options: selectColorRange,
    },
    text: {
      displayName: 'CTA Text',
      type: 'string',
      defaultValue: 'Get costs',
    },
    state: {
      type: 'string',
      defaultValue: 'outline',
    },
    inquiryId: {
      displayName: 'Inquiry Form ID',
      type: 'string',
      defaultValue: 'Get costs',
    },
    bgColor: {
      displayName: 'Background Color',
      type: 'choice',
      options: selectColor,
      defaultValue: 'graphic',
    },
    bgColorRange: {
      displayName: 'Background Color Range',
      type: 'choice',
      options: selectColorRange,
      defaultValue: '50',
    },
    textColor: {
      displayName: 'Text Color',
      type: 'choice',
      options: selectColor,
    },
    textColorRange: {
      displayName: 'Text Color Range',
      type: 'choice',
      options: selectColorRange,
    },
  },
});

PLASMIC.registerComponent(Reviews, {
  name: 'Reviews',
  props: {
    templateId: {
      type: 'string',
      defaultValue: templateId,
    },
    title: {
      type: 'string',
      defaultValue: '',
    },
    description: {
      type: 'string',
      defaultValue: '',
    },
    headingElement: {
      type: 'choice',
      options: Object.keys(headingSizes),
    },
    linkText: {
      type: 'string',
    },
    orderBy: {
      type: 'choice',
      displayName: 'Order By',
      defaultValue: 'most_recent_review_desc',
      options: [
        { value: 'most_recent_review_desc', label: 'Most recent review' },
        { value: 'rating_review_desc', label: 'Highest to lowest review' },
        { value: 'rating_review_asc', label: 'Lowest to the highest revieww' },
      ],
    },
    includeCountOfReview: {
      displayName: 'Include aggregate review',
      type: 'choice',
      options: [
        { value: 'true', label: 'True' },
        { value: 'false', label: 'False' },
      ],
    },
    enableReviewSearch: {
      displayName: 'Review Search',
      type: 'choice',
      options: [
        { value: 'true', label: 'True' },
        { value: 'false', label: 'False' },
      ],
    },
    includeFallbackIfNoReviewAvailable: {
      displayName: 'Include fallback state if no reviews available',
      type: 'choice',
      options: [
        { value: 'true', label: 'True' },
        { value: 'false', label: 'False' },
      ],
    },
    displayBadges: {
      type: 'choice',
      options: [
        { value: 'true', label: 'True' },
        { value: 'false', label: 'False' },
      ],
    },
    displayHomeCarePulseBadges: {
      type: 'choice',
      options: [
        { value: 'true', label: 'True' },
        { value: 'false', label: 'False' },
      ],
    },
    includeAggregateReview: {
      displayName: 'Include aggregate review',
      type: 'choice',
      options: [
        { value: 'true', label: 'True' },
        { value: 'false', label: 'False' },
      ],
    },
    includeParametricReview: {
      displayName: 'Include parametric review',
      type: 'choice',
      options: [
        { value: 'true', label: 'True' },
        { value: 'false', label: 'False' },
      ],
    },
    parametricReviewColor: {
      displayName: 'Parametric review color',
      type: 'choice',
      options: selectColor,
    },
    reviewGuidelinesURL: 'string',
    contactUsURL: 'string',
    bgBorderRadius: 'string',
    headingReviewsStarsColor: {
      displayName: 'Heading reviews stars color',
      type: 'choice',
      options: selectColor,
    },
    headingReviewsStarsColorRange: {
      displayName: 'Heading reviews stars color range',
      type: 'choice',
      options: selectColorRange,
    },
    cardReviewsStarsColor: {
      displayName: 'Card reviews stars color',
      type: 'choice',
      options: selectColor,
    },
    cardReviewsStarsColorRange: {
      displayName: 'Card reviews stars color range',
      type: 'choice',
      options: selectColorRange,
    },
    cardCareTypeTagsColor: {
      displayName: 'Card care type tags color',
      type: 'choice',
      options: selectColor,
    },
    cardCareTypeTagsColorRange: {
      displayName: 'Card care type tags color range',
      type: 'choice',
      options: selectColorRange,
    },
    deviceVisibility: deviceVisibilityProps,
    // this is missing in the form definition
    // defaultOpenModal: {
    //   type: 'choice',
    //   options: [
    //     { value: 'true', label: 'True' },
    //     { value: 'false', label: 'False' },
    //   ],
    // },
    activitiesName: 'string',
    activitiesCount: 'string',
    activitiesRatingValue: 'string',
    activitiesMinimumRating: 'string',
    activitiesMaximumRating: 'string',

    facilitiesName: 'string',
    facilitiesCount: 'string',
    facilitiesRatingValue: 'string',
    facilitiesMinimumRating: 'string',
    facilitiesMaximumRating: 'string',

    foodName: 'string',
    foodCount: 'string',
    foodRatingValue: 'string',
    foodMinimumRating: 'string',
    foodMaximumRating: 'string',

    staffName: 'string',
    staffCount: 'string',
    staffRatingValue: 'string',
    staffMinimumRating: 'string',
    staffMaximumRating: 'string',

    valueName: 'string',
    valueCount: 'string',
    valueRatingValue: 'string',
    valueMinimumRating: 'string',
    valueMaximumRating: 'string',
  },
});

PLASMIC.registerComponent(WhyCaringWrapper, {
  name: 'WhyCaring',
  props: {
    title: {
      type: 'string',
      defaultValue: 'What you can do with Caring',
    },
    deviceVisibility: deviceVisibilityProps,
    bgColor: {
      displayName: 'Background Color',
      type: 'choice',
      options: selectColor,
      defaultValue: 'gray',
    },
    bgColorRange: {
      displayName: 'Background Color Range',
      type: 'choice',
      options: selectColorRange,
      defaultValue: '50',
    },
    itemsBgColor: {
      displayName: 'Item Background Color',
      type: 'choice',
      options: selectColor,
      defaultValue: 'white',
    },
    itemsBgColorRange: {
      displayName: 'Item Background Color Range',
      type: 'choice',
      options: selectColorRange,
      defaultValue: '50',
    },
    iconsTextColor: {
      displayName: 'Icon Color',
      type: 'choice',
      options: selectColor,
      defaultValue: 'accent',
    },
    iconsTextColorRange: {
      displayName: 'Icon Color Range',
      type: 'choice',
      options: selectColorRange,
      defaultValue: '400',
    },
    iconsBgColor: {
      displayName: 'Icon Background Color',
      type: 'choice',
      options: selectColor,
      defaultValue: 'accent',
    },
    iconsBgColorRange: {
      displayName: 'Icon Background Color Range',
      type: 'choice',
      options: selectColorRange,
      defaultValue: '400',
    },
    firstItemTitle: {
      type: 'string',
      defaultValue: 'Search the Caring database',
    },
    firstItemDescription: {
      type: 'string',
      defaultValue:
        'Quickly search the largest online senior living and senior care directory',
    },
    firstItemIcon: {
      type: 'choice',
      options: selectIcon,
      defaultValue: 'MdSearch',
    },
    secondItemTitle: {
      type: 'string',
      defaultValue: 'Read reviews and expert guidance',
    },
    secondItemDescription: {
      type: 'string',
      defaultValue:
        'Read our authentic consumer reviews and content by experts',
    },
    secondItemIcon: {
      type: 'choice',
      options: selectIcon,
      defaultValue: 'MdFavorite',
    },
    thirdItemTitle: {
      type: 'string',
      defaultValue: 'Speak to a Family Advisor for free',
    },
    thirdItemDescription: {
      type: 'string',
      defaultValue:
        'Guided, expert advice to find the best community or agency for you',
    },
    thirdItemIcon: {
      type: 'choice',
      options: selectIcon,
      defaultValue: 'MdChat',
    },
    fourthItemTitle: {
      type: 'string',
      defaultValue: 'Take a tour to decide yourself',
    },
    fourthItemDescription: {
      type: 'string',
      defaultValue:
        'We’ll help you book tours or get in touch with local agencies',
    },
    fourthItemIcon: {
      type: 'choice',
      options: selectIcon,
      defaultValue: 'MdOtherHouses',
    },
  },
});

PLASMIC.registerComponent(ProviderCareTypesWrapper, {
  name: 'ProviderCareTypes',
  props: {
    templateId: {
      type: 'string',
      defaultValue: templateId,
    },
    title: {
      displayName: 'Section title',
      type: 'string',
      defaultValue: 'Costs of {provider.name}',
    },
    headingElement: {
      type: 'choice',
      options: Object.keys(headingSizes),
      defaultValue: 'h2',
    },
    // needs to be fixed on component
    // linkText: {
    //   type: 'string',
    //   description:  'This field won’t be displayed within the section, it will be the TOC jumplink text for that section.'
    // },
    summary: {
      type: 'string',
      displayName: 'Section summary',
    },
    withImages: {
      displayName: 'Images',
      type: 'boolean',
      description: 'Include images in tiles',
    },
    withDescription: {
      displayName: 'Description',
      type: 'boolean',
      description: 'Include description in tiles',
    },
    withPricing: {
      displayName: 'Pricing',
      type: 'boolean',
      description: 'Include pricing in tiles',
    },
    obfuscatePricing: {
      displayName: 'Obfuscate Pricing',
      type: 'boolean',
      description: 'Obfuscated Pricing',
    },
    withButton: {
      displayName: 'What can I afford button',
      type: 'boolean',
      description: 'Include What can I afford button',
    },
    deviceVisibility: deviceVisibilityProps,
    state: {
      type: 'choice',
      displayName: 'CTA State',
      options: [
        { value: 'solid', label: 'Solid' },
        { value: 'outline', label: 'Outline' },
        { value: 'ghost', label: 'Ghost' },
      ],
    },
    // alignment: {
    //   type: 'choice',
    //   displayName: 'CTA Alignment',
    //   options: [
    //     { value: 'left', label: 'Left' },
    //     { value: 'center', label: 'Center' },
    //     { value: 'right', label: 'Right' },
    //   ],
    // },
    // width: {
    //   type: 'choice',
    //   displayName: 'CTA Width',
    //   options: [
    //     { value: 'fit-content', label: 'Minimum Width' },
    //     { value: 'xs', label: 'Extra Small' },
    //     { value: 'sm', label: 'Small' },
    //     { value: 'md', label: 'Medium' },
    //     { value: 'lg', label: 'Large' },
    //     { value: 'xl', label: 'Extra Large' },
    //     { value: '100%', label: 'Full Width' },
    //   ],
    // },
    // size: {
    //   type: 'choice',
    //   displayName: 'CTA Size',
    //   options: [
    //     { value: 'xs', label: 'Extra Small' },
    //     { value: 'sm', label: 'Small' },
    //     { value: 'md', label: 'Medium' },
    //     { value: 'lg', label: 'Large' },
    //   ],
    // },
    text: 'string',
    actionBehavior: {
      type: 'choice',
      displayName: 'CTA Action Behavior',
      options: [
        { value: 'openInquiry', label: 'Open an inquiry form within a modal' },
        { value: 'openLink', label: 'Follow URL' },
        { value: 'openReviewModal', label: 'Open Review Modal' },
      ],
    },
    inquiryId: {
      type: 'string',
      displayName: 'Inquiry Form ID',
      hidden: (props) => props.actionBehavior !== 'openInquiry',
      description: 'Inquiry Form that will be opened in a modal.',
    },
    url: {
      type: 'string',
      hidden: (props) => props.actionBehavior !== 'openLink',
    },
    behavior: {
      type: 'choice',
      options: [
        { value: '_blank', label: 'Open in a new tab' },
        { value: '_self', label: 'Open in the same frame as it was clicked' },
        { value: '_top', label: 'Open in the full body of the window' },
      ],
      hidden: (props) => props.actionBehavior !== 'openLink',
    },
    rel: {
      type: 'choice',
      options: ['external', 'nofollow', 'noopener', 'noreferrer', 'opener'],
      multiSelect: true,
      hidden: (props) => props.actionBehavior !== 'openLink',
    },
    type: {
      type: 'choice',
      options: [
        { value: 'tel', label: 'Phone' },
        { value: 'mailto', label: 'E-mail' },
        { value: 'sms', label: 'Text message' },
        { value: '', label: 'None above' },
      ],
      hidden: (props) => props.actionBehavior !== 'openLink',
    },
    icon: {
      displayName: 'Icon',
      type: 'choice',
      options: selectIcon,
    },
    bgColor: {
      displayName: 'Background Color',
      type: 'choice',
      options: selectColor,
      defaultValue: 'graphic',
    },
    textColor: {
      displayName: 'Text Color',
      type: 'choice',
      options: selectColor,
    },
  },
});

PLASMIC.registerComponent(OfferingListing, {
  name: 'OfferingListing',
  props: {
    templateId: {
      type: 'string',
      defaultValue: templateId,
    },
    title: {
      displayName: 'Title',
      type: 'string',
      defaultValue: '',
    },
    headingElement: {
      type: 'choice',
      options: Object.keys(headingSizes),
      defaultValue: 'h2',
    },
    blurAmenities: {
      type: 'boolean',
    },
    inquiryId: {
      type: 'string',
    },
    itemsToShow: {
      type: 'number',
    },
    deviceVisibility: deviceVisibilityProps,
    useLegacyAmenities: {
      type: 'boolean',
    },
  },
});

PLASMIC.registerComponent(MedicareReviews, {
  name: 'MedicareReviews',
  props: {
    templateId: {
      type: 'string',
      defaultValue: templateId,
    },
    title: {
      displayName: 'Title',
      type: 'string',
      defaultValue: '',
    },
    deviceVisibility: deviceVisibilityProps,
  },
});

PLASMIC.registerComponent(SectionTitleWrapper, {
  name: 'SectionTitle',
  props: {
    templateId: {
      type: 'string',
      defaultValue: templateId,
    },
    title: {
      displayName: 'Title',
      type: 'string',
      defaultValue: '',
    },
    headingElement: {
      type: 'choice',
      options: Object.keys(headingSizes),
      defaultValue: 'h2',
    },
    titleColor: {
      displayName: 'Title Color',
      type: 'choice',
      options: selectColor,
      defaultValue: 'black',
    },
    titleColorRange: {
      displayName: 'Title Color Range',
      type: 'choice',
      options: selectColorRange,
      defaultValue: '700',
    },
    titleAlignment: {
      type: 'choice',
      options: ['left', 'center', 'right'],
      defaultValue: 'left',
    },
    alertStyle: 'string',
    alertText: 'string',
    text: {
      displayName: 'Description Text',
      type: 'richText',
    },
    textColor: {
      displayName: 'Description Text Color',
      type: 'choice',
      options: selectColor,
      defaultValue: 'black',
    },
    textColorRange: {
      displayName: 'Description Text Color Range',
      type: 'choice',
      options: selectColorRange,
      defaultValue: '700',
    },
    textAlignment: {
      type: 'choice',
      options: ['left', 'center', 'right'],
      defaultValue: 'left',
    },
    deviceVisibility: deviceVisibilityProps,
    hideIfTextIsEmpty: {
      type: 'boolean',
      defaultValue: false,
    },
    field: {
      displayName: 'Should we max this text collapsible?',
      type: 'boolean',
      defaultValue: false,
    },
    minLength: {
      displayName: 'Numbers of characters to collapse to',
      type: 'number',
      defaultValue: MIN_TEXT_LENGTH,
    },
    defaultState: {
      displayName: 'Default state',
      type: 'choice',
      options: ['expanded', 'collapsed'],
    },
  },
});

PLASMIC.registerComponent(ImageWrapper, {
  name: 'Image',
  props: {
    imageAlt: 'string',
    imageUrl: 'string',
    preloadImage: {
      type: 'boolean',
      defaultValue: false,
    },
    url: {
      displayName: 'Link Url',
      type: 'string',
    },

    type: {
      displayName: 'Link Type',
      type: 'choice',
      options: [
        { value: 'tel', label: 'Phone' },
        { value: 'mailto', label: 'E-mail' },
        { value: 'sms', label: 'Text message' },
        { value: '', label: 'None above' },
      ],
    },
    target: {
      displayName: 'Link Target',
      type: 'choice',
      options: ['_blank', '_self', '_parent', '_top'],
    },
    rel: {
      displayName: 'Link Rel',
      type: 'choice',
      options: ['external', 'nofollow', 'noopener', 'noreferrer', 'opener'],
      multiSelect: true,
    },
    imageBorderRadius: 'string',
    desktopImageBorderRadius: 'string',
    deviceVisibility: deviceVisibilityProps,
  },
});

PLASMIC.registerComponent(CollapsibleSection, {
  name: 'CollapsibleSection',
  props: {
    templateId: {
      type: 'string',
      defaultValue: templateId,
    },
    title: 'string',
    text: 'string',
    headingElement: {
      type: 'choice',
      options: Object.keys(headingSizes),
      defaultValue: 'h2',
    },
    textAlignment: {
      type: 'choice',
      displayName: 'Text Alignment',
      options: ['left', 'center', 'right'],
      defaultValue: 'left',
    },
    titleAlignment: {
      type: 'choice',
      displayName: 'Title Alignment',
      options: ['left', 'center', 'right'],
      defaultValue: 'left',
    },
    deviceVisibility: deviceVisibilityProps,
    collapsibleComponents: {
      displayName: 'collapsibleComponents',
      type: 'slot',
    },
    nonCollapsibleComponents: {
      displayName: 'nonCollapsibleComponents',
      type: 'slot',
    },
  },
});

PLASMIC.registerComponent(SubscriptionProviderCta, {
  name: 'SubscriptionProviderCta',
  props: {
    templateId: {
      type: 'string',
      defaultValue: templateId,
    },
    deviceVisibility: deviceVisibilityProps,
    ...legacyCtaProps,
  },
});

PLASMIC.registerComponent(CallToAction, {
  name: 'CallToAction',
  props: {
    ...legacyCtaProps,
    deviceVisibility: deviceVisibilityProps,
  },
});

PLASMIC.registerComponent(NewCallToAction, {
  name: 'MarketingCTA',
  props: {
    heading: 'string',
    headingElement: {
      type: 'choice',
      options: Object.keys(headingSizes),
    },
    headingSize: {
      type: 'choice',
      options: Object.values(headingSizes),
    },
    headingColor: {
      type: 'choice',
      options: selectColor,
    },
    headingColorRange: {
      type: 'choice',
      options: selectColorRange,
    },
    linkText: 'string',
    summary: { type: 'richText' },
    summaryColor: {
      type: 'choice',
      options: selectColor,
    },
    summaryColorRange: {
      type: 'choice',
      options: selectColorRange,
    },
    deviceVisibility: deviceVisibilityProps,
    size: {
      type: 'object',
      fields: {
        field: {
          type: 'choice',
          options: ['mini', 'fullwidth'],
        },
        bgColor: {
          type: 'choice',
          options: selectColor,
          hidden: (props) => props.size?.field !== 'mini',
        },
        bgColorVariation: {
          type: 'choice',
          options: selectColorRange,
          hidden: (props) => props.size?.field !== 'mini',
        },
        switchable: {
          type: 'object',
          hidden: (props) => props.size?.field !== 'fullwidth',
          fields: {
            field: {
              type: 'choice',
              options: [
                { value: 'bgExternalImageForm', label: 'Image' },
                { value: 'bgColorForm', label: 'Color' },
              ],
              hidden: (props) => props.size?.field !== 'fullwidth',
            },
            bgColor: {
              type: 'choice',
              options: selectColor,
              hidden: (props) =>
                props.size?.switchable?.field !== 'bgColorForm',
            },
            bgColorVariation: {
              type: 'choice',
              options: selectColorRange,
              hidden: (props) =>
                props.size?.switchable?.field !== 'bgColorForm',
            },
            imageUrl: {
              type: 'string',
              hidden: (props) =>
                props.size?.switchable.field !== 'bgExternalImageForm',
            },
          },
        },
      },
    },
    minHeightBase: {
      type: 'choice',
      options: chakraSize,
      description: themeSpacingDescription,
    },
    minHeightLG: {
      type: 'choice',
      options: chakraSize,
      description: themeSpacingDescription,
    },
    marginBottom: {
      type: 'choice',
      options: chakraSize,
      description: themeSpacingDescription,
    },
    paddingBase: {
      type: 'choice',
      options: chakraSize,
      description: themeSpacingDescription,
    },
    paddingSM: {
      type: 'choice',
      options: chakraSize,
      description: themeSpacingDescription,
    },
    paddingMD: {
      type: 'choice',
      options: chakraSize,
      description: themeSpacingDescription,
    },
    paddingLG: {
      type: 'choice',
      options: chakraSize,
      description: themeSpacingDescription,
    },
    paddingXL: {
      type: 'choice',
      options: chakraSize,
      description: themeSpacingDescription,
    },
    padding2XL: {
      type: 'choice',
      options: chakraSize,
      description: themeSpacingDescription,
    },
    cta1: {
      type: 'object',
      fields: {
        ...newCtaProps('cta1'),
      },
    },
    cta2: {
      type: 'object',
      fields: {
        ...newCtaProps('cta2'),
      },
    },
    cta3: {
      type: 'object',
      fields: {
        ...newCtaProps('cta3'),
      },
    },
    cta4: {
      type: 'object',
      fields: {
        ...newCtaProps('cta4'),
      },
    },
    card: {
      type: 'object',
      fields: {
        enabled: {
          type: 'boolean',
          description: 'Select to display as a card',
        },
        size: {
          type: 'choice',
          options: ['sm', 'md', 'lg'],
          hidden: (props) => !props.card?.enabled,
        },
        variant: {
          type: 'choice',
          options: ['elevated', 'outline', 'filled'],
          hidden: (props) => !props.card?.enabled,
        },
      },
    },
  },
});

PLASMIC.registerComponent(Map, {
  name: 'Map',
  props: {
    templateId: {
      type: 'string',
      defaultValue: templateId,
    },
    title: 'string',
    headingElement: {
      type: 'choice',
      options: Object.keys(headingSizes),
      defaultValue: 'h2',
    },
    linkText: 'string',
    width: 'string',
    height: 'string',
    zoom: {
      type: 'number',
      control: 'slider',
      defaultValue: 10,
      min: 1,
      max: 30,
      step: 1,
    },
    hasZoomControl: {
      type: 'boolean',
      defaultValue: true,
    },
    hasdoubleClickZoom: {
      type: 'boolean',
      defaultValue: true,
    },
    hasScrollWheelZoom: {
      type: 'boolean',
      defaultValue: true,
    },
    hasStreetViewControl: {
      type: 'boolean',
      defaultValue: true,
    },
    hasMapTypeControl: {
      type: 'boolean',
      defaultValue: true,
    },
    hasDraggable: {
      type: 'boolean',
      defaultValue: true,
    },
    hasFullScreenControl: {
      type: 'boolean',
      defaultValue: true,
    },
    latitude: {
      type: 'string',
      required: true,
    },
    longitude: {
      type: 'string',
      required: true,
    },
    deviceVisibility: deviceVisibilityProps,
    features: {
      type: 'array',
      itemType: {
        type: 'object',
        fields: {
          altText: 'string',
          icon: {
            displayName: 'Icon',
            type: 'choice',
            options: selectIcon,
          },
          pinColor: {
            displayName: 'Pin Color',
            type: 'choice',
            options: selectColor,
          },
          keywords: {
            type: 'string',
            description:
              'If you want more than one keyword, you should separate them with ;',
          },
        },
      },
    },
    overlayText: {
      displayName: 'Overlay Text',
      type: 'richText',
    },
    limitCharsBeforeCTA: {
      type: 'number',
      defaultValue: 200,
      description: 'This definition will be used only for small screens',
    },
    ctaText: 'string',
    ctaUrl: 'string',
    ctaBehavior: {
      type: 'choice',
      options: [
        { value: '_blank', label: 'Open in a new tab' },
        { value: '_self', label: 'Open in the same frame as it was clicked' },
        { value: '_parent', label: 'Open in the parent frame' },
        { value: '_top', label: 'Open in the full body of the window' },
      ],
    },
    ctaRel: {
      type: 'choice',
      options: ['external', 'nofollow', 'noopener', 'noreferrer', 'opener'],
      multiSelect: true,
    },
    ignoreMaxWidth: {
      type: 'boolean',
      defaultValue: true,
      description: 'Ignore container max width',
    },
  },
});

const newPageProps = (propName?: string) => {
  return {
    displayName: propName,
    type: 'object',
    fields: {
      image: 'string',
      switchable: {
        displayName: 'Page Info',
        type: 'object',
        fields: {
          title: {
            displayName: 'Title',
            type: 'string',
            defaultValue: '',
          },
          url: 'string',
        },
      },
    },
  } as any;
};

PLASMIC.registerComponent(RelatedArticlesWrapper, {
  name: 'RelatedArticles',
  props: {
    templateId: {
      type: 'string',
      defaultValue: templateId,
    },
    title: 'string',
    headingElement: {
      type: 'choice',
      options: Object.keys(headingSizes),
      defaultValue: 'h2',
    },
    titleAlignment: {
      type: 'choice',
      options: ['left', 'center', 'right'],
      defaultValue: 'left',
    },
    text: 'string',
    textAlignment: {
      type: 'choice',
      options: ['left', 'center', 'right'],
      defaultValue: 'left',
    },
    withImages: 'boolean',
    cta: {
      displayName: 'CTA Text',
      type: 'string',
    },
    ctaColor: {
      type: 'choice',
      options: selectColor,
    },
    ctaColorRange: {
      type: 'string',
      hidden: (props) => true,
      defaultValue: '400',
    },
    state: {
      type: 'choice',
      options: ['solid', 'outline', 'ghost', 'link'],
    },
    tilesPerRow: {
      type: 'number',
      defaultValue: 1,
    },
    inSideBar: {
      type: 'boolean',
      defaultValue: false,
      description: 'Changes gap and heading size to accomodate the sidebar',
    },
    deviceVisibility: deviceVisibilityProps,
    page0: {
      ...newPageProps('page0'),
    },
    page1: {
      ...newPageProps('page1'),
    },
    page2: {
      ...newPageProps('page2'),
    },
    page3: {
      ...newPageProps('page3'),
    },
    page4: {
      ...newPageProps('page4'),
    },
    page5: {
      ...newPageProps('page5'),
    },
  },
});

PLASMIC.registerComponent(ProviderFAQ, {
  name: 'ProviderFAQ',
  props: {
    templateId: {
      type: 'string',
      defaultValue: templateId,
    },
    title: 'string',
    text: 'string',
    headingElement: {
      type: 'choice',
      options: Object.keys(headingSizes),
      defaultValue: 'h2',
    },
    deviceVisibility: deviceVisibilityProps,
    switchable: {
      type: 'object',
      fields: {
        field: {
          displayName: 'Data source type',
          defaultValue: 'manually',
          type: 'choice',
          options: ['manually', 'dataCatalog'],
        },
        faqs: {
          type: 'object',
          defaultValue: {},
        },
      },
      defaultValue: {
        field: 'manually',
        faqs: {},
      },
    },
  },
});

const NearbyGeoPagesLinksConfig = {
  type: 'object',
  fields: {
    enabled: {
      type: 'boolean',
      defaultValue: false,
    },
    title: {
      type: 'object',
      fields: {
        content: {
          type: 'string',
        },
        element: {
          type: 'choice',
          options: Object.keys(headingSizes),
          defaultValue: headingSizes.h2,
        },
        linkText: 'string',
      },
    },
  },
} as any;

PLASMIC.registerComponent(NearbyGeoPages, {
  name: 'NearbyGeoPages',
  props: {
    options: {
      type: 'object',
      fields: {
        providerSlug: {
          type: 'string',
          defaultValue: '{provider.slug}',
        },
        maxLinks: {
          type: 'number',
          defaultValue: 6,
        },
        enableTrailingSlash: {
          type: 'boolean',
          defaultValue: false,
        },
      },
    },
    title: {
      type: 'object',
      fields: {
        content: {
          type: 'string',
          defaultValue: 'Explore Nearby Care',
        },
        element: {
          type: 'choice',
          options: Object.keys(headingSizes),
          defaultValue: headingSizes.h2,
        },
        linkText: 'string',
      },
    },
    content: {
      type: 'object',
      fields: {
        content: {
          type: 'string',
          defaultValue:
            'Expand your provider search by geography and care type.',
        },
      },
    },
    deviceVisibility: deviceVisibilityProps,
    nearbyCountiesSameType: {
      label: 'Nearby counties with same care type (nearbyCountiesSameType)',
      ...NearbyGeoPagesLinksConfig,
    },
    nearbyCitiesSameType: {
      label: 'Nearby cities with same care type (nearbyCitiesSameType)',
      ...NearbyGeoPagesLinksConfig,
    },
    otherTypesHere: {
      label: 'Other types here (otherTypesHere)',
      ...NearbyGeoPagesLinksConfig,
    },
    otherTypesNearby: {
      label: 'Other types nearby (otherTypesNearby)',
      ...NearbyGeoPagesLinksConfig,
    },
    data: 'object',
  },
});

const NearbyConfig = {
  type: 'object',
  fields: {
    enable: 'boolean',
    heading: 'string',
    headingElement: {
      type: 'choice',
      options: Object.keys(headingSizes),
      defaultValue: 'h2',
    },
    content: 'string',
    enableTrailingSlash: 'boolean',
  },
} as any;

PLASMIC.registerComponent(NearbyCareWrapper, {
  name: 'NearbyCare',
  props: {
    bgBorderRadius: {
      displayName: 'Border radius',
      type: 'string',
      defaultValue: '0',
    },
    communities: {
      label: 'Nearby Communities',
      ...NearbyConfig,
    },
    numberOfItemsPerRow: {
      displayName: 'Number of items per row',
      type: 'choice',
      options: ['2', '3', '4', '6'],
      defaultValue: '3',
    },
    providerTitleColor: {
      displayName: 'Title Color',
      type: 'choice',
      options: selectColor,
      defaultValue: 'primary',
    },
    providerTitleColorRange: {
      displayName: 'Rating (Stars) Color Range',
      type: 'choice',
      options: selectColorRange,
      defaultValue: '600',
    },
    displayBadges: 'boolean',
    displayLearnMoreButton: 'boolean',
    learnMoreButtonText: 'string',
    displayRequestInfoButton: 'boolean',
    inquiryId: 'string',
    readMoreButton: 'string',
    requestInfoButtonText: 'string',
    requestInfoButtonColorScheme: {
      displayName: 'Request Info Button Color',
      type: 'choice',
      options: selectColor,
      defaultValue: 'secondary',
    },
    learnMoreButtonColorScheme: {
      displayName: 'Learn More Button Color',
      type: 'choice',
      options: selectColor,
      defaultValue: 'secondary',
    },
    ratingStarsColor: {
      displayName: 'Rating (Stars) Color',
      type: 'choice',
      options: selectColor,
      defaultValue: 'tertiary',
    },
    ratingStarsColorRange: {
      displayName: 'Rating (Stars) Color Range',
      type: 'choice',
      options: selectColorRange,
      defaultValue: '400',
    },
    deviceVisibility: deviceVisibilityProps,
    data: 'object',
  },
});

PLASMIC.registerComponent(ExperimentViewed, {
  name: 'ExperimentViewed',
  props: {
    experimentId: {
      type: 'string',
      defaultValue: '',
    },
    experimentName: {
      type: 'string',
      defaultValue: '',
    },
    variationId: {
      type: 'string',
      defaultValue: '',
    },
    variationName: {
      type: 'string',
      defaultValue: '',
    },
  },
});

PLASMIC.registerComponent(MultiStepFormWrapper, {
  name: 'MultiStepForm',
  displayName: 'Multi-Step Form',
  props: {
    data: {
      type: 'object',
      defaultExpr: `$ctx.multiStepForm`,
      hidden: (props) => true,
    },
    templateId: {
      type: 'string',
      defaultValue: templateId,
      hidden: (props) => props.templateId !== undefined,
    },
    steps: {
      displayName: 'Steps',
      type: 'slot',
      allowedComponents: ['Form Step'],
      defaultValue: [
        {
          type: 'component',
          name: 'Form Step',
        },
      ],
    },
    currentStep: {
      displayName: 'Current Step',
      helpText: 'The current step to display while in the editor',
      editOnly: true,
      defaultValue: 0,
      type: 'choice',
      options: (props) => {
        return props.steps.props?.children
          ? props.steps.props.children.map((_, i) => ({
              value: i,
              label: `Step ${i + 1}`,
            }))
          : [
              {
                value: 0,
                label: `Step 1`,
              },
            ];
      },
    },
    generalTab: {
      type: 'custom',
      control: () => renderSettingsDivider('General'),
      displayName: '',
      editOnly: true,
      readOnly: true,
    },
    formBackgroundColor: {
      displayName: 'Background color for all forms',
      type: 'object',
      defaultValue: {
        color: 'background',
        range: '50',
      },
      fields: {
        color: {
          displayName: 'Form background color',
          type: 'choice',
          options: selectColor,
        },
        range: {
          displayName: 'Form background color range',
          type: 'choice',
          options: selectColorRange,
        },
      },
    },
    formEntriesColor: {
      displayName: 'Color for all form entries',
      type: 'object',
      defaultValue: {
        color: 'background',
        range: '400',
      },
      fields: {
        color: {
          displayName: 'Form entries color',
          type: 'choice',
          options: selectColor,
        },
        range: {
          displayName: 'Form entries color range',
          type: 'choice',
          options: selectColorRange,
        },
      },
    },
    errorColor: {
      displayName: 'Color for error messages',
      type: 'object',
      defaultValue: {
        color: 'error',
        range: '500',
      },
      fields: {
        color: {
          displayName: 'Color',
          type: 'choice',
          options: selectColor,
        },
        range: {
          displayName: 'Color Range',
          type: 'choice',
          options: selectColorRange,
        },
      },
    },
    image: {
      type: 'object',
      helpText:
        'Choose an image here if you want multiple steps to show the same image',
      defaultValue: {
        switchable: {
          field: 'none',
        },
      },
      fields: {
        switchable: {
          type: 'object',
          fields: {
            field: {
              displayName: 'Image',
              type: 'choice',
              options: [
                { value: 'none', label: 'None' },
                { value: 'externalSource', label: 'External Source (URL)' },
              ],
            },
            imageUrl: {
              type: 'string',
              hidden: (props) =>
                props.image?.switchable?.field !== 'externalSource',
            },
            imageAlt: {
              type: 'string',
              hidden: (props) =>
                props.image?.switchable?.field !== 'externalSource',
            },
          },
        },
      },
    },
    formSubmissionTab: {
      type: 'custom',
      control: () => renderSettingsDivider('Form Submission'),
      displayName: '',
      editOnly: true,
      readOnly: true,
    },
    errorMessages: {
      type: 'object',
      defaultValue: {
        errorOnSubmit: 'Something went wrong. Please try again.',
        validationError:
          'Please review the previous steps, there is an error on at least one of them.',
      },
      fields: {
        errorOnSubmit: {
          type: 'string',
          helpText: 'When form submission fails',
        },
        validationError: {
          type: 'string',
          helpText: 'When previous steps have errors',
        },
      },
    },
    thankYou: {
      type: 'object',
      defaultValue: {
        path: '/thank-you/',
      },
      fields: {
        path: {
          type: 'string',
          helpText: 'Path of the Thank You page',
        },
      },
    },
    eventTrackingTab: {
      type: 'custom',
      control: () => renderSettingsDivider('Event Tracking'),
      displayName: '',
      editOnly: true,
      readOnly: true,
    },
    formType: {
      type: 'object',
      defaultValue: {
        field: 'inquiry',
        display: 'horizontal',
        type: 'request-info',
        rollup: 'senior-living',
      },
      fields: {
        field: {
          displayName: 'Form type',
          type: 'choice',
          options: [
            { value: 'inquiry', label: 'Inquiry' },
            { value: 'other', label: 'Other' },
          ],
        },
        display: {
          displayName: 'Form display',
          type: 'choice',
          hidden: (props) => props?.formType?.field !== 'inquiry',
          options: [
            { value: 'vertical', label: 'Vertical' },
            { value: 'horizontal', label: 'Horizontal' },
            { value: 'full-page-modal', label: 'Full page modal' },
            { value: 'fit-content-modal', label: 'Fit to Content Modal' },
          ],
        },
        type: {
          displayName: 'Inquiry type',
          type: 'choice',
          hidden: (props) => props.formType?.field !== 'inquiry',
          options: [
            { value: 'request-info', label: 'Request info' },
            { value: 'display-cost-data', label: 'Display cost data' },
          ],
        },
        rollup: {
          displayName: 'Roll up type',
          type: 'choice',
          hidden: (props) => props.formType?.field !== 'inquiry',
          options: [
            { value: 'senior-living', label: 'Senior Living' },
            { value: 'senior-care', label: 'Senior Care' },
          ],
        },
      },
    },
    hiddenEntriesTab: {
      type: 'custom',
      control: () => renderSettingsDivider('Hidden Entries'),
      displayName: '',
      editOnly: true,
      readOnly: true,
    },
    hiddenEntries: {
      displayName: 'Entries',
      type: 'array',
      itemType: {
        type: 'object',
        description:
          'Hidden entries are values that need to be sent with the form, but that are not filled up by the user. Examples: UTM params, IP address, browser, etc. Tip: Use {client.browser} and {client.ip} to access browser info and client IP address',
        fields: {
          defaultValue: 'string',
          name: 'string',
          doNotSubmit: 'boolean',
        },
      },
    },
  },
  actions: [
    {
      type: 'button-action',
      label: 'Add step',
      onClick: ({ studioOps }) => {
        studioOps.appendToSlot(
          {
            type: 'component',
            name: 'Form Step',
          },
          'steps'
        );
      },
    },
  ],
});

PLASMIC.registerComponent(EmptyComponent, {
  name: 'Form Step',
  parentComponentName: 'MultiStepForm',
  hideFromContentCreators: true,
  providesData: true,
  props: {
    headline: {
      type: 'object',
      displayName: 'Step headline',
      defaultValue: {
        textFormatting: {
          field: 'default',
          element: 'h2',
          sizeOnMobile: 'md',
          sizeOnDesktop: 'lg',
          color: {
            color: 'gray',
            range: '900',
          },
        },
      },
      fields: {
        text: {
          type: 'string',
        },
        textFormatting: {
          type: 'object',
          fields: {
            field: {
              displayName: 'Text',
              type: 'choice',
              options: [
                { value: 'default', label: 'Use default' },
                { value: 'custom', label: 'Customize' },
              ],
            },
            element: {
              displayName: 'Text element',
              type: 'choice',
              options: Object.keys(headingSizes),
              hidden: (props, ctx, { item }) => item.field !== 'custom',
            },
            sizeOnMobile: {
              displayName: 'Text size on mobile',
              type: 'choice',
              options: Object.values(headingSizes),
              hidden: (props, ctx, { item }) => item.field !== 'custom',
            },
            sizeOnDesktop: {
              displayName: 'Text size on desktop',
              type: 'choice',
              options: Object.values(headingSizes),
              hidden: (props, ctx, { item }) => item.field !== 'custom',
            },
            color: {
              displayName: 'Text color',
              type: 'object',
              fields: {
                color: {
                  displayName: 'Color',
                  type: 'choice',
                  options: selectColor,
                },
                range: {
                  displayName: 'Color Range',
                  type: 'choice',
                  options: selectColorRange,
                },
              },
              hidden: (props, ctx, { item }) => item.field !== 'custom',
            },
            styles: {
              displayName: 'Text styles',
              type: 'choice',
              options: ['Bold', 'Italic'],
              multiSelect: true,
              hidden: (props, ctx, { item }) => item.field !== 'custom',
            },
          },
        },
      },
    },
    subHeadline: {
      displayName: 'Step subheadline',
      type: 'object',
      defaultValue: {
        textFormatting: {
          field: 'default',
          element: 'p',
          sizeOnMobile: 'sm',
          sizeOnDesktop: 'md',
          color: {
            color: 'gray',
            range: '800',
          },
        },
      },
      fields: {
        text: 'string',
        textFormatting: {
          type: 'object',
          fields: {
            field: {
              displayName: 'Text',
              type: 'choice',
              options: [
                { value: 'default', label: 'Use default' },
                { value: 'custom', label: 'Customize' },
              ],
            },
            element: {
              displayName: 'Text element',
              type: 'choice',
              options: [...Object.keys(headingSizes), 'p'],
              hidden: (props, ctx, { item }) => item.field !== 'custom',
            },
            sizeOnMobile: {
              displayName: 'Text size on mobile',
              type: 'choice',
              options: Object.values(headingSizes),
              hidden: (props, ctx, { item }) => item.field !== 'custom',
            },
            sizeOnDesktop: {
              displayName: 'Text size on desktop',
              type: 'choice',
              options: Object.values(headingSizes),
              hidden: (props, ctx, { item }) => item.field !== 'custom',
            },
            color: {
              displayName: 'Text color',
              type: 'object',
              fields: {
                color: {
                  displayName: 'Color',
                  type: 'choice',
                  options: selectColor,
                },
                range: {
                  displayName: 'Color Range',
                  type: 'choice',
                  options: selectColorRange,
                },
              },
              hidden: (props, ctx, { item }) => item.field !== 'custom',
            },
            styles: {
              displayName: 'Text styles',
              type: 'choice',
              options: ['Bold', 'Italic'],
              multiSelect: true,
              hidden: (props, ctx, { item }) => item.field !== 'custom',
            },
          },
        },
      },
    },
    image: {
      type: 'object',
      defaultValue: {
        switchable: {
          field: 'none',
        },
      },
      fields: {
        switchable: {
          type: 'object',
          fields: {
            field: {
              displayName: 'Image',
              type: 'choice',
              options: [
                { value: 'none', label: 'None' },
                { value: 'componentDefault', label: 'Same for All Steps' },
                { value: 'externalSource', label: 'External Source (URL)' },
              ],
            },
            imageUrl: {
              type: 'string',
              hidden: (props, ctx, { item }) => item.field !== 'externalSource',
            },
            imageAlt: {
              type: 'string',
              hidden: (props, ctx, { item }) => item.field !== 'externalSource',
            },
          },
        },
      },
    },

    formHeadline: {
      displayName: 'Form headline',
      type: 'string',
    },
    entryList: {
      displayName: 'Form entries:',
      type: 'array',
      itemType: {
        type: 'object',
        fields: {
          label: 'string',
          entryType: {
            displayName: 'Type',
            type: 'choice',
            defaultValue: 'input',
            options: [
              { value: 'input', label: 'Free-form text' },
              { value: 'select', label: 'Dropdown' },
            ],
          },
          placeholder: {
            type: 'string',
            displayName: 'Placeholder',
            hidden: (props, ctx, { item }) => {
              console.log('props', props);
              console.log('ctx', ctx);
              console.log('item', item);
              return item.entryType !== 'input';
            },
            // hidden: props => props.entryType !== 'input',
          },
          field: {
            type: 'choice',
            defaultValue: 'generic',
            options: [
              {
                value: 'generic',
                label: 'Generic (all inputs are accepted)',
              },
              { value: 'email', label: 'Email' },
              { value: 'phone', label: 'Phone Number' },
            ],
            hidden: (props) => props.entryType !== 'input',
          },
          emailTypeErrorMessage: {
            type: 'string',
            helpText: 'Error message to be rendered when value is not an email',
            defaultValue: 'Please enter a valid email',
            hidden: (props) => props.field !== 'email',
          },
          phoneTypeErrorMessage: {
            type: 'string',
            helpText:
              'Error message to be rendered when value is not a phone number',
            defaultValue: 'Please enter a valid US phone number',
            hidden: (props) => props.field !== 'phone',
          },
          typeMask: {
            type: 'boolean',
            defaultValue: true,
            displayName: 'Use custom mask to format phone number?',
            hidden: (props) => props.field !== 'phone',
          },
          mask: {
            type: 'string',
            displayName: 'Custom mask',
            defaultValue: '(999)999-9999',
            helpText:
              'Use the following characters: 9: numeric, A: letter, *: alphanumeric',
            hidden: (props) => props.field !== 'phone' && !props.typeMask,
          },
          isRequired: {
            displayName: 'Is this entry required?',
            type: 'boolean',
          },
          isRequiredErrorMessage: {
            type: 'string',
            displayName: 'Error message when user does not fill this entry',
            defaultValue: 'This field is required',
            hidden: (props) => !props.isRequired,
          },
          defaultValue: {
            displayName: 'Pre-selected value',
            type: 'string',
          },
          name: {
            displayName: 'Name of prop to store value at',
            helpText:
              'The name of the property on the payload sent when form is submitted',
            required: true,
            type: 'string',
          },
          doNotSubmit: {
            type: 'boolean',
          },
          options: {
            displayName: 'Options',
            type: 'array',
            itemType: {
              type: 'object',
              fields: {
                label: {
                  type: 'string',
                  displayName: 'Option label',
                  helpText: 'The text user will see on the dropdown',
                },
                value: {
                  type: 'string',
                  displayName: 'Option value',
                  helpText: 'The text to be sent on form submission',
                },
              },
            },
            hidden: (props, ctx, { item }) => {
              return item.entryType !== 'select';
            },
          },
        },
      },
    },
    cta: {
      type: 'object',
      displayName: 'Form CTA',
      defaultValue: {
        field: 'default',
        color: 'secondary',
        size: 'md',
        variant: 'solid',
      },
      fields: {
        label: {
          type: 'string',
          defaultValueHint: 'Next',
        },
        formatting: {
          type: 'object',
          fields: {
            field: {
              type: 'choice',
              options: [
                { value: 'default', label: 'Use default' },
                { value: 'custom', label: 'Customize' },
              ],
            },
            color: {
              displayName: 'CTA color',
              type: 'choice',
              options: selectColor,
              hidden: (props, ctx, { item }) => item.field !== 'custom',
            },
            size: {
              displayName: 'CTA size',
              type: 'choice',
              options: [
                { value: 'lg', label: 'Large' },
                { value: 'md', label: 'Medium' },
                { value: 'sm', label: 'Small' },
                { value: 'xs', label: 'Extra Small' },
              ],
              hidden: (props, ctx, { item }) => item.field !== 'custom',
            },
            variant: {
              displayName: 'CTA variant',
              type: 'choice',
              options: [
                { value: 'solid', label: 'Solid' },
                { value: 'outline', label: 'Outline' },
                { value: 'ghost', label: 'Ghost' },
              ],
              hidden: (props, ctx, { item }) => item.field !== 'custom',
            },
          },
        },
      },
    },
    legalDisclosure: {
      displayName: 'Legal disclosure',
      type: 'richText',
    },
  },
});
