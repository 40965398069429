import React, {
  useState,
  useEffect,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react';
import { Input } from '@chakra-ui/react';
import { getStateAbbreviation } from '~/utils/UsStates';
import useDebounce from '@hooks/useDebounce';
import { InputProps } from '@chakra-ui/input';
import AutocompleteList from './AutocompleteList';
import { Box } from '@chakra-ui/react';
import { BoxProps } from '@chakra-ui/layout';

const KEY_ARROW_UP = 'ArrowUp';
const KEY_ARROW_DOWN = 'ArrowDown';
const KEY_ENTER = 'Enter';

export interface AutocompleteInputProps extends InputProps {
  defaultValue?: string;
  buttonOutsideInput?: boolean;
  setKeyword?: Dispatch<SetStateAction<string>> | ((value: string) => void);
  listContainerProps?: BoxProps;
  name?: string;
}

export interface AutocompleteFormattedLabel {
  label: string;
  value: string;
}
export interface MapboxFeature {
  id: string;
  type: string;
  place_type?: string[] | null;
  relevance: number;
  properties: { mapbox_id: string };
  text: string;
  place_name: string;
  bbox?: number[] | null;
  center?: number[] | null;
  geometry: {
    type: string;
    coordinates?: number[] | null;
  };
  context?:
    | {
        id: string;
        mapbox_id: string;
        wikidata: string;
        text: string;
        short_code?: string | null;
      }[]
    | null;
}

export const autocompleteSuggestionMapBoxAPI = (inputParams, callback) => {
  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
    inputParams
  )}.json?access_token=${
    process.env.NEXT_PUBLIC_MAPBOX_API_KEY
  }&country=US&limit=6&types=place,postcode,locality`;
  fetch(url)
    .then((response) => response.json())
    .then((data) => {
      callback(data.features as MapboxFeature[]);
    })
    .catch((error) => {
      console.error('Mapbox error:', error);
    });
};

export const parseAutocompleteResults = (
  autocompleteResults: MapboxFeature[]
) => {
  return autocompleteResults.map((item) => {
    const isZip = item.place_type?.includes('postcode');
    const [city, stateAndZip] = item.place_name.split(', ');
    const lastSpaceIndex = stateAndZip.lastIndexOf(' ');

    const state = isZip ? stateAndZip.slice(0, lastSpaceIndex) : stateAndZip;
    const zip = isZip ? stateAndZip.slice(lastSpaceIndex + 1) : null;
    const data = {
      label: `${zip ? `${zip}, ` : ''}${city}, ${getStateAbbreviation(state)}`,
      value: `${zip ? `${zip}` : `${city}, ${getStateAbbreviation(state)}`}`,
    };
    return data;
  });
};

function AutocompleteInput({
  defaultValue = '',
  buttonOutsideInput,
  setKeyword,
  listContainerProps,
  name,
  ...rest
}: AutocompleteInputProps) {
  const [inputValue, setInputValue] = useState(defaultValue);
  const debouncedInputValue = useDebounce(inputValue, 300);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [autocompleteResults, setAutocompleteResults] = useState<
    MapboxFeature[]
  >([]);
  const [autocompleteList, setAutocompleteList] = useState<
    AutocompleteFormattedLabel[]
  >([]);
  const [currentFocus, setCurrentFocus] = useState(-1);
  const listRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const isFocusedAndHasResults = isInputFocused && autocompleteList.length > 0;
  const setInputValues = (input: string) => {
    setInputValue(input);
    if (setKeyword) {
      setKeyword(input);
    }
  };

  useEffect(() => {
    if (debouncedInputValue.length > 2) {
      autocompleteSuggestionMapBoxAPI(
        debouncedInputValue,
        setAutocompleteResults
      );
    } else if (autocompleteList.length > 0) {
      setAutocompleteResults([]);
      setAutocompleteList([]);
    }
  }, [debouncedInputValue, autocompleteList.length]);

  useEffect(() => {
    if (autocompleteResults?.length > 0) {
      setAutocompleteList(parseAutocompleteResults(autocompleteResults));
    }
  }, [autocompleteResults, setAutocompleteList]);

  const handleInputChange = (e) => {
    if (!isInputFocused) {
      setIsInputFocused(true);
    }
    setInputValues(e.target.value);

    if (autocompleteResults?.length > 0) {
      setAutocompleteList(parseAutocompleteResults(autocompleteResults));
    }
  };

  const handleArrowUp = () => {
    if (currentFocus > 0) setCurrentFocus(currentFocus - 1);
  };

  const handleArrowDown = () => {
    if (currentFocus + 1 < autocompleteList.length) {
      setCurrentFocus(currentFocus + 1);
    }
  };

  const handleEnter = (event) => {
    if (!isFocusedAndHasResults) return;
    event.preventDefault();
    if (
      currentFocus >= 0 &&
      currentFocus < listRef.current?.childNodes.length
    ) {
      setInputValues(
        (listRef.current?.childNodes[currentFocus] as HTMLElement).innerHTML
      );
      setIsInputFocused(false);
    }
  };

  const handleKeyDown = (event) => {
    switch (event.key) {
      case KEY_ARROW_UP:
        handleArrowUp();
        break;
      case KEY_ARROW_DOWN:
        handleArrowDown();
        break;
      case KEY_ENTER:
        handleEnter(event);
        break;
      default:
        break;
    }
  };

  return (
    <Box position="relative" width="100%">
      <Input
        type="text"
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onClick={() => setIsInputFocused(true)}
        onBlur={() => {
          if (isFocusedAndHasResults) {
            setInputValues(
              autocompleteList[currentFocus]?.value ??
                autocompleteList[0]?.value
            );
          }
          setIsInputFocused(false);
          setAutocompleteList([]);
          setCurrentFocus(-1);
        }}
        borderBottomRadius={isFocusedAndHasResults ? '0' : 'md'}
        value={inputValue}
        name={name}
        {...rest}
      />
      {isFocusedAndHasResults && (
        <AutocompleteList
          autocompleteList={autocompleteList}
          currentFocus={currentFocus}
          listRef={listRef}
          setInputValue={setInputValue}
          setKeyword={setKeyword}
          setIsInputFocused={setIsInputFocused}
          setCurrentFocus={setCurrentFocus}
          {...listContainerProps}
        />
      )}
    </Box>
  );
}
export default AutocompleteInput;
