import { Checkbox } from '@chakra-ui/checkbox';
import { Flex, HStack, Text } from '@chakra-ui/layout';
import { STRING_TO_ICON_COMPONENT } from '@components/RenderIcon';
import { ChangeEvent } from 'react';
import { MapFeature } from '~/types/MapFeature';

type Props = {
  features: MapFeature[];
  setFeatures: React.Dispatch<React.SetStateAction<MapFeature[] | null>>;
};

const MapFeatures: React.FC<Props> = ({ features, setFeatures }) => {
  const onChangeCheckbox = (featureId: string, isChecked: boolean) => {
    const indexFeature = features.findIndex(
      (feature) => feature.featureId === featureId
    );

    const featuresTemp = Array.from(features);
    featuresTemp[indexFeature] = {
      ...featuresTemp[indexFeature],
      active: isChecked,
    };

    setFeatures(featuresTemp);
  };

  return (
    <Flex bgColor="caringGray.100" gap={10} py={2} px={4}>
      {features.map((feature) => (
        <HStack key={feature.altText} spacing={2}>
          <Checkbox
            defaultChecked={feature.active}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onChangeCheckbox(feature.featureId, e.target.checked)
            }
            colorScheme={feature.pinColor}
          />
          <HStack spacing={1}>
            {STRING_TO_ICON_COMPONENT[feature.icon]}
            <Text fontWeight="bold" fontSize="sm">
              {feature.altText}
            </Text>
          </HStack>
        </HStack>
      ))}
    </Flex>
  );
};

export default MapFeatures;
