import { HStack, IconButton } from '@chakra-ui/react';
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md';
import { Color, getColor } from '~/utils/getColor';

interface Props {
  color?: Color;
  currentStep: number;
  totalSteps: number;
  setCurrentStep: (newStep: number) => void;
}

const StepPagination: React.FC<Props> = ({
  currentStep,
  color = {
    color: 'gray',
    range: '300',
  },
  totalSteps,
  setCurrentStep,
}) => {
  const colorKey = getColor(color.color, color.range);
  return (
    <HStack>
      <IconButton
        {...{
          background: 'white',
          color: colorKey,
          borderColor: colorKey,
          borderWidth: '1px',
        }}
        size="sm"
        isDisabled={currentStep === 0}
        onClick={() => {
          setCurrentStep(currentStep - 1);
        }}
        aria-label="Previous step"
      >
        <MdArrowBackIosNew />
      </IconButton>
      <IconButton
        {...{
          background: 'white',
          color: colorKey,
          borderColor: colorKey,
          borderWidth: '1px',
        }}
        size="sm"
        isDisabled={currentStep === totalSteps - 1}
        onClick={() => {
          setCurrentStep(currentStep + 1);
        }}
        aria-label="Next step"
      >
        <MdArrowForwardIos />
      </IconButton>
    </HStack>
  );
};

export default StepPagination;
