import { Button } from '@chakra-ui/button';
import { Section } from '@components/Sections';
import { useRouter } from 'next/router';
import { useTenantFunctions } from '~/contexts/TenantFunctionsContext';
import NearbyGrid from './NearbyGrid';
import { NearByLinkProps } from './types';

const NearbyOtherCareTypesNearbyCity = ({
  heading,
  headingElement = 'h3',
  content,
  links,
  careType,
  enableTrailingSlash = false,
}: NearByLinkProps) => {
  const route = useRouter();
  const { getCareTypePath } = useTenantFunctions();
  return (
    <>
      <Section
        headingElement={headingElement}
        title={heading}
        richText={content}
      />
      <NearbyGrid align="flex-start">
        {links.map((option, index) => {
          const path = getCareTypePath(option, route, enableTrailingSlash);

          return (
            <a key={index} href={path} target="_blank">
              <Button
                variant="outline"
                colorScheme="primary"
                whiteSpace="pre-wrap"
              >
                {option.linkText}
              </Button>
            </a>
          );
        })}
      </NearbyGrid>
    </>
  );
};

export default NearbyOtherCareTypesNearbyCity;
