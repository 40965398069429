import { useContext, MouseEvent } from 'react';
import { IconButton, Button } from '@chakra-ui/react';
import { HStack, Link, Text } from '@chakra-ui/layout';
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md';
import SiteContext from '~/contexts/SiteContext';
import { getPaginationStructure } from '@utils/pagination';
import {
  getStylesForDisabledNavigationButton,
  getStylesForEnabledNavigationButton,
  getStylesForPageButton,
  getStylesForActivePageButton,
} from './SearchPaginationStyles';

interface Props {
  currentPage: number;
  totalPages: number;
  setPage: (page: number) => void;
  baseUrl: string;
  queryParamKey?: string;
}

const SearchPagination: React.FC<Props> = ({
  currentPage,
  totalPages,
  setPage,
  baseUrl,
  queryParamKey,
}) => {
  const siteProps = useContext(SiteContext);
  const domain = siteProps.site?.path ?? '';

  const reactCurrentPage = currentPage + 1;
  const isFirstPage = reactCurrentPage - 1 === 0;
  const isLastPage = reactCurrentPage === totalPages;
  const isOutOfRange = reactCurrentPage < 1 || reactCurrentPage > totalPages;
  const isNextPageDisabled = isLastPage || isOutOfRange;
  const isPrevPageDisabled = isFirstPage || isOutOfRange;

  const pagination = getPaginationStructure({
    currentPage: reactCurrentPage,
    totalPages,
    siblingCount: 0,
    displayLeftEllipsis: false,
  });

  if (totalPages <= 1) {
    return null;
  }

  const navigateToPage = (pageIndex: number, e: MouseEvent) => {
    e.preventDefault();
    setPage(pageIndex);
  };

  const includeLinkTags = baseUrl && queryParamKey;
  const getLinkForPageIndex = (pageIndex: number): string =>
    pageIndex === 0 ? baseUrl : `${baseUrl}?${queryParamKey}=${pageIndex + 1}`;

  // currentPage is zero-based
  const prevPageLink = getLinkForPageIndex(currentPage - 1);
  const nextPageLink = getLinkForPageIndex(currentPage + 1);
  const prevPageProps = { href: prevPageLink, as: Link };
  const nextPageProps = { href: nextPageLink, as: Link };

  const prevPageButtonStyles = isPrevPageDisabled
    ? getStylesForDisabledNavigationButton(domain)
    : getStylesForEnabledNavigationButton(domain);
  const nextPageButtonStyles = isNextPageDisabled
    ? getStylesForDisabledNavigationButton(domain)
    : getStylesForEnabledNavigationButton(domain);
  const regularPageButtonStyles = getStylesForPageButton(domain);
  const activePageButtonStyles = getStylesForActivePageButton(domain);

  return (
    <HStack justify="center">
      <IconButton
        {...(includeLinkTags && !isPrevPageDisabled && prevPageProps)}
        {...prevPageButtonStyles}
        size="sm"
        isDisabled={isPrevPageDisabled}
        onClick={(e) => navigateToPage(currentPage - 1, e)}
        aria-label="Previous page"
      >
        <MdArrowBackIosNew />
      </IconButton>
      {pagination.map((paginationItem, index) => {
        if (paginationItem.type === 'page') {
          const isActivePage = reactCurrentPage === paginationItem.value;
          const buttonStyles = isActivePage
            ? activePageButtonStyles
            : regularPageButtonStyles;
          return (
            <Button
              {...buttonStyles}
              key={paginationItem.value}
              size="sm"
              height="32px"
              width="32px"
              textDecoration="none"
              _hover={{
                ...buttonStyles['_hover'],
                textDecoration: 'none',
              }}
              onClick={(e: MouseEvent) =>
                navigateToPage(paginationItem.value - 1, e)
              }
              as={Link}
              href={getLinkForPageIndex(paginationItem.value - 1)}
              aria-label={`Page ${paginationItem.value}`}
            >
              <Text>{paginationItem.value}</Text>
            </Button>
          );
        } else {
          return (
            <Button
              {...regularPageButtonStyles}
              key={`ellipsis-${index}`}
              size="sm"
              height="32px"
              width="32px"
              textDecoration="none"
              _hover={{ textDecoration: 'none' }}
              pointerEvents="none"
              tabIndex={-1}
            >
              <Text>...</Text>
            </Button>
          );
        }
      })}
      <IconButton
        {...(includeLinkTags && !isNextPageDisabled && nextPageProps)}
        {...nextPageButtonStyles}
        size="sm"
        isDisabled={isNextPageDisabled}
        onClick={(e) => navigateToPage(currentPage + 1, e)}
        aria-label="Next page"
      >
        <MdArrowForwardIos />
      </IconButton>
    </HStack>
  );
};

export default SearchPagination;
