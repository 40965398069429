import Image, { ImageProps } from 'next/image';
import { MagnoliaImage } from '~/types/Magnolia';

interface DAMImageProps extends Omit<ImageProps, 'src' | 'alt'> {
  src: MagnoliaImage;
  priority?: boolean;
  maxWidth?: string;
  maxHeight?: string;
  fallbackAlt?: string;
}

const DAMImage: React.FC<DAMImageProps> = ({
  src,
  priority,
  maxWidth,
  maxHeight,
  fallbackAlt,
  fill,
  ...rest
}) => {
  if (
    !src ||
    typeof src['@path'] === 'undefined' ||
    typeof src['@id'] === 'undefined'
  ) {
    return null;
  }

  const baseUrl = process.env.NEXT_PUBLIC_MGNL_HOST || null;
  if (!baseUrl) {
    return null;
  }

  return (
    <Image
      src={baseUrl + '/dam/' + src['@id'] + src['@path']}
      alt={src.metadata.caption ?? fallbackAlt}
      width={fill ? undefined : src.metadata.width || 500}
      height={fill ? undefined : src.metadata.height || 500}
      priority={priority}
      fill={fill}
      {...rest}
    />
  );
};

export default DAMImage;
