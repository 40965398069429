import Head from 'next/head';

export type SearchCarouselItemList = {
  '@type': string;
  position: number;
  url: string;
  image?: {
    '@type': string;
    name: string;
    url: string;
  };
};

interface Props {
  numberOfItems: number;
  name?: string;
  data: SearchCarouselItemList[];
}

const SeoCarousel = ({ numberOfItems, name, data }: Props) => {
  const json: Record<string, unknown> = {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    numberOfItems: numberOfItems,
    itemListElement: data,
  };

  if (name) {
    json.name = name;
  }

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(json) }}
      />
    </Head>
  );
};

export default SeoCarousel;
