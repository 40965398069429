const BATCH_SIZE = 10000;

const buildURL = (url: string, params: Record<string, string>) => {
  // create a new URL object with the url passed in
  const fetchUrl = new URL(url);

  // for each key value pair in the params object, add to the query string
  Object.entries(params).forEach(([key, value]) => {
    fetchUrl.searchParams.set(key, value);
  });

  // return the url object
  return fetchUrl;
};

export const fetchPages = async ({
  url,
  offset = 0,
  limit = BATCH_SIZE,
  filters = {},
}) => {
  // initialize an empty array to hold the results
  const results: any[] = [];

  // set the offset and limit parameters for the fetch
  const fetchParams = {
    offset: String(offset),
    limit: String(limit),
    ...filters,
  };

  // build the url with the updated parameters
  const fetchUrl = buildURL(url, fetchParams);

  // fetch the data from the api
  const response = await fetch(fetchUrl).then((res) => res.json());

  // if the response has results, add them to the results array
  if (response.results) {
    results.push(...response.results);
  }

  // set the next offset
  const nextOffset = offset + limit;

  // if there are more results to fetch, recursively call this function
  if (response.total > nextOffset) {
    const nextResults = await fetchPages({
      url,
      offset: nextOffset,
      limit,
    });
    results.push(...nextResults);
  }

  // return the results array
  return results;
};
