import { Grid, GridItem, VStack } from '@chakra-ui/layout';
import {
  navigateToQuery,
  navigateToSearchPage,
} from '@components/FacetedSearch/navigation';
import { getFacetedSearchParams } from '@components/FacetedSearch/search-params';
import { getSourceForFacetedSearch } from '@components/FacetedSearch/utils';
import Heading from '@components/Heading';
import { PageContext } from '@components/LayoutStructure/EditablePage';
import SearchBar from '@components/SearchBar';
import { SEARCH_PARAM } from '@constants/search-params';
import useInquiryFormSubmitted from '@hooks/use-inquiry-form-submitted';
import useQueryState from '@hooks/use-query-state';
import useSearch from '@hooks/useSearch';
import { getProviders } from '@services/enhanced-search/api';
import { AmenityCategoryNode, CareType } from '@utils/faceted-search';
import { getBackgroundColor } from '@utils/getColor';
import { strip } from '@utils/parser';
import isObject from 'lodash/isObject';
import kebabCase from 'lodash/kebabCase';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useCallback, useContext, useState } from 'react';
import withHydrationOnDemand from 'react-hydration-on-demand';
import 'setimmediate';
import { HeadingElements, HeadingSizes } from '~/@types/heading';
import { Provider } from '~/contexts/Provider';
import SiteContext from '~/contexts/SiteContext';
import { useTenantFunctions } from '~/contexts/TenantFunctionsContext';
import {
  DefaultFilterValues,
  PhoneNumberConfig,
} from '~/types/componentsConfig';
import { Domain, Domains } from '~/types/Domains';
import { EditableAreaType, Metadata } from '~/types/Magnolia';
import { getKeywordQueryParams } from '../EnhancedSearch.utils';
import { getAmenitiesByCategory } from '../FacetedSearch/utils';
import { HandleSearchParams, SearchStates } from '../Search';
import { getDefaultKeywords, RenderResults } from '../Search.utils';
import SearchPagination from '../SearchPagination';
import SearchResultSkeleton from '../SearchResultSkeleton';
import ShowMore from '../ShowMore';
import { DisplayMode, DISPLAY_MODE, SearchOption } from './constants';
import { parseComponentConfig } from './parser';
import { CareTypeObject, CareTypeString } from './ServerComponent';
import SearchSeoCarousel from '@components/Search/SearchSeoCarousel';
const GuidedSearchBanner = dynamic(
  () => import('@components/GuidedSearch/GuidedSearchBanner')
);
const Container = dynamic(
  () => import('@components/LayoutStructure/Container')
);

/** CONSTANTS */
export const CARE_TYPE_PARAM = 'care-type';
export const DEFAULT_HITS_PER_PAGE = 20;
export const DEFAULT_RADIUS_FOR_SEARCH_IN_MILES = 25;
export const PAGE_NUMBER_PARAM = 'listing-page';
export const SEARCH_KEYWORD_PARAM = 'keyword';

/** TYPES and INTERFACES */
export interface EnhancedSearchComponentDialogProps {
  countOfTiles?: string;
  countOfTilesPerRow?: string;
  radiusForSearch?: number;
  title?: string;
  headingElement: HeadingElements;
  titleSize: HeadingSizes;
  desktopTitleSize: HeadingSizes;
  state?: string;
  city?: string;
  county?: string;
  latitude?: string;
  longitude?: string;
  careType?: CareTypeObject | CareTypeString;
  data: SearchData;
  displayGuidedSearchBanner?: boolean;
  guidedSearchInquiryId?: string;
  fullWidthTile?: boolean;
  displayCompareOption?: boolean;
  displayBadges?: boolean;
  excludeNearby?: boolean;
  displayToggleMap?: boolean;
  hideSearchButton?: boolean;
  searchBarBgColor: string;
  searchBarBgColorRange: string;
  searchBarButtonColorScheme: string;
  displaySearchBar?: boolean;
  displayTotal?: boolean;
  blurCosts?: boolean;
  readMoreButton?: string;
  genericBlock1: EditableAreaType;
  genericBlock2: EditableAreaType;
  metadata: Metadata;
  displayLearnMoreButton?: boolean;
  learnMoreButtonText?: string;
  displayRequestInfoButton?: boolean;
  infoButtonInquiryId?: string;
  requestInfoButtonText?: string;
  requestInfoButtonColorScheme: string;
  learnMoreButtonColorScheme: string;
  ratingStarsColor: string;
  ratingStarsColorRange: string;
  providerTitleColor: string;
  providerTitleColorRange: string;
  visibleLimit?: string;
  bgColor: string;
  bgColorRange: string;
  bgBorderRadius: number;
  displayLocationFilter: boolean;
  readOnlyLocationInput: boolean;
  displayCareTypeFilter: boolean;
  displayProviderPhoneNumber: boolean;
  providerPhoneNumberSource?: {
    field: string;
    providerPhoneNumber?: string;
  };
  readOnlyLocationInputPlaceholder: string;
  enablePredictiveSearch: boolean;
  boxShadow: string;
  tileBorder: string;
  tileBorderColor: string;
  tileBorderColorRange: string;
  titleAlignment?: 'left' | 'center' | 'right';
  facetedSearch?: {
    enabled?: boolean;
    amenityCategory?: string;
    basePath?: string;
  };
  maxWidth?: number;
  filterAndSort?: {
    filters?: Array<SearchOption>;
    phoneNumber?: PhoneNumberConfig;
    defaultValues?: DefaultFilterValues;
  };
  mapView?: {
    enabled?: boolean;
  };
  displayMode?: DisplayMode;
  groupSearchCareTypeOptions?: boolean;
  preFillWithPageValues?: boolean;
  dontOpenInNewTab: boolean;
  promotionColorScheme?: string;
}

export type ZeroIndexedPage = number;

export interface SearchData {
  amenityCategories: AmenityCategoryNode[];
  careTypes: CareTypeObject[];
  currentPage: number;
  listId?: string;
  nearbyCount: number;
  outOfBounds: boolean;
  queryId?: string;
  regionCount: number;
  resultCount: number;
  results: Provider[];
  resultsPerPage: ZeroIndexedPage;
  searchState: SearchStates;
}

/** COMPONENT */
const EnhancedSearch: React.FC<EnhancedSearchComponentDialogProps> = (
  props
) => {
  const {
    countOfTiles: countOfTilesFromProps,
    radiusForSearch,
    title,
    headingElement,
    titleSize = 'xl',
    desktopTitleSize = '2xl',
    titleAlignment = 'left',
    state,
    city,
    county,
    latitude,
    longitude,
    careType,
    displayCompareOption = false,
    displayGuidedSearchBanner = false,
    guidedSearchInquiryId,
    fullWidthTile = false,
    displayBadges = false,
    excludeNearby = false,
    hideSearchButton = false,
    searchBarBgColor,
    searchBarBgColorRange,
    searchBarButtonColorScheme,
    data,
    displayToggleMap,
    displaySearchBar,
    displayTotal,
    countOfTilesPerRow,
    blurCosts,
    readMoreButton,
    genericBlock1,
    genericBlock2,
    metadata,
    providerTitleColor,
    providerTitleColorRange,
    displayLearnMoreButton,
    learnMoreButtonText,
    displayRequestInfoButton,
    requestInfoButtonColorScheme,
    learnMoreButtonColorScheme,
    ratingStarsColor,
    ratingStarsColorRange,
    infoButtonInquiryId,
    requestInfoButtonText,
    visibleLimit = 0,
    bgColor,
    bgColorRange,
    bgBorderRadius = 0,
    displayLocationFilter = true,
    readOnlyLocationInput = false,
    displayCareTypeFilter = false,
    readOnlyLocationInputPlaceholder = '',
    enablePredictiveSearch = false,
    boxShadow = 'lg',
    tileBorder = 'none',
    tileBorderColor = 'none',
    tileBorderColorRange = 'none',
    displayProviderPhoneNumber = false,
    providerPhoneNumberSource,
    facetedSearch,
    maxWidth,
    displayMode = DISPLAY_MODE.LIST,
    groupSearchCareTypeOptions,
    dontOpenInNewTab,
    preFillWithPageValues = false,
    promotionColorScheme = 'primary',
  } = props;
  const { buildSearchUrl } = useSearch();
  const router = useRouter();
  const parsedConfig = parseComponentConfig(props);
  const searchParams = getFacetedSearchParams(router.query);
  // https://caring.atlassian.net/browse/CME-893
  // this should be temporary
  const alteredCounty = strip(county?.replace('County', '').trim());
  const { query } = router;
  const queryPage = Number(query[PAGE_NUMBER_PARAM]);
  const { getProviderDetailsPath, getProviderDescription } =
    useTenantFunctions();
  const pageProps = useContext(PageContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [results, setResults] = useState<Provider[]>(data.results);
  const [resultCount, setResultCount] = useState<number>(data.resultCount);
  const [searchState, setSearchState] = useState<SearchStates>(
    data.searchState
  );
  const queryKeyword =
    getKeywordQueryParams(query[SEARCH_KEYWORD_PARAM]) ?? ('' as string);
  const [currentPage, setCurrentPage] = useState<number>(
    !isNaN(queryPage) ? queryPage - 1 : 0
  );
  const [showMoreLimit, setShowMoreLimit] = useState<number>(
    Number(visibleLimit)
  );
  const inquiryFormHasSubmitted = useInquiryFormSubmitted();
  const showPrice = blurCosts ? inquiryFormHasSubmitted : true;
  const [keyword] = useQueryState(
    'keyword',
    getDefaultKeywords({
      state,
      city,
      county: alteredCounty,
      queryKeyword,
    })
  );
  const [careTypeFromQuery] = useQueryState(SEARCH_PARAM.CARE_TYPE);
  const alteredCareType = isObject(careType) ? careType.name : strip(careType);
  const hitsPerPage = Number(countOfTilesFromProps) || DEFAULT_HITS_PER_PAGE;
  const howManyPages = Math.ceil(resultCount / hitsPerPage);
  const lat = parseFloat(latitude ?? '') || 0;
  const lng = parseFloat(longitude ?? '') || 0;
  const itemsPerRow = Number(countOfTilesPerRow) * 3 ?? 12;
  const siteContext = useContext(SiteContext);
  const domain = siteContext.site?.path as Domain;

  const handleSearch = useCallback(
    async ({ keyword, careType, page = 0 }: HandleSearchParams) => {
      setIsLoading(true);
      const amenityCategory = facetedSearch?.amenityCategory ?? '';
      const source = getSourceForFacetedSearch({
        displayMode,
        domain,
        amenityCategoryChips: parsedConfig.amenityCategoryChips,
        searchParams,
      });
      const amenities = getAmenitiesByCategory(amenityCategory);
      const data = await getProviders({
        filters: {
          // excludeNearby,
          amenities,
          careType: kebabCase(careType as string),
          city: city,
          county: county,
          hitsPerPage,
          keyword: keyword as string,
          latitude: String(lat),
          longitude: String(lng),
          page,
          radiusForSearch: radiusForSearch ?? 0,
          source,
          state: state,
        },
        apiVersion: String(router.query.geo),
      });

      // Fallback to the first page if the page number is greater than the total number of pages
      const totalPages = Math.max(0, data.totalItems - 1); // page is zero-based
      if (page > totalPages) {
        setCurrentPage(0);
        navigateToSearchPage(router, 0);
        handleSearch({
          keyword: keyword as string,
          page: 0,
          careType: alteredCareType ?? (careTypeFromQuery as string) ?? '',
        });
        return;
      }

      setResults(data.results as unknown as Provider[]);
      setResultCount(data.totalItems);
      setSearchState(
        data.totalItems === 0
          ? SearchStates.INPUT_WITH_NO_RESULTS
          : SearchStates.INPUT_WITH_RESULTS
      );
      setIsLoading(false);
    },
    [
      // excludeNearby,
      alteredCareType,
      careTypeFromQuery,
      city,
      county,
      displayMode,
      domain,
      facetedSearch,
      hitsPerPage,
      lat,
      lng,
      radiusForSearch,
      router,
      state,
      searchParams,
      parsedConfig.amenityCategoryChips,
    ]
  );

  const handlePagination = (newPage: number) => {
    setCurrentPage(newPage);
    navigateToSearchPage(router, newPage);
    handleSearch({
      keyword: keyword as string,
      page: newPage,
      careType: alteredCareType || (careTypeFromQuery as string),
    });

    setImmediate(() => {
      document.getElementById('listings-app')?.scrollIntoView();
    });
  };

  const handleShowMore = (showMoreLimit: number) => {
    setShowMoreLimit(showMoreLimit);
  };

  return (
    <Container
      id="listings-app"
      sx={{ scrollMarginTop: '8' }}
      borderRadius={bgBorderRadius}
      bg={getBackgroundColor(bgColor, bgColorRange)}
      py="8"
    >
      <SearchSeoCarousel
        results={results ?? []}
        state={state}
        city={city}
        county={county}
        careType={kebabCase(careType as string)}
        totalCount={resultCount}
      />
      <VStack alignItems={'left'} spacing="5">
        {title && (
          <Heading
            as={headingElement}
            size={{ base: titleSize, md: desktopTitleSize }}
            textAlign={titleAlignment}
            title={title}
            withContainer={false}
          />
        )}

        {displaySearchBar ? (
          <SearchBar
            amenityCategories={data.amenityCategories}
            careTypes={data.careTypes}
            groupSearchCareTypeOptions={groupSearchCareTypeOptions}
            displayCareTypeFilter={displayCareTypeFilter}
            displayLocationFilter={displayLocationFilter}
            displayToggleMap={displayToggleMap}
            displayTotal={displayTotal}
            enablePredictiveSearch={enablePredictiveSearch}
            facetedSearch={facetedSearch}
            hideSearchButton={hideSearchButton}
            isLoading={isLoading}
            maxWidth={maxWidth}
            readOnlyLocationInput={readOnlyLocationInput}
            readOnlyLocationInputPlaceholder={readOnlyLocationInputPlaceholder}
            searchBarBgColor={searchBarBgColor}
            searchBarBgColorRange={searchBarBgColorRange}
            searchBarButtonColorScheme={searchBarButtonColorScheme}
            searchBarId={metadata?.['@id']}
            totalResults={resultCount}
            careType={parsedConfig.searchOptions.careType ?? ''}
            cityOrState={props.city || props.state}
            preFillWithPageValues={preFillWithPageValues}
            onSubmit={(formData) => {
              const careType = formData.careType ?? CareType.AssistedLiving;
              const keyword = formData.keyword ?? '';

              if (
                domain === Domains.CaringDomains.LIVE &&
                parsedConfig.searchOptions.displayMode === DISPLAY_MODE.LIST
              ) {
                const searchPageUrl = buildSearchUrl({ careType, keyword });
                location.assign(searchPageUrl);
              } else {
                const searchParams = new URLSearchParams({
                  [SEARCH_PARAM.KEYWORD]: keyword,
                  [SEARCH_PARAM.CARE_TYPE]: careType,
                });
                navigateToQuery(router, searchParams);
              }

              handleSearch({
                careType: formData.careType,
                keyword: formData.keyword,
              });
            }}
          />
        ) : null}

        {displayGuidedSearchBanner && (
          <GuidedSearchBanner modalId={guidedSearchInquiryId} />
        )}

        {isLoading ? (
          <Grid
            templateColumns={
              fullWidthTile
                ? '1fr'
                : {
                    base: 'repeat(3, 1fr)',
                    sm: 'repeat(6, 1fr)',
                    md: `repeat(${itemsPerRow}, 1fr)`,
                  }
            }
            gap={6}
          >
            {new Array(6).fill(0).map((value, key) => (
              <GridItem colSpan={3} key={`${key}-${value}`}>
                <SearchResultSkeleton fullWidth={fullWidthTile} />
              </GridItem>
            ))}
          </Grid>
        ) : (
          <RenderResults
            results={results}
            searchState={searchState}
            itemsPerRow={itemsPerRow}
            providerTitleColor={providerTitleColor}
            providerTitleColorRange={providerTitleColorRange}
            requestInfoButtonColorScheme={requestInfoButtonColorScheme}
            learnMoreButtonColorScheme={learnMoreButtonColorScheme}
            ratingStarsColor={ratingStarsColor}
            ratingStarsColorRange={ratingStarsColorRange}
            boxShadow={boxShadow}
            tileBorder={tileBorder}
            tileBorderColor={tileBorderColor}
            tileBorderColorRange={tileBorderColorRange}
            fullWidthTile={fullWidthTile}
            displayBadges={displayBadges}
            showPrice={showPrice}
            genericBlock1={genericBlock1}
            genericBlock2={genericBlock2}
            metadata={metadata}
            currentPage={currentPage}
            getProviderDetailsPath={getProviderDetailsPath}
            getProviderDescription={getProviderDescription}
            showMoreLimit={howManyPages > 1 ? 0 : showMoreLimit}
            displayLearnMoreButton={displayLearnMoreButton}
            learnMoreButtonText={learnMoreButtonText}
            displayRequestInfoButton={displayRequestInfoButton}
            requestInfoButtonText={requestInfoButtonText}
            readMoreButton={readMoreButton}
            inquiryId={infoButtonInquiryId}
            displayProviderPhoneNumber={displayProviderPhoneNumber}
            providerPhoneNumberSource={providerPhoneNumberSource}
            careType={alteredCareType || (careTypeFromQuery as string)}
            queryId={data.queryId}
            listId={data.listId}
            displayCompareOption={displayCompareOption}
            dontOpenInNewTab={dontOpenInNewTab}
            promotionColorScheme={promotionColorScheme}
          />
        )}
        {Number(visibleLimit) > 0 && howManyPages <= 1 && (
          <ShowMore
            limit={Number(visibleLimit)}
            currentLimit={showMoreLimit}
            setShowMore={handleShowMore}
            resultCount={resultCount}
          />
        )}
        <SearchPagination
          currentPage={currentPage}
          totalPages={howManyPages}
          setPage={handlePagination}
          baseUrl={pageProps?.page?.canonical || ''}
          queryParamKey={PAGE_NUMBER_PARAM}
        />
      </VStack>
    </Container>
  );
};

export default withHydrationOnDemand({
  on: [['scroll', () => document], ['visible']],
})(EnhancedSearch);
