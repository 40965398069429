import { Box } from '@chakra-ui/layout';
import { Hide } from '@chakra-ui/media-query';
import Heading from '@components/Heading';
import Container from '@components/LayoutStructure/Container';
import { mockModMonProvider } from '@mocks/modmon.mock';
import { normalizeMapFeatures } from '@utils/normalizeMapFeatures';
import { ParseMagnoliaPage } from '@utils/parser/magnolia';
import { AdvancedMarker, Map as GoogleMap } from '@vis.gl/react-google-maps';
import kebabCase from 'lodash/kebabCase';
import { useContext, useEffect, useMemo, useState } from 'react';
import withHydrationOnDemand from 'react-hydration-on-demand';
import { HeadingElements } from '~/@types/heading';
import ProviderContext from '~/contexts/Provider';
import { MapFeature } from '~/types/MapFeature';
import { getPinMarkers, PinMarker } from './Map.utils';
import MapFeatures from './MapFeatures';
import MapOverlay from './MapOverlay';
import MapWithoutCoordinates from './MapWithoutCoordinates';
import { APIProvider } from '@vis.gl/react-google-maps';
import {
  DeviceVisibility,
  useResponsiveDisplay,
} from '@hooks/useResponsiveDisplay';

const MAP_ID = process.env.NEXT_PUBLIC_GOOGLE_MAPS_LOCATION_MAP_ID;
const GOOGLE_MAP_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY || '';

export enum PositionBy {
  BY_ADDRESS = 'byAddress',
  BY_COORDINATES = 'byLatLong',
}

export type Coordinates = {
  lat: number;
  lng: number;
};

export type MapProps = {
  title?: string;
  headingElement?: HeadingElements;
  width?: string;
  height?: string;
  zoom: number;
  hasZoomControl: boolean;
  hasDoubleClickZoom: boolean;
  hasScrollWheelZoom: boolean;
  hasStreetViewControl: boolean;
  hasMapTypeControl: boolean;
  hasDraggable: boolean;
  hasFullScreenControl: boolean;
  latitude?: string;
  longitude?: string;
  features: {
    [key: string]: string | string[] | undefined | MapFeature;
  };
  overlayText?: string;
  limitCharsBeforeCTA?: number;
  ctaText?: string;
  ctaBehavior?: string;
  ctaUrl?: string;
  ctaRel?: ['external' | 'nofollow' | 'noopener' | 'noreferrer' | 'opener'];
  data?: Coordinates;
  templateId?: string;
  ignoreMaxWidth?: boolean;
  className?: string;
  deviceVisibility?: DeviceVisibility;
};

const SMALL_SCREEN = '940px';
const ICON_TO_CENTERED_POINT = 'MdLocationSearching';
const COLOR_TO_CENTERED_POINT = 'orange';

const Map: React.FC<MapProps> = (props) => {
  const {
    title = '',
    headingElement = 'h2',
    width = '100%',
    height = '100vh',
    zoom,
    hasZoomControl,
    hasDoubleClickZoom,
    hasScrollWheelZoom,
    hasStreetViewControl,
    hasMapTypeControl,
    hasDraggable,
    hasFullScreenControl,
    features: featuresFromProps,
    overlayText = '',
    limitCharsBeforeCTA,
    ctaText,
    ctaBehavior,
    ctaUrl,
    ctaRel,
    latitude,
    longitude,
    templateId,
    ignoreMaxWidth = false,
    className,
    deviceVisibility,
  } = props;
  const [displayMap, setDisplayMap] = useState(false);
  const inPlasmic = templateId !== '' ? mockModMonProvider : null;
  const provider = useContext(ProviderContext)?.provider || inPlasmic;
  const [markers, setMarkers] = useState<Array<PinMarker>>([]);
  const isHidden = useResponsiveDisplay(deviceVisibility);

  const parsed = {
    title: title,
    latitude: latitude,
    longitude: longitude,
  };

  ParseMagnoliaPage({
    source: parsed,
    values: { provider: provider || {} },
    strip: true,
  });
  const mapDivID = `map-div-${kebabCase(title)}`;

  const coordinates = useMemo(() => {
    return {
      lat: parseFloat(parsed.latitude ?? '') || 0,
      lng: parseFloat(parsed.longitude ?? '') || 0,
    };
  }, [parsed.latitude, parsed.longitude]);

  const normalizedFeatures = useMemo(
    () => normalizeMapFeatures(featuresFromProps),
    [featuresFromProps]
  );

  const [features, setFeatures] = useState<MapFeature[] | null>(
    normalizedFeatures
  );

  useEffect(() => {
    /** PROVIDER PIN */
    const centralizedPin = {
      pinColor: COLOR_TO_CENTERED_POINT,
      icon: ICON_TO_CENTERED_POINT,
      lat: coordinates.lat,
      lng: coordinates.lng,
    };

    /** FEATURES PIN */
    const activeFeatures = features?.filter((feature) => feature.active) ?? [];

    const getMarkers = async () => {
      const pins = await getPinMarkers(centralizedPin, activeFeatures);
      setMarkers(pins);
    };

    getMarkers();
  }, [features, mapDivID, coordinates]);

  // Ensure the map is displayed only in the client side.
  useEffect(() => {
    setDisplayMap(true);
  }, []);

  if (coordinates.lat === 0 && coordinates.lng === 0)
    return <MapWithoutCoordinates />;

  if (isHidden) {
    return <></>;
  }

  const withHeading = !!title;
  return (
    <APIProvider apiKey={GOOGLE_MAP_API_KEY}>
      <Container width={width} className={className} ignoreMaxWidth>
        {withHeading && (
          <Heading
            headingElement={headingElement}
            title={parsed.title}
            withContainer={false}
          />
        )}
        <Box boxShadow="lg" mt={withHeading ? 4 : 0}>
          {features && features.length > 0 && (
            <MapFeatures features={features} setFeatures={setFeatures} />
          )}

          <Box width={width} height={height}>
            {displayMap ? (
              <GoogleMap
                id={mapDivID}
                mapId={MAP_ID}
                clickableIcons={false}
                defaultCenter={coordinates}
                defaultZoom={zoom}
                disableDoubleClickZoom={!hasDoubleClickZoom}
                draggable={hasDraggable}
                fullscreenControl={hasFullScreenControl}
                mapTypeControl={hasMapTypeControl}
                scrollwheel={hasScrollWheelZoom}
                streetViewControl={hasStreetViewControl}
                zoomControl={hasZoomControl}
                reuseMaps
              >
                {markers.map((marker, index) => (
                  <AdvancedMarker
                    key={index}
                    position={{ lat: marker.lat, lng: marker.lng }}
                  />
                ))}
              </GoogleMap>
            ) : null}
          </Box>

          {overlayText && (
            <Hide below={SMALL_SCREEN}>
              <MapOverlay
                content={overlayText}
                position="overlay"
                cta={{
                  text: ctaText,
                  behavior: ctaBehavior,
                  url: ctaUrl,
                  rel: ctaRel,
                }}
              />
            </Hide>
          )}
        </Box>
        <Hide above={SMALL_SCREEN}>
          <MapOverlay
            content={overlayText}
            limitCharsBeforeCTA={limitCharsBeforeCTA}
            position="footer"
            cta={{
              text: ctaText,
              behavior: ctaBehavior,
              url: ctaUrl,
              rel: ctaRel,
            }}
          />
        </Hide>
      </Container>
    </APIProvider>
  );
};

export default withHydrationOnDemand({
  on: ['idle', 'visible'],
  initialVisible: true,
})(Map);
