import { parseCareTypeTranslation } from '@utils/parser/careTypeTranslation';
const DEFAULT_BASE_URL = process.env.NEXT_PUBLIC_MGNL_HOST;
const DOMAIN_API_URL = `${DEFAULT_BASE_URL}/.rest/delivery/domain`;
const CARETYPE_TRANSLATION_API_URL = `${DEFAULT_BASE_URL}/.rest/delivery/caretypetranslation`;

async function fetchResponseJSON(api) {
  const response = await fetch(api);
  const responseJson = await response.json();
  return responseJson;
}

export interface DataByDomainParams {
  domain: string;
}

export interface CareType {
  id: string;
  slug: string;
  name: string;
  rollUpType: string;
  image?: string;
}

export interface DomainResponse {
  name: string;
  logo: string;
  url: string;
  brandVariant?: string;
  brandPalette?: string;
  careTypes: CareType[];
}

export interface DomainResponseData {
  results: DomainResponse[];
}

export interface GetDataByDomainResponse {
  careTypes: CareType[] | [];
}

export const getDataByDomain = async ({
  domain,
}: DataByDomainParams): Promise<GetDataByDomainResponse> => {
  try {
    const domainUrl = `${DOMAIN_API_URL}?name=${domain}&limit=1`;
    const careTypeTranslationUrl = `${CARETYPE_TRANSLATION_API_URL}?@ancestor=/${domain}&limit=50`;
    const [careTypeTranslationResponse, domainResponse] =
      await Promise.allSettled([
        fetchResponseJSON(careTypeTranslationUrl),
        fetchResponseJSON(domainUrl),
      ]);
    const careTypeTranslation = parseCareTypeTranslation(
      careTypeTranslationResponse.status === 'fulfilled'
        ? careTypeTranslationResponse.value
        : null
    );
    const domainResponseData: DomainResponseData =
      domainResponse.status === 'fulfilled' ? domainResponse.value : [];

    let careTypesByDomain = [] as CareType[];

    if (domainResponseData.results.length > 0) {
      careTypesByDomain = domainResponseData.results[0].careTypes?.map(
        (item) => {
          return {
            id: item.id || item.name,
            slug: item.name,
            name: careTypeTranslation[item.name] || item.name,
            rollUpType: careTypeTranslation[item.rollUpType] || item.rollUpType,
          };
        }
      ) as CareType[];
    }
    return {
      careTypes: careTypesByDomain,
    };
  } catch (e) {
    console.error(e);
    return {
      careTypes: [],
    };
  }
};
