import { GridItem, GridItemProps } from '@chakra-ui/layout';

interface ImageContainerProps {
  domain: string;
  children: React.ReactNode;
}

const styles: Record<string, GridItemProps> = {
  'caring.com': {
    height: { base: '175px', md: '325px' },
  },
  default: {
    height: '210px',
  },
};

const ImageContainer: React.FC<ImageContainerProps> = ({
  domain,
  children,
}) => {
  const containerStyles = styles[domain] || styles.default;
  return (
    <GridItem
      bg="gray.200"
      colSpan={{ base: 12, md: 5 }}
      overflow="hidden"
      position="relative"
      rounded="md"
      {...containerStyles}
    >
      {children}
    </GridItem>
  );
};

export default ImageContainer;
