import { Button } from '@chakra-ui/button';
import { useDisclosure } from '@chakra-ui/hooks';
import { Flex, Text } from '@chakra-ui/layout';
import useElementClicked, {
  ElementActions,
  ElementNames,
  ElementTypes,
} from '@components/Analytics/events/ElementClicked';
import HtmlToReact from '@components/HtmlToReact';
import useTruncateText from '@hooks/useTruncate';
import dynamic from 'next/dynamic';
import { memo, useContext } from 'react';
import SiteContext from '~/contexts/SiteContext';
import ProviderReviewResponse from './ProviderReviewResponse';
import ProviderReviewsCardHeader from './ProviderReviewsCardHeader';
const ProviderReviewsModal = dynamic(() => import('./ProviderReviewsModal'));

const TRUNCATE_LIMIT = 500;

const getButtonAction = (
  domain: string,
  handleReadMore: Function,
  readTruncatedText: Function
) => {
  switch (domain) {
    case 'caring.com':
      return readTruncatedText;
    default:
      return handleReadMore;
  }
};

const getDisplayButtonWithAction = (domain: string, text: string = '') => {
  switch (domain) {
    case 'caring.com':
      return text?.length > TRUNCATE_LIMIT;
    default:
      return true;
  }
};

const getButtonText = (
  domain: string,
  isOpen: boolean,
  isTruncated: boolean
) => {
  switch (domain) {
    case 'caring.com':
      return !isTruncated || isOpen ? 'READ LESS' : 'READ MORE';
    default:
      return isOpen ? 'Read Less' : 'Read More';
  }
};

const ReadMoreButton = ({
  domain,
  display = 'block',
  displayButtonWithAction,
  getButtonProps,
  buttonAction,
  buttonText,
}) => {
  switch (domain) {
    case 'caring.com':
      return (
        <Button
          color="info.400"
          variant="ghost"
          justifySelf="end"
          width="min-content"
          pt={4}
          px={0}
          size="md"
          _hover={{ backgroundColor: 'none' }}
          height="min-content"
          display={displayButtonWithAction ? display : 'none'}
          {...getButtonProps()}
          onClick={buttonAction}
        >
          {buttonText}
        </Button>
      );
    default:
      return (
        <Button
          position={'absolute'}
          bottom="0"
          colorScheme="secondary"
          variant="outline"
          size="sm"
          justify
          width="fit-content"
          mb={4}
          {...getButtonProps()}
          onClick={buttonAction}
        >
          {buttonText}
        </Button>
      );
  }
};
interface ProviderReviewCardProps {
  author: string;
  reviewText: string;
  createdAt?: string;
  subtitle?: string;
  rating: number;
  title: string;
  providerResponse?: string | null;
  displayButton?: boolean;
  serviceCategoryName?: string;
  isVisible?: boolean;
  tagsColor?: string;
  tagsColorRange?: string;
  ratingColor?: string;
  ratingColorRange?: string;
}

const ProviderReviewsCard: React.FC<ProviderReviewCardProps> = memo(
  ({
    author,
    reviewText,
    createdAt,
    rating,
    title,
    providerResponse,
    subtitle,
    displayButton = true,
    serviceCategoryName,
    isVisible = false,
    tagsColor,
    tagsColorRange,
    ratingColor,
    ratingColorRange,
  }) => {
    const { isOpen, onClose, getButtonProps, getDisclosureProps } =
      useDisclosure();
    const elementClicked = useElementClicked();
    const siteContext = useContext(SiteContext);
    const domain = siteContext.site?.path || '';
    const reviewTruncated = useTruncateText(reviewText, TRUNCATE_LIMIT);
    const providerTruncated = useTruncateText(providerResponse, TRUNCATE_LIMIT);
    const {
      truncatedText: reviewTruncatedText,
      isTruncated: isReviewTruncated,
      handleShowAllClick: handleReviewShowAllClick,
    } = reviewTruncated;
    const {
      truncatedText: providerTruncatedText,
      isTruncated: isProviderTruncated,
      handleShowAllClick: handleProviderShowAllClick,
    } = providerTruncated;

    const readTruncatedText = () => {
      handleReviewShowAllClick();
      elementClicked({
        element: {
          type: ElementTypes.BUTTON,
          action: isReviewTruncated
            ? ElementActions.EXPAND
            : ElementActions.COLLAPSE,
          name: ElementNames.READ_MORE_REVIEW,
          text: isOpen || !isReviewTruncated ? 'Read Less' : 'Read More',
          color: 'white',
          textColor: 'black',
        },
      });
    };

    const readTruncatedProviderResponse = () => {
      handleProviderShowAllClick();
      elementClicked({
        element: {
          type: ElementTypes.BUTTON,
          action: isProviderTruncated
            ? ElementActions.EXPAND
            : ElementActions.COLLAPSE,
          name: ElementNames.READ_MORE_REVIEW,
          text: isOpen || !isProviderTruncated ? 'Read Less' : 'Read More',
          color: 'white',
          textColor: 'black',
        },
      });
    };

    const handleReadMore = () => {
      getButtonProps().onClick();
      elementClicked({
        element: {
          type: ElementTypes.BUTTON,
          action: ElementActions.OPEN_MODAL,
          name: ElementNames.READ_MORE_REVIEW,
          text: isOpen ? 'Read Less' : 'Read More',
          color: 'white',
          textColor: 'black',
        },
      });
    };

    const handleReadLess = () => {
      onClose();
      elementClicked({
        element: {
          type: ElementTypes.BUTTON,
          action: ElementActions.CLOSE_MODAL,
          name: ElementNames.READ_LESS_REVIEW,
          text: 'Read Less',
          color: 'white',
          textColor: 'black',
        },
      });
    };

    const buttonAction = getButtonAction(
      domain,
      handleReadMore,
      readTruncatedText
    );
    const providerButtonAction = getButtonAction(
      domain,
      handleReadMore,
      readTruncatedProviderResponse
    );
    const displayButtonWithAction = getDisplayButtonWithAction(
      domain,
      reviewText
    );
    const displayProviderButtonWithAction = getDisplayButtonWithAction(
      domain,
      providerResponse || ''
    );
    const buttonText = getButtonText(domain, isOpen, isReviewTruncated);
    const providerButtonText = getButtonText(
      domain,
      isOpen,
      isProviderTruncated
    );
    const displayProviderResponseReadMoreButton =
      domain === 'caring.com' ? true : false;
    const reviewContent =
      domain === 'caring.com' ? reviewTruncatedText : reviewText;
    const numberOfLines =
      domain === 'caring.com'
        ? {}
        : {
            ...(providerResponse ? { base: 3, lg: 2 } : { base: 3, lg: 5 }),
          };

    const numberOfLinesProviderResponse =
      domain === 'caring.com' ? {} : { base: 3, lg: 4 };

    return (
      <>
        <Flex
          position="relative"
          bg="white"
          direction="column"
          padding={isVisible ? '5' : '0'}
          mb={isVisible ? 4 : 0}
          height={isVisible ? 'auto' : '0px'}
          visibility={isVisible ? 'visible' : 'hidden'}
          rounded="lg"
          shadow="sm"
          boxShadow="md"
          zIndex={1}
          color="gray.800"
        >
          <ProviderReviewsCardHeader
            author={author}
            createdAt={createdAt}
            rating={rating}
            title={title}
            subtitle={subtitle}
            serviceCategoryName={serviceCategoryName}
            tagsColor={tagsColor}
            tagsColorRange={tagsColorRange}
            ratingColor={ratingColor}
            ratingColorRange={ratingColorRange}
          />

          <Text
            noOfLines={numberOfLines}
            color="gray.800"
            marginBottom={domain === 'caring.com' ? 0 : 12}
          >
            {HtmlToReact({
              html: reviewContent,
            })}
          </Text>

          {displayButton && isVisible && (
            <ReadMoreButton
              domain={domain}
              displayButtonWithAction={displayButtonWithAction}
              getButtonProps={getButtonProps}
              buttonAction={buttonAction}
              buttonText={buttonText}
            />
          )}
          {providerResponse && (
            <ProviderReviewResponse
              response={providerTruncatedText}
              noOfLines={numberOfLinesProviderResponse}
              content={
                <>
                  {displayButton &&
                    displayProviderResponseReadMoreButton &&
                    isVisible && (
                      <ReadMoreButton
                        domain={domain}
                        display="contents"
                        displayButtonWithAction={
                          displayProviderButtonWithAction
                        }
                        getButtonProps={getButtonProps}
                        buttonAction={providerButtonAction}
                        buttonText={providerButtonText}
                      />
                    )}
                </>
              }
            />
          )}
        </Flex>
        {displayButtonWithAction && (
          <ProviderReviewsModal
            author={author}
            createdAt={createdAt}
            rating={rating}
            title={title}
            providerResponse={providerResponse}
            serviceCategoryName={serviceCategoryName}
            reviewText={reviewText}
            isOpen={isOpen}
            onClose={onClose}
            handleReadLess={handleReadLess}
          />
        )}
      </>
    );
  }
);

ProviderReviewsCard.displayName = 'ProviderReviewsCard';

export default ProviderReviewsCard;
