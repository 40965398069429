interface SettingsDividerProps {
  text: string;
}

export function renderSettingsDivider(text: string): JSX.Element {
  return <SettingsDivider text={text} />;
}

const SettingsDivider = ({ text = '' }: SettingsDividerProps) => {
  return <div style={{ fontWeight: 600 }}>{text}</div>;
};

export default SettingsDivider;
