import { useEffect, useState } from 'react';

const useTruncateText = (text, limit = 500) => {
  const [truncatedText, setTruncatedText] = useState(text);
  const [isTruncated, setIsTruncated] = useState(text?.length > limit);

  useEffect(() => {
    const newText =
      isTruncated && text?.length > limit
        ? `${text.substring(0, limit)}...`
        : text;
    setTruncatedText(newText);
  }, [isTruncated, text, limit]);

  const handleShowAllClick = () => {
    setIsTruncated(!isTruncated);
  };

  return { truncatedText, isTruncated, handleShowAllClick };
};

export default useTruncateText;
