import { Heading, Text, TextProps } from '@chakra-ui/layout';
import { ReactNode, useContext } from 'react';
import SiteContext from '~/contexts/SiteContext';
import parse from 'html-react-parser';

const providerResponseHeading = (domain: string) => {
  switch (domain) {
    case 'caring.com':
      return 'Provider Response';
    default:
      return 'Business Response';
  }
};

interface ProviderReviewResponseProps {
  response: string;
  content?: ReactNode;
  noOfLines?: TextProps['noOfLines'];
}

const ProviderReviewResponse: React.FC<ProviderReviewResponseProps> = ({
  response,
  content,
  noOfLines,
}) => {
  const siteContext = useContext(SiteContext);
  const domain = siteContext.site?.path || '';
  const responseHeading = providerResponseHeading(domain);
  return (
    <>
      <Heading as="h4" py={4} mt={4} size="xs">
        {responseHeading}
      </Heading>
      <Text
        as="div"
        noOfLines={noOfLines}
        fontSize="sm"
        whiteSpace="pre-line"
        borderLeft="8px"
        borderColor="gray.200"
        paddingLeft="4"
        className="magnolia-text"
        mt={4}
      >
        {parse(response)} {content}
      </Text>
    </>
  );
};

export default ProviderReviewResponse;
