import { Button } from '@chakra-ui/button';
import { Box, Grid } from '@chakra-ui/layout';
import { useContext, useMemo, useState } from 'react';
import SiteContext from '~/contexts/SiteContext';
import { ProviderReview } from '~/types/reviews';
import ProviderReviewsCard from './ProviderReviewsCard';
import { createID } from '@utils/strings';

export const VISIBLE_REVIEW_LIMIT = 6;
export const REVIEW_INCREMENT = 6;

const ProviderReviewsListContainer = ({ domain, children }) => {
  switch (domain) {
    case 'caring.com':
      return (
        <Grid templateColumns="1" mb={8} zIndex={1}>
          {children}
        </Grid>
      );
    default:
      return (
        <Grid
          templateColumns={{ base: '1', lg: 'repeat(4, 1fr)' }}
          gap="8"
          mb={8}
        >
          {children}
        </Grid>
      );
  }
};

const viewMoreButtonVariant = (domain) => {
  switch (domain) {
    case 'caring.com':
      return 'outline';
    default:
      return 'solid';
  }
};

export const sortReviews = (reviews, orderBy) => {
  if (orderBy === 'rating_review_desc') {
    return [...reviews].sort(
      (a, b) =>
        b.rating - a.rating || Date.parse(b.createdAt) - Date.parse(a.createdAt)
    );
  }
  if (orderBy === 'rating_review_asc') {
    return [...reviews].sort(
      (a, b) =>
        a.rating - b.rating || Date.parse(b.createdAt) - Date.parse(a.createdAt)
    );
  }
  if (orderBy === 'date_review_asc') {
    return [...reviews].sort(
      (a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt)
    );
  }
  return [...reviews].sort(
    (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)
  );
};

interface ProviderReviewsListProps {
  title?: string;
  reviews: ProviderReview[];
  orderBy: string;
  cardReviewsStarsColor?: string;
  cardReviewsStarsColorRange?: string;
  cardCareTypeTagsColor?: string;
  cardCareTypeTagsColorRange?: string;
}

const ProviderReviewsList: React.FC<ProviderReviewsListProps> = ({
  title,
  reviews,
  orderBy,
  cardReviewsStarsColor,
  cardReviewsStarsColorRange,
  cardCareTypeTagsColor,
  cardCareTypeTagsColorRange,
}) => {
  const [visibleReviewCount, setVisibleReviewCount] =
    useState<number>(VISIBLE_REVIEW_LIMIT);
  const reviewLength = reviews.length;
  const siteContext = useContext(SiteContext);
  const domain = siteContext.site?.path || '';
  const sortedReviews = useMemo(
    () => sortReviews(reviews, orderBy),
    [reviews, orderBy]
  );
  const reviewJumpLink = title ? createID(title) : 'all_reviews_section';
  const filteredOrderedReviews = useMemo(
    () => sortedReviews.slice(0, visibleReviewCount),
    [sortedReviews, visibleReviewCount]
  );

  const isExpandable = reviewLength > VISIBLE_REVIEW_LIMIT;

  const handleViewAllReviewsClick = () => {
    if (reviewLength <= visibleReviewCount) {
      setVisibleReviewCount(VISIBLE_REVIEW_LIMIT);
      return;
    }
    setVisibleReviewCount(visibleReviewCount + REVIEW_INCREMENT);
  };

  return (
    <Box>
      <ProviderReviewsListContainer domain={domain}>
        {sortedReviews.map((review) => {
          const isVisible =
            !!filteredOrderedReviews.find((r) => r.id === review.id) ?? false;
          return (
            <ProviderReviewsCard
              key={review.id}
              author={review.authorName}
              reviewText={review.content}
              createdAt={review.createdAt}
              rating={review.rating}
              title={review.title}
              tagsColor={cardCareTypeTagsColor}
              tagsColorRange={cardCareTypeTagsColorRange}
              ratingColor={cardReviewsStarsColor}
              ratingColorRange={cardReviewsStarsColorRange}
              providerResponse={review.providerResponse}
              serviceCategoryName={review.serviceCategoryName}
              isVisible={isVisible}
            />
          );
        })}
      </ProviderReviewsListContainer>

      {isExpandable && (
        <Button
          colorScheme="secondary"
          onClick={handleViewAllReviewsClick}
          width={{ base: '100%', lg: 'min-content' }}
          size="lg"
          fontSize={'16px'}
          variant={viewMoreButtonVariant(domain)}
        >
          {reviewLength <= visibleReviewCount ? (
            <a href={`#${reviewJumpLink}`}>Read less</a>
          ) : (
            'Read more reviews'
          )}
        </Button>
      )}
    </Box>
  );
};

export default ProviderReviewsList;
