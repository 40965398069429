import { useState, useEffect } from 'react';
import { getClientIp } from '~/utils/ipify';

const useClientIP = () => {
  const [clientIP, setClientIP] = useState<string | undefined>(undefined);

  useEffect(() => {
    let ignore = false;
    const fetchData = async () => {
      getClientIp().then((ip) => {
        if (!ignore) {
          setClientIP(ip);
        }
      });
    };

    fetchData();
    return () => {
      ignore = true;
    };
  }, []);

  return clientIP;
};

export default useClientIP;
