import { Button } from '@chakra-ui/button';
import { Box, Text } from '@chakra-ui/layout';

type Props = {
  content: string;
  limitCharsBeforeCTA?: number;
  position: 'overlay' | 'footer';
  cta: {
    text?: string;
    behavior?: string;
    url?: string;
    rel?: ['external' | 'nofollow' | 'noopener' | 'noreferrer' | 'opener'];
  };
};

const MapOverlay: React.FC<Props> = ({
  content,
  limitCharsBeforeCTA = 0,
  position,
  cta,
}) => {
  const { text = 'Read more', url, behavior = '_blank', rel = [''] } = cta;

  if (!url) return null;

  const isOverlay = position === 'overlay';

  const shouldLimitContent = !isOverlay && limitCharsBeforeCTA > 0;
  const cleanedContent = shouldLimitContent
    ? `${content.slice(0, limitCharsBeforeCTA)}...`
    : content;

  return (
    <Box
      bgColor="white"
      p="8"
      w={isOverlay ? '30%' : '100%'}
      height={isOverlay ? '100%' : undefined}
      position={isOverlay ? 'absolute' : 'relative'}
      right={0}
    >
      <Text
        dangerouslySetInnerHTML={{ __html: cleanedContent }}
        mb="6"
        fontWeight="light"
        fontSize="lg"
        className="magnolia-text"
      />
      <a href={url} key={text} target={behavior} rel={rel?.join(' ') || ''}>
        <Button variant="outline" colorScheme="primary">
          {text}
        </Button>
      </a>
    </Box>
  );
};

export default MapOverlay;
