import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/form-control';
import { Input as ChakraInput } from '@chakra-ui/react';
import { InputFieldConfig } from '../types';
import { FormFieldCommonProps } from './FormField';
import PhoneNumberInput from './PhoneNumberInput';

type Props = InputFieldConfig & FormFieldCommonProps;

const Input: React.FC<Props> = ({
  entriesColorKey,
  error,
  errorColorKey,
  inputType,
  isRequired,
  label,
  name,
  placeholder,
  register,
  setValue,
  trigger,
}) => {
  return (
    <FormControl isInvalid={Boolean(error)} isRequired={isRequired}>
      {label && <FormLabel my={2}>{label}</FormLabel>}
      {inputType === 'phone' ? (
        <PhoneNumberInput
          register={register}
          setValue={setValue}
          trigger={trigger}
          name={name}
          error={error}
          borderColor={entriesColorKey}
          placeholder={placeholder}
          errorBorderColor={errorColorKey}
        />
      ) : (
        <ChakraInput
          {...register(name)}
          borderColor={entriesColorKey}
          placeholder={placeholder}
          errorBorderColor={errorColorKey}
        />
      )}
      <FormErrorMessage textColor={errorColorKey} data-testid={`error-${name}`}>
        {String(error?.message)}
      </FormErrorMessage>
    </FormControl>
  );
};

export default Input;
