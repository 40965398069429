import { GridItem, GridItemProps } from '@chakra-ui/layout';

interface ActionsContainerProps {
  domain: string;
  children: React.ReactNode;
  colSpan: GridItemProps['colSpan'];
}

const styles: Record<string, GridItemProps> = {
  'caring.com': {
    py: { base: 3, md: 0 },
  },
  default: {
    py: 0,
  },
};

const ActionsContainer: React.FC<ActionsContainerProps> = ({
  domain,
  colSpan,
  children,
}) => {
  const containerStyles = styles[domain] || styles.default;
  return (
    <GridItem
      rounded="md"
      minWidth={115}
      colSpan={colSpan}
      {...containerStyles}
    >
      {children}
    </GridItem>
  );
};

export default ActionsContainer;
