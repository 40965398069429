import {
  AmenityCategory,
  AMENITY_CATEGORY,
} from '@components/Search/EnhancedSearch/constants';

export const isAmenityCategory = (
  amenityCategory: string
): amenityCategory is AmenityCategory => {
  return Object.values(AMENITY_CATEGORY).includes(
    amenityCategory as AmenityCategory
  );
};

export enum CareType {
  AssistedLiving = 'assisted-living',
  IndependentLiving = 'independent-living',
  MemoryCare = 'memory-care-facilities',
  NursingHomes = 'nursing-homes',
}

export interface AmenityCategoryNode {
  name: string;
  value: string;
  amenities: string[];
  count?: number;
}

export const AMENITY_CATEGORIES: {
  [K in AmenityCategory]: AmenityCategoryNode;
} = {
  [AMENITY_CATEGORY.GOLF]: {
    name: 'Golf',
    value: AMENITY_CATEGORY.GOLF,
    amenities: ['Golf'],
  },
  [AMENITY_CATEGORY.MEALS_PROVIDED]: {
    name: 'Meals Provided',
    value: AMENITY_CATEGORY.MEALS_PROVIDED,
    amenities: ['Meal Preparation'],
  },
  [AMENITY_CATEGORY.PETS_ALLOWED]: {
    name: 'Pets Allowed',
    value: AMENITY_CATEGORY.PETS_ALLOWED,
    amenities: ['Pets'],
  },
  [AMENITY_CATEGORY.PHYSICAL_THERAPY]: {
    name: 'Physical Therapy',
    value: AMENITY_CATEGORY.PHYSICAL_THERAPY,
    amenities: [
      'Occupational Therapy/Rehabilitation',
      'Physical Therapy/Rehabilitation',
    ],
  },
  [AMENITY_CATEGORY.POOL_OR_HOT_TUB]: {
    name: 'Pool / Hot Tub',
    value: AMENITY_CATEGORY.POOL_OR_HOT_TUB,
    amenities: ['Swimming Pool', 'Hot Tub Spa'],
  },
  [AMENITY_CATEGORY.VETERANS_BENEFITS]: {
    name: 'Veterans Benefits',
    value: AMENITY_CATEGORY.VETERANS_BENEFITS,
    amenities: [
      'Veterans Aid (VA) Benefits Accepted',
      'Veterans Affairs (VA) Aid Assistance',
    ],
  },
};

export const AMENITY_CATEGORIES_BY_CARE_TYPE = {
  [CareType.AssistedLiving]: [
    AMENITY_CATEGORY.PETS_ALLOWED,
    AMENITY_CATEGORY.PHYSICAL_THERAPY,
    AMENITY_CATEGORY.POOL_OR_HOT_TUB,
    AMENITY_CATEGORY.VETERANS_BENEFITS,
  ],
  [CareType.IndependentLiving]: [
    AMENITY_CATEGORY.GOLF,
    AMENITY_CATEGORY.MEALS_PROVIDED,
    AMENITY_CATEGORY.PETS_ALLOWED,
    AMENITY_CATEGORY.POOL_OR_HOT_TUB,
  ],
  [CareType.MemoryCare]: [
    AMENITY_CATEGORY.PHYSICAL_THERAPY,
    AMENITY_CATEGORY.VETERANS_BENEFITS,
  ],
  [CareType.NursingHomes]: [
    AMENITY_CATEGORY.PHYSICAL_THERAPY,
    AMENITY_CATEGORY.VETERANS_BENEFITS,
  ],
};
