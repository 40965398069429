import { Heading, Stack, StackProps, Text } from '@chakra-ui/layout';
import { Progress } from '@chakra-ui/progress';

interface ProgressChartProps extends StackProps {
  id: string;
  label: string;
  value: number;
  displayValue: string;
  ariaLabel?: string;
  color?: string;
  labelType?: string;
  textColor?: string;
}

const ProgressChart: React.FC<ProgressChartProps> = ({
  id,
  label,
  value,
  displayValue,
  ariaLabel = `${label} = ${displayValue}`,
  color = 'red',
  labelType = 'text',
  textColor = 'gray.800',
  ...props
}) => {
  return (
    <Stack
      id={id}
      direction="row"
      alignItems="end"
      justifyContent="space-between"
      spacing="5"
      w="full"
      aria-label={ariaLabel}
      {...props}
    >
      <Stack spacing="1" justifyContent="start" flexGrow="1" minW="40">
        {labelType === 'text' ? (
          <Text fontSize="lg" color={textColor}>
            {label}
          </Text>
        ) : (
          <Heading size="md" color={textColor}>
            {label}
          </Heading>
        )}
        <Progress
          aria-labelledby={id}
          backgroundColor={`gray.00`}
          colorScheme={color}
          value={value}
          borderRadius="full"
        />
      </Stack>
      <Heading as="p" color="gray.800" size="md" whiteSpace="nowrap">
        {displayValue}
      </Heading>
    </Stack>
  );
};

export default ProgressChart;
