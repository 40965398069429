import {
  Box,
  Flex,
  Heading,
  HeadingProps,
  Link,
  LinkOverlay,
  StackProps,
  Text,
  VStack,
} from '@chakra-ui/layout';
import { CaringStars } from '@components/CaringStars';
import { Award } from '~/contexts/Provider';
import useElementClicked, {
  ElementActions,
  ElementNames,
  ElementTypes,
} from '@components/Analytics/events/ElementClicked';
interface SummaryProps {
  domain: string;
  path: string;
  address: string;
  title: string;
  titleColor: HeadingProps['color'];
  phoneNumber?: string;
  caringStars?: Award[];
  query: {
    locationId: string;
    queryId: string;
    listId: string;
  };
  dontOpenInNewTab: boolean;
}

const titleStyles: Record<string, HeadingProps> = {
  'caring.com': {
    size: 'md',
  },
  default: {
    size: { base: 'sm', md: 'md' },
  },
};

const Title = ({
  domain,
  title,
  titleColor,
}: Pick<SummaryProps, 'domain' | 'title' | 'titleColor'>) => {
  const styles = titleStyles[domain] || titleStyles.default;
  return (
    <Heading as={'h3'} color={titleColor} {...styles}>
      {title}
    </Heading>
  );
};

const leftColumnStyles: Record<string, StackProps> = {
  'caring.com': {
    spacing: { base: 2.5, lg: 1 },
  },
  default: {
    spacing: 1,
  },
};

const LeftColumn = ({
  domain,
  children,
}: {
  domain: SummaryProps['domain'];
  children: React.ReactNode;
}) => {
  const styles = leftColumnStyles[domain] || leftColumnStyles.default;
  return (
    <VStack alignItems="start" flex="1" {...styles}>
      {children}
    </VStack>
  );
};

const Summary: React.FC<SummaryProps> = ({
  domain,
  path,
  address,
  title,
  titleColor,
  phoneNumber,
  caringStars,
  query,
  dontOpenInNewTab,
}) => {
  const elementClicked = useElementClicked();
  return (
    <Flex alignItems="start" gap={2.5} width="full">
      <LeftColumn domain={domain}>
        <LinkOverlay
          href={path}
          color="gray.700"
          _hover={{ textDecoration: 'underline' }}
          onClick={(e) => {
            elementClicked({
              element: {
                type: ElementTypes.LINK,
                action: ElementActions.INTERNAL_LINK,
                name: ElementNames.PROVIDER_CARD,
                text: title,
                color: 'white',
                textColor: titleColor?.toString() || '',
              },
              destinationUrl: path,
              query: { ...query },
            });
          }}
          isExternal={!dontOpenInNewTab}
        >
          <Title domain={domain} title={title} titleColor={titleColor} />
        </LinkOverlay>

        <Text fontSize="sm">{address}</Text>

        {phoneNumber && (
          <Text fontSize="sm" pt={2}>
            Call{' '}
            <Link color="link.500" href={`tel:+${phoneNumber}`}>
              {phoneNumber}
            </Link>{' '}
            for details
          </Text>
        )}
      </LeftColumn>

      {caringStars && (
        <Box
          display={{ base: 'block', md: 'none' }}
          w="71px"
          flexShrink={0}
          // mx={2.5}
        >
          <CaringStars caringStars={caringStars} />
        </Box>
      )}
    </Flex>
  );
};

export default Summary;
