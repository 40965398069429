import { Button } from '@chakra-ui/button';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';

interface Props {
  limit: number;
  currentLimit: number;
  resultCount: number;
  setShowMore: (page: number) => void;
}

const ShowMore: React.FC<Props> = ({
  limit,
  currentLimit,
  resultCount,
  setShowMore,
}) => {
  const handleShowMore = (e) => {
    e.preventDefault();
    if (currentLimit < resultCount) {
      setShowMore(currentLimit + limit);
    }
  };

  const handleShowLess = (e) => {
    e.preventDefault();
    if (currentLimit > limit) {
      setShowMore(currentLimit - limit);
    }
  };

  return (
    <>
      {currentLimit > limit && (
        <Button
          size="sm"
          variant="ghost"
          onClick={handleShowLess}
          aria-label="Show less"
        >
          Show less <FaCaretUp />
        </Button>
      )}
      {currentLimit < resultCount && (
        <Button
          size="sm"
          variant="ghost"
          onClick={handleShowMore}
          aria-label="Show more"
        >
          Show more <FaCaretDown />
        </Button>
      )}
    </>
  );
};

export default ShowMore;
