import { Catalog } from '~/types/LocaleCatalog';
import { MagnoliaImage, Page, PageProps, Story } from '~/types/Magnolia';

export const mockMagnoliaPage: Page = {
  '@id': 'https://www.magnolia-cms.com/en',
  '@name': 'Home',
  '@nodes': [],
  '@nodeType': 'mgnl:page',
  '@path': '/',
  'mgnl:created': '2021-03-02T15:00:00.000+01:00',
  'mgnl:lastModified': '2021-03-02T15:00:00.000+01:00',
  'mgnl:template': 'mgnl:page',
  dataName: 'Home',
  description:
    'Magnolia CMS is a Java-based, open source content management system (CMS) that is designed to be flexible and scalable.',
  keywords:
    'Magnolia CMS, Java, open source, content management system, CMS, flexible, scalable',
  title: 'Magnolia CMS - Open Source Java CMS',
  metaDescription: '',
  metaTitle: '',
  metaKeywords: '',
  noindex: undefined,
  main: {
    '@nodes': [],
  },
  sidebar: {
    '@nodes': [],
  },
};

export const mockMagnoliaPageProps: PageProps = {
  page: {
    ...mockMagnoliaPage,
  },
  context: {
    params: {},
    req: {
      url: '/',
    },
  },
  pagePath: '/',
  templateAnnotations: {},
  catalog: {},
  provider: null,
  story: null,
};

export const mockMagnoliaPageNotFound: PageProps = {
  page: {},
  context: {
    params: {},
  },
  notFound: true,
} as PageProps;

export const mockMagnoliaCatalog: Catalog = {
  urlPath: '/',
  name: 'Home',
  state: 'arizona',
  city: 'phoenix',
  careType: 'assisted-living',
  latitude: 33.4484,
  longitude: -112.074,
};

export const mockStory: Story = {
  template: {
    template: '',
    'mgnl:lastModified': '2022-03-02T15:00:00.000+01:00',
  },
  title: '2023 Wills and Estate Planning Study',
  lead: 'Caring.com’s Article',
  'mgnl:lastModified': '2022-03-02T15:00:00.000+01:00',
  'mgnl:created': '2022-03-02T15:00:00.000+01:00',
  sitePath: 'caring.com',
  noindex: false,
  nofollow: false,
  metaDescription: 'metaDescription',
  metaTitle: 'metaTitle',
  metaKeywords: 'metaKeywords',
  jsonSchemas: [],
  stories: [
    {
      '@name': 'Article',
      '@path': '/caring.com/article',
      urlPath: '/article',
      visualType: 'image',
      title: '2023 Wills and Estate Planning Study',
      lead: 'Caring.com’s Article',
      '@nodes': [],
    },
  ],
  'story:template': 'two-column-right',
  'mgnl:template': 'spa-lm:pages/story',
  main: {
    '0': {
      '@name': '0',
      '@path': '/caring.com/First-Story/blocks/0',
      '@id': 'text',
      '@nodeType': 'mgnl:block',
      text: '<p>with some copy</p>',
      'mgnl:type': 'text',
      'mgnl:template': 'spa-lm:components/text',
      '@nodes': [],
    },
    '@nodes': ['0'],
  },
  '@nodes': ['main'],
  imageSource: {} as MagnoliaImage,
  urlPath: '/first',
  canonical: 'https:/www.caring.com/first',
  careType: {
    name: '55-communities',
  },
  pageType: {
    dataName: 'Topical Authority',
    category: {
      dataName: 'Product Content',
    },
  },
  theme: {
    dataName: 'Caregiving',
  },
  topic: {
    dataName: 'Online Will Creation',
  },
  factCheckedBy: undefined,
  editedBy: undefined,
  reviewedBy: undefined,
  writtenBy: {
    staffPosition: 'Managing Editor',
    name: 'Daniel Cobb',
    bio: '',
    linkedIn: '',
  },
  lastModified: 'December 11, 2023',
};
