import RelatedArticles from '@components/RelatedArticle/RelatedArticle';
import { HeadingElements } from '~/@types/heading';
import { Metadata } from '~/types/Magnolia';

interface Props {
  headingElement: HeadingElements;
  title?: string;
  titleAlignment?: 'left' | 'center' | 'right';
  text?: string;
  textAlignment?: 'left' | 'center' | 'right';
  withImages: boolean;
  cta?: string;
  ctaColor?: string;
  ctaColorRange?: string;
  state?: 'solid' | 'outline' | 'ghost' | 'link';
  tilesPerRow?: number;
  page0: {};
  page1: {};
  page2: {};
  page3: {};
  page4: {};
  page5: {};
  metadata: Pick<Metadata, '@path'>;
  inSideBar: boolean;
}

const RelatedArticlesWrapper = ({
  headingElement,
  title,
  titleAlignment,
  text,
  textAlignment,
  withImages,
  cta,
  ctaColor,
  ctaColorRange,
  state,
  tilesPerRow,
  page0,
  page1,
  page2,
  page3,
  page4,
  page5,
  inSideBar,
}: Props): JSX.Element => {
  const plasmicPages = {
    pages0: {
      ...page0,
    },
    pages1: {
      switchable: {
        field: 'external',
      },
      ...page1,
    },
    pages2: {
      ...page2,
    },
    pages3: {
      ...page3,
    },
    pages4: {
      ...page4,
    },
    pages5: {
      ...page5,
    },
    '@nodes': ['pages0', 'pages1', 'pages2', 'pages3', 'pages4', 'pages5'],
  };

  plasmicPages['@nodes'].forEach((key) => {
    plasmicPages[key].switchable = {
      field: 'external',
      title: plasmicPages[key]?.switchable?.title,
      url: plasmicPages[key]?.switchable?.url,
    };
  });

  const metadata = {
    '@path': inSideBar ? 'sidebar' : '',
  };

  return (
    <RelatedArticles
      headingElement={headingElement}
      title={title}
      titleAlignment={titleAlignment}
      text={text}
      textAlignment={textAlignment}
      withImages={withImages}
      cta={cta}
      ctaColor={ctaColor}
      ctaColorRange={ctaColorRange}
      state={state}
      tilesPerRow={tilesPerRow}
      pages={plasmicPages}
      metadata={metadata}
    />
  );
};

export default RelatedArticlesWrapper;
