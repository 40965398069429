import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import { Box } from '@chakra-ui/react';
import { ArrowProps } from '@components/Account/Favorites/FavoriteLocationCard';
import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { MdArrowBackIos, MdArrowForwardIos } from 'react-icons/md';
import Slider, { Settings } from 'react-slick';
import { Provider } from '~/contexts/Provider';
import { useTenantFunctions } from '~/contexts/TenantFunctionsContext';
import { calculateProviderMinPrice } from '~/utils/providers';
import CommunityComparisonCard from './CommunityComparisonCard';

export interface CommunityComparisonContainerProps {
  providers?: Provider[];
  enableTrailingSlash?: boolean;
  providerTitleColor?: string;
  providerTitleColorRange?: string;
  displayBadges?: boolean;
  displayViewCommunityButton?: boolean;
  displayCurrentProvider?: boolean;
  viewCommunityButtonText?: string;
  displayRequestInfoButton?: boolean;
  modalId?: string;
  viewCommunityButtonColorScheme?: string;
  ratingStarsColor?: string;
  ratingStarsColorRange?: string;
  queryId?: string;
  listId?: string;
  customSliderSettings?: Settings;
}

const DotsWrapper = styled(Box)`
  .slick-slider {
    @media (max-width: 1024px) {
      margin: 15px;
    }
  }
  .slick-dots li {
    position: relative;
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0.5;
    &.slick-active {
      opacity: 1;
    }
  }
`;

const PrevArrow = ({ className, style, onClick, currentSlide }: ArrowProps) => {
  return (
    <Box
      h="48px"
      w="48px"
      left={{ base: '-45px', xl: '-60px' }}
      borderRadius="100%"
      className={className}
      _before={{ display: 'none' }}
      style={{
        ...style,
        zIndex: '100',
        backgroundColor: 'white',
        boxShadow: '0px 13px 26px -6px rgba(0, 0, 0, 0.25)',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      display={{
        base:
          currentSlide && currentSlide > 0
            ? 'flex !important'
            : 'none !important',
      }}
      onClick={onClick}
    >
      <MdArrowBackIos
        fontSize="14px"
        color="black"
        width="14px"
        height="14px"
      />
    </Box>
  );
};

const NextArrow = ({
  className,
  style,
  onClick,
  currentSlide,
  slideCount,
}: ArrowProps) => {
  return (
    <Box
      h="48px"
      w="48px"
      right={{ base: '-45px', xl: '-60px' }}
      borderRadius="100%"
      className={className}
      _before={{ display: 'none' }}
      style={{
        ...style,
        zIndex: '100',
        backgroundColor: 'white',
        boxShadow: '0px 13px 26px -6px rgba(0, 0, 0, 0.25)',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      display={{
        base:
          currentSlide && currentSlide + 1 === slideCount
            ? 'none !important'
            : 'flex !important',
        md:
          currentSlide && currentSlide + 2 === slideCount
            ? 'none !important'
            : 'flex !important',
        lg:
          currentSlide && currentSlide + 3 === slideCount
            ? 'none !important'
            : 'flex !important',
      }}
      onClick={onClick}
    >
      <MdArrowForwardIos
        fontSize="14px"
        color="black"
        width="14px"
        height="14px"
      />
    </Box>
  );
};

const CommunityComparisonContainer = ({
  providers,
  modalId = '',
  queryId = '',
  listId = '',
  ratingStarsColor,
  providerTitleColor,
  displayBadges = false,
  ratingStarsColorRange,
  providerTitleColorRange,
  viewCommunityButtonText,
  customSliderSettings = {},
  displayViewCommunityButton,
  enableTrailingSlash = false,
  displayCurrentProvider = true,
  viewCommunityButtonColorScheme,
  displayRequestInfoButton = true,
}: CommunityComparisonContainerProps): JSX.Element => {
  const { getProviderDetailsPath } = useTenantFunctions();

  const [maxCardHeight, setMaxCardHeight] = useState('100%');

  const settings = {
    dots: true,
    appendDots: (dots: React.ReactNode) => (
      <ul style={{ bottom: '-40px' }}> {dots} </ul>
    ),
    customPaging: () => (
      <Box _before={{ color: 'gray.600', content: '"•"' }}></Box>
    ),
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
    ...customSliderSettings,
  };

  useEffect(() => {
    if (window != undefined) {
      const innerWidth = window.innerWidth;
      // Calculate the maximum height of CommunityComparisonCard components
      const cardElements = document.querySelectorAll('.slick-slide > div');
      let maxHeight = 0;

      cardElements.forEach((element) => {
        const height = element.clientHeight;
        maxHeight = Math.max(maxHeight, height);
      });

      // Set the maximum height for all cards
      setMaxCardHeight(`${innerWidth > 768 ? maxHeight : '100%'}`);
    }
  }, [providers]);

  return (
    <DotsWrapper>
      <Slider {...settings}>
        {providers?.map((result, index) => {
          const currentProvider = index === 0;
          const price = calculateProviderMinPrice({ result });
          const url = getProviderDetailsPath(result);
          const address = `${result.address?.city}, ${result.address?.state} ${
            result.address?.zipCode || ''
          }`;
          const images = result?.images ?? [];
          const title = result.name;
          const id = result.legacyId ?? result.id;
          const accommodations = result?.accommodations
            ?.filter((accommodation) => accommodation.title)
            .map((item) => item.title?.toLocaleLowerCase());

          return (
            <CommunityComparisonCard
              key={id}
              id={id}
              url={url}
              title={title}
              price={price}
              images={images}
              listId={listId}
              provider={result}
              address={address}
              queryId={queryId}
              modalId={modalId}
              height={maxCardHeight}
              displayBadges={displayBadges}
              accommodations={accommodations}
              currentProvider={currentProvider}
              ratingStarsColor={ratingStarsColor}
              providerTitleColor={providerTitleColor}
              enhancedListing={result.enhancedListing}
              ratingStarsColorRange={ratingStarsColorRange}
              displayCurrentProvider={displayCurrentProvider}
              providerTitleColorRange={providerTitleColorRange}
              viewCommunityButtonText={viewCommunityButtonText}
              displayRequestInfoButton={displayRequestInfoButton}
              displayViewCommunityButton={displayViewCommunityButton}
              viewCommunityButtonColorScheme={viewCommunityButtonColorScheme}
            />
          );
        })}
      </Slider>
    </DotsWrapper>
  );
};

export default CommunityComparisonContainer;
