import { Box, Text } from '@chakra-ui/react';
import { AutocompleteFormattedLabel } from './AutocompleteInput';
import { BoxProps } from '@chakra-ui/layout';

interface AutocompleteListProps extends BoxProps {
  autocompleteList: AutocompleteFormattedLabel[];
  currentFocus: number;
  listRef: React.MutableRefObject<HTMLDivElement>;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  setKeyword?:
    | React.Dispatch<React.SetStateAction<string>>
    | ((keyword: string) => void);
  setIsInputFocused: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentFocus: React.Dispatch<React.SetStateAction<number>>;
}

const AutocompleteList: React.FC<AutocompleteListProps> = ({
  autocompleteList,
  currentFocus,
  listRef,
  setInputValue,
  setKeyword,
  setIsInputFocused,
  setCurrentFocus,
  ...props
}) => {
  return (
    <Box
      position="absolute"
      bg="white"
      width="100%"
      boxShadow="md"
      borderBottomRadius="md"
      onMouseEnter={() => setCurrentFocus(-1)}
      ref={listRef}
      as="ul"
      listStyleType="none"
      {...props}
    >
      {autocompleteList?.map((item, index) => (
        <Text
          key={index}
          onMouseDown={(e) => {
            e.preventDefault();
            setInputValue(item.label);
            if (setKeyword) {
              setKeyword(item.value);
            }
            setIsInputFocused(false);
          }}
          _hover={{ bg: 'blue.500', color: 'white' }}
          bg={currentFocus === index ? 'blue.500' : 'white'}
          color={currentFocus === index ? 'white' : 'black'}
          p={2}
          borderBottomRadius={index === autocompleteList.length - 1 ? 'md' : 0}
          as="li"
        >
          {item?.label}
        </Text>
      ))}
    </Box>
  );
};

export default AutocompleteList;
