import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Flex, Text, VStack } from '@chakra-ui/layout';
import {
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu';

import { FormType } from '@components/Analytics/events/FormStepSubmission';
import useSearchStepSubmission from '@components/Analytics/events/SearchStepSubmission';
import useSearchSubmission from '@components/Analytics/events/SearchSubmission';
import useElementClicked, {
  ElementActions,
  ElementNames,
  ElementTypes,
} from '@components/Analytics/events/ElementClicked';
import { Review } from '@services/modular-monolith/types/location.type';
import { useState } from 'react';
import { RiCloseLine, RiSearchLine } from 'react-icons/ri';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';
import ProviderReviewsFilters, {
  ReviewFilters,
} from './ProviderReviewsFilters';

interface ProviderReviewsSearchProps {
  originalReviews: Review[];
  reviewTotal: number;
  searchValue: string;
  filters: ReviewFilters;
  sortOrder: string;
  setSearchValue: (searchValue: string) => void;
  setFilters: (filters: ReviewFilters) => void;
  setSortOrder: (order: string) => void;
  resetAll: () => void;
  metadataId: string;
}
const sortingOrders = {
  date_review_desc: 'Newest',
  date_review_asc: 'Oldest',
  rating_review_desc: 'Rating: Highest to Lowest',
  rating_review_asc: 'Rating: Lowest to Highest',
};

export const applySearchFilter = (
  reviews: Review[],
  searchValue: string = ''
): Review[] => {
  const regex = new RegExp(`(${searchValue})`, 'gi');
  if (searchValue?.length >= 3) {
    return reviews
      .map((review) => {
        if (review.content.match(regex)) {
          const parts = review.content.split(regex);
          const highlightedReview = parts
            .map((part, i) =>
              regex.test(part)
                ? `<span style="background-color: var(--chakra-colors-tertiary-200); display: inline;">${part}</span>`
                : part
            )
            .join('');
          return { ...review, content: highlightedReview };
        }
        return;
      })
      .filter((review) => review !== undefined) as Review[];
  }
  return reviews;
};

const SearchInformation = ({
  searchValue,
  reviewCount,
  isSearching,
  isFiltering,
}: {
  searchValue: string;
  reviewCount: number;
  isSearching: boolean;
  isFiltering: boolean;
}) => {
  if (reviewCount === 0) {
    return (
      <Box fontSize="lg" pb={{ base: 6, md: 8 }} textAlign="center">
        <Text fontWeight="bold" pb={1}>
          No reviews found
        </Text>
        {`Try adjusting your ${isSearching ? 'search' : ''}${
          isFiltering && isSearching ? ' and ' : ''
        } ${isFiltering ? 'filters' : ''} to see more reviews.`}
      </Box>
    );
  }
  if (isSearching) {
    return (
      <Text
        fontSize="lg"
        fontWeight="bold"
        pb={{ base: 6, md: 8 }}
      >{`${reviewCount} ${reviewCount === 1 ? 'review' : 'reviews'} ${
        reviewCount === 1 ? 'mentions' : 'mention'
      } "${searchValue}"`}</Text>
    );
  }
  if (isFiltering) {
    return (
      <Text fontSize="lg" fontWeight="bold" pb={{ base: 6, md: 8 }}>
        {`${reviewCount} ${reviewCount === 1 ? 'review' : 'reviews'} found`}
      </Text>
    );
  }
  return <></>;
};

const ProviderReviewsSearch: React.FC<ProviderReviewsSearchProps> = ({
  originalReviews,
  reviewTotal,
  searchValue,
  filters,
  sortOrder,
  setSearchValue,
  setFilters,
  setSortOrder,
  resetAll,
  metadataId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [sortOpen, setSortOpen] = useState(false);
  const searchStepSubmission = useSearchStepSubmission();
  const searchSubmission = useSearchSubmission();
  const elementClicked = useElementClicked();
  const isSearching = searchValue?.length >= 3 ?? false;
  const isFiltering = Object.values(filters).some(
    (filter) => filter.length > 0
  );

  const handleSearchBlur = () => {
    const stepInstanceId = uuidv4();
    const searchTemplateId = metadataId || uuidv4();
    const stepId = uuidv5('Search Reviews', searchTemplateId);
    const searchInstanceId = uuidv4();
    searchStepSubmission({
      search_template_id: searchTemplateId,
      search_instance_id: searchInstanceId,
      step_id: stepId,
      step_instance_id: stepInstanceId,
      step_index: 1,
      form_type: FormType.REVIEWS_SEARCH,
      step_content: [
        {
          prompt_id: uuidv4(),
          prompt_type: 'text',
          prompt_instance_id: uuidv4(),
          prompt_index: 1,
          prompt_value: 'Search Reviews',
          response_array: [
            {
              response_value: searchValue,
              response_id: uuidv4(),
            },
          ],
        },
      ],
    });
    searchSubmission({
      search_template_id: searchTemplateId,
      search_instance_id: searchInstanceId,
      search_type: FormType.REVIEWS_SEARCH,
      step_submissions: [
        {
          step_id: stepId,
          step_instance_id: stepInstanceId,
          step_index: 1,
        },
      ],
    });
  };

  return (
    <VStack>
      <Flex
        mb={7}
        direction={{ base: 'column', xl: 'row' }}
        width="100%"
        gap="10px"
      >
        <InputGroup flexGrow={2} flexBasis={1}>
          <InputLeftElement pointerEvents="none" height="48px">
            <RiSearchLine size="20px" />
          </InputLeftElement>
          <Input
            height={12}
            width="100%"
            _placeholder={{ fontStyle: 'italic', fontWeight: 'normal' }}
            fontWeight="bold"
            fontSize="lg"
            placeholder="Search Reviews"
            value={searchValue}
            backgroundColor="white"
            onChange={(e) => setSearchValue(e.target.value)}
            onBlur={handleSearchBlur}
          />
          {isSearching && (
            <InputRightElement
              data-testid="clear-button"
              cursor="pointer"
              height="100%"
              onClick={() => {
                setSearchValue('');
              }}
            >
              <RiCloseLine size="20px" />
            </InputRightElement>
          )}
        </InputGroup>
        <Flex
          flexGrow={2}
          flexBasis={0}
          flexWrap={{ base: 'wrap', sm: 'nowrap' }}
          gap="10px"
        >
          <InputGroup zIndex={2}>
            <Menu
              isOpen={sortOpen}
              onClose={() => setSortOpen(false)}
              matchWidth={true}
            >
              <MenuButton
                onClick={() => setSortOpen(!sortOpen)}
                width="100%"
                height={12}
                maxWidth={{ base: 'unset', xl: '190px' }}
                outline="none"
                justifyContent="flex-start"
                textAlign="left"
                fontSize="lg"
                fontWeight="bold"
                border="2px solid"
                borderColor={
                  sortOrder === 'most_recent_review_desc'
                    ? 'gray.200'
                    : 'info.500'
                }
                borderRadius={6}
                backgroundColor="white"
                textColor={
                  sortOrder === 'most_recent_review_desc'
                    ? 'primary.900'
                    : 'info.500'
                }
                mb={0}
                px={4}
                whiteSpace="nowrap"
              >
                <Text pr="10px" textOverflow="ellipsis" overflow="hidden">
                  {sortingOrders[sortOrder] ?? 'Sort By'}
                </Text>
                <InputRightElement height="100%">
                  {sortOpen ? (
                    <ChevronUpIcon width={5} height={5} />
                  ) : (
                    <ChevronDownIcon width={5} height={5} />
                  )}
                </InputRightElement>
              </MenuButton>
              <MenuList
                backgroundColor="white"
                marginTop="-8px"
                border="none"
                shadow="md"
                rootProps={{ marginTop: '-8px' }}
              >
                {Object.entries(sortingOrders).map(([key, value]) => (
                  <MenuItem
                    key={key}
                    onClick={() => {
                      setSortOrder(key);
                      elementClicked({
                        element: {
                          type: ElementTypes.DROPDOWN,
                          action: ElementActions.SORT,
                          name: ElementNames.PROVIDER_REVIEWS,
                          text: value,
                          color: 'white',
                          textColor: 'gray.800',
                        },
                      });
                    }}
                  >
                    <Text>{value}</Text>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </InputGroup>

          <InputGroup>
            <Button
              onClick={() => setIsOpen(!isOpen)}
              variant="outline"
              width="100%"
              size="lg"
              justifyContent="flex-start"
              colorScheme={isOpen ? 'info' : 'gray'}
              backgroundColor="white"
              textColor={isOpen ? 'info.500' : 'primary.900'}
              mb={0}
              px={4}
            >
              {isOpen ? 'Hide Filters' : 'Show Filters'}
              <InputRightElement>
                {isOpen ? (
                  <ChevronUpIcon width={5} height={5} />
                ) : (
                  <ChevronDownIcon width={5} height={5} />
                )}
              </InputRightElement>
            </Button>
          </InputGroup>
        </Flex>
      </Flex>
      <ProviderReviewsFilters
        isOpen={isOpen}
        reviews={originalReviews}
        setFilters={setFilters}
        resetAll={resetAll}
      />
      <SearchInformation
        searchValue={searchValue}
        reviewCount={reviewTotal}
        isSearching={isSearching}
        isFiltering={isFiltering}
      />
    </VStack>
  );
};

export default ProviderReviewsSearch;
