export const DEFAULT_VALUES = {
  ITEMS_PER_PAGE: 20,
  ITEMS_PER_ROW: 3,
  RADIUS_FOR_SEARCH_IN_MILES: 50,
} as const;

export const ALIGNMENT = {
  CENTER: 'center',
  LEFT: 'left',
  RIGHT: 'right',
} as const;

export const AMENITY_CATEGORY = {
  GOLF: 'golf',
  MEALS_PROVIDED: 'meals-provided',
  PETS_ALLOWED: 'pets-allowed',
  PHYSICAL_THERAPY: 'physical-therapy',
  POOL_OR_HOT_TUB: 'pool-or-hot-tub',
  VETERANS_BENEFITS: 'veterans-benefits',
} as const;

export type AmenityCategory =
  (typeof AMENITY_CATEGORY)[keyof typeof AMENITY_CATEGORY];

export const BOX_SHADOW = {
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  NONE: 'none',
} as const;

export const DISPLAY_MODE = { LIST: 'list', SEARCH: 'search' } as const;

export type DisplayMode = (typeof DISPLAY_MODE)[keyof typeof DISPLAY_MODE];

export const HEADING_ELEMENT = {
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
  H6: 'h6',
} as const;

export const HEADING_SIZE = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
  '2XL': '2xl',
  '3XL': '3xl',
  '4XL': '4xl',
} as const;

export const SEARCH_OPTION = {
  ACCESSIBILITY: 'accessibility',
  AWARDS: 'awards',
  DINING: 'dining',
  DISTANCE: 'distance',
  FIVE_PLUS_PHOTOS: 'fivePlusPhotos',
  HEALTH_SERVICES: 'healthServices',
  LANGUAGES: 'languages',
  LIFESTYLE: 'lifestyle',
  MATCH_ALL_FILTERS: 'matchAllFilters',
  OTHER_AMENITIES: 'otherAmenities',
  PERSONAL_CARE: 'personalCare',
  PROMOTIONS: 'promotions',
  REVIEWS: 'reviews',
  ROOM_AMENITIES: 'roomAmenities',
  ROOM_TYPE: 'roomType',
  SORT_BY: 'sortBy',
  STAFF_QUALIFICATIONS: 'staffQualifications',
  VERIFIED: 'verified',
} as const;

export type SearchOption = (typeof SEARCH_OPTION)[keyof typeof SEARCH_OPTION];

export const SORT_OPTION = {
  BEST_ACTIVITIES: 'best-activities',
  BEST_MEALS_DINING: 'best-meals-dining',
  BEST_RATED: 'best-rated',
  BEST_STAFF: 'best-staff',
  DISTANCE: 'distance',
  MOST_REVIEWS: 'most-reviews',
} as const;
