import { Box, BoxProps } from '@chakra-ui/layout';
import { Button, chakra, ChakraStyledOptions } from '@chakra-ui/react';

const ResultTileHelpersContainerStyles = (domain): ChakraStyledOptions => {
  const baseStyle: BoxProps = {
    display: 'flex',
    gap: 2,
  };
  switch (domain) {
    case 'seniorhomes.com':
      return {
        baseStyle: {
          ...baseStyle,
          width: '100%',
          '& .ButtonsContainer': {
            width: '100%',
            display: 'flex',
            gap: 2,
            flexDirection: 'column',
          },
        },
      };
    case 'caring.com':
      return {
        baseStyle: {
          ...baseStyle,
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'column',
          '& .ButtonsContainer': {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          },
        },
      };
    default:
      return baseStyle;
  }
};

const ResultTileHelpersContainer = (domain): typeof Box => {
  return chakra(Box, { ...ResultTileHelpersContainerStyles(domain) });
};

const ResultTileHelpersButtonStyles = (domain): ChakraStyledOptions => {
  switch (domain) {
    case 'seniorhomes.com':
      return {
        baseStyle: {
          width: 'full',
        },
      };
    case 'caring.com':
      return {
        baseStyle: {
          width: 'full',
          fontSize: { base: '0.625rem', lg: '1rem' },
          padding: { base: '0.625rem 0.5rem', lg: '0.625rem 1rem;' },
        },
      };
    default:
      return {};
  }
};

const ResultTileHelpersButton = (domain): typeof Button => {
  return chakra(Button, { ...ResultTileHelpersButtonStyles(domain) });
};
export { ResultTileHelpersContainer, ResultTileHelpersButton };
