import { useQuery } from '@tanstack/react-query';
import { fetchPages } from '@utils/sitemap';

const MAX_TO_FETCH = 100;

type UseLocaleCatalogParams = {
  initialData?: any[];
  filters?: Record<string, string>;
  limit?: number;
};

const useLocaleCatalog = ({
  initialData,
  filters,
  limit = MAX_TO_FETCH,
}: UseLocaleCatalogParams) => {
  const resolver = async () => {
    return getLocaleCatalogEntries(filters, limit);
  };

  return useQuery({
    queryFn: resolver,
    queryKey: ['locale-catalog', filters, limit],
    enabled: filters && Object.keys(filters).length > 0,
    initialData: initialData,
  });
};

export const getLocaleCatalogEntries = async (
  filters: Record<string, string> | undefined,
  limit?: number | undefined
) => {
  const baseUrl = process.env.NEXT_PUBLIC_MGNL_HOST;
  const url = `${baseUrl}/.rest/delivery/geo`;
  limit = limit ?? MAX_TO_FETCH;
  try {
    return await fetchPages({ url, filters, limit });
  } catch (error) {
    console.error('Error fetching geo catalog cities', error);
    return [];
  }
};

export default useLocaleCatalog;
