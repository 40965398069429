import { Icon } from '@chakra-ui/icon';
import {
  Flex,
  Grid,
  GridItem,
  GridItemProps,
  Heading,
  HeadingProps,
  Text,
  TextProps,
} from '@chakra-ui/layout';
import { chakra, shouldForwardProp } from '@chakra-ui/react';
import { formatRating } from '@utils/number';
import { MdStar } from 'react-icons/md';

interface RatingProps {
  domain: string;
  rating: number;
  starColor: TextProps['color'];
  reviewCount: number;
}

const CustomIcon = chakra(Icon, {
  shouldForwardProp: (prop) => {
    const isChakraProp = shouldForwardProp(prop);
    return isChakraProp || prop === 'viewBox';
  },
});

const labelStyles: Record<string, HeadingProps> = {
  'caring.com': {
    fontSize: { base: 'sm', lg: 'md' },
    lineHeight: { base: '120%', lg: 'unset' },
  },
  default: {
    fontSize: 'md',
    lineHeight: 'unset',
  },
};

const RatingLabel = ({ domain, rating, ratingColor }) => {
  const styles = labelStyles[domain] || labelStyles.default;
  return (
    <Heading
      as="p"
      color={ratingColor}
      aria-label={`${rating} star rating`}
      {...styles}
    >
      {rating}
    </Heading>
  );
};

const RatingStars = ({ domain, starCount, starColor }) => {
  switch (domain) {
    case 'caring.com':
      return (
        <Flex
          alignItems="center"
          justifyContent="center"
          gap={{ base: 0, lg: 1 }}
        >
          {Array.from({ length: starCount }, (_, i) => (
            <CustomIcon
              key={i}
              as={MdStar}
              boxSize={{ base: '12.5px', lg: '16.67px' }}
              data-testid="star-icon"
              color={starColor}
              role="presentation"
              viewBox="2 2.5 20 20"
            />
          ))}
        </Flex>
      );
    default:
      return (
        <Flex alignItems="center" justifyContent="center" gap={1}>
          {Array.from({ length: starCount }, (_, i) => (
            <Icon
              key={i}
              as={MdStar}
              boxSize={5}
              data-testid="star-icon"
              color={starColor}
              role="presentation"
            />
          ))}
        </Flex>
      );
  }
};

const ReviewCount = ({ domain, reviewCount }) => {
  switch (domain) {
    case 'caring.com':
      return (
        <>
          <Text
            color="gray.800"
            fontSize={{ base: 'xs', lg: 'lg' }}
            lineHeight={{ base: '150%', lg: 'unset' }}
          >
            ({reviewCount}
            <Text as="span" display={{ base: 'initial', lg: 'none' }}>
              {' '}
              reviews
            </Text>
            )
          </Text>
        </>
      );
    default:
      return (
        <Text color="gray.800" fontSize="lg" lineHeight="unset">
          ({reviewCount})
        </Text>
      );
  }
};

const ratingContainerStyles: Record<string, GridItemProps> = {
  'caring.com': {
    gap: { base: 0.5, lg: 1 },
  },
  default: {
    gap: 1,
  },
};

const ratingLabelStyles: Record<string, GridItemProps> = {
  'caring.com': {
    area: { base: 'left', lg: 'center' },
  },
  default: {},
};

const Rating: React.FC<RatingProps> = ({
  domain,
  rating,
  starColor,
  reviewCount,
}) => {
  const containerStyles =
    ratingContainerStyles[domain] || ratingContainerStyles.default;
  const labelStyles = ratingLabelStyles[domain] || ratingLabelStyles.default;
  const formattedRating = formatRating(rating);
  const starCount = Math.trunc(Number(formattedRating));
  return (
    <Grid
      templateAreas={`"left center right"`}
      alignItems="center"
      {...containerStyles}
    >
      <GridItem>
        <RatingStars
          domain={domain}
          starCount={starCount}
          starColor={starColor}
        />
      </GridItem>

      <GridItem {...labelStyles}>
        <RatingLabel
          domain={domain}
          rating={formattedRating}
          ratingColor={starColor}
        />
      </GridItem>

      <GridItem>
        <ReviewCount domain={domain} reviewCount={reviewCount} />
      </GridItem>
    </Grid>
  );
};

export default Rating;
