import { isWithinInterval } from 'date-fns';
import { SearchResultFullWidthProps } from '@components/Search/SearchResultType';

export const getActivePromotion = (
  promotions: NonNullable<SearchResultFullWidthProps['promotions']>
) => {
  const today = new Date();
  return promotions.find((promotion) => {
    const { startsAt, endsAt, externalPromotionText, visibleOnlyToFa } =
      promotion;

    try {
      return (
        startsAt &&
        endsAt &&
        externalPromotionText &&
        !visibleOnlyToFa &&
        isWithinInterval(today, {
          start: new Date(startsAt),
          end: new Date(endsAt),
        })
      );
    } catch (error) {
      return false;
    }
  });
};
