import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '@utils/queryKey';
import { ONE_DAY_IN_MILLISECONDS } from '~/constants';

type UseQueryMapFeatureParams = {
  featureId: string;
  keywords: string;
  lat: number;
  lng: number;
};

export const getMapFeature = async ({ queryKey }, keywords) => {
  const [_key, { lat, lng }] = queryKey;

  const response = await fetch(
    `/api/places-near-by?lat=${lat}&lng=${lng}&keywords=${keywords}`
  );
  return await response.json();
};

export const useQueryMapFeature = ({
  featureId,
  keywords,
  lat,
  lng,
}: UseQueryMapFeatureParams) => {
  return useQuery({
    queryKey: [QueryKey.MapFeatures, { featureId, lat, lng }],
    queryFn: (queryContext) => getMapFeature(queryContext, keywords),
    staleTime: ONE_DAY_IN_MILLISECONDS * 30,
    cacheTime: ONE_DAY_IN_MILLISECONDS * 30,
  });
};
