import dynamic from 'next/dynamic';
import withHydrationOnDemand from 'react-hydration-on-demand';
import { isAmenitiesEmpty, getExtraAmenities } from '~/utils/amenities';
import { providerIsEnhancedAndNotSuppressed } from '~/utils/providers';
import { HeadingElements } from '~/@types/heading';
import { useContext } from 'react';
import SiteContext from '~/contexts/SiteContext';
import useTranslation from '@hooks/use-translation';
import { Amenities } from '~/contexts/Provider';
import ProviderContext from '~/contexts/Provider';
import { mockModMonProvider } from '@mocks/modmon.mock';
import { Parser } from '@utils/parser';
import {
  DeviceVisibility,
  useResponsiveDisplay,
} from '@hooks/useResponsiveDisplay';

const Container = dynamic(
  () => import('@components/LayoutStructure/Container')
);
const ProviderDetailsAmenitiesLegacy = dynamic(
  () => import('./legacy/ProviderDetailsAmenitiesLegacy')
);
const ProviderDetailsAmenities = dynamic(
  () => import('./ProviderDetailsAmenities')
);
interface Props {
  templateId?: string;
  title?: string;
  itemsToShow?: number;
  blurAmenities?: boolean;
  inquiryId?: string;
  headingElement?: HeadingElements;
  deviceVisibility?: DeviceVisibility;
  useLegacyAmenities?: boolean;
}

const OfferingListingComponent: React.FC<Props> = ({
  itemsToShow,
  title,
  templateId,
  headingElement,
  blurAmenities = false,
  inquiryId = '',
  deviceVisibility,
  useLegacyAmenities = false,
}: Props) => {
  const { t } = useTranslation();
  const siteProps = useContext(SiteContext);
  const domain = siteProps.site?.path ?? '';

  const plasmicProvider = templateId !== '' ? mockModMonProvider : null;
  const provider = useContext(ProviderContext)?.provider || plasmicProvider;
  const isHidden = useResponsiveDisplay(deviceVisibility);
  if (isHidden) {
    return <></>;
  }
  if (!provider) {
    return null;
  }
  const amenities = (provider?.amenities ?? []) as Amenities;
  const amenitiesLegacy = provider?.amenitiesLegacy ?? {};
  const providerName = provider?.name ?? '';
  const heading = title || `What ${providerName} Offers`;
  const starHealth = provider?.starsBySection?.health ?? null;
  const starQuality = provider?.starsBySection?.quality ?? null;
  const starStaff = provider?.starsBySection?.staff ?? null;
  const starOverall = provider?.starsBySection?.overall ?? null;
  const isEnhanced = providerIsEnhancedAndNotSuppressed(provider);
  const extraAmenities = getExtraAmenities(amenitiesLegacy);
  const allAmenities = amenities.concat(extraAmenities);

  // If the provider has no amenities, we fallback to the legacy amenities
  const isNewAmenitiesSchemaEnabled = isAmenitiesEmpty(amenities, domain, t)
    ? false
    : useLegacyAmenities
    ? false
    : true;

  if (
    isAmenitiesEmpty(
      isNewAmenitiesSchemaEnabled ? amenities : amenitiesLegacy,
      domain,
      t
    )
  ) {
    return null;
  }

  const parsedHeading = Parser({
    source: heading,
    values: { provider: provider || {} },
    strip: true,
  });

  return (
    <Container>
      {isNewAmenitiesSchemaEnabled ? (
        <ProviderDetailsAmenities
          heading={parsedHeading}
          headingElement={headingElement}
          amenities={allAmenities}
          isEnhanced={isEnhanced}
          itemsToShow={itemsToShow ?? -1}
          blurAmenities={blurAmenities}
          inquiryId={inquiryId}
          starHealth={starHealth}
          starQuality={starQuality}
          starStaff={starStaff}
          starOverall={starOverall}
        />
      ) : (
        <ProviderDetailsAmenitiesLegacy
          heading={heading}
          headingElement={headingElement}
          amenities={amenitiesLegacy}
          itemsToShow={itemsToShow ?? -1}
          starHealth={starHealth}
          starQuality={starQuality}
          starStaff={starStaff}
          starOverall={starOverall}
        />
      )}
    </Container>
  );
};

const OfferingListing: React.FC<Props> = withHydrationOnDemand({
  on: ['idle', 'visible'],
  initialVisible: true,
})(OfferingListingComponent);

export default OfferingListing;
