import { Fragment } from 'react';
import { Text } from '@chakra-ui/layout';
import { Icon, VStack } from '@chakra-ui/react';
import { StringToIconKeys } from '@components/RenderIcon';
import CallToAction, {
  CallToActionProps,
} from '@components/CallToAction/CallToAction';
import {
  ElementActions,
  ElementNames,
} from '@components/Analytics/events/ElementClicked';
import { STRING_TO_ICON_CLASS } from '@components/RenderIcon';
import withHydrationOnDemand from 'react-hydration-on-demand';
import {
  DeviceVisibility,
  useResponsiveDisplay,
} from '@hooks/useResponsiveDisplay';

export interface InfoRequestProps extends CallToActionProps {
  title: string;
  icon: StringToIconKeys;
  iconColor: StringToIconKeys;
  iconColorRange: StringToIconKeys;
  bgColorRange: StringToIconKeys;
  deviceVisibility?: DeviceVisibility;
}

const InfoRequestComponent: React.FC<InfoRequestProps> = ({
  title,
  icon,
  iconColor,
  iconColorRange,
  color,
  state,
  text,
  inquiryId,
  bgColor,
  bgColorRange,
  deviceVisibility,
}) => {
  const isHidden = useResponsiveDisplay(deviceVisibility);
  if (isHidden) {
    return <></>;
  }
  return (
    <Fragment>
      <VStack>
        {icon && (
          <Icon
            w={70}
            h={70}
            color={`${iconColor}.${iconColorRange}`}
            as={STRING_TO_ICON_CLASS[icon]}
          />
        )}
        <Text fontSize={20} as="span" fontWeight="700" pb={2}>
          {title}
        </Text>
        <CallToAction
          py={2}
          height="100%"
          isInquiry={true}
          state={state}
          text={text}
          inquiryId={inquiryId}
          bgColor={bgColor}
          buttonProps={{
            elementAction: ElementActions.OPEN_MODAL,
            height: 58,
            width: 194,
          }}
          bg={`${color}.${bgColorRange}`}
          trackingName={ElementNames.INFO_REQUEST_SECTION}
        />
      </VStack>
    </Fragment>
  );
};

const InfoRequest: React.FC<InfoRequestProps> = withHydrationOnDemand({
  on: ['idle', 'visible'],
  initialVisible: true,
})(InfoRequestComponent);

export default InfoRequest;
