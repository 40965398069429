import { GetServerSidePropsContext } from 'next';
import { ParsedUrlQuery } from 'querystring';
import { getNodes } from '~/utils';
import { Parser } from '~/utils/parser';
import { MultiStepFormProps } from './MultiStepForm';
import {
  FieldConfig,
  HiddenField,
  MagnoliaFieldConfig,
  MagnoliaMultiStepForm,
  MagnoliaMultiStepFormSubmissionProps,
  MagnoliaStep,
  MagnoliaStepImage,
  SelectOption,
  StepConfig,
} from './types';

export const serverSideOnlyProps: Array<
  keyof MagnoliaMultiStepFormSubmissionProps
> = ['postUrl', 'postFormat', 'includeCaringPartnerToken'];

export const getServerSideComponentProps = async (
  componentConfig: MagnoliaMultiStepForm,
  context: GetServerSidePropsContext
): Promise<MultiStepFormProps['data']> => {
  const { steps, hiddenFields } = transformMagnoliaMultiStepForm(
    componentConfig,
    context.query
  );
  return {
    id: componentConfig['@id'],
    hiddenFields,
    steps,
    pagination: {
      active: !!context.preview,
      color: componentConfig.formEntriesColor,
    },
  };
};

export const transformMagnoliaMultiStepForm = (
  componentConfig: MagnoliaMultiStepForm,
  queryParams: ParsedUrlQuery
): { steps: StepConfig[]; hiddenFields: HiddenField[] } => {
  const stepsConfig = componentConfig.steps
    ? getNodes<MagnoliaStep>(componentConfig.steps)
    : [];
  const hiddenFields = componentConfig.hiddenEntries
    ? getNodes<HiddenField>(componentConfig.hiddenEntries)
    : [];

  const steps = stepsConfig.map<StepConfig>((stepConfig) => ({
    headline: stepConfig.headline
      ? {
          ...stepConfig.headline,
          text: parseText(queryParams, stepConfig.headline.text),
        }
      : stepConfig.headline,
    subHeadline: stepConfig.subHeadline
      ? {
          ...stepConfig.subHeadline,
          text: parseText(queryParams, stepConfig.subHeadline.text),
        }
      : stepConfig.subHeadline,
    image:
      stepConfig.image.switchable.field === 'componentDefault'
        ? transformMagnoliaImage(componentConfig.image)
        : transformMagnoliaImage(stepConfig.image),
    form: {
      ...stepConfig.form,
      headline: parseText(queryParams, stepConfig.form.headline),
      backgroundColor: componentConfig.formBackgroundColor,
      entriesColor: componentConfig.formEntriesColor,
      errorColor: componentConfig.errorColor,
      fields: transformMagnoliaStep(stepConfig).fields,
    },
  }));

  return { steps, hiddenFields };
};

const transformMagnoliaImage = (
  image?: MagnoliaStepImage
): MagnoliaStepImage | undefined => {
  switch (image?.switchable.field) {
    case 'none':
      return undefined;
    default:
      return image;
  }
};

const transformMagnoliaStep = (
  stepConfig: MagnoliaStep
): { fields: Array<FieldConfig> } => {
  const formEntries = getNodes<MagnoliaFieldConfig>(stepConfig.form.entryList);
  // @ts-ignore TS cannot handle deconstructing with type-specific props, so it gets
  // confused with `formEntry.entryType.field`, as it could be both "input" and "select".
  // We're safe here because for each value of this prop, the other fields will match
  // the expected props for each type
  const fields = formEntries.map<FieldConfig>((formEntry) => {
    const { entryType, name, label, defaultValue, isRequired, doNotSubmit } =
      formEntry;
    const entryIsRequired = isRequired.field === 'true';
    const entryTypeProps = transformMagnoliaEntryType(entryType);
    return {
      ...entryTypeProps,
      type: entryType.field,
      name,
      label,
      defaultValue,
      doNotSubmit,
      isRequired: entryIsRequired,
      isRequiredErrorMessage: entryIsRequired
        ? isRequired.isRequiredErrorMessage
        : undefined,
    };
  });
  return { fields };
};

const parseText = (queryParams: ParsedUrlQuery, text?: string) => {
  if (!text) return text;
  return Parser({
    source: text,
    values: { url: queryParams },
  });
};

const transformMagnoliaEntryType = (
  entryType: MagnoliaFieldConfig['entryType']
): Partial<FieldConfig> => {
  switch (entryType.field) {
    case 'select':
      const options = getNodes<SelectOption>(entryType.options);
      return { options };
    case 'input':
      const {
        inputType: { typeErrorMessage, typeMask, field: inputTypeField },
        placeholder,
      } = entryType;
      return {
        inputType: inputTypeField,
        typeErrorMessage: typeErrorMessage,
        typeMask: typeMask?.field === 'true' ? typeMask.mask : undefined,
        placeholder: placeholder,
      };
  }
};
