import { Card, CardBody, CardHeader } from '@chakra-ui/card';
import {
  Box,
  HStack,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import { FormEventHandler, useMemo } from 'react';
import {
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form';
import { getBackgroundColor, getColor } from '~/utils/getColor';
import { FormField, SubmitButton } from './formElements';
import { FieldErrorDetails, StepConfig } from './types';

export type FormCardProps = {
  errors: {
    [fieldName: string]: FieldErrorDetails | undefined;
  };
  form: StepConfig['form'];
  formError?: string;
  showErrorsOfOtherSteps: boolean;
  errorMessageOnValidationError?: string;
  isSubmitting: boolean;
  onSubmit: FormEventHandler<HTMLFormElement>;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  trigger: UseFormTrigger<any>;
};

const FormCard: React.FC<FormCardProps> = ({
  errors,
  form,
  formError,
  showErrorsOfOtherSteps,
  errorMessageOnValidationError,
  isSubmitting,
  onSubmit,
  register,
  setValue,
  trigger,
}) => {
  const {
    cta,
    backgroundColor,
    entriesColor,
    errorColor,
    fields,
    headline,
    legalDisclosure,
  } = form;

  const fitsHorizontalForm = useBreakpointValue({ base: false, md: true });
  const hasASingleFieldWithNoLabel = fields.length === 1 && !fields[0].label;
  const horizontalForm = fitsHorizontalForm && hasASingleFieldWithNoLabel;

  const errorColorKey = getColor(errorColor.color, errorColor.range);
  const backgroundColorKey = getBackgroundColor(
    backgroundColor.color,
    backgroundColor.range
  );
  const entriesColorKey = getColor(entriesColor.color, entriesColor.range);

  const fieldNames = fields.map((field) => field.name);

  const hasErrorsOnOtherSteps = useMemo(() => {
    if (!showErrorsOfOtherSteps) return false;

    const allErrors = Object.keys(errors);
    const errorsOfOtherSteps = allErrors.filter(
      (errorKey) => !fieldNames.includes(errorKey)
    );
    return errorsOfOtherSteps.length > 0;
  }, [errors, fieldNames, showErrorsOfOtherSteps]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const noErrors = await trigger(fieldNames);
    if (noErrors) {
      onSubmit(e);
    }
  };

  const listOfFields = fields.map((fieldProps, index) => (
    <FormField
      key={index}
      error={errors[fieldProps.name]}
      errorColorKey={errorColorKey}
      entriesColorKey={entriesColorKey}
      register={register}
      setValue={setValue}
      trigger={trigger}
      {...fieldProps}
    />
  ));

  return (
    <Card
      align="center"
      bg={backgroundColorKey}
      padding="8"
      rounded="lg"
      shadow="sm"
      boxShadow="md"
      width={{ md: '3xl' }}
    >
      <CardHeader textAlign="center">
        <Text fontSize="xl" className="magnolia-text">
          {headline}
        </Text>
      </CardHeader>
      <CardBody width={{ base: 'full', md: 'sm' }}>
        <VStack spacing="10px" align="flex-start">
          {/* noValidate: allow "required" indicator to be displayed,
              but delegate the form validation to react-hook-form */}
          <form onSubmit={handleSubmit} style={{ width: '100%' }} noValidate>
            {horizontalForm ? (
              <VStack>
                <HStack spacing="10px" width="md" alignItems="flex-start">
                  {...listOfFields}
                  <SubmitButton cta={cta} size="md" />
                </HStack>
              </VStack>
            ) : (
              <>
                <VStack>{...listOfFields}</VStack>
                <SubmitButton
                  isLoading={isSubmitting}
                  cta={cta}
                  width="100%"
                  mt={hasASingleFieldWithNoLabel ? '10px' : 8}
                />
              </>
            )}
          </form>

          {formError && <Text color={errorColorKey}>{formError}</Text>}
          {hasErrorsOnOtherSteps && (
            <Text color={errorColorKey}>{errorMessageOnValidationError}</Text>
          )}

          {legalDisclosure && (
            <Box
              pt="6px"
              fontSize="sm"
              fontWeight="400"
              lineHeight="150%"
              className="magnolia-text"
              dangerouslySetInnerHTML={{ __html: legalDisclosure }}
            />
          )}
        </VStack>
      </CardBody>
    </Card>
  );
};

export default FormCard;
