import {
  DEFAULT_VALUES,
  SORT_OPTION,
} from '@components/Search/EnhancedSearch/constants';
import { SEARCH_PARAM } from '@constants/search-params';
import {
  coerceBoolean,
  coerceNumber,
  coerceNumberArray,
  coerceString,
  coerceStringArray,
} from '@utils/coercion';
import isEqual from 'lodash/isEqual';
import { ParsedUrlQuery } from 'querystring';
import { z } from 'zod';

export const FacetedSearchParamsParser = z.object({
  accessibility: coerceStringArray(z.array(z.string()).catch([])),
  awards: coerceStringArray(z.array(z.string()).catch([])),
  careType: coerceString(z.string().catch('')),
  dining: coerceStringArray(z.array(z.string()).catch([])),
  distanceInMiles: coerceNumber(
    z.number().catch(DEFAULT_VALUES.RADIUS_FOR_SEARCH_IN_MILES)
  ),
  healthServices: coerceStringArray(z.array(z.string()).catch([])),
  keyword: coerceString(z.string().catch('')),
  languages: coerceStringArray(z.array(z.string()).catch([])),
  lifestyle: coerceStringArray(z.array(z.string()).catch([])),
  matchAllFilters: coerceBoolean(z.boolean().nullable()),
  ongoingPromotion: coerceStringArray(z.array(z.string()).catch([])),
  otherAmenities: coerceStringArray(z.array(z.string()).catch([])),
  page: coerceNumber(
    z
      .number()
      .int()
      .positive()
      .transform((number) => number - 1) // Algolia uses zero-based indexing
      .catch(0)
  ),
  personalCare: coerceStringArray(z.array(z.string()).catch([])),
  priceRange: coerceNumberArray(
    z.tuple([z.number(), z.number()]).catch([1, 4])
  ),
  providersWith: coerceStringArray(z.array(z.string()).catch([])),
  reviews: coerceStringArray(z.array(z.string()).catch([])),
  roomAmenities: coerceStringArray(z.array(z.string()).catch([])),
  roomType: coerceStringArray(z.array(z.string()).catch([])),
  sortBy: coerceString(z.nativeEnum(SORT_OPTION).catch('distance')),
  staffQualifications: coerceStringArray(z.array(z.string()).catch([])),
  verified: coerceStringArray(z.array(z.string()).catch([])),
});

export type FacetedSearchParsedParams = z.infer<
  typeof FacetedSearchParamsParser
>;

export const PARAM_NAMES = FacetedSearchParamsParser.keyof().options;

export const QUERY_KEY_MAP: {
  [key in keyof FacetedSearchParsedParams]: string;
} = {
  accessibility: 'accessibility',
  awards: 'awards',
  careType: SEARCH_PARAM.CARE_TYPE,
  dining: 'dining',
  distanceInMiles: 'distance',
  healthServices: 'health-services',
  keyword: SEARCH_PARAM.KEYWORD,
  languages: 'languages',
  lifestyle: 'lifestyle',
  matchAllFilters: 'match-all',
  ongoingPromotion: 'promotions',
  otherAmenities: 'other-amenities',
  page: SEARCH_PARAM.PAGE,
  personalCare: 'personal-care',
  priceRange: 'price',
  providersWith: 'providers-with',
  reviews: 'reviews',
  roomAmenities: 'room-amenities',
  roomType: 'room-type',
  sortBy: 'sort-by',
  staffQualifications: 'staff-qualifications',
  verified: 'verified',
} as const;

export const getDefaultFacetedSearchParams = (): FacetedSearchParsedParams => {
  return FacetedSearchParamsParser.parse({});
};

export const getFacetedSearchParams = (
  query: ParsedUrlQuery
): FacetedSearchParsedParams => {
  try {
    const facetedSearchParams = {};

    for (const key in QUERY_KEY_MAP) {
      facetedSearchParams[key] = query[QUERY_KEY_MAP[key]];
    }

    const parsedParams = FacetedSearchParamsParser.parse(facetedSearchParams);

    return parsedParams;
  } catch (error) {
    console.error('Error parsing search params', error);
    return getDefaultFacetedSearchParams();
  }
};

export const hasSearchParamsChanged = (
  searchParams: FacetedSearchParsedParams
): Boolean => {
  const defaultSearchParams = getDefaultFacetedSearchParams();
  const { page: _, ...defaultQueryWithoutPage } = defaultSearchParams;
  const { page: __, ...currentQueryWithoutPage } = searchParams;

  const hasFiltersSelected = !isEqual(
    defaultQueryWithoutPage,
    currentQueryWithoutPage
  );

  return hasFiltersSelected;
};
