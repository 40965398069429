import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/form-control';
import { Select as ChakraSelect } from '@chakra-ui/react';
import { SelectFieldConfig } from '../types';
import { FormFieldCommonProps } from './FormField';

type Props = SelectFieldConfig & FormFieldCommonProps;

const Select: React.FC<Props> = ({
  error,
  errorColorKey,
  entriesColorKey,
  label,
  name,
  options,
  isRequired,
  register,
}) => {
  return (
    <FormControl
      fontSize="md"
      isRequired={isRequired}
      isInvalid={Boolean(error)}
    >
      <FormLabel my={2}>{label}</FormLabel>
      <ChakraSelect
        {...register(name)}
        borderColor={entriesColorKey}
        errorBorderColor={errorColorKey}
      >
        {(options || []).map(({ value, label }, index) => (
          <option
            key={`${index}-${value}`}
            value={value || ''}
            data-testid="select-option"
          >
            {label}
          </option>
        ))}
      </ChakraSelect>
      <FormErrorMessage textColor={errorColorKey}>
        {String(error?.message)}
      </FormErrorMessage>
    </FormControl>
  );
};

export default Select;
