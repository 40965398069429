import { GetServerSidePropsContext } from 'next';

export const mockNextContext = {
  req: {
    headers: {
      cookie: '',
    },
    url: '',
  },
  params: {
    id: '1',
  },
  resolvedUrl: '',
} as unknown as GetServerSidePropsContext;
