import { Button } from '@chakra-ui/button';
import { Section } from '@components/Sections';
import useLocaleCatalog from '@hooks/use-locale-catalog';
import { isJSON } from '@utils/isJSON';
import { modifyTrailingSlash } from '@utils/modifyTrailingSlash';
import { labelToSlug } from '@utils/strings';
import kebabCase from 'lodash/kebabCase';
import lowerCase from 'lodash/lowerCase';
import { useRouter } from 'next/router';
import { useContext } from 'react';
import { HeadingElements } from '~/@types/heading';
import SiteContext, { SiteDefinition } from '~/contexts/SiteContext';
import NearbyGrid from './NearbyGrid';
import { CatalogLink, Link } from './types';

export type NearbyCitiesProps = {
  heading?: string;
  headingElement?: HeadingElements;
  content?: string;
  links: Array<Link>;
  catalogLinks?: string;
  careType: string;
  enableTrailingSlash?: boolean;
  useCatalogForNearby?: boolean;
  initialData?: any[];
  city?: string;
  state?: string;
};

const useNearbySameCareType = (
  site: SiteDefinition | null,
  filters: { state: string; city: string; careType: string } | undefined,
  initialData: any[]
) => {
  let filtersToUse = {};

  if (filters && Object.keys(filters).length > 0) {
    const { state, city, careType } = filters;
    filtersToUse = {
      orderBy: 'urlPath asc',
      'state[eq]': lowerCase(state),
      'city[eq]': lowerCase(city),
      'careType[eq]': kebabCase(careType),
      '@ancestor': `/${site?.path}`,
    };
  }

  return useLocaleCatalog({
    filters: filtersToUse,
    initialData,
  });
};

const formatLink = ({ city, enableTrailingSlash, pagePath, careType }) => {
  const state = city?.localResourceTypeRegion?.state?.urlName || '';
  const region = city?.localResourceTypeRegion?.region?.urlName || '';

  const basePath = pagePath.split(careType)[0];
  const path = modifyTrailingSlash(
    enableTrailingSlash,
    `${basePath}${labelToSlug(careType)}${labelToSlug(state)}/${labelToSlug(
      region
    )}`
  );
  return { text: city.linkText, href: path };
};

const NearbyCities = ({
  heading,
  headingElement = 'h3',
  content,
  links,
  catalogLinks = '',
  careType,
  enableTrailingSlash = false,
  useCatalogForNearby = false,
  initialData = [],
  city = '',
  state = '',
}: NearbyCitiesProps) => {
  const route = useRouter();
  const pagePath = route.asPath;
  const siteContext = useContext(SiteContext);
  const careTypeFromProvider = careType || 'Assisted Living';
  const parsedLinks =
    catalogLinks && isJSON(catalogLinks) && JSON.parse(catalogLinks);
  const hasCatalogLinks =
    careTypeFromProvider === 'Assisted Living' && !!parsedLinks.data;

  const filters =
    useCatalogForNearby && !hasCatalogLinks
      ? { state, city, careType }
      : undefined;

  const { data: catalogData } = useNearbySameCareType(
    siteContext?.site,
    filters,
    initialData
  );

  let availableLinks: CatalogLink[] | Link[] = [];

  if (useCatalogForNearby) {
    if (hasCatalogLinks) {
      availableLinks = parsedLinks.data;
    } else {
      availableLinks =
        catalogData?.[0] && catalogData?.[0]['browseNearby.data']
          ? JSON.parse(catalogData?.[0]['browseNearby.data']).data
          : [];
    }
  } else {
    const formattedLinks = links.map((link) =>
      formatLink({
        city: link,
        enableTrailingSlash,
        pagePath,
        careType,
      })
    );
    availableLinks = formattedLinks;
  }

  if (availableLinks?.length === 0) {
    return null;
  }

  return (
    <>
      <Section
        headingElement={headingElement}
        title={heading}
        richText={content}
      />
      <NearbyGrid align="flex-start">
        {availableLinks?.map((link, i) => {
          return (
            <a key={`${i}-${link.href}`} href={link.href} target="_blank">
              <Button
                variant="outline"
                colorScheme="primary"
                whiteSpace="pre-wrap"
              >
                {link.text}
              </Button>
            </a>
          );
        })}
      </NearbyGrid>
    </>
  );
};

export default NearbyCities;
