import useExperimentViewed from '@components/Analytics/events/ExperimentViewed';
import { useEffect, useContext, useState } from 'react';
import { SessionContext } from '~/contexts/SessionContext';
import { useRouter } from 'next/router';

const removeQuery = (pathname: string) => {
  const queryStart = pathname.indexOf('?');
  if (queryStart !== -1) {
    return pathname.substring(0, queryStart);
  } else {
    return pathname;
  }
};

export interface ExperimentViewedProps {
  experimentId: string;
  experimentName: string;
  variationId: string;
  variationName: string;
}
const ExperimentViewed = ({
  experimentId = '',
  experimentName = '',
  variationId = '',
  variationName = '',
}: ExperimentViewedProps): null => {
  const sessionContext = useContext(SessionContext);
  const experimentViewed = useExperimentViewed();
  const pageSessionId = sessionContext?.pageSessionId;
  const sessionId = sessionContext?.sessionId;
  const router = useRouter();
  const path = removeQuery(router.asPath);
  const [previousPath, setPreviousPath] = useState<string>();

  useEffect(() => {
    if (!!pageSessionId && !!sessionId && path !== previousPath) {
      setPreviousPath(path);
      experimentViewed(
        sessionId,
        pageSessionId,
        experimentId,
        experimentName,
        variationId,
        variationName
      );
    }
  }, [
    pageSessionId,
    sessionId,
    experimentId,
    experimentName,
    variationId,
    variationName,
    experimentViewed,
    path,
    previousPath,
  ]);

  return null;
};

export default ExperimentViewed;
