import omit from 'lodash/omit';

import { useRouter } from 'next/router';

/**
 * @name useQueryState()
 *
 * @desc This hook behaves the same way as `useState` but stores the value
 * on the URL query parameters and uses the Next.js router to update the
 * value.
 *
 *
 * ### Without default values
 *
 * ```tsx
 * const [searchQuery, setSearchQuery] = useQueryState("search")
 * setSearchQuery("Something awesome")
 *
 * // https://example.com?search=Something%20awesome
 * ```
 *
 * ### With default values
 *
 * ```tsx
 * const [name, setName] = useQueryState("name", "Bob")
 * ...
 * console.log(name) // "Bob"
 * ```
 *
 * ### Reset to default value and remove from url
 *
 * Call the setter without a value to reset the value to its default and clear
 * the paramter from the URL.
 *
 * ```tsx
 * setValue()
 * ```
 */

const useQueryState = (
  key: string,
  defaultValue?: string
): [string | string[] | undefined, (nextValue?: string) => void] => {
  const { query, push, pathname } = useRouter();

  const setValue = (nextValue?: string) => {
    const nextQuery = nextValue
      ? { ...query, [key]: nextValue }
      : omit(query, key);

    push({ pathname, query: nextQuery }, undefined, {
      scroll: false,
      shallow: true,
    });
  };

  const value = query[key] || defaultValue;
  return [value, setValue];
};

export default useQueryState;
