import withHydrationOnDemand from 'react-hydration-on-demand';
import ProviderCareTypes from '@components/ProviderCareTypes/ProviderCareTypes';
import { HeadingElements } from '~/@types/heading';
import { CallToActionProps } from '@components/NewCallToAction/NewCallToAction';
import { StringToIconKeys } from '@components/RenderIcon';
interface Props {
  temaplateId: string;
  title: string;
  headingElement?: HeadingElements;
  summary: string;
  withImages?: boolean;
  withDescription?: boolean;
  withPricing?: boolean;
  obfuscatePricing?: boolean;
  withButton?: boolean;
  state?: 'solid' | 'outline' | 'ghost';
  text?: string;
  actionBehavior?: 'openInquiry' | 'openLink' | 'openReviewModal';
  inquiryId?: string;
  url?: string;
  behavior?: '_blank' | '_self' | '_parent' | '_top';
  rel?: Array<'external' | 'nofollow' | 'noopener' | 'noreferrer' | 'opener'>;
  type?: 'tel' | 'mailto' | 'sms';
  icon?: StringToIconKeys;
  bgColor?: string;
  textColor?: string;
}

const ProviderCareTypesWrapper = ({
  temaplateId,
  title,
  headingElement,
  summary,
  withImages,
  withDescription,
  withPricing,
  obfuscatePricing,
  withButton,
  state,
  text,
  actionBehavior,
  inquiryId,
  url,
  behavior,
  rel,
  type,
  icon,
  bgColor,
  textColor,
}: Props): JSX.Element => {
  const metadata = {
    '@id': temaplateId,
  };

  const cta = {
    text: text,
    textColor: textColor,
    bgColor: bgColor,
    inquiryId: inquiryId,
    actionBehavior: {
      field: actionBehavior,
      inquiryId: inquiryId,
      url: url,
      type: type,
      behavior: behavior,
      rel: rel,
    },
    icon: icon,
    behavior: behavior,
    rel: rel,
    type: type,
    state: state,
  } as CallToActionProps;

  return (
    <ProviderCareTypes
      title={title}
      summary={summary}
      withImages={withImages}
      withDescription={withDescription}
      withPricing={withPricing}
      obfuscatePricing={obfuscatePricing}
      withButton={withButton}
      headingElement={headingElement}
      cta={cta}
      metadata={metadata}
    />
  );
};

export default withHydrationOnDemand({
  on: ['idle', 'visible'],
  initialVisible: true,
})(ProviderCareTypesWrapper);
