import {
  Card as ChakraCard,
  CardProps as ChakraCardProps,
} from '@chakra-ui/card';
import { CardBody, CardFooter } from '@chakra-ui/react';

interface CardProps {
  domain: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
}

const styles: Record<string, ChakraCardProps> = {
  'caring.com': {
    p: { base: 2.5, lg: 4 },
    gap: { base: 2.5, lg: 4 },
  },
  default: {
    p: 4,
    gap: 4,
  },
};

const footerStyles: Record<string, ChakraCardProps> = {
  'caring.com': {
    px: { base: 2.5, lg: 4 },
  },
  default: {
    px: 4,
  },
};

const Card: React.FC<CardProps> = ({ domain, children, footer }) => {
  const cardStyles = styles[domain] || styles.default;

  return (
    <ChakraCard
      variant={{ base: 'elevated', md: 'outline' }}
      transition={'all ease 0.5s'}
      _hover={{
        transform: 'scale(1.05)',
        boxShadow: '0px 0px 25px 0px rgba(0, 0, 0, 0.35)',
      }}
      position="relative"
      p={0}
    >
      <CardBody
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        minHeight="56"
        justifyContent="space-between"
        {...cardStyles}
      >
        {children}
      </CardBody>
      {footer && (
        <CardFooter {...footerStyles} py={0}>
          {footer}
        </CardFooter>
      )}
    </ChakraCard>
  );
};

export default Card;
