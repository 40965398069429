import { Button } from '@chakra-ui/button';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/input';
import { Divider, Flex, Heading, Text } from '@chakra-ui/layout';
import { Select } from '@chakra-ui/select';
import { Switch } from '@chakra-ui/switch';
import AutocompleteInput from '@components/AutocompleteInput/AutocompleteInput';
import { SearchFormData } from '@components/SearchBar/SearchForm';
import useSearchFormSubmission from '@components/SearchBar/useSearchFormSubmission';
import { zodResolver } from '@hookform/resolvers/zod';
import useQueryState from '@hooks/use-query-state';
import useDebounce from '@hooks/useDebounce';
import useSearch from '@hooks/useSearch';
import { CareType, getDataByDomain } from '@services/magnolia/getDataByDomain';
import { AmenityCategoryNode } from '@utils/faceted-search';
import { getBackgroundColor } from '@utils/getColor';
import { capitalizeFullSentence } from '@utils/strings';
import kebabCase from 'lodash/kebabCase';
import dynamic from 'next/dynamic';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z as zod } from 'zod';
import SiteContext from '~/contexts/SiteContext';
import FacetedSearchBar from './FacetedSearchBar';
import { HandleSearchParams } from './Search';

const AnchorIcon = dynamic(() => import('@components/icons/AnchorIcon'));

export type SearchHandlerFunction = (params: HandleSearchParams) => void;

interface Props {
  currentPage: number;
  onChange?: SearchHandlerFunction;
  isLoading?: boolean;
  displayToggleMap?: boolean;
  displayTotal?: boolean;
  totalResults?: number;
  hideSearchButton?: boolean;
  searchBarBgColor?: string;
  searchBarBgColorRange?: string;
  searchBarButtonColorScheme?: string;
  readOnlyLocationInputPlaceholder?: string;
  enablePredictiveSearch: boolean;
  displayLocationFilter?: boolean;
  readOnlyLocationInput?: boolean;
  displayCareTypeFilter?: boolean;
  searchBarId: string;
  facetedSearch?: {
    enabled?: boolean;
    amenityCategory?: string;
    basePath?: string;
  };
  maxWidth?: number;
  amenityCategories?: AmenityCategoryNode[];
}

export enum SearchCareType {
  assisted = 'Assisted Living',
  independent = 'Independent Living',
}

const SearchBar = ({
  onChange,
  isLoading,
  currentPage,
  displayTotal,
  totalResults,
  displayToggleMap,
  hideSearchButton,
  searchBarBgColor = 'secondary',
  searchBarBgColorRange = '50',
  searchBarButtonColorScheme = 'accent',
  displayLocationFilter,
  readOnlyLocationInput,
  displayCareTypeFilter,
  readOnlyLocationInputPlaceholder,
  enablePredictiveSearch,
  searchBarId,
  facetedSearch,
  maxWidth = 70,
  amenityCategories,
}: Props) => {
  const { buildSearchUrl } = useSearch();
  const [keyword, setKeyword] = useQueryState('keyword');
  const [careType, setCareType] = useQueryState('careType');
  const siteProps = useContext(SiteContext);
  const domain = siteProps.site?.path ?? '';
  const [careTypesByDomain, setCareTypeByDomain] = useState<CareType[]>([]);
  const debouncedValue = useDebounce<string | string[] | undefined>(
    keyword,
    1000
  );
  const locationPlaceholder = 'Enter City, State or Zip';
  const selectCarePlaceholder = 'Select Care level';
  const showFacetedSearchBar =
    facetedSearch?.enabled && (amenityCategories ?? []).length > 0;

  const trackFormSubmission = useSearchFormSubmission({
    searchBarId,
    careTypeSelect: {
      placeholder: selectCarePlaceholder,
      visible: displayCareTypeFilter ?? false,
    },
    locationInput: {
      placeholder: locationPlaceholder,
      visible: displayLocationFilter ?? false,
    },
  });

  useEffect(() => {
    async function fetchData() {
      const domainData = (await getDataByDomain({ domain })) ?? [];
      if (domainData?.careTypes && domainData?.careTypes.length > 0) {
        const filteredCareTypes = domainData?.careTypes.filter(
          (careType) => careType.name
        );
        setCareTypeByDomain(filteredCareTypes);
      }
    }
    fetchData();
  }, [domain]);

  useEffect(() => {
    const fireOnChange = () => {
      if (
        onChange &&
        (debouncedValue || debouncedValue === '') &&
        hideSearchButton
      ) {
        onChange({
          keyword: debouncedValue as string,
          careType: careType as string,
          page: currentPage,
        });
      }
    };

    fireOnChange();
  }, [debouncedValue, onChange, currentPage, careType, hideSearchButton]);

  const handleKeywordOnChange = (value: string) => {
    setKeyword(value);
  };
  const handleCareTypeOnChange = (value: string) => {
    setCareType(value);
    if (hideSearchButton && onChange) {
      onChange({
        keyword: debouncedValue as string,
        careType: value as string,
        page: currentPage,
      });
    }
  };

  const getPlaceholderText = () => {
    if (readOnlyLocationInputPlaceholder) {
      return { placeholder: readOnlyLocationInputPlaceholder };
    }
  };

  const schema = zod.object({
    keyword: zod.string().trim(),
    careType: zod.string().trim(),
  });

  const { register, handleSubmit } = useForm({
    mode: 'onBlur',
    resolver: zodResolver(schema),
    defaultValues: {
      keyword: keyword || '',
      careType: careType || '',
    },
  });

  const onSubmit = handleSubmit(async (data, e) => {
    if (e) {
      e.preventDefault();
    }
    if (onChange) {
      trackFormSubmission(data as SearchFormData);
      if (domain === 'caring.com') {
        const careType =
          kebabCase(data.careType as string) || 'assisted-living';
        const keyword = data.keyword as string;
        const searchPageUrl = buildSearchUrl({ careType, keyword });

        location.assign(searchPageUrl);

        return;
      }
      onChange({
        keyword: data.keyword,
        careType: data.careType,
        page: currentPage,
      });
    }
  });

  return (
    <Flex direction="column">
      <Flex
        bg={getBackgroundColor(searchBarBgColor, searchBarBgColorRange)}
        alignItems="left"
        width="100%"
        padding={2}
        gap={2}
        rounded="md"
        justifyContent="space-between"
        direction={{ base: 'column', md: 'row' }}
      >
        <Flex
          maxW={{ base: '100%', md: `${maxWidth}%` }}
          direction={{ base: 'column', md: 'row' }}
          gap={4}
        >
          <form onSubmit={onSubmit} data-testid="search-bar-form">
            <Flex direction={{ base: 'column', md: 'row' }} gap={4}>
              {displayLocationFilter && (
                <Flex w={{ base: '100%' }}>
                  <InputGroup
                    display="flex"
                    alignItems="center"
                    zIndex="dropdown"
                  >
                    {enablePredictiveSearch ? (
                      <AutocompleteInput
                        aria-label="search-keyword"
                        bg="white"
                        size="lg"
                        isDisabled={readOnlyLocationInput}
                        isRequired={domain === 'caring.com'}
                        placeholder={locationPlaceholder}
                        {...getPlaceholderText()}
                        setKeyword={setKeyword}
                        sx={{ _disabled: { span: { opacity: 1 } } }}
                      />
                    ) : (
                      <Input
                        size="lg"
                        {...register('keyword', {
                          onChange: (e) =>
                            handleKeywordOnChange(e.target.value),
                        })}
                        autoComplete="shipping address-line1"
                        placeholder={locationPlaceholder}
                        bg="white"
                        aria-label="search-keyword"
                        isDisabled={readOnlyLocationInput}
                        sx={{ _disabled: { span: { opacity: 1 } } }}
                        {...getPlaceholderText()}
                        isRequired={domain === 'caring.com'}
                      />
                    )}

                    <InputRightElement height="100%">
                      <AnchorIcon />
                    </InputRightElement>
                  </InputGroup>
                </Flex>
              )}
              {displayCareTypeFilter && careTypesByDomain.length > 0 && (
                <Flex alignItems="center" w={{ base: '100%' }}>
                  <Select
                    size="lg"
                    {...register('careType', {
                      onChange: (e) => handleCareTypeOnChange(e.target.value),
                    })}
                    bg="white"
                    aria-label="search-care-type"
                    defaultValue=""
                  >
                    <option disabled value="">
                      {selectCarePlaceholder}
                    </option>
                    {careTypesByDomain.map((careTypeItem, index) => (
                      <option
                        value={careTypeItem.id}
                        key={`${careTypeItem.rollUpType}-${index}`}
                      >
                        {capitalizeFullSentence(careTypeItem.name)}
                      </option>
                    ))}
                  </Select>
                </Flex>
              )}
              {!hideSearchButton && (
                <Flex whiteSpace="nowrap" alignItems="center">
                  <Button
                    colorScheme={searchBarButtonColorScheme}
                    justifyContent="center"
                    name="search-button"
                    type="submit"
                    isLoading={isLoading}
                    textAlign="center"
                    width={{ base: 'full', md: 'auto' }}
                    height={{ base: '48px', md: '100%' }}
                  >
                    <Text color="white">Search</Text>
                  </Button>
                </Flex>
              )}
            </Flex>
          </form>
          {displayTotal && totalResults !== 0 && (
            <Flex whiteSpace="nowrap" alignItems="center">
              <Heading as="h3" size="sm">
                {totalResults === 1
                  ? `${totalResults} Result`
                  : `${totalResults} Results`}
              </Heading>
            </Flex>
          )}
        </Flex>
        <Flex justifyContent="flex-end">
          {displayToggleMap && (
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="toggle-map" mb="0">
                <Heading size="sm">Show Map</Heading>
              </FormLabel>
              <Switch id="toggle-map" size="lg" colorScheme="primary" />
            </FormControl>
          )}
        </Flex>
      </Flex>

      {showFacetedSearchBar && (
        <Flex direction="column" gap={4} px={2} py={5}>
          <Divider />
          <FacetedSearchBar
            initialValue={facetedSearch.amenityCategory}
            basePath={facetedSearch.basePath}
            links={amenityCategories ?? []}
            numberOfResults={totalResults}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default SearchBar;
