import { useContext } from 'react';
import withHydrationOnDemand from 'react-hydration-on-demand';
import ProviderContext, { Provider } from '~/contexts/Provider';
import {
  contractIsSubscription,
  providerIsEnhancedAndNotSuppressed,
} from '~/utils/providers';
import CallToAction from '../CallToAction';
import { CallToActionProps } from '../CallToAction/CallToAction';

interface Props extends CallToActionProps {
  provider?: Provider;
}

const SubscriptionProviderCTAComponent: React.FC<Props> = (props: Props) => {
  const providerCtx = useContext(ProviderContext)?.provider;
  const provider = props.provider || providerCtx;
  const emptyCta = !props.url && !props.isInquiry;

  if (
    emptyCta ||
    !provider ||
    !contractIsSubscription(provider) ||
    !providerIsEnhancedAndNotSuppressed(provider)
  ) {
    return null;
  }

  return <CallToAction width="fit-content" {...props} />;
};
const SubscriptionProviderCTA: React.FC<Props> = withHydrationOnDemand({
  on: ['idle', 'visible'],
  initialVisible: true,
})(SubscriptionProviderCTAComponent);

export default SubscriptionProviderCTA;
