import { GridItem, GridItemProps } from '@chakra-ui/layout';

interface InfoContainerProps {
  domain: string;
  colSpan: GridItemProps['colSpan'];
  children: React.ReactNode;
}

const styles: Record<string, GridItemProps> = {
  'caring.com': {
    gap: { base: 2.5, lg: 1 },
  },
  default: {
    gap: 1,
  },
};

const InfoContainer: React.FC<InfoContainerProps> = ({
  domain,
  colSpan,
  children,
}) => {
  const containerStyles = styles[domain] || styles.default;
  return (
    <GridItem
      colSpan={colSpan}
      rounded="md"
      alignItems="start"
      display="flex"
      flexDirection="column"
      {...containerStyles}
    >
      {children}
    </GridItem>
  );
};

export default InfoContainer;
