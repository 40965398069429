import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Divider, Flex, Heading } from '@chakra-ui/layout';
import { Switch } from '@chakra-ui/switch';
import { CareTypeObject } from '@components/Search/EnhancedSearch/ServerComponent';
import FacetedSearchBar from '@components/Search/FacetedSearchBar';
import { SEARCH_PARAM } from '@constants/search-params';
import { AmenityCategoryNode } from '@utils/faceted-search';
import { getBackgroundColor } from '@utils/getColor';
import { useSearchParams } from 'next/navigation';
import { useContext } from 'react';
import { SubmitHandler } from 'react-hook-form';
import SiteContext from '~/contexts/SiteContext';
import { Domains } from '~/types/Domains';
import SearchForm, { SearchFormData } from './SearchForm';
import useSearchFormSubmission from './useSearchFormSubmission';

interface SearchBarProps {
  isLoading?: boolean;
  displayToggleMap?: boolean;
  displayTotal?: boolean;
  totalResults?: number;
  hideSearchButton?: boolean;
  searchBarBgColor?: string;
  searchBarBgColorRange?: string;
  searchBarButtonColorScheme?: string;
  readOnlyLocationInputPlaceholder?: string;
  enablePredictiveSearch: boolean;
  displayLocationFilter?: boolean;
  readOnlyLocationInput?: boolean;
  displayCareTypeFilter?: boolean;
  searchBarId: string;
  facetedSearch?: {
    enabled?: boolean;
    amenityCategory?: string;
    basePath?: string;
  };
  maxWidth?: number;
  amenityCategories?: AmenityCategoryNode[];
  careTypes: CareTypeObject[];
  onSubmit: (data: SearchFormData) => void;
  groupSearchCareTypeOptions?: boolean;
  careType?: string;
  cityOrState?: string;
  preFillWithPageValues?: boolean;
}

const SearchBar: React.FC<SearchBarProps> = ({
  isLoading,
  displayTotal,
  totalResults,
  displayToggleMap,
  hideSearchButton,
  searchBarBgColor = 'secondary',
  searchBarBgColorRange = '50',
  searchBarButtonColorScheme = 'accent',
  displayLocationFilter,
  readOnlyLocationInput,
  displayCareTypeFilter,
  readOnlyLocationInputPlaceholder,
  enablePredictiveSearch,
  searchBarId,
  facetedSearch,
  maxWidth = 70,
  amenityCategories = [],
  careTypes,
  onSubmit,
  groupSearchCareTypeOptions,
  careType,
  cityOrState,
  preFillWithPageValues,
}) => {
  const searchParams = useSearchParams();
  const searchParamsCareType = searchParams?.get(SEARCH_PARAM.CARE_TYPE) ?? '';
  const keyword = searchParams?.get(SEARCH_PARAM.KEYWORD) ?? '';
  const siteProps = useContext(SiteContext);
  const domain = siteProps.site?.path ?? '';
  const hasChips = facetedSearch?.enabled && amenityCategories.length > 0;
  const locationPlaceholder = 'Enter City, State or Zip';
  const selectCarePlaceholder = 'Select Care level';

  const trackFormSubmission = useSearchFormSubmission({
    searchBarId,
    careTypeSelect: {
      placeholder: selectCarePlaceholder,
      visible: displayCareTypeFilter ?? false,
    },
    locationInput: {
      placeholder: locationPlaceholder,
      visible: displayLocationFilter ?? false,
    },
  });

  const handleSubmit: SubmitHandler<SearchFormData> = (data) => {
    trackFormSubmission(data);
    onSubmit(data);
  };

  return (
    <Flex direction="column">
      <Flex
        bg={getBackgroundColor(searchBarBgColor, searchBarBgColorRange)}
        direction={{ base: 'column', md: 'row' }}
        gap={2}
        justifyContent="space-between"
        padding={2}
        px={{ base: 0, md: 2 }}
        rounded="md"
        width="100%"
      >
        <Flex
          direction={{ base: 'column', md: 'row' }}
          gap={4}
          maxW={{ base: '100%', md: `${maxWidth}%` }}
        >
          <SearchForm
            defaultValues={{
              keyword: keyword,
              careType: searchParamsCareType,
            }}
            groupSearchCareTypeOptions={groupSearchCareTypeOptions}
            locationInput={{
              disabled: !!readOnlyLocationInput,
              placeholder: readOnlyLocationInputPlaceholder
                ? readOnlyLocationInputPlaceholder
                : locationPlaceholder,
              required: domain === Domains.CaringDomains.LIVE,
              visible: displayLocationFilter ?? false,
            }}
            careTypeSelect={{
              options: careTypes,
              placeholder: selectCarePlaceholder,
              visible: displayCareTypeFilter ?? false,
            }}
            submitButton={{
              colorScheme: searchBarButtonColorScheme,
              isLoading: isLoading ?? false,
              visible: !hideSearchButton,
            }}
            enablePredictiveSearch={enablePredictiveSearch}
            onSubmit={handleSubmit}
            careType={careType}
            cityOrState={cityOrState}
            preFillWithPageValues={preFillWithPageValues}
          />

          {displayTotal && totalResults !== 0 && (
            <Flex alignItems="center" whiteSpace="nowrap">
              <Heading as="h3" size="sm">
                {totalResults === 1
                  ? `${totalResults} Result`
                  : `${totalResults} Results`}
              </Heading>
            </Flex>
          )}
        </Flex>

        {displayToggleMap && (
          <Flex justifyContent="flex-end">
            <FormControl alignItems="center" display="flex">
              <FormLabel htmlFor="toggle-map" mb="0">
                <Heading size="sm">Show Map</Heading>
              </FormLabel>
              <Switch id="toggle-map" size="lg" whiteSpace="nowrap" />
            </FormControl>
          </Flex>
        )}
      </Flex>

      {hasChips && (
        <Flex
          direction="column"
          gap={4}
          pb={{ base: 0, lg: 5 }}
          pt={5}
          px={{ base: 0, lg: 2 }}
        >
          <Divider />
          <FacetedSearchBar
            basePath={facetedSearch.basePath}
            initialValue={facetedSearch.amenityCategory}
            links={amenityCategories}
            numberOfResults={totalResults}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default SearchBar;
