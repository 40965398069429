import { mockModMonProvider } from '@mocks/modmon.mock';

export const mockNearbyCare = {
  nearbyProviders: {
    results: [
      {
        id: '756dc5f8-49c0-41e7-8cce-c11efdf5f273',
        legacyId: '14599',
        name: 'Esplanade Gardens',
        description:
          '<p>Esplanade Gardens Senior Community is one of the premier senior living rental communities serving seniors age 55 and better. This beautiful campus setting offers studios in assisted living and memory care. Respite (short-term) stays are also available. Esplanade offers a vibrant and engaging lifestyle of wellness, independence, dignity and care.</p>\r\n' +
          '\r\n' +
          '<h4>Assisted Living Studio Suite Features:</h4>\r\n' +
          '<ul>\r\n' +
          '<li>Spacious studio suites</li>\r\n' +
          '<li>Emergency call system monitored by on-site staff</li>\r\n' +
          '<li>Individually-controlled temperature settings</li>\r\n' +
          '<li>Neutral carpet and finishes</li>\r\n' +
          '<li>Cable TV provided</li>\r\n' +
          '<li>Walk-in showers</li>\r\n' +
          '<li>Ample closet and cabinet space</li>\r\n' +
          '<li>Blinds on all windows</li>\r\n' +
          '</ul>\r\n' +
          '\r\n' +
          '<h4>Campus Amenities:</h4>\r\n' +
          '<ul>\r\n' +
          '<li>Social, recreational, spiritual and educational programs</li>\r\n' +
          '<li>Scheduled transportation to appointments and shopping</li>\r\n' +
          '<li>Craft/Activity room with programming</li>\r\n' +
          '<li>3 meals served daily with All Day Dining</li>\r\n' +
          '<li>Beauty/Barber shop with salon services</li>\r\n' +
          '<li>Library and card room</li>\r\n' +
          '<li>Guest rooms available</li>\r\n' +
          '<li>Daily snacks offered</li>\r\n' +
          '<li>Scheduled Chapel services</li>\r\n' +
          '<li>Weekly housekeeping</li>\r\n' +
          '<li>Photocopy and Fax service</li>\r\n' +
          '<li>Beautifully appointed common areas</li>\r\n' +
          '<li>Walking paths and outdoor patios</li>\r\n' +
          '<li>Restaurant-style dining room with varied menus</li>\r\n' +
          '<li>Weekly flat linen service; personal laundry may be arranged separately</li>\r\n' +
          '</ul>',
        address: mockModMonProvider.address,
        phoneNumber: '2105667600',
        reviewCount: 33,
        slug: 'esplanade-gardens-78109',
        image: 'https://d13iq96prksfh0.cloudfront.net/cdn/photos/215515',
        averageRating: 4.15,
        awards: [],
        images: [
          'https://d13iq96prksfh0.cloudfront.net/cdn/photos/215515',
          'https://d13iq96prksfh0.cloudfront.net/cdn/photos/329062',
          'https://d13iq96prksfh0.cloudfront.net/cdn/photos/215512',
          'https://d13iq96prksfh0.cloudfront.net/cdn/photos/329063',
        ],
        services: [mockModMonProvider.services],
        promotions: [],
        locationId: '756dc5f8-49c0-41e7-8cce-c11efdf5f273',
        amenities: [mockModMonProvider.amenities],
        accommodations: [mockModMonProvider.accommodations],
      },
      {
        id: '5e82314e-9106-459b-a360-a25f9d6d8e65',
        legacyId: '55426',
        name: 'Sodalis Windcrest at San Antonio',
        description:
          '<p>Whether you enjoy the freedom of personal independence or the comfort of being catered to, Sodalis San Antonio is tailored to meet your personal needs and preferences. Our community offers a wide range of options and services for seniors who want to enjoy a carefree lifestyle, providing everything you expect and more.</p>\r\n' +
          '\r\n' +
          '<p>When care is your concern, you and your loved ones are able to live worry-free, with the confidence that our 24/7 care staff is there to help in times of need. Every effort has been made to make Sodalis San Antonio a place where you feel comfortable and secure. </p>\r\n' +
          '\r\n' +
          '<p>Engaged living, a tranquil setting, and compassionate care are hallmarks of Sodalis San Antonio, an assisted living community that encourages an active lifestyle and daily activities for residents, both on campus and throughout the beautiful Jacksonville area. With designer touches throughout, you’ll enjoy a spacious apartment home and a list of amenities, knowing that you have a full range of personalized care available. Our chef will provide you with three delicious meals and snacks daily. With our “residents first” philosophy, our team embraces a proactive approach to the health and wellness of our residents, making our community distinctly capable of providing the highest levels of care, delivered with the warmth and hospitality of a family member. We have thought of everything to make our community your perfect new home. </p>\r\n' +
          '\r\n' +
          '<p> The Memory Care team embraces a proactive approach to resident health and wellness, delivering the highest levels of care with the warmth and hospitality of family. The team has specialized training to optimize quality of life through everyday activities in a meaningful way. For caregivers needing extra help during the day or while on vacation, Sodalis San Antonio offers respite stays, if rooms are available. </p> \r\n' +
          '\r\n' +
          '<p><h4>THOUGHTFUL AMENITIES</h4></p>\r\n' +
          '<ul>\r\n' +
          '<li>Studio and two-bedroom apartments</li>\r\n' +
          '<li>Professional care team who customizes a detailed plan to meet medical, physical, and social needs</li>\r\n' +
          '<li>Full-time concierge</li>\r\n' +
          '<li>Full-time Activity Director creates engaging live entertainment, off-site outings, and group activities</li>\r\n' +
          '<li>3 nutritionally balanced meals a day served restaurant-style in our dining room</li>\r\n' +
          '<li>Snacks and beverages available 24/7</li>\r\n' +
          '<li>Weekly housekeeping, including linen laundering, and daily changing and making beds </li>\r\n' +
          '<li>Professional and trained clinical staff available 24-hours a day</li>\r\n' +
          '<li>Spacious and beautiful public areas</li>\r\n' +
          '<li>Covered front porch, patio garden</li>\r\n' +
          '<li>Scheduled medical and shopping transportation</li>\r\n' +
          '<li>On-site beauty salon</li>\r\n' +
          '<li>On-site religious services</li>\r\n' +
          '<li>Pet-friendly</li>\r\n' +
          '<li>Wireless personal emergency service</li>\r\n' +
          '<li>Monthly rate includes all utilities (except telephone and cable)</li>\r\n' +
          '</ul>',
        address: mockModMonProvider.address,
        phoneNumber: '2109464994',
        reviewCount: 32,
        slug: 'sodalis-windcrest-at-san-antonio',
        image: 'https://d13iq96prksfh0.cloudfront.net/cdn/photos/387689',
        averageRating: 4.33,
        awards: [],
        images: [
          'https://d13iq96prksfh0.cloudfront.net/cdn/photos/329063',
          'https://d13iq96prksfh0.cloudfront.net/cdn/photos/329064',
          'https://d13iq96prksfh0.cloudfront.net/cdn/photos/329238',
        ],
        services: [mockModMonProvider.services],
        promotions: [],
        locationId: '5e82314e-9106-459b-a360-a25f9d6d8e65',
        amenities: [mockModMonProvider.amenities],
        accommodations: [mockModMonProvider.accommodations],
      },
      {
        id: 'f3e3a3d9-0e08-459e-972e-fd6d8f6921a5',
        legacyId: '84825',
        name: 'Victory Home',
        description:
          '<p>Victory Home is locally family owned assisted living care home with 9 residents. Victory Home is located in a beautiful quiet neighborhood. </p>\r\n' +
          '\r\n' +
          "<p>We aim to provide the highest quality of care to all residents at all times by making them feel safe, secure and cared for by our team comprising of an experienced caregivers. We recognize and uphold the resident's bill of rights. We respect the dignity and unique worth of each resident. </p>\r\n" +
          '\r\n' +
          '<p>We maintain and promote quality of life by ensuring that opportunities are available, accessible and developed in order that every individual resident is assisted and motivated for growth in all dimensions of life being physical, psychological, and social. </p>',
        address: [mockModMonProvider.address],
        phoneNumber: '2104627818',
        reviewCount: 1,
        slug: 'victory-home-78247',
        image: 'https://d13iq96prksfh0.cloudfront.net/cdn/photos/164034',
        averageRating: 2,
        awards: [],
        images: [
          'https://d13iq96prksfh0.cloudfront.net/cdn/photos/215514',
          'https://d13iq96prksfh0.cloudfront.net/cdn/photos/329237',
          'https://d13iq96prksfh0.cloudfront.net/cdn/photos/215510',
          'https://d13iq96prksfh0.cloudfront.net/cdn/photos/215509',
          'https://d13iq96prksfh0.cloudfront.net/cdn/photos/215511',
          'https://d13iq96prksfh0.cloudfront.net/cdn/photos/215513',
        ],
        services: [mockModMonProvider.services],
        promotions: [],
        locationId: 'f3e3a3d9-0e08-459e-972e-fd6d8f6921a5',
        amenities: [mockModMonProvider.amenities],
        accommodations: [mockModMonProvider.accommodations],
      },
      {
        id: '5e82314e-9106-459b-a360-a25f9d6d8e66',
        legacyId: '55426',
        name: 'Sodalis Windcrest at San Antonio',
        description:
          '<p>Whether you enjoy the freedom of personal independence or the comfort of being catered to, Sodalis San Antonio is tailored to meet your personal needs and preferences. Our community offers a wide range of options and services for seniors who want to enjoy a carefree lifestyle, providing everything you expect and more.</p>\r\n',
        address: mockModMonProvider.address,
        phoneNumber: '2109464994',
        reviewCount: 32,
        slug: 'sodalis-windcrest-at-san-antonio',
        image: 'https://d13iq96prksfh0.cloudfront.net/cdn/photos/387689',
        averageRating: 4.33,
        awards: [],
        images: [
          'https://d13iq96prksfh0.cloudfront.net/cdn/photos/329063',
          'https://d13iq96prksfh0.cloudfront.net/cdn/photos/329064',
          'https://d13iq96prksfh0.cloudfront.net/cdn/photos/329238',
        ],
        services: [mockModMonProvider.services],
        promotions: [],
        locationId: '5e82314e-9106-459b-a360-a25f9d6d8e65',
        amenities: [mockModMonProvider.amenities],
        accommodations: [mockModMonProvider.accommodations],
      },
    ],
    pages: 6,
    queryId: '',
    listId: '',
  },
  nearbyCities: [],
  nearbyOtherCareTypeSameCity: [],
};
