import { Button } from '@chakra-ui/button';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/input';
import { Flex, Text } from '@chakra-ui/layout';
import { Select } from '@chakra-ui/select';
import { CareTypeObject } from '@components/Search/EnhancedSearch/ServerComponent';
import { zodResolver } from '@hookform/resolvers/zod';
import { capitalizeFullSentence } from '@utils/strings';
import dynamic from 'next/dynamic';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z as zod } from 'zod';
import AutocompleteInput from '@components/AutocompleteInput/AutocompleteInput';
import { groupItemsByType, mappedCareTypes } from '@components/Search/utils';

const AnchorIcon = dynamic(() => import('@components/icons/AnchorIcon'));

const formSchema = zod.object({
  careType: zod.string().trim().min(1),
  keyword: zod.string().trim().min(1),
});

type DefaultFormData = zod.infer<typeof formSchema>;

export type SearchFormData = Partial<zod.infer<typeof formSchema>>;

interface SearchFormProps {
  careTypeSelect: {
    options: Array<CareTypeObject>;
    placeholder: string;
    visible: boolean;
  };
  defaultValues?: DefaultFormData;
  locationInput: {
    disabled: boolean;
    placeholder: string;
    required: boolean;
    visible: boolean;
  };
  submitButton: {
    colorScheme: string;
    isLoading: boolean;
    visible: boolean;
  };
  enablePredictiveSearch: boolean;
  onSubmit: SubmitHandler<SearchFormData>;
  groupSearchCareTypeOptions?: boolean;
  careType?: string;
  cityOrState?: string;
  preFillWithPageValues?: boolean;
}

const SelectCareType = ({ careTypes, areCareTypesGrouped = false }) => {
  if (areCareTypesGrouped) {
    const groupedItems = groupItemsByType(careTypes);
    return Object.keys(groupedItems).map((type) => (
      <optgroup key={type} label={`${type}`}>
        {groupedItems[type].map((careTypeItem) => (
          <option key={careTypeItem.id} value={careTypeItem.slug}>
            {capitalizeFullSentence(careTypeItem.name)}
          </option>
        ))}
      </optgroup>
    ));
  } else {
    return careTypes.map((careTypeItem) => (
      <option key={careTypeItem.id} value={careTypeItem.slug}>
        {capitalizeFullSentence(careTypeItem.name)}
      </option>
    ));
  }
};

const SearchForm: React.FC<SearchFormProps> = ({
  careTypeSelect,
  defaultValues,
  groupSearchCareTypeOptions = false,
  locationInput,
  submitButton,
  enablePredictiveSearch,
  onSubmit,
  careType,
  cityOrState,
  preFillWithPageValues = false,
}) => {
  const mappedKnownCareTypes = mappedCareTypes(
    careType ?? '',
    careTypeSelect.options
  );
  // Omit fields that are hidden from the validation schema
  const validationSchema = formSchema.omit({
    keyword: locationInput.visible ? undefined : true,
    careType: careTypeSelect.visible ? undefined : true,
  });

  const { handleSubmit, register, setValue, watch } = useForm<SearchFormData>({
    defaultValues: {
      careType: preFillWithPageValues
        ? mappedKnownCareTypes ?? defaultValues?.careType
        : defaultValues?.careType,
      keyword: preFillWithPageValues
        ? cityOrState || defaultValues?.keyword
        : defaultValues?.keyword,
    },
    mode: 'onBlur',
    resolver: zodResolver(validationSchema),
  });

  const selectedOption = watch('careType');

  const setKeyword = (value: string) => setValue('keyword', value);

  return (
    <Flex
      as="form"
      data-testid="search-bar-form"
      direction={{ base: 'column', md: 'row' }}
      gap={4}
      onSubmit={handleSubmit(onSubmit)}
    >
      {locationInput.visible ? (
        <Flex w={{ base: '100%' }}>
          <InputGroup alignItems="center" display="flex" zIndex="dropdown">
            {enablePredictiveSearch ? (
              <AutocompleteInput
                aria-label="search-keyword"
                bg="white"
                defaultValue={
                  preFillWithPageValues
                    ? cityOrState ?? defaultValues?.keyword
                    : defaultValues?.keyword
                }
                isDisabled={locationInput.disabled}
                isRequired={locationInput.required}
                placeholder={locationInput.placeholder}
                setKeyword={setKeyword}
                size="lg"
                sx={{ _disabled: { span: { opacity: 1 } } }}
              />
            ) : (
              <Input
                aria-label="search-keyword"
                autoComplete="shipping address-line1"
                bg="white"
                disabled={locationInput.disabled}
                placeholder={locationInput.placeholder}
                required={locationInput.required}
                size="lg"
                sx={{ _disabled: { span: { opacity: 1 } } }}
                {...register('keyword')}
              />
            )}
            <InputRightElement height="100%">
              <AnchorIcon />
            </InputRightElement>
          </InputGroup>
        </Flex>
      ) : null}

      {careTypeSelect.visible ? (
        <Flex alignItems="center" w={{ base: '100%' }}>
          <Select
            aria-label="search-care-type"
            bg="white"
            size="lg"
            {...register('careType')}
            color={selectedOption == '' ? 'gray.500' : 'primary'}
          >
            <option disabled hidden value="">
              Select Care Type
            </option>
            {SelectCareType({
              careTypes: careTypeSelect.options,
              areCareTypesGrouped: groupSearchCareTypeOptions,
            })}
          </Select>
        </Flex>
      ) : null}

      {submitButton.visible ? (
        <Flex alignItems="center" whiteSpace="nowrap">
          <Button
            colorScheme={submitButton.colorScheme}
            height={{ base: '48px', md: '100%' }}
            isLoading={submitButton.isLoading}
            justifyContent="center"
            name="search-button"
            textAlign="center"
            type="submit"
            width={{ base: 'full', md: 'auto' }}
          >
            <Text color="white">Search</Text>
          </Button>
        </Flex>
      ) : null}
    </Flex>
  );
};

export default SearchForm;
