import { HEADER_DESKTOP_HEIGHT } from '@components/Layouts/layoutConstants';
import {
  DisplayMode,
  DISPLAY_MODE,
  SEARCH_OPTION,
} from '@components/Search/EnhancedSearch/constants';
import { EnhancedSearchParsedConfig } from '@components/Search/EnhancedSearch/parser';
import { isAmenityCategory } from '@utils/faceted-search';
import { Domain, Domains } from '~/types/Domains';
import { Source } from '~/types/json-api-response';
import {
  FacetedSearchParsedParams,
  hasSearchParamsChanged,
} from './search-params';
import { FacetedSearchParsedProps } from './types';
import { CareType } from '@services/magnolia/getDataByDomain';

const getScrollMargins = (target: HTMLElement) => {
  const computedStyle = getComputedStyle(target);
  const top = parseFloat(computedStyle.scrollMarginTop) || 0;
  const right = parseFloat(computedStyle.scrollMarginRight) || 0;
  const bottom = parseFloat(computedStyle.scrollMarginBottom) || 0;
  const left = parseFloat(computedStyle.scrollMarginLeft) || 0;

  return { top, right, bottom, left };
};

export const scrollToElement = (
  element: HTMLElement | null,
  behavior: ScrollBehavior = 'smooth',
  offset: number = HEADER_DESKTOP_HEIGHT
) => {
  if (!element) {
    return;
  }

  const scrollMargin = getScrollMargins(element).top;

  const top = element.offsetTop - offset - scrollMargin;

  window.scrollTo({
    top,
    behavior,
  });
};

export const createEnhancedSearchQuery = ({
  searchParams,
  componentProps,
}: {
  searchParams: FacetedSearchParsedParams;
  componentProps: FacetedSearchParsedProps;
}) => {
  const query = {
    careType: componentProps.careType,
    city: componentProps.city,
    county: componentProps.county,
    hitsPerPage: componentProps.resultsPerPage,
    keyword: searchParams.keyword,
    latitude: componentProps.latitude,
    longitude: componentProps.longitude,
    page: searchParams.page,
    state: componentProps.state,
    source: componentProps.source,
    radiusForSearch: searchParams.distanceInMiles,
    domain: componentProps.domain,
  };
  return query;
};

const createGeoParams = (componentProps: FacetedSearchParsedProps) => {
  return componentProps.distanceFilterEnabled
    ? { latitude: componentProps.latitude, longitude: componentProps.longitude }
    : {
        city: componentProps.city,
        county: componentProps.county,
        state: componentProps.state,
      };
};

const createCriteria = (
  searchParams: FacetedSearchParsedParams,
  componentProps: FacetedSearchParsedProps,
  geoParams: object
) => {
  return {
    accessibility: searchParams.accessibility,
    amenityCategory: componentProps.amenityCategory
      ? [componentProps.amenityCategory]
      : [],
    awards: searchParams.awards,
    careType:
      componentProps.displayMode === DISPLAY_MODE.LIST
        ? componentProps.careType
        : searchParams.careType,
    dining: searchParams.dining,
    distanceInMiles: searchParams.distanceInMiles,
    domain: componentProps.domain,
    healthServices: searchParams.healthServices,
    keyword: searchParams.keyword,
    languages: searchParams.languages,
    lifestyle: searchParams.lifestyle,
    ongoingPromotion: searchParams.ongoingPromotion,
    otherAmenities: searchParams.otherAmenities,
    personalCare: searchParams.personalCare,
    priceRange: searchParams.priceRange,
    providersWith: searchParams.providersWith,
    reviews: searchParams.reviews,
    roomAmenities: searchParams.roomAmenities,
    roomType: searchParams.roomType,
    staffQualifications: searchParams.staffQualifications,
    verified: searchParams.verified,
    ...geoParams,
  };
};

export const createFacetedSearchQuery = ({
  componentProps,
  searchParams,
}: {
  componentProps: FacetedSearchParsedProps;
  searchParams: FacetedSearchParsedParams;
}) => {
  const geoParams =
    componentProps.displayMode === DISPLAY_MODE.SEARCH
      ? {}
      : createGeoParams(componentProps);
  const criteria = createCriteria(searchParams, componentProps, geoParams);

  return {
    hitsPerPage: componentProps.resultsPerPage,
    matchAllFilters: searchParams.hasOwnProperty('matchAllFilters')
      ? searchParams.matchAllFilters
      : null,
    page: searchParams.page,
    sortBy: searchParams.sortBy,
    criteria,
  };
};

export const isAmenityCategoryPage = (
  amenityCategoryChips: EnhancedSearchParsedConfig['amenityCategoryChips']
): Boolean => {
  if (
    amenityCategoryChips.visible &&
    isAmenityCategory(amenityCategoryChips.amenityCategory || '')
  ) {
    return true;
  }

  return false;
};

export const getSourceForFacetedSearch = ({
  displayMode,
  domain,
  amenityCategoryChips,
  searchParams,
}: {
  displayMode: DisplayMode;
  domain: Domain;
  amenityCategoryChips: EnhancedSearchParsedConfig['amenityCategoryChips'];
  searchParams: FacetedSearchParsedParams;
}): Source => {
  const useAlgolia =
    domain !== Domains.CaringDomains.LIVE ||
    displayMode === DISPLAY_MODE.SEARCH ||
    isAmenityCategoryPage(amenityCategoryChips) ||
    hasSearchParamsChanged(searchParams);

  return useAlgolia ? Source.ALGOLIA : Source.LEGACY;
};

export const hasDistanceFilter = (
  searchSidebar: EnhancedSearchParsedConfig['searchSidebar']
) => {
  return searchSidebar.filters.includes(SEARCH_OPTION.DISTANCE);
};

export const getCareTypeId = (slug: string, careTypes: CareType[]): string => {
  const matchingCareType = careTypes.find((e) => e.slug === slug);
  if (matchingCareType) return matchingCareType.id;
  else return slug;
};
