import { Button } from '@chakra-ui/button';
import { Section } from '@components/Sections';
import { modifyTrailingSlash } from '@utils/modifyTrailingSlash';
import { labelToSlug } from '@utils/strings';
import { useRouter } from 'next/router';
import NearbyGrid from './NearbyGrid';
import { NearByLinkProps } from './types';

const NearbyCounties = ({
  links,
  heading,
  headingElement = 'h3',
  content,
  careType,
  enableTrailingSlash = false,
}: NearByLinkProps) => {
  const route = useRouter();
  const pagePath = route.asPath;
  return (
    <>
      <Section
        headingElement={headingElement}
        title={heading}
        richText={content}
      />
      <NearbyGrid align="flex-start">
        {links.map((county, index) => {
          const state = county?.localResourceTypeRegion?.state?.urlName || '';
          const region = county?.localResourceTypeRegion?.region?.urlName || '';
          const basePath = pagePath.split(careType)[0];
          const path = modifyTrailingSlash(
            enableTrailingSlash,
            `${basePath}${labelToSlug(careType)}${labelToSlug(
              state
            )}/${labelToSlug(region)}`
          );

          return (
            <a key={index} href={path} target="_blank">
              <Button
                variant="outline"
                colorScheme="primary"
                whiteSpace="pre-wrap"
              >
                {county.linkText}
              </Button>
            </a>
          );
        })}
      </NearbyGrid>
    </>
  );
};

export default NearbyCounties;
