import kebabCase from 'lodash/kebabCase';
import { MapFeature } from '~/types/MapFeature';

export const normalizeMapFeatures = (featuresFromProps: {
  [key: string]: string | string[] | MapFeature | undefined;
}): MapFeature[] | null => {
  if (typeof featuresFromProps == 'undefined') {
    return null;
  }

  if (typeof featuresFromProps['@nodes'] == 'undefined') {
    if (Array.isArray(featuresFromProps) && featuresFromProps.length > 0) {
      return featuresFromProps.map(
        (feature) =>
          ({
            ...feature,
            featureId: kebabCase(feature.altText),
            active: true,
          } as MapFeature)
      );
    }
    return null;
  }

  const featuresKeys = Array.isArray(featuresFromProps['@nodes'])
    ? featuresFromProps['@nodes']
    : undefined;

  const featuresUnstructured: MapFeature[] | undefined = featuresKeys?.map(
    (featureKey) => featuresFromProps[featureKey] as MapFeature
  );

  if (!featuresUnstructured) return null;

  return featuresUnstructured.map(
    (featuresUnstructured) =>
      ({
        ...featuresUnstructured,
        featureId: kebabCase(featuresUnstructured.altText),
        active: true,
      } as MapFeature)
  );
};
