import { FocusLock } from '@chakra-ui/focus-lock';
import { Box } from '@chakra-ui/layout';
import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import { useBreakpointValue } from '@chakra-ui/react';
import { navigateToQuery } from '@components/FacetedSearch/navigation';
import { SEARCH_PARAM } from '@constants/search-params';
import { getSearchParamsFromWindow } from '@utils/url';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { Photo } from './types';
import PhotoGalleryModalSlider from '@components/PhotoGallery/PhotoGalleryModalSlider';

interface PhotoGalleryModalProps {
  photos: Array<Photo>;
  modalSidebar?: React.ReactNode;
  galleryIndex: number | null;
  setGalleryIndex: React.Dispatch<React.SetStateAction<null | number>>;
}

const PhotoGalleryModal: React.FC<PhotoGalleryModalProps> = ({
  photos,
  modalSidebar,
  galleryIndex = null,
  setGalleryIndex,
}) => {
  const router = useRouter();
  const shouldShowSidebar = useBreakpointValue({ base: false, lg: true });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has(SEARCH_PARAM.PHOTO_INDEX)) {
      setGalleryIndex(parseInt(urlParams.get(SEARCH_PARAM.PHOTO_INDEX) ?? ''));
    }
  }, [setGalleryIndex]);

  const onCloseModal = () => {
    setGalleryIndex(null);
    const searchParams = getSearchParamsFromWindow();
    searchParams.delete(SEARCH_PARAM.PHOTO_INDEX);
    navigateToQuery(router, searchParams);
  };

  return (
    <Modal
      isCentered
      isOpen={!!galleryIndex}
      size={{ base: 'lg', lg: '4xl' }}
      trapFocus={false}
      onClose={onCloseModal}
    >
      <FocusLock persistentFocus>
        <ModalOverlay />
        <ModalContent
          position="absolute"
          containerProps={{
            width: {
              base: '100vw',
              lg: modalSidebar ? '70vw' : '100vw',
              '3xl': modalSidebar ? '80vw' : '100vw',
            },
          }}
        >
          {!!galleryIndex && (
            <PhotoGalleryModalSlider
              images={photos}
              galleryIndex={galleryIndex}
              setGalleryIndex={setGalleryIndex}
              onCloseModal={onCloseModal}
            />
          )}
        </ModalContent>

        {shouldShowSidebar && modalSidebar && (
          <Box
            position="fixed"
            top={0}
            bottom={0}
            right={0}
            zIndex={1500}
            maxWidth={{
              base: '30vw',
              '3xl': '20vw',
            }}
          >
            {modalSidebar}
          </Box>
        )}
      </FocusLock>
    </Modal>
  );
};

export default PhotoGalleryModal;
