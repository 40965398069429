import { ButtonProps } from '@chakra-ui/button';
import Button from '@components/Button';
import { Icon } from '@chakra-ui/icon';
import { Box } from '@chakra-ui/layout';
import { ComponentWithAs } from '@chakra-ui/system';
import {
  ElementActions,
  ElementNames,
  ElementTypes,
} from '@components/Analytics/events/ElementClicked';
import { SEARCH_PARAM } from '@constants/search-params';
import Image from 'next/image';
import { MdOutlineCameraAlt, Md360 } from 'react-icons/md';
import { Photo } from './types';

interface SinglePhotoGalleryProps {
  photos: Array<Photo>;
  setIsStreetViewModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setGalleryIndex: React.Dispatch<React.SetStateAction<null | number>>;
}

const StreetViewIconButton: ComponentWithAs<'button', ButtonProps> = ({
  children,
  ...rest
}) => {
  return (
    <Button
      bg="white"
      borderRadius="4px"
      borderWidth="1px"
      colorScheme="primary"
      h="30px"
      width={7}
      leftIcon={<Icon as={Md360} boxSize={6} />}
      iconSpacing={0}
      minWidth={0}
      variant="outline"
      data-testid="street-view"
      elementAction={ElementActions.OPEN_MODAL}
      elementName={ElementNames.STREET_VIEW}
      elementType={ElementTypes.BUTTON}
      {...rest}
    />
  );
};

const ShowAllPhotosIconButton: ComponentWithAs<'button', ButtonProps> = ({
  children,
  ...rest
}) => {
  return (
    <Button
      bg="white"
      borderRadius="4px"
      borderWidth="1px"
      colorScheme="primary"
      fontSize="16px"
      fontWeight="700"
      h="30px"
      leftIcon={<Icon as={MdOutlineCameraAlt} mr="-1.5" boxSize={5} />}
      px="4px"
      variant="outline"
      elementAction={ElementActions.OPEN_MODAL}
      elementName={ElementNames.VIEW_GALLERY}
      elementType={ElementTypes.BUTTON}
      {...rest}
    >
      {children}
    </Button>
  );
};

export const SinglePhotoGallery: React.FC<SinglePhotoGalleryProps> = ({
  photos,
  setIsStreetViewModalOpen,
  setGalleryIndex,
}) => {
  const [photoToDisplay] = photos;

  return (
    <>
      <Box
        onClick={() => {
          setGalleryIndex(1);
          window.history.replaceState(
            window.history.state,
            '',
            `?${SEARCH_PARAM.PHOTO_INDEX}=1`
          );
        }}
      >
        <Box position="relative" sx={{ aspectRatio: '627 / 407' }}>
          <Image
            alt={photoToDisplay.alt}
            fill
            priority
            sizes="(max-width: 992px) 100vw, 850px"
            src={photoToDisplay.url}
            style={{ objectFit: 'cover', objectPosition: 'center' }}
          />
        </Box>
      </Box>
      <Box
        bottom="12px"
        left="12px"
        position="absolute"
        gap="4px"
        display="flex"
      >
        {photos.length > 0 ? (
          <ShowAllPhotosIconButton
            onClick={() => {
              setGalleryIndex(1);
              window.history.replaceState(
                window.history.state,
                '',
                `?${SEARCH_PARAM.PHOTO_INDEX}=1`
              );
            }}
          >
            {photos.length}
          </ShowAllPhotosIconButton>
        ) : null}
        <StreetViewIconButton
          onClick={() => {
            setIsStreetViewModalOpen && setIsStreetViewModalOpen(true);
            window.history.replaceState(
              window.history.state,
              '',
              `?${SEARCH_PARAM.STREET_VIEW}=true`
            );
          }}
        />
      </Box>
    </>
  );
};

export default SinglePhotoGallery;
