import { Box, Grid, Stack } from '@chakra-ui/layout';
import dynamic from 'next/dynamic';
const Container = dynamic(
  () => import('@components/LayoutStructure/Container')
);
const ProvidersNearMeCards = dynamic(
  () => import('@components/ProvidersNearMe/ProvidersNearMeCards')
);
import { Section } from '@components/Sections';
import { ProvidersNearMeQueryResponse } from '@hooks/provider';
import orderBy from 'lodash/orderBy';

import { useContext } from 'react';
import CatalogContext from '~/contexts/CatalogContext';
import NearbyCities from './NearbyCities';
import NearbyCounties from './NearbyCounties';
import NearbyOtherCareTypeSameCity from './NearbyOtherCareTypeSameCity';
import NearbyOtherCareTypesNearbyCity from './NearbyOtherCareTypesNearbyCity';
import { NearbyCareProps, Section as SectionType } from './types';
import withHydrationOnDemand from 'react-hydration-on-demand';
import ProviderContext from '~/contexts/Provider';
import { Domains } from '~/types/Domains';
import SiteContext from '~/contexts/SiteContext';
import isObject from 'lodash/isObject';
import { providerIsEnhancedAndNotSuppressed } from '~/utils/providers';
import { useResponsiveDisplay } from '@hooks/useResponsiveDisplay';

export type FetchResponse = {
  nearbyProviders: ProvidersNearMeQueryResponse | {};
  nearbyCities: any[] | [];
  nearbyOtherCareTypeSameCity: any[] | [];
};

const NearbyCare: React.FC<NearbyCareProps> = ({
  sections,
  communities,
  nearbyCitiesSameType,
  nearbyCountiesSameType,
  otherCareTypesSameCity,
  otherCareTypesNearbyCity,
  numberOfItemsToDisplay = '8',
  bgBorderRadius,
  state,
  city,
  county,
  careType,
  data,
  numberOfItemsPerRow,
  providerTitleColor,
  providerTitleColorRange,
  displayBadges = false,
  displayLearnMoreButton = false,
  learnMoreButtonText = 'Learn More',
  displayRequestInfoButton = false,
  inquiryId = '',
  requestInfoButtonText = 'Request Info',
  readMoreButton = 'redirect_to_provider_page',
  requestInfoButtonColorScheme = 'accent',
  learnMoreButtonColorScheme = 'accent',
  ratingStarsColor = 'accent',
  ratingStarsColorRange = '500',
  dontOpenInNewTab,
  deviceVisibility,
}) => {
  const { nearbyProviders, nearbyCities, nearbyOtherCareTypeSameCity } = data;
  const { site } = useContext(SiteContext);
  const catalogContext = useContext(CatalogContext);
  const { legacyNearbyData } = catalogContext || {};
  const isCaringWebSite = site?.path === Domains.CaringDomains.LIVE;
  const provider = useContext(ProviderContext)?.provider;
  const alteredCareType = isObject(careType) ? careType.name : careType;
  const isEnhanced = providerIsEnhancedAndNotSuppressed(provider);
  const isBasicListing = isCaringWebSite && !isEnhanced;
  const displayNearbyProviderListing = !isCaringWebSite || isBasicListing;
  const isHidden = useResponsiveDisplay(deviceVisibility);
  if (isHidden) {
    return <></>;
  }
  const {
    nearbyTypesNearbyCity,
    nearbyTypesSameCity,
    sameTypeNearbyCities,
    sameTypeNearbyCounties,
  } = legacyNearbyData || {};
  // https://caring.atlassian.net/browse/CME-893
  // this should be temporary
  const alteredCounty = county?.replace('County', '').trim();

  const nearbyCommunities =
    orderBy(nearbyProviders?.results, ['bayesian_average'], ['desc']) || [];
  if (nearbyCommunities.length === 0) return <Box />;

  const stateAndCounty = [state, alteredCounty].join(', ');
  const nearbyCommunitiesHeading = `Senior Living Communities near ${stateAndCounty}`;
  const showNearbyCommunities =
    communities?.enable && nearbyCommunities.length > 0;

  const nearbyCitiesHeading = `Explore other cities in ${stateAndCounty}`;
  const showNearbyCities =
    (nearbyCitiesSameType?.enable &&
      sameTypeNearbyCities &&
      sameTypeNearbyCities.length > 0) ||
    nearbyCitiesSameType?.useCatalogForNearby;

  const nearbyCountiesHeading = `Go a little further out, finding care in nearby counties`;
  const showNearbyCounties =
    nearbyCountiesSameType?.enable &&
    sameTypeNearbyCounties &&
    sameTypeNearbyCounties.length > 0;

  const nearbyTypesSameCityHeading = `Other Options`;
  const showOtherCareTypesSameCity =
    (otherCareTypesSameCity?.enable &&
      nearbyTypesSameCity &&
      nearbyTypesSameCity.length > 0) ||
    otherCareTypesSameCity?.useCatalogForNearby;

  const nearbyTypesNearbyCityHeading = `More Options Near`;
  const showOtherCareTypesNearbyCity =
    otherCareTypesNearbyCity?.enable &&
    nearbyTypesNearbyCity &&
    nearbyTypesNearbyCity.length > 0;

  if (
    (!showNearbyCommunities || !displayNearbyProviderListing) &&
    !showNearbyCities &&
    !showNearbyCounties &&
    !showOtherCareTypesSameCity &&
    !showOtherCareTypesNearbyCity &&
    sections?.['@nodes']?.length === 0
  )
    return <></>;

  return (
    <Container bg="gray.50" py="16" borderRadius={bgBorderRadius}>
      <Stack spacing="8" align="stretch">
        {sections?.['@nodes']?.map((node) => {
          const { headingElement, heading, content } = sections[
            node
          ] as SectionType;
          return (
            <Section
              key={node}
              headingElement={headingElement}
              title={heading}
              richText={content}
            />
          );
        })}

        {showNearbyCommunities && displayNearbyProviderListing && (
          <>
            <Section
              headingElement={communities.headingElement}
              richText={communities.content}
              title={communities.heading || nearbyCommunitiesHeading}
            />
            <Grid
              templateColumns={{
                base: 'repeat(3, 1fr)',
                sm: 'repeat(6, 1fr)',
                md: 'repeat(12, 1fr)',
              }}
              gap={6}
            >
              <ProvidersNearMeCards
                providers={nearbyCommunities}
                enableTrailingSlash={communities.enableTrailingSlash}
                numberOfItemsPerRow={numberOfItemsPerRow}
                providerTitleColor={providerTitleColor}
                providerTitleColorRange={providerTitleColorRange}
                displayBadges={displayBadges}
                displayLearnMoreButton={displayLearnMoreButton}
                learnMoreButtonText={learnMoreButtonText}
                displayRequestInfoButton={displayRequestInfoButton}
                requestInfoButtonText={requestInfoButtonText}
                readMoreButton={readMoreButton}
                modalId={inquiryId}
                requestInfoButtonColorScheme={requestInfoButtonColorScheme}
                learnMoreButtonColorScheme={learnMoreButtonColorScheme}
                ratingStarsColor={ratingStarsColor}
                ratingStarsColorRange={ratingStarsColorRange}
                dontOpenInNewTab={dontOpenInNewTab}
              />
            </Grid>
          </>
        )}

        {showNearbyCities && (
          <NearbyCities
            careType={alteredCareType}
            links={sameTypeNearbyCities || []}
            catalogLinks={nearbyCitiesSameType?.catalogLinks || ''}
            content={nearbyCitiesSameType.content}
            heading={nearbyCitiesSameType.heading || nearbyCitiesHeading}
            headingElement={nearbyCitiesSameType.headingElement}
            enableTrailingSlash={nearbyCitiesSameType.enableTrailingSlash}
            useCatalogForNearby={nearbyCitiesSameType.useCatalogForNearby}
            initialData={nearbyCities}
            city={city}
            state={state}
          />
        )}

        {showNearbyCounties && (
          <NearbyCounties
            careType={alteredCareType}
            links={sameTypeNearbyCounties}
            content={nearbyCountiesSameType.content}
            heading={nearbyCountiesSameType.heading || nearbyCountiesHeading}
            headingElement={nearbyCountiesSameType.headingElement}
            enableTrailingSlash={nearbyCountiesSameType.enableTrailingSlash}
          />
        )}
        {showOtherCareTypesSameCity && (
          <NearbyOtherCareTypeSameCity
            careType={alteredCareType}
            links={nearbyTypesSameCity || []}
            content={otherCareTypesSameCity.content}
            heading={
              otherCareTypesSameCity.heading || nearbyTypesSameCityHeading
            }
            headingElement={otherCareTypesSameCity.headingElement}
            enableTrailingSlash={otherCareTypesSameCity.enableTrailingSlash}
            useCatalogForNearby={otherCareTypesSameCity.useCatalogForNearby}
            initialData={nearbyOtherCareTypeSameCity}
            city={city}
            state={state}
          />
        )}
        {showOtherCareTypesNearbyCity && (
          <NearbyOtherCareTypesNearbyCity
            careType={alteredCareType}
            links={nearbyTypesNearbyCity}
            content={otherCareTypesNearbyCity.content}
            heading={
              otherCareTypesNearbyCity.heading || nearbyTypesNearbyCityHeading
            }
            headingElement={otherCareTypesNearbyCity.headingElement}
            enableTrailingSlash={otherCareTypesNearbyCity.enableTrailingSlash}
          />
        )}
      </Stack>
    </Container>
  );
};

export const getServerSideProps = async () => {
  return {};
};

export default withHydrationOnDemand({
  on: ['idle', 'visible'],
  initialVisible: true,
})(NearbyCare);
