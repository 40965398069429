import { searchStepSubmission, searchStep } from './eventContracts';
import {
  SessionContextType,
  useSessionContext,
} from '~/contexts/SessionContext';
import segmentEvents from '~/config/segment-events';

interface SearchStepSubmissionType {
  sessionContext: SessionContextType;
  searchStepData: searchStep;
}

const searchStepSubmit = ({
  sessionContext,
  searchStepData,
}: SearchStepSubmissionType) => {
  const searchStepSubmissionContract: searchStepSubmission = {
    search_step_submission_json: JSON.stringify({
      search_step_submission: [searchStepData],
    }),
    page_session_id: sessionContext.pageSessionId,
    session_id: sessionContext.sessionId,
    form_type: searchStepData.form_type ?? '',
  };
  window.tracking.track(
    segmentEvents.SEARCH_STEP_SUBMITTED,
    searchStepSubmissionContract
  );
};

const useSearchStepSubmission = () => {
  const sessionContext = useSessionContext();
  return (searchStepData: searchStep) => {
    return searchStepSubmit({ sessionContext, searchStepData });
  };
};

export default useSearchStepSubmission;
