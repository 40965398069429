import {
  Heading as ChakraHeading,
  HeadingProps as ChakraHeadingProps,
} from '@chakra-ui/layout';
import { createID } from '@utils/strings';
import dynamic from 'next/dynamic';
import { HeadingElements, HeadingSizes } from '~/@types/heading';
const Container = dynamic(
  () => import('@components/LayoutStructure/Container')
);
import { filterNonReactAttributes } from '@utils/filterNonReactAttributes';
export interface HeadingProps extends ChakraHeadingProps {
  title: string;
  headingElement?: HeadingElements;
  headingSize?: HeadingSizes | HeadingSizes[];
  withContainer?: boolean;
  titleAlignment?: 'left' | 'center' | 'right';
  paddingTop?: string;
  paddingBottom?: string;
}
const Heading = ({
  title,
  headingElement = 'h2',
  headingSize = 'xl',
  withContainer = true,
  titleAlignment = 'left',
  paddingTop,
  paddingBottom,
  children,
  ...rest
}: HeadingProps): JSX.Element => {
  const attrubutes = filterNonReactAttributes(rest);
  const getHeading = () => (
    <ChakraHeading
      as={headingElement}
      size={headingSize}
      id={createID(title)}
      textAlign={titleAlignment}
      style={{ scrollMarginTop: 48 }}
      pt={paddingTop}
      pb={paddingBottom}
      {...attrubutes}
    >
      {title}
      {children}
    </ChakraHeading>
  );

  if (!withContainer) return getHeading();

  return <Container noHorizontalPadding={true}>{getHeading()}</Container>;
};

export default Heading;
