import NearbyCare from '@components/NearbyCare/NearbyCare';
import { NearbyCareProps } from '@components/NearbyCare/types';

const NearbyCareWrapper = ({
  communities,
  nearbyCitiesSameType,
  nearbyCountiesSameType,
  otherCareTypesSameCity,
  otherCareTypesNearbyCity,
  numberOfItemsToDisplay = '8',
  bgBorderRadius,
  numberOfItemsPerRow,
  providerTitleColor,
  providerTitleColorRange,
  displayBadges = false,
  displayLearnMoreButton = false,
  learnMoreButtonText = 'Learn More',
  displayRequestInfoButton = false,
  inquiryId,
  requestInfoButtonText = 'Request Info',
  readMoreButton = 'redirect_to_provider_page',
  requestInfoButtonColorScheme = 'accent',
  learnMoreButtonColorScheme = 'accent',
  ratingStarsColor = 'accent',
  ratingStarsColorRange = '500',
  data,
}: NearbyCareProps): JSX.Element => {
  return (
    <NearbyCare
      communities={communities}
      nearbyCitiesSameType={nearbyCitiesSameType}
      nearbyCountiesSameType={nearbyCountiesSameType}
      otherCareTypesSameCity={otherCareTypesSameCity}
      otherCareTypesNearbyCity={otherCareTypesNearbyCity}
      numberOfItemsToDisplay={numberOfItemsToDisplay}
      bgBorderRadius={bgBorderRadius}
      data={data}
      numberOfItemsPerRow={numberOfItemsPerRow}
      providerTitleColor={providerTitleColor}
      providerTitleColorRange={providerTitleColorRange}
      displayBadges={displayBadges}
      displayLearnMoreButton={displayLearnMoreButton}
      learnMoreButtonText={learnMoreButtonText}
      displayRequestInfoButton={displayRequestInfoButton}
      inquiryId={inquiryId}
      requestInfoButtonText={requestInfoButtonText}
      readMoreButton={readMoreButton}
      requestInfoButtonColorScheme={requestInfoButtonColorScheme}
      learnMoreButtonColorScheme={learnMoreButtonColorScheme}
      ratingStarsColor={ratingStarsColor}
      ratingStarsColorRange={ratingStarsColorRange}
    />
  );
};

export default NearbyCareWrapper;
