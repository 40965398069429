import { Input as ChakraInput, PropsOf } from '@chakra-ui/react';
import InputMask from 'react-input-mask';
import useReactInputMask from '~/hooks/use-react-input-mask';
import { InputFieldConfig } from '../types';
import { FormFieldCommonProps } from './FormField';

type Props = PropsOf<typeof ChakraInput> &
  Pick<FormFieldCommonProps, 'error' | 'register' | 'setValue' | 'trigger'> &
  Pick<InputFieldConfig, 'name' | 'typeMask'>;

const PhoneNumberInput: React.FC<Props> = ({
  error,
  name,
  register,
  setValue,
  trigger,
  typeMask = '(999) 999-9999',
  ...chakraInputProps
}) => {
  const phoneNumberMaskProps = {
    mask: typeMask,
    maskChar: '_',
  };
  const { beforeMaskedValueChange, formatValueOnAutofill } =
    useReactInputMask(phoneNumberMaskProps);

  return (
    <ChakraInput
      {...chakraInputProps}
      as={InputMask}
      type="tel"
      mask={phoneNumberMaskProps.mask}
      maskChar={phoneNumberMaskProps.maskChar}
      beforeMaskedValueChange={beforeMaskedValueChange}
      {...register(name, {
        // Bugfix: on Chrome in iOS, autofill phone numbers are removed when value is not already
        // formatted as the mask. This happens because we're using InputMask here, and this is a
        // know issue of react-input-mask. See
        // https://www.npmjs.com/package/react-input-mask#autofill for more details.
        // And because this does not happen on desktop browsers, this cannot be tested on our specs,
        // so be sure to manually test this on a Chrome in iOS when changing this event code
        onChange: (e) => {
          const { changed, value } = formatValueOnAutofill(e);
          if (changed) {
            setValue(name, value, {
              shouldValidate: true,
              shouldDirty: true,
              shouldTouch: true,
            });
          } else if (Boolean(error)) {
            trigger(name);
          }
        },
      })}
    />
  );
};

export default PhoneNumberInput;
