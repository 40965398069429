import { Box } from '@chakra-ui/layout';
import { useBreakpointValue } from '@chakra-ui/media-query';
// import { Spinner } from '@chakra-ui/spinner';
import InquiryForm from '@components/InquiryForm';
import { InquiryFormProps } from '@components/InquiryForm/InquiryForm';
import { Display } from '@components/InquiryForm/InquiryForm.types';
import Container from '@components/LayoutStructure/Container';
import CaringPhotoGallery from '@components/PhotoGallery/CaringPhotoGallery';
import PhotoGalleryModal from '@components/PhotoGallery/PhotoGalleryModal';
import SeniorHomesPhotoGallery from '@components/PhotoGallery/SeniorHomesPhotoGallery';
import StreetViewModal from '@components/PhotoGallery/StreetViewModal';
import { getColor } from '@utils/getColor';
import { getRandomProviderPic } from '@utils/getRandomProviderPic';
import Image from 'next/image';
import { useContext, useId, useState } from 'react';
import withHydrationOnDemand from 'react-hydration-on-demand';
import { HeadingElements } from '~/@types/heading';
import { Provider } from '~/contexts/Provider';
import SiteContext from '~/contexts/SiteContext';
import { Metadata } from '~/types/Magnolia';
import {
  DeviceVisibility,
  useResponsiveDisplay,
} from '@hooks/useResponsiveDisplay';

interface OpenInquiryForm
  extends Omit<InquiryFormProps, 'metadata' | 'formId' | 'display'> {
  field: 'true' | 'false';
}

interface Props {
  templateId: string;
  provider: Provider;
  deviceVisibility?: DeviceVisibility;
  openInquiryForm?: OpenInquiryForm;
  heading?: string;
  headingElement?: HeadingElements;
  marginBottom?: string;
  metadata: Metadata;
  clickableOverlayColor: string;
  clickableOverlayColorRange: string;
}

interface GalleryProps {
  providerPhotos:
    | {
        url: string;
      }[]
    | {
        url: string;
        name: string;
      }[];
  providerName: string;
  providerCoordinates: { latitude: number; longitude: number };
  domain: string;
  clickableOverlayColor: string;
  desktopSidebar?: React.ReactNode;
  isStreetViewModalOpen: boolean;
  setIsStreetViewModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  galleryIndex: number | null;
  setGalleryIndex: React.Dispatch<React.SetStateAction<null | number>>;
}

const Gallery = ({
  providerPhotos,
  providerName,
  providerCoordinates,
  domain,
  desktopSidebar,
  isStreetViewModalOpen,
  setIsStreetViewModalOpen,
  galleryIndex,
  setGalleryIndex,
}: GalleryProps) => {
  switch (domain) {
    case 'seniorhomes.com':
      return (
        <>
          <SeniorHomesPhotoGallery
            photos={providerPhotos.map((image) => ({
              url: image.url,
              alt: providerName,
            }))}
            setGalleryIndex={setGalleryIndex}
          />
          <PhotoGalleryModal
            photos={providerPhotos.map((image) => ({
              url: image.url,
              alt: providerName,
            }))}
            modalSidebar={desktopSidebar}
            galleryIndex={galleryIndex}
            setGalleryIndex={setGalleryIndex}
          />
        </>
      );
    case 'caring.com':
      return (
        <>
          <CaringPhotoGallery
            photos={providerPhotos.map((image) => ({
              url: image.url,
              alt: providerName,
            }))}
            setIsStreetViewModalOpen={setIsStreetViewModalOpen}
            setGalleryIndex={setGalleryIndex}
          />
          <PhotoGalleryModal
            photos={providerPhotos.map((image) => ({
              url: image.url,
              alt: providerName,
            }))}
            modalSidebar={desktopSidebar}
            galleryIndex={galleryIndex}
            setGalleryIndex={setGalleryIndex}
          />
          <StreetViewModal
            coordinates={providerCoordinates}
            modalSidebar={desktopSidebar}
            isStreetViewModalOpen={isStreetViewModalOpen}
            setIsStreetViewModalOpen={setIsStreetViewModalOpen}
          />
        </>
      );
    default:
      return (
        <Box height="md">
          <Image
            src={providerPhotos[0].url}
            alt={providerName}
            priority
            tabIndex={0}
            fill
            style={{ objectFit: 'cover', objectPosition: 'center' }}
          />
        </Box>
      );
  }
};

const ProviderImagesGalleryComponent = ({
  templateId,
  provider,
  deviceVisibility,
  openInquiryForm = {} as OpenInquiryForm,
  marginBottom,
  metadata,
  clickableOverlayColor = 'primary',
  clickableOverlayColorRange = '600',
}: Props): React.ReactElement => {
  const siteProps = useContext(SiteContext);
  const domain = siteProps.site?.path ?? '';
  const [isStreetViewModalOpen, setIsStreetViewModalOpen] = useState(false);
  const [galleryIndex, setGalleryIndex] = useState<number | null>(null);
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const color = getColor(clickableOverlayColor, clickableOverlayColorRange);

  const seed = useId();
  const isHidden = useResponsiveDisplay(deviceVisibility);
  if (isHidden) {
    return <></>;
  }

  if (!provider) {
    return <></>;
  }

  const fallbackImage = getRandomProviderPic(
    `${provider.name}${provider.slug}` ?? seed
  );
  const providerPhotos = provider?.hasImages
    ? provider.photos
    : [{ url: fallbackImage, name: provider.name }];

  return (
    <Container
      position="relative"
      ignoreMaxWidth={isMobile}
      mb={marginBottom ? `${marginBottom} !important` : undefined}
    >
      <Gallery
        providerPhotos={providerPhotos ?? []}
        providerName={provider.name}
        providerCoordinates={{
          latitude: provider?.address?.latitude ?? 0,
          longitude: provider?.address?.longitude ?? 0,
        }}
        domain={domain}
        desktopSidebar={
          openInquiryForm.field === 'true' && (
            <InquiryForm
              formId={metadata['@id']}
              display={Display.VERTICAL}
              height="100vh"
              metadata={metadata}
              {...openInquiryForm}
            />
          )
        }
        clickableOverlayColor={color}
        isStreetViewModalOpen={isStreetViewModalOpen}
        setIsStreetViewModalOpen={setIsStreetViewModalOpen}
        galleryIndex={galleryIndex}
        setGalleryIndex={setGalleryIndex}
      />
    </Container>
  );
};

const ProviderImagesGallery: React.FC<Props> = withHydrationOnDemand({
  on: ['idle', 'visible'],
})(ProviderImagesGalleryComponent);

export default ProviderImagesGallery;
