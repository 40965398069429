import {
  stepContent,
  stepSubmission,
} from '@components/Analytics/events/eventContracts';
import useFormStepSubmission from '@components/Analytics/events/FormStepSubmission';
import useFormSubmission from '@components/Analytics/events/FormSubmission';
import useInquirySubmission from '@components/Analytics/events/InquirySubmission';
import { CTAAction, Display } from '@components/InquiryForm/InquiryForm.types';
import { MultiStepFormProps } from '@components/MultiStepForm/MultiStepForm';
import { RollUpType } from '@components/Navigation';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { FieldValues, UseFormGetValues } from 'react-hook-form';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';

type TrackFormSuccessfullySubmitted = (data: Record<string, string>) => void;
type TrackStepSubmitted = (
  stepIndex: number,
  getValues: UseFormGetValues<FieldValues>
) => void;

const useMultiStepFormEventTracking = ({
  metadata,
  formType = { field: 'other' },
  stepsConfig,
}: {
  metadata: MultiStepFormProps['metadata'];
  formType: MultiStepFormProps['formType'];
  stepsConfig: MultiStepFormProps['data']['steps'];
}): {
  formInstanceId: string;
  trackFormSuccessfullySubmitted: TrackFormSuccessfullySubmitted;
  trackStepSubmitted: TrackStepSubmitted;
} => {
  const inquirySubmission = useInquirySubmission();
  const formStepSubmission = useFormStepSubmission();
  const formSubmission = useFormSubmission();

  const stepsSubmittedState = useState<stepSubmission[]>([]);

  const formInstanceId = useMemo(() => uuidv4(), []);
  const trackingInfo = useMemo(() => {
    const {
      display = Display.VERTICAL,
      type = CTAAction.REQUEST_INFO,
      rollUp = RollUpType.SENIOR_LIVING,
    } = formType.field === 'other' ? {} : formType;
    const formName = `multi-step-${rollUp}-${display}-${type}`
      .toLowerCase()
      .replace(' ', '-');
    const formNamespace = metadata['@id'];
    const formTemplateId = uuidv5(formName, formNamespace);

    return {
      formName,
      formNamespace,
      formTemplateId,
      formTypeField: formType.field,
      display,
      type,
    };
  }, [metadata, formType]);

  const trackStepSubmitted: TrackStepSubmitted = (stepIndex, getValues) =>
    onStepSubmitted(stepIndex, getValues, stepsSubmittedState);

  const onStepSubmitted = useCallback(
    (
      stepIndex: number,
      getValues: UseFormGetValues<FieldValues>,
      stepsSubmittedState: [
        stepSubmission[],
        Dispatch<SetStateAction<stepSubmission[]>>
      ]
    ) => {
      const [stepsSubmitted, setStepsSubmitted] = stepsSubmittedState;
      const { formNamespace, formTemplateId, formTypeField } = trackingInfo;
      const stepId = uuidv5(String(stepIndex), formNamespace);
      const stepSubmission = {
        step_id: stepId,
        step_instance_id: uuidv4(),
        step_index: stepIndex,
      };

      const form = stepsConfig[stepIndex].form;
      const stepContent: stepContent[] = form.fields.map(
        (field, fieldIndex) => ({
          prompt_id: uuidv5(field.name, formNamespace),
          prompt_type: field.type,
          prompt_instance_id: uuidv4(),
          prompt_index: fieldIndex + 1,
          prompt_value: field.label || form.headline || '',
          response_array: [
            {
              response_value: getValues(field.name),
              response_id: uuidv4(),
            },
          ],
        })
      );

      formStepSubmission({
        form_template_id: formTemplateId,
        form_instance_id: formInstanceId,
        form_type: formTypeField,
        step_id: stepSubmission.step_id,
        step_instance_id: stepSubmission.step_instance_id,
        step_index: stepSubmission.step_index,
        step_content: stepContent,
      });

      const allStepsSubmitted = [...stepsSubmitted, stepSubmission];
      if (stepIndex === stepsConfig.length - 1) {
        formSubmission({
          form_type: formTypeField,
          form_template_id: formTemplateId,
          form_instance_id: formInstanceId,
          step_submissions: allStepsSubmitted,
        });
      }
      setStepsSubmitted(allStepsSubmitted);
    },
    [
      trackingInfo,
      formInstanceId,
      formStepSubmission,
      formSubmission,
      stepsConfig,
    ]
  );

  const trackFormSuccessfullySubmitted: TrackFormSuccessfullySubmitted =
    useCallback(
      (data: Record<string, string>) => {
        const { formName, display, type } = trackingInfo;
        inquirySubmission({
          ...data,
          name: formName,
          display,
          type,
        });
      },
      [trackingInfo, inquirySubmission]
    );

  return formType.field === 'other'
    ? {
        formInstanceId,
        trackStepSubmitted: () => {},
        trackFormSuccessfullySubmitted: () => {},
      }
    : {
        formInstanceId,
        trackStepSubmitted,
        trackFormSuccessfullySubmitted,
      };
};

export default useMultiStepFormEventTracking;
