import { Button, PropsOf } from '@chakra-ui/react';
import { getCtaButtonProps } from '../MultiStepForm.utils';
import { MagnoliaFormConfig } from '../types';

type SubmitButtonProps = PropsOf<typeof Button> & {
  cta: MagnoliaFormConfig['cta'];
};

const SubmitButton: React.FC<SubmitButtonProps> = ({ cta, ...buttonProps }) => {
  const { label, formatting } = cta;
  return (
    <Button
      type="submit"
      px={10}
      {...buttonProps}
      {...getCtaButtonProps(formatting)}
    >
      {label}
    </Button>
  );
};

export default SubmitButton;
