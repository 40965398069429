import { Icon, IconProps } from '@chakra-ui/icon';
import { Box, BoxProps, HStack } from '@chakra-ui/layout';
import { MdStar, MdStarHalf, MdStarOutline } from 'react-icons/md';
import { formatRating } from '~/utils/number';

interface Props {
  rating: string;
  size?: IconProps['boxSize'];
  color?: IconProps['color'];
}
const StarContainer = ({ ...props }: BoxProps) => {
  return <Box as="span" role="figure" {...props} />;
};

const ReviewStars: React.FC<Props> = ({
  rating,
  size = '6',
  color = 'red.400',
}) => {
  const formattedRating = formatRating(rating);
  const roundedRating = Number(formattedRating);
  const fullStars = Math.floor(roundedRating);
  const ratingFraction = roundedRating - fullStars;
  const isRatingFractionHalf = ratingFraction >= 0.4;
  const halfStar = isRatingFractionHalf ? 1 : 0;
  const emptyStars = 5 - fullStars - halfStar;

  return (
    <HStack
      spacing="0"
      role="img"
      aria-label={`${formattedRating} star rating`}
    >
      {[...Array(fullStars)].map((_, i) => (
        <StarContainer aria-label="full star" key={i} boxSize={size}>
          <Icon as={MdStar} boxSize={size} color={color} role="presentation" />
        </StarContainer>
      ))}
      {halfStar > 0 && (
        <StarContainer aria-label="half star" boxSize={size}>
          <Icon
            as={MdStarHalf}
            boxSize={size}
            color={color}
            role="presentation"
          />
        </StarContainer>
      )}
      {[...Array(emptyStars)].map((_, i) => (
        <StarContainer key={i} aria-label="empty star" boxSize={size}>
          <Icon
            as={MdStarOutline}
            boxSize={size}
            color={color}
            role="presentation"
          />
        </StarContainer>
      ))}
    </HStack>
  );
};

export default ReviewStars;
