import { Box, LinkBox } from '@chakra-ui/layout';
import Button from '@components/Button';
import ActionsContainer from '@components/ProviderCard/ActionsContainer';
import Card from '@components/ProviderCard/Card';
import ImageContainer from '@components/ProviderCard/ImageContainer';
import InfoContainer from '@components/ProviderCard/InfoContainer';
import PromotionBanner from '@components/ProviderCard/PromotionBanner';
import PromotionButton from '@components/ProviderCard/PromotionButton';
import Rating from '@components/ProviderCard/Rating';
import Summary from '@components/ProviderCard/Summary';
import { getActivePromotion } from '@utils/getActivePromotion';
import { getColor } from '@utils/getColor';
import { formatPhoneNumber } from '@utils/strings';
import { formatDistanceStrict } from 'date-fns';
import dynamic from 'next/dynamic';
import { useContext, useState } from 'react';
import CookieStorage from 'utils/cookieStorage';
import { useModal } from '~/contexts/ModalContext';
import SiteContext from '~/contexts/SiteContext';
import { ResultTileHelpersContainer } from './SearchResultStyles';
import { SearchResultFullWidthProps } from './SearchResultType';
import {
  ElementActions,
  ElementNames,
  ElementTypes,
} from '@components/Analytics/events/ElementClicked';
const Description = dynamic(
  () => import('@components/ProviderCard/Description')
);
const ImageSlider = dynamic(
  () => import('@components/ProviderCard/ImageSlider')
);
const CaringStars = dynamic(
  () => import('@components/CaringStars/CaringStars')
);
const FeaturedAmenities = dynamic(
  () => import('@components/ProviderCard/FeaturedAmenities')
);
import { getFeaturedAmenities } from '@utils/amenities';
import { Checkbox, Divider, Stack, VStack } from '@chakra-ui/react';

const formatDaysLeft = (endDate: string) => {
  try {
    const daysLeft = formatDistanceStrict(new Date(), new Date(endDate), {
      unit: 'day',
      roundingMethod: 'ceil',
    });
    return `${daysLeft} left`;
  } catch (error) {
    return '';
  }
};

const SearchResultFullWidth: React.FC<SearchResultFullWidthProps> = ({
  id,
  legacyId,
  images,
  title,
  address,
  reviewCount,
  displayBadges,
  averageRating,
  description: descriptionFromProps,
  caringStars = [],
  path,
  displayLearnMoreButton = false,
  learnMoreButtonText = 'Learn More',
  displayRequestInfoButton = false,
  modalId = '',
  requestInfoButtonText = 'Request Info',
  readMoreButton = 'redirect_to_provider_page',
  isHidden = false,
  requestInfoButtonColorScheme = 'accent',
  learnMoreButtonColorScheme = 'accent',
  ratingStarsColor = 'accent',
  ratingStarsColorRange = '500',
  providerTitleColor = 'primary',
  providerTitleColorRange = '600',
  boxShadow = 'lg',
  border,
  borderColor,
  borderColorRange,
  displayProviderPhoneNumber,
  providerPhoneNumberSource,
  phoneNumber,
  promotions = [],
  legacyResourceId,
  lastReviewSnippet,
  queryId = '',
  listId = '',
  amenities,
  isChecked = false,
  displayCompareOption,
  handleCompare,
  dontOpenInNewTab,
  promotionColorScheme,
}) => {
  const [showPromotionBanner, setShowPromotionBanner] = useState(false);
  const promotionBannerTitle = 'PROMOTION!';
  const activePromotion = getActivePromotion(promotions);
  const daysLeft = activePromotion?.endsAt
    ? formatDaysLeft(activePromotion.endsAt)
    : '';
  const { show: showInquiryForm } = useModal(modalId);
  const siteProps = useContext(SiteContext);
  const domain = siteProps.site?.path ?? '';
  const StyledTileHelpersContainer = ResultTileHelpersContainer(domain);
  const howManyStars = Math.trunc(averageRating);
  const hasBadges = caringStars.length > 0 && displayBadges;
  const shouldRenderHelpers =
    displayRequestInfoButton || displayLearnMoreButton || hasBadges;
  const shouldRenderRating = howManyStars > 0;
  const colSpanMiddleColumn = shouldRenderHelpers ? 5 : 8;
  const getColSpanByDomain = () => {
    if (domain === 'caring.com') return { base: 12, md: 2 };
    return { base: 12, md: 2 };
  };

  const openInquiryForm = () => {
    const selectedProvider = {
      slug: path,
      id,
      services: [
        {
          legacyResourceId,
        },
      ],
    };

    if (CookieStorage.enabled) {
      CookieStorage.set('provider', JSON.stringify(selectedProvider));
    }

    showInquiryForm();
  };

  const getProviderPhoneNumber = () => {
    if (providerPhoneNumberSource?.field === 'providersDatabase' && phoneNumber)
      return formatPhoneNumber(1 + phoneNumber);
    if (
      providerPhoneNumberSource?.field === 'globalCatalog' &&
      providerPhoneNumberSource.providerPhoneNumber
    )
      return formatPhoneNumber(providerPhoneNumberSource.providerPhoneNumber);
  };

  return (
    <LinkBox
      className="provider-card"
      as="article"
      zIndex={1}
      display={isHidden ? 'none' : ''}
    >
      <Card
        domain={domain}
        footer={
          <>
            {displayCompareOption && (
              <VStack w="full">
                <Divider borderColor="gray.300" w="full" />
                <Stack
                  spacing={5}
                  direction="row"
                  justifyContent="center"
                  py={4}
                  mt={0}
                >
                  <Checkbox
                    mt={0}
                    isChecked={isChecked}
                    onChange={() => handleCompare(id)}
                  >
                    Compare
                  </Checkbox>
                </Stack>
              </VStack>
            )}
          </>
        }
      >
        <ImageContainer domain={domain}>
          {/* Promotion Banner */}
          {activePromotion && showPromotionBanner && (
            <PromotionBanner
              title={promotionBannerTitle}
              subheader={daysLeft}
              content={activePromotion.externalPromotionText!}
              link={path}
              onClose={() => setShowPromotionBanner(false)}
              openInNewTab={!dontOpenInNewTab}
              colorScheme={promotionColorScheme ?? 'primary'}
            />
          )}

          {/* Promotion Button */}
          {activePromotion && !showPromotionBanner && (
            <PromotionButton
              label={promotionBannerTitle}
              subLabel={daysLeft}
              position="absolute"
              top="12px"
              left="12px"
              zIndex={2}
              onClick={() => setShowPromotionBanner(true)}
              colorScheme={promotionColorScheme ?? 'primary'}
            />
          )}
          <ImageSlider
            domain={domain}
            path={path}
            title={title}
            images={images}
            query={{ locationId: legacyId, queryId: queryId, listId: listId }}
            zIndex={1}
            openInNewTab={!dontOpenInNewTab}
          />
        </ImageContainer>

        <InfoContainer
          domain={domain}
          colSpan={{ base: 12, md: colSpanMiddleColumn }}
        >
          <Summary
            domain={domain}
            path={path}
            address={address}
            title={title}
            titleColor={getColor(providerTitleColor, providerTitleColorRange)}
            phoneNumber={
              displayProviderPhoneNumber ? getProviderPhoneNumber() : undefined
            }
            caringStars={hasBadges ? caringStars : undefined}
            query={{ locationId: legacyId, queryId: queryId, listId: listId }}
            dontOpenInNewTab={dontOpenInNewTab}
          />

          {shouldRenderRating && (
            <Rating
              domain={domain}
              starColor={getColor(ratingStarsColor, ratingStarsColorRange)}
              rating={averageRating}
              reviewCount={reviewCount}
            />
          )}

          <Description
            description={descriptionFromProps}
            lastReviewSnippet={lastReviewSnippet}
            readMoreButton={readMoreButton}
            domain={domain}
            path={path}
            openInNewTab={!dontOpenInNewTab}
          />
          <FeaturedAmenities
            amenities={getFeaturedAmenities(amenities || {})}
            hasJumpLink={false}
          />
        </InfoContainer>

        {shouldRenderHelpers && (
          <ActionsContainer domain={domain} colSpan={getColSpanByDomain()}>
            <StyledTileHelpersContainer>
              <Box className="ButtonsContainer">
                {displayRequestInfoButton && (
                  <Button
                    colorScheme={requestInfoButtonColorScheme}
                    size={{ base: 'sm', xl: 'md' }}
                    onClick={openInquiryForm}
                    elementType={ElementTypes.BUTTON}
                    elementAction={ElementActions.OPEN_MODAL}
                    elementName={ElementNames.INFO_REQUEST_SECTION}
                    destinationUrl={path}
                    query={{
                      locationId: legacyId,
                      queryId: queryId,
                      listId: listId,
                    }}
                  >
                    {requestInfoButtonText}
                  </Button>
                )}
                {displayLearnMoreButton && (
                  <Button
                    as="a"
                    href={path}
                    colorScheme={learnMoreButtonColorScheme}
                    variant="outline"
                    size={{ base: 'sm', xl: 'md' }}
                    elementAction={ElementActions.VIEW_PROVIDER}
                    elementName={ElementNames.PROVIDER_CARD}
                    elementType={ElementTypes.BUTTON}
                    destinationUrl={path}
                    query={{
                      locationId: legacyId,
                      queryId: queryId,
                      listId: listId,
                    }}
                    target={dontOpenInNewTab ? '_self' : '_blank'}
                    rel="noopener noreferrer"
                  >
                    {learnMoreButtonText}
                  </Button>
                )}
              </Box>

              <Box display={{ base: 'none', md: 'block' }}>
                {hasBadges && <CaringStars caringStars={caringStars} />}
              </Box>
            </StyledTileHelpersContainer>
          </ActionsContainer>
        )}
      </Card>
    </LinkBox>
  );
};

export default SearchResultFullWidth;
