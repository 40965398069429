import { Grid, GridItem, Heading, Link, Text } from '@chakra-ui/layout';
import { EditableArea } from '@magnolia/react-editor';
import { NextRouter, useRouter } from 'next/router';
import { calculateProviderMinPrice } from '~/utils/providers';
import { SearchStates } from './Search';
import SearchResult from './SearchResult';
import SearchResultFullWidth from './SearchResultFullWidth';
import { renderResultProps } from './SearchResultType';
import { Amenities, LegacyAmenities, Provider } from '~/contexts/Provider';
import {
  ComparisonErrorDialog,
  CommunityComparisonBanner,
  Community,
} from '@components/CommunityComparison/GeosCommunityComparison';
import { useEffect, useState } from 'react';

export const getDefaultKeywords = ({
  state,
  city,
  county,
  queryKeyword = '',
}) => {
  let keyword = '';

  const shouldRenderSearchBar = !!(state || city || county);

  if (!shouldRenderSearchBar && !queryKeyword) {
    return '';
  }

  city && !county && (keyword += `${city} `);
  state && (keyword += `${state} `);
  county && (keyword += `${county} `);

  if (queryKeyword) keyword += queryKeyword;

  return keyword;
};

const getContent = (
  fullWidthTile,
  result: Provider,
  index,
  showPrice = true,
  displayBadges = false,
  getProviderDetailsPath,
  getProviderDescription,
  displayLearnMoreButton,
  learnMoreButtonText,
  displayRequestInfoButton,
  requestInfoButtonText,
  readMoreButton,
  inquiryId,
  showMoreLimit,
  requestInfoButtonColorScheme,
  learnMoreButtonColorScheme,
  ratingStarsColor,
  ratingStarsColorRange,
  providerTitleColor,
  providerTitleColorRange,
  boxShadow,
  tileBorder,
  tileBorderColor,
  tileBorderColorRange,
  displayProviderPhoneNumber,
  providerPhoneNumberSource,
  dontOpenInNewTab,
  careType?: string,
  queryId?: string,
  listId?: string,
  isChecked?: boolean,
  displayCompareOption?: boolean,
  onSelectProvider?: (community: Community) => void,
  promotionColorScheme?: string
) => {
  const {
    legacyId,
    address,
    name,
    awards,
    images,
    averageRating,
    reviewCount,
    id,
    promotions,
    lastReviewSnippet,
    amenities,
  } = result;

  const minPrice = calculateProviderMinPrice({ result, careType });
  const path = getProviderDetailsPath(result);
  const isHidden = showMoreLimit === 0 ? false : index > showMoreLimit;
  if (fullWidthTile) {
    return (
      <SearchResultFullWidth
        key={index}
        id={id}
        legacyId={legacyId ?? id}
        title={name}
        address={address?.formattedAddress ?? ''}
        images={images ?? []}
        averageRating={averageRating}
        reviewCount={reviewCount}
        description={getProviderDescription(result)}
        price={minPrice}
        path={path}
        isChecked={isChecked}
        handleCompare={() => {
          if (onSelectProvider) {
            onSelectProvider({ index, ...result });
          }
        }}
        // TODO: make sure that the API always returns an array
        caringStars={awards || []}
        displayBadges={displayBadges}
        displayLearnMoreButton={displayLearnMoreButton}
        learnMoreButtonText={learnMoreButtonText}
        displayRequestInfoButton={displayRequestInfoButton}
        requestInfoButtonText={requestInfoButtonText}
        readMoreButton={readMoreButton}
        modalId={inquiryId}
        isHidden={isHidden}
        requestInfoButtonColorScheme={requestInfoButtonColorScheme}
        learnMoreButtonColorScheme={learnMoreButtonColorScheme}
        ratingStarsColor={ratingStarsColor}
        ratingStarsColorRange={ratingStarsColorRange}
        providerTitleColor={providerTitleColor}
        providerTitleColorRange={providerTitleColorRange}
        boxShadow={boxShadow}
        border={tileBorder}
        borderColor={tileBorderColor}
        borderColorRange={tileBorderColorRange}
        displayProviderPhoneNumber={displayProviderPhoneNumber}
        providerPhoneNumberSource={providerPhoneNumberSource}
        phoneNumber={result.phoneNumber}
        promotions={promotions}
        legacyResourceId={result.services?.[0]?.legacyResourceId}
        lastReviewSnippet={lastReviewSnippet}
        queryId={queryId}
        listId={listId}
        displayCompareOption={displayCompareOption}
        amenities={amenities as Amenities | LegacyAmenities}
        dontOpenInNewTab={dontOpenInNewTab}
        promotionColorScheme={promotionColorScheme}
      />
    );
  }
  return (
    <GridItem colSpan={3} key={index} display={isHidden ? 'none' : ''}>
      <SearchResult
        legacyId={legacyId ?? id}
        title={name}
        address={address?.formattedAddress ?? ''}
        images={images ?? []}
        averageRating={averageRating}
        reviewCount={reviewCount}
        price={minPrice}
        showPrice={showPrice}
        path={path}
        ratingStarsColor={ratingStarsColor}
        ratingStarsColorRange={ratingStarsColorRange}
        providerTitleColor={providerTitleColor}
        providerTitleColorRange={providerTitleColorRange}
        boxShadow={boxShadow}
        border={tileBorder}
        borderColor={tileBorderColor}
        borderColorRange={tileBorderColorRange}
        queryId={queryId}
        listId={listId}
        displayCompareOption={displayCompareOption}
        isChecked={isChecked}
        handleCompare={() => {
          if (onSelectProvider) {
            onSelectProvider({ index, ...result });
          }
        }}
        dontOpenInNewTab={dontOpenInNewTab}
      />
    </GridItem>
  );
};

export const RenderResults = ({
  results,
  searchState,
  itemsPerRow,
  providerTitleColor,
  providerTitleColorRange,
  requestInfoButtonColorScheme,
  learnMoreButtonColorScheme,
  ratingStarsColor,
  ratingStarsColorRange,
  boxShadow,
  tileBorder,
  tileBorderColor,
  tileBorderColorRange,
  fullWidthTile,
  displayBadges,
  showPrice,
  genericBlock1,
  genericBlock2,
  metadata,
  currentPage,
  getProviderDetailsPath,
  getProviderDescription,
  showMoreLimit,
  displayLearnMoreButton,
  learnMoreButtonText,
  displayRequestInfoButton,
  requestInfoButtonText,
  readMoreButton,
  inquiryId,
  displayProviderPhoneNumber,
  providerPhoneNumberSource,
  careType,
  filterAndSort,
  queryId,
  listId,
  displayCompareOption = false,
  dontOpenInNewTab,
  promotionColorScheme,
}: renderResultProps) => {
  const router = useRouter();
  const selectedCommunities = router.query?.['selectedCommunities'];
  const [isCompareModalOpen, setIsCompareModalOpen] = useState<Boolean>(
    router.query?.['isModalOpen'] === 'true'
  );

  const [providersList, setProvidersList] = useState<Community[]>([]);
  const [displayComparisonErrorDialog, setDisplayComparisonErrorDialog] =
    useState<boolean>(false);

  const genericBlock =
    currentPage !== undefined && currentPage % 2 === 0
      ? genericBlock1
      : genericBlock2;

  const gridSetup = fullWidthTile
    ? '1fr'
    : {
        base: 'repeat(3, 1fr)',
        sm: 'repeat(6, 1fr)',
        md: `repeat(${itemsPerRow}, 1fr)`,
      };
  const genericBlockFrequency = 6;

  useEffect(() => {
    if (selectedCommunities) {
      const selectedItems = (selectedCommunities as string).split(',');
      const filteredProviders = results
        .map((provider, index) => {
          if (selectedItems.includes(String(provider.id))) {
            return {
              ...provider,
              index,
            } as Community;
          }
          return null;
        })
        .filter((provider): provider is Community => provider !== null);

      setProvidersList(filteredProviders);
    }
  }, [selectedCommunities, results]);

  useEffect(() => {
    if (router?.query?.hasOwnProperty('isModalOpen')) {
      setIsCompareModalOpen(router.query?.isModalOpen === 'true');
    }
  }, [router.query]);

  const handleCompareProvider = (community: Community) => {
    const isProviderPresent = isProviderInList(community, providersList);

    if (providersList.length > 3 && !isProviderPresent) {
      return setDisplayComparisonErrorDialog(true);
    }

    if (!isProviderPresent) {
      setProvidersList([...providersList, community]);
    } else {
      const filteredProviders = providersList.filter(
        (existingProvider) => existingProvider.id !== community.id
      );
      setProvidersList(filteredProviders);
    }
  };

  switch (searchState) {
    case SearchStates.INPUT_WITH_RESULTS:
      const resultsToRenderFirst = results.slice(0, genericBlockFrequency);
      const resultsToRenderAfter = results.slice(
        genericBlockFrequency,
        results.length
      );
      return (
        <>
          <Grid templateColumns={gridSetup} gap={6}>
            {resultsToRenderFirst.map((result, index) => {
              return getContent(
                fullWidthTile,
                result,
                index,
                showPrice,
                displayBadges,
                getProviderDetailsPath,
                getProviderDescription,
                displayLearnMoreButton,
                learnMoreButtonText,
                displayRequestInfoButton,
                requestInfoButtonText,
                readMoreButton,
                inquiryId,
                showMoreLimit,
                requestInfoButtonColorScheme,
                learnMoreButtonColorScheme,
                ratingStarsColor,
                ratingStarsColorRange,
                providerTitleColor,
                providerTitleColorRange,
                boxShadow,
                tileBorder,
                tileBorderColor,
                tileBorderColorRange,
                displayProviderPhoneNumber,
                providerPhoneNumberSource,
                dontOpenInNewTab,
                careType,
                queryId,
                listId,
                isProviderInList(result, providersList),
                displayCompareOption,
                handleCompareProvider,
                promotionColorScheme
              );
            })}
          </Grid>
          {genericBlock && (
            <EditableArea
              content={genericBlock}
              parentTemplateId={metadata?.['mgnl:template']}
            />
          )}
          <Grid templateColumns={gridSetup} gap={6}>
            {resultsToRenderAfter.map((result, index) => {
              const showMoreLimitAfter =
                showMoreLimit && showMoreLimit > 0
                  ? showMoreLimit - genericBlockFrequency
                  : 0;
              return getContent(
                fullWidthTile,
                result,
                index,
                showPrice,
                displayBadges,
                getProviderDetailsPath,
                getProviderDescription,
                displayLearnMoreButton,
                learnMoreButtonText,
                displayRequestInfoButton,
                requestInfoButtonText,
                readMoreButton,
                inquiryId,
                showMoreLimitAfter,
                requestInfoButtonColorScheme,
                learnMoreButtonColorScheme,
                ratingStarsColor,
                ratingStarsColorRange,
                providerTitleColor,
                providerTitleColorRange,
                boxShadow,
                tileBorder,
                tileBorderColor,
                tileBorderColorRange,
                displayProviderPhoneNumber,
                providerPhoneNumberSource,
                dontOpenInNewTab,
                careType,
                queryId,
                listId,
                isProviderInList(result, providersList),
                displayCompareOption,
                handleCompareProvider
              );
            })}
          </Grid>
          {providersList.length > 0 && (
            <CommunityComparisonBanner
              listId={listId}
              router={router}
              queryId={queryId}
              modalId={inquiryId}
              providers={providersList}
              displayBadges={displayBadges}
              onCancel={handleCompareProvider}
              displayViewCommunityButton={true}
              ratingStarsColor={ratingStarsColor}
              providerTitleColor={providerTitleColor}
              viewCommunityButtonColorScheme="secondary"
              ratingStarsColorRange={ratingStarsColorRange}
              openCompareModal={
                Boolean(selectedCommunities) && Boolean(isCompareModalOpen)
              }
              providerTitleColorRange={providerTitleColorRange}
              displayRequestInfoButton={displayRequestInfoButton}
            />
          )}
          {displayComparisonErrorDialog && (
            <ComparisonErrorDialog
              isOpen={displayComparisonErrorDialog}
              onClose={() => setDisplayComparisonErrorDialog(false)}
            />
          )}
        </>
      );
    case SearchStates.NO_SEARCH_INPUT:
      return (
        <GridItem
          display="flex"
          flexDirection={'column'}
          justifyContent="center"
          textAlign="center"
          height={500}
          colSpan={12}
        >
          <Heading size="lg">No Results</Heading>
          <Heading size="md">
            Please change your filters to produce results
          </Heading>
        </GridItem>
      );
    case SearchStates.INPUT_WITH_NO_RESULTS:
      return (
        <Text fontSize="lg" textAlign="center" fontWeight="normal">
          There aren&apos;t any providers that match your preferences. If
          you&apos;ve selected multiple filters, try expanding your search with
          fewer filters.
          <br />
          <br />
          {filterAndSort?.phoneNumber ? (
            <>
              If you&apos;re still unable to find a provider that meets your
              needs, our expert{' '}
              <Text as="span" fontWeight="bold">
                Family Advisor team can help.
              </Text>{' '}
              <Text as="span" fontWeight="bold" color="accent.500">
                Call us at{' '}
                <Link href={`tel:${filterAndSort.phoneNumber.number}`}>
                  {filterAndSort.phoneNumber.label}
                </Link>
                .
              </Text>
            </>
          ) : null}
        </Text>
      );
    default:
      return <>No results</>;
  }
};

export const updateQueryParamPage = (
  newPage: number,
  queryParamKey: string,
  router: NextRouter
) => {
  const { pathname, push, query } = router;
  const { [queryParamKey]: _, ...prevQuery } = query;
  const nextQuery = { ...prevQuery };
  const isFirstPage = newPage === 0;

  if (!isFirstPage) {
    nextQuery[queryParamKey] = (newPage + 1) as unknown as string;
  }

  push({ pathname, query: nextQuery }, undefined, {
    scroll: false,
    shallow: true,
  });
};

export const isProviderInList = (provider, providersList) => {
  return providersList.some(
    (existingProvider) => existingProvider.id === provider.id
  );
};

export const updateQueryParams = (
  updates: { [key: string]: string },
  router: NextRouter
) => {
  const { pathname, query } = router;
  const updatedQuery = { ...query, ...updates };

  // Handle empty values
  for (const key in updatedQuery) {
    if (!updatedQuery[key]) {
      delete updatedQuery[key];
    }
  }
  router.push({ pathname, query: updatedQuery });
};
