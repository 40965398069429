import SectionTitle from '@components/Sections/SectionTitle';
import { HeadingElements } from '~/@types/heading';
import withHydrationOnDemand from 'react-hydration-on-demand';

export const MIN_TEXT_LENGTH = 250;

interface Props {
  temaplateId?: string;
  headingElement: HeadingElements | 'title';
  title: string;
  titleColor: string;
  titleColorRange: string;
  alertStyle?: 'default' | 'info' | 'warning' | 'success' | 'error';
  alertText?: string;
  text: string;
  textColor: string;
  textColorRange: string;
  clientSideRendering?: boolean;
  textAlignment?: 'left' | 'center' | 'right';
  titleAlignment?: 'left' | 'center' | 'right';
  hideIfTextIsEmpty?: boolean;
  field: boolean;
  minLength?: number;
  defaultState?: 'expanded' | 'collapsed';
}

const SectionTitleWrapper = ({
  temaplateId,
  title,
  headingElement,
  titleColor,
  titleColorRange,
  alertStyle,
  alertText,
  text,
  textColor,
  textColorRange,
  clientSideRendering = false,
  textAlignment = 'left',
  titleAlignment = 'left',
  hideIfTextIsEmpty = false,
  field = false,
  minLength = MIN_TEXT_LENGTH,
  defaultState = 'expanded',
}: Props): JSX.Element => {
  const metadata = {
    '@id': temaplateId,
  };

  const collapsible = {
    field: field,
    minLength: minLength,
    defaultState: defaultState,
  };

  return (
    <SectionTitle
      title={title}
      headingElement={headingElement}
      titleColor={titleColor}
      titleColorRange={titleColorRange}
      alertStyle={alertStyle}
      alertText={alertText}
      text={text}
      textColor={textColor}
      textColorRange={textColorRange}
      clientSideRendering={clientSideRendering}
      textAlignment={textAlignment}
      titleAlignment={titleAlignment}
      hideIfTextIsEmpty={hideIfTextIsEmpty}
      collapsible={collapsible}
    />
  );
};

export default withHydrationOnDemand({
  on: ['idle', 'visible'],
  initialVisible: true,
})(SectionTitleWrapper);
