import { Flex, VStack } from '@chakra-ui/layout';
import { Box, Button, Checkbox, Grid, GridItem } from '@chakra-ui/react';
import { Review } from '@services/modular-monolith/types/location.type';
import React, { useEffect, useState } from 'react';
import { MdStar } from 'react-icons/md';
import useElementClicked, {
  ElementActions,
  ElementNames,
  ElementTypes,
} from '@components/Analytics/events/ElementClicked';

const relationshipMapping = {
  'I am/was a resident of this facility': 'Senior residents',
  'I am a friend or relative of a current/past resident':
    'Family/friends of residents',
  'I am a friend or relative of a resident': 'Family/friends of residents',
  'I visited this facility': 'Visitors/tour takers',
  'I am a current client of this provider': 'Current clients',
  'I am a past client of this provider': 'Past clients',
  'I used this service': 'Past clients',
  'My loved one used this service': 'Past clients',
  'I interviewed this provider': 'Prospective clients',
  Other: 'Other',
};

function groupKeysByValue(object: Record<string, string>) {
  const groupedKeys: Record<string, string[]> = {};
  for (let [key, value] of Object.entries(object)) {
    if (!groupedKeys[value]) {
      groupedKeys[value] = [];
    }
    groupedKeys[value].push(key);
  }

  return groupedKeys;
}

interface ProviderReviewsFiltersProps {
  isOpen: boolean;
  reviews: Review[];
  setFilters: ({
    selectedStars,
    selectedCareTypes,
    selectedRelationships,
  }: ReviewFilters) => void;
  resetAll: () => void;
}

export interface ReviewFilters {
  selectedStars: number[];
  selectedCareTypes: string[];
  selectedRelationships: string[];
}

export const applyFilters = (
  reviews: Review[],
  selectedStars: ReviewFilters['selectedStars'],
  selectedCareTypes: ReviewFilters['selectedCareTypes'],
  selectedRelationships: ReviewFilters['selectedRelationships']
): Review[] => {
  return reviews.filter(
    (review) =>
      (selectedStars.length === 0 || selectedStars.includes(review.rating)) &&
      (selectedCareTypes.length === 0 ||
        selectedCareTypes.includes(review.serviceCategoryName)) &&
      (selectedRelationships.length === 0 ||
        selectedRelationships.includes(review.title))
  );
};

const ProviderReviewsFilters: React.FC<ProviderReviewsFiltersProps> = ({
  isOpen,
  reviews,
  setFilters,
  resetAll,
}) => {
  const elementClicked = useElementClicked();
  const [selectedStars, setSelectedStars] = useState<number[]>([]);
  const [selectedCareTypes, setSelectedCareTypes] = useState<string[]>([]);
  const [selectedRelationships, setSelectedRelationships] = useState<string[]>(
    []
  );

  const allReviewRatings = reviews.map((review) => review.rating);
  const reviewRatings = Array.from(new Set(allReviewRatings));

  const allReviewCareTypes = reviews.map(
    (review) => review.serviceCategoryName
  );
  const reviewCareTypes = Array.from(new Set(allReviewCareTypes));

  const allReviewRelationships = reviews.map((review) => review.title);
  const groupedRelationships = groupKeysByValue(relationshipMapping);

  useEffect(() => {
    setFilters({ selectedStars, selectedCareTypes, selectedRelationships });
  }, [selectedStars, selectedCareTypes, selectedRelationships, setFilters]);

  const handleStarChange = (star: number) => {
    if (selectedStars.includes(star)) {
      setSelectedStars(selectedStars.filter((s) => s !== star));
    } else {
      setSelectedStars([...selectedStars, star]);
    }
  };

  const handleCareTypeChange = (careType: string) => {
    if (selectedCareTypes.includes(careType)) {
      setSelectedCareTypes(selectedCareTypes.filter((ct) => ct !== careType));
    } else {
      setSelectedCareTypes([...selectedCareTypes, careType]);
    }
  };

  const handleRelationshipChange = (relationship: string[]) => {
    if (relationship.length > 0) {
      if (selectedRelationships.some((r) => relationship.includes(r))) {
        setSelectedRelationships(
          selectedRelationships.filter((sr) => !relationship.includes(sr))
        );
      } else {
        setSelectedRelationships([...selectedRelationships, ...relationship]);
      }
    }
  };

  const handleClearFilters = () => {
    resetAll();
    setSelectedStars([]);
    setSelectedCareTypes([]);
    setSelectedRelationships([]);
  };

  const renderStars = (filled: number) => {
    const stars: JSX.Element[] = [];
    for (let i = 0; i < 5; i++) {
      if (i < filled) stars.push(<MdStar key={i} size={20} />);
    }
    return stars;
  };

  function getSortedRelationships() {
    const allRelationshipsSet = new Set(allReviewRelationships);
    const relationships = Object.entries(groupedRelationships).reduce(
      (
        acc: {
          convertedRelationship: string;
          originalRelationships: string[];
          relationshipCount: number;
        }[],
        [convertedRelationship, originalRelationships]
      ) => {
        const relationshipCount = allReviewRelationships.filter((type) =>
          originalRelationships.includes(type)
        ).length;
        if (relationshipCount > 0) {
          acc.push({
            convertedRelationship,
            originalRelationships,
            relationshipCount,
          });
          originalRelationships.forEach((relationship) =>
            allRelationshipsSet.delete(relationship)
          );
        }
        return acc;
      },
      []
    );
    const otherRelationships = Array.from(allRelationshipsSet);
    const otherRelationshipCount = otherRelationships.length;
    if (otherRelationshipCount > 0) {
      relationships.push({
        convertedRelationship: 'Other',
        originalRelationships: otherRelationships,
        relationshipCount: otherRelationshipCount,
      });
    }

    return relationships.sort(
      (a, b) => b.relationshipCount - a.relationshipCount
    );
  }

  return (
    <>
      {isOpen && (
        <Box pb={6} width="100%">
          <Grid
            templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
            gap={4}
          >
            <GridItem
              borderWidth={{ base: '0 1px 1px 0', md: '0 1px 0 0' }}
              borderColor="gray.300"
              pb={{ base: 6, md: 0 }}
            >
              <Box fontWeight="bold" pb={4}>
                Star Rating:
              </Box>
              <VStack alignItems="flex-start" gap="10px">
                {Array.from({ length: 5 }).map((_, i) => {
                  const star = 5 - i;
                  const starCount = allReviewRatings.filter(
                    (rating) => rating === star
                  ).length;
                  return (
                    <Checkbox
                      key={star}
                      isChecked={selectedStars.includes(star)}
                      onChange={() => {
                        elementClicked({
                          element: {
                            type: ElementTypes.CHECKBOX,
                            action: ElementActions.FILTERS,
                            name: ElementNames.PROVIDER_REVIEWS,
                            text: `star-${star}`,
                            color: reviewRatings.includes(star)
                              ? 'tertiary.600'
                              : 'gray.500',
                            textColor: '',
                          },
                        });
                        handleStarChange(star);
                      }}
                      disabled={!reviewRatings.includes(star)}
                      style={{ marginTop: '0' }}
                      data-testid={`star-${star}`}
                    >
                      <Flex
                        color={
                          reviewRatings.includes(star)
                            ? 'tertiary.600'
                            : 'gray.500'
                        }
                      >
                        {renderStars(star)} ({starCount})
                      </Flex>
                    </Checkbox>
                  );
                })}
              </VStack>
            </GridItem>

            <GridItem
              borderWidth={{ base: '0 1px 1px 0', md: '0 1px 0 0' }}
              borderColor="gray.300"
              pb={{ base: 6, md: 0 }}
            >
              <Box fontWeight="bold" pb={4}>
                Care Type:
              </Box>
              <VStack alignItems="flex-start">
                {reviewCareTypes.map((careType) => {
                  const careTypeCount = allReviewCareTypes.filter(
                    (type) => type === careType
                  ).length;
                  const checkboxLabel = `${careType} (${careTypeCount})`;
                  return (
                    <Checkbox
                      key={careType}
                      isChecked={selectedCareTypes.includes(careType)}
                      onChange={() => {
                        elementClicked({
                          element: {
                            type: ElementTypes.CHECKBOX,
                            action: ElementActions.FILTERS,
                            name: ElementNames.PROVIDER_REVIEWS,
                            text: checkboxLabel,
                            color: '',
                            textColor: 'black',
                          },
                        });
                        handleCareTypeChange(careType);
                      }}
                    >
                      {checkboxLabel}
                    </Checkbox>
                  );
                })}
              </VStack>
            </GridItem>

            <GridItem
              borderBottom={{ base: '1px', md: 0 }}
              borderColor="gray.300"
              pb={{ base: 6, md: 0 }}
            >
              <Box fontWeight="bold" pb={4}>
                Relationship:
              </Box>
              <VStack alignItems="flex-start" position="relative" height="100%">
                {getSortedRelationships().map(
                  ({
                    convertedRelationship,
                    originalRelationships,
                    relationshipCount,
                  }) => {
                    const checkboxLabel = `${convertedRelationship} (${relationshipCount})`;
                    return (
                      <Checkbox
                        key={convertedRelationship}
                        isChecked={selectedRelationships.some((r) =>
                          originalRelationships.includes(r)
                        )}
                        onChange={() => {
                          elementClicked({
                            element: {
                              type: ElementTypes.CHECKBOX,
                              action: ElementActions.FILTERS,
                              name: ElementNames.PROVIDER_REVIEWS,
                              text: checkboxLabel,
                              color: '',
                              textColor: 'black',
                            },
                          });

                          handleRelationshipChange(originalRelationships);
                        }}
                      >
                        {convertedRelationship && <>{checkboxLabel}</>}
                      </Checkbox>
                    );
                  }
                )}
                <Button
                  display={{ base: 'none', md: 'block' }}
                  mt={4}
                  variant="outline"
                  colorScheme="info"
                  size="xs"
                  height={9}
                  onClick={handleClearFilters}
                  position="absolute"
                  bottom={10}
                  right={0}
                >
                  Reset All
                </Button>
              </VStack>
            </GridItem>
            <Button
              display={{ base: 'block', md: 'none' }}
              variant="outline"
              colorScheme="info"
              size="md"
              onClick={handleClearFilters}
            >
              Reset All
            </Button>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default ProviderReviewsFilters;
