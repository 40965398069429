import { Flex, Heading } from '@chakra-ui/layout';

const MapWithoutCoordinates: React.FC = () => {
  return (
    <Flex w="100%" p={4} justifyContent="center">
      <Heading size="md">The coordinates must be defined!</Heading>
    </Flex>
  );
};

export default MapWithoutCoordinates;
