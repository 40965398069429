import { Button } from '@chakra-ui/button';
import { Section } from '@components/Sections';
import useLocaleCatalog from '@hooks/use-locale-catalog';
import { modifyTrailingSlash } from '@utils/modifyTrailingSlash';
import { labelToSlug, toCapitalizedWords } from '@utils/strings';
import { getStateAbbreviation } from '@utils/UsStates';
import kebabCase from 'lodash/kebabCase';
import lowerCase from 'lodash/lowerCase';
import { useRouter } from 'next/router';
import { useContext } from 'react';
import { HeadingElements } from '~/@types/heading';
import SiteContext, { SiteDefinition } from '~/contexts/SiteContext';
import NearbyGrid from './NearbyGrid';
import { CatalogLink, Link } from './types';

export type NearbyOtherCareTypeSameCityProps = {
  heading?: string;
  headingElement?: HeadingElements;
  content?: string;
  links: Array<Link>;
  catalogLinks?: string;
  careType: string;
  enableTrailingSlash?: boolean;
  useCatalogForNearby?: boolean;
  initialData?: any[];
  city?: string;
  state?: string;
};

const useNearbyOtherCareTypeSameCity = (
  site: SiteDefinition | null,
  filters: { state: string; city: string; careType: string } | undefined,
  initialData: any[]
) => {
  let filtersToUse = {};

  if (filters && Object.keys(filters).length > 0) {
    const { state, city, careType } = filters;

    filtersToUse = {
      orderBy: 'urlPath asc',
      'state[eq]': lowerCase(state),
      'city[eq]': lowerCase(city),
      'careType[ne]': kebabCase(careType),
      '@ancestor': `/${site?.path}`,
    };
  }

  return useLocaleCatalog({
    filters: filtersToUse,
    initialData,
  });
};

const buildLinks = (catalogLinks, enableTrailingSlash) => {
  const links: Array<CatalogLink> = [];
  catalogLinks.forEach((v) => {
    links.push({
      text: `${toCapitalizedWords(v.careType)} in ${toCapitalizedWords(
        v.city
      )}, ${getStateAbbreviation(v.state)}`,
      href: modifyTrailingSlash(enableTrailingSlash, v.urlPath),
    });
  });
  return links;
};

const formatLink = ({
  careType,
  enableTrailingSlash,
  careTypeFromProps,
  pagePath,
}) => {
  const careTypeURL =
    careType?.localResourceTypeRegion?.localResourceType?.urlName || '';
  const basePath = pagePath.split(careTypeFromProps)[0];
  const cityPath = pagePath.split(careTypeFromProps)[1];
  const path = modifyTrailingSlash(
    enableTrailingSlash,
    `${basePath}${labelToSlug(careTypeURL)}${cityPath}`
  );

  return { text: careType.linkText, href: path };
};

const NearbyOtherCareTypeSameCity = ({
  links,
  heading,
  headingElement = 'h3',
  content,
  careType,
  enableTrailingSlash = false,
  useCatalogForNearby = false,
  initialData = [],
  city = '',
  state = '',
}: NearbyOtherCareTypeSameCityProps) => {
  const siteContext = useContext(SiteContext);
  const route = useRouter();
  const pagePath = route.asPath;
  const filters = useCatalogForNearby ? { state, city, careType } : undefined;

  const { data: catalogData } = useNearbyOtherCareTypeSameCity(
    siteContext?.site,
    filters,
    initialData
  );

  let availableLinks: CatalogLink[] | Link[] = [];

  if (useCatalogForNearby) {
    availableLinks = buildLinks(catalogData, enableTrailingSlash);
  } else {
    availableLinks = links.map((link) =>
      formatLink({
        careType: link,
        enableTrailingSlash,
        careTypeFromProps: careType,
        pagePath,
      })
    );
  }

  if (availableLinks?.length === 0) {
    return null;
  }

  return (
    <>
      <Section
        headingElement={headingElement}
        title={heading}
        richText={content}
      />
      <NearbyGrid align="flex-start">
        {availableLinks?.map((link, i) => {
          return (
            <a key={`${i}-${link.href}`} href={link.href} target="_blank">
              <Button
                variant="outline"
                colorScheme="primary"
                whiteSpace="pre-wrap"
              >
                {link.text}
              </Button>
            </a>
          );
        })}
      </NearbyGrid>
    </>
  );
};

export default NearbyOtherCareTypeSameCity;
