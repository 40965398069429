import { HStack, Icon, Link, Text } from '@chakra-ui/react';
import { STRING_TO_ICON_CLASS } from '@components/RenderIcon';

interface Props {
  careType: string | null;
  federalProviderNumber: string | null;
  state: string | null;
}

const MedicareReviewsLink: React.FC<Props> = ({
  careType,
  federalProviderNumber,
  state,
}) => {
  const medicareCareType =
    careType === 'nursing_homes'
      ? 'nursing-home'
      : careType === 'home_healthcare_agencies'
      ? 'home-health'
      : null;

  if (!federalProviderNumber || !medicareCareType || !state) return null;

  return (
    <HStack>
      <Icon as={STRING_TO_ICON_CLASS['MdCheck']} boxSize={6} />
      <Text as="span">
        Medicare Provider Number:{' '}
        <Link
          textDecoration="underline"
          target="_blank"
          href={`https://www.medicare.gov/care-compare/details/${medicareCareType}/${federalProviderNumber}?state=${state}`}
        >
          <strong>{federalProviderNumber}</strong>
        </Link>
      </Text>
    </HStack>
  );
};

export default MedicareReviewsLink;
