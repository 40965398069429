import {
  MultiStepForm,
  MultiStepFormProps,
} from '@components/MultiStepForm/MultiStepForm';
import { transformMagnoliaMultiStepForm } from '@components/MultiStepForm/ServerComponent';
import {
  MagnoliaMultiStepForm,
  HiddenField,
  MagnoliaFieldConfig,
  MagnoliaFormConfig,
  MagnoliaStep,
  SelectOption,
} from '@components/MultiStepForm/types';
import { useContext } from 'react';
import { PlasmicCanvasContext } from '@plasmicapp/loader-nextjs';
import { Color } from '~/utils/getColor';
import { MagnoliaNode } from '~/types/Magnolia';
import { useRouter } from 'next/router';

interface EntryListProps {
  label?: string;
  emailTypeErrorMessage: string;
  entryType: string;
  field: string;
  isRequired?: boolean;
  isRequiredErrorMessage?: string;
  mask: string;
  name?: string;
  phoneTypeErrorMessage: string;
  typeMask: boolean;
  doNotSubmit: boolean;
  defaultValue: string;
  placeholder?: string;
  options: SelectOption[];
}

interface HeadlineOrSubHeadline {
  text: string;
  textFormatting: {
    color: Color;
    element: string;
    field: string;
    sizeOnDesktop: string;
    sizeOnMobile: string;
  };
}

interface StepProps {
  entryList: EntryListProps[];
  cta?: {
    formatting: {
      color: string;
      field: string;
      size: string;
      variant: string;
    };
    label: string;
  };
  legalDisclosure: string;
  formHeadline: string;
  headline?: HeadlineOrSubHeadline;
  subHeadline?: HeadlineOrSubHeadline;
  image?: {
    imageBorderRadius: string;
    switchable: {
      field: string;
      imageUrl?: string;
      imageAlt?: string;
    };
  };
}

interface StepsProps {
  props: StepProps & {
    children?: {
      props: StepProps;
    }[];
  };
}

export interface MultiStepFormWrapperProps extends MultiStepFormProps {
  errorColor: MagnoliaMultiStepForm['errorColor'];
  formBackgroundColor: MagnoliaMultiStepForm['formBackgroundColor'];
  formEntriesColor: MagnoliaMultiStepForm['formEntriesColor'];
  image: MagnoliaMultiStepForm['image'];
  hiddenEntries: HiddenField[];
  steps: StepsProps;
  thankYou: MagnoliaMultiStepForm['thankYou'];
  templateId: string;
  className: string;
  currentStep: any; // This is a number but Plasmic wont let me set it as a number
}

function createHiddenEntries(
  formHiddenEntries: HiddenField[]
): MagnoliaNode & { [key: string]: HiddenField } {
  const hiddenEntries: { [key: string]: HiddenField } =
    formHiddenEntries?.reduce((acc, entry, index) => {
      const name = `hiddenEntries${index}`;
      acc[name] = {
        name: entry?.name || name,
        defaultValue: entry?.defaultValue,
        doNotSubmit: entry?.doNotSubmit || false,
      };
      return acc;
    }, {});

  const nodes: MagnoliaNode = { '@nodes': Object.keys(hiddenEntries || {}) };
  return {
    ...hiddenEntries,
    ...nodes,
  };
}

function createOption(
  option: SelectOption,
  index: number
): { [key: string]: SelectOption } {
  const optionName = `option${index}`;
  return {
    [optionName]: {
      label: option.label,
      value: option.value,
    },
  };
}

function createEntry(
  entry: EntryListProps,
  i: number
): Omit<MagnoliaFormConfig['entryList'], '@nodes'> {
  if (!entry) {
    return {};
  }
  const entryName = `entryList${i}`;
  const isRequired = entry?.isRequired?.toString() || 'false';
  const hasTypeMask = entry?.typeMask?.toString() || 'false';

  const options = entry.options?.reduce((options, option, i) => {
    return { ...options, ...createOption(option, i) };
  }, {});
  const newEntry: Omit<MagnoliaFieldConfig, '@nodes'> = {
    '@name': entryName,
    name: entry?.name || entryName,
    label: entry?.label,
    defaultValue: entry?.defaultValue,
    entryType: {
      field: entry.entryType,
      ...(entry.entryType !== 'select'
        ? { placeholder: entry.placeholder }
        : {}),
      ...(entry.entryType !== 'select'
        ? {
            inputType: {
              field: entry.field,
              typeErrorMessage:
                entry.field === 'email'
                  ? entry.emailTypeErrorMessage
                  : entry.field === 'phone'
                  ? entry.phoneTypeErrorMessage
                  : '',
              ...(entry.field === 'phone'
                ? {
                    typeMask: {
                      field: hasTypeMask,
                      ...(hasTypeMask ? { mask: entry.mask } : {}),
                    },
                  }
                : {}),
            },
          }
        : {}),
      ...(options
        ? {
            options: {
              ...options,
              '@nodes': Object.keys(options),
            },
          }
        : {}),
    },
    isRequired: {
      field: isRequired,
      ...(entry.isRequiredErrorMessage && isRequired === 'true'
        ? {
            isRequiredErrorMessage: entry.isRequiredErrorMessage,
          }
        : {}),
    },
    doNotSubmit: entry.doNotSubmit || false,
  };
  return {
    [entryName]: newEntry,
  };
}

function convertSteps(
  formSteps: StepsProps[]
): MagnoliaNode & { [key: string]: MagnoliaStep } {
  const steps: { [key: string]: MagnoliaStep } = formSteps?.reduce(
    (acc, step, index) => {
      const entryList = step.props.entryList;
      const name = `step${index}`;
      const entries = entryList?.reduce((entriesAcc, child, i) => {
        return { ...entriesAcc, ...createEntry(child, i) };
      }, {});

      return {
        ...acc,
        [name]: {
          ...step.props,
          form: {
            headline: step.props.formHeadline,
            cta: step.props.cta,
            legalDisclosure: step.props.legalDisclosure,
            entryList: {
              ...entries,
              '@nodes': Object.keys(entries),
            },
          },
        },
      };
    },
    {}
  );
  const stepNodes: MagnoliaNode = { '@nodes': Object.keys(steps || {}) };
  return {
    ...steps,
    ...stepNodes,
  };
}

const MultiStepFormWrapper = ({
  data,
  templateId,
  className,
  errorColor,
  errorMessages,
  formBackgroundColor,
  formEntriesColor,
  formType,
  hiddenEntries,
  image,
  steps,
  thankYou,
  currentStep,
}: MultiStepFormWrapperProps) => {
  const router = useRouter();
  const inEditor = !!useContext(PlasmicCanvasContext);
  const formSteps = convertSteps(
    steps?.props.children && steps?.props.children.length > 0
      ? steps.props.children
      : [steps]
  );
  const metadata = {
    '@id': data?.id ?? templateId,
  };
  const formHiddenEntries = createHiddenEntries(hiddenEntries);

  const componentConfig: MagnoliaMultiStepForm = {
    '@id': '',
    'mgnl:template': '',
    '@nodes': [],
    errorColor: errorColor,
    formBackgroundColor: formBackgroundColor,
    formEntriesColor: formEntriesColor,
    image: {
      imageBorderRadius: '0',
      switchable: {
        ...image.switchable,
      },
    },
    metadata: metadata,
    steps: formSteps,
    hiddenEntries: formHiddenEntries,
    thankYou: thankYou,
  };

  const { steps: parsedSteps, hiddenFields } = transformMagnoliaMultiStepForm(
    componentConfig,
    router.query
  );

  const plasmicData = {
    hiddenFields,
    steps: parsedSteps,
    pagination: {
      active: false,
      color: componentConfig.formEntriesColor,
    },
  };
  return (
    <MultiStepForm
      data={plasmicData}
      errorMessages={errorMessages}
      formType={formType}
      metadata={metadata}
      thankYou={thankYou}
      className={className}
      inPlasmicEditor={inEditor}
      plasmicEditorStep={currentStep}
    />
  );
};

export default MultiStepFormWrapper;
