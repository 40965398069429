import { Grid } from '@chakra-ui/layout';
import CardWithLinks from '@components/CardWithLinks';
import EmptyComponent from '@components/EmptyComponent';
import Container from '@components/LayoutStructure/Container';
import { Section } from '@components/Sections';
import withHydrationOnDemand from 'react-hydration-on-demand';
import { ContentConfig, TitleConfig } from '~/types/componentsConfig';
import {
  DeviceVisibility,
  useResponsiveDisplay,
} from '@hooks/useResponsiveDisplay';

export interface GeoPageLink {
  text: string;
  url: string;
}

interface NearbyGeoPageNode {
  heading: string;
  links: Array<GeoPageLink>;
}

export interface NearbyGeoPagesLinks {
  encodedId: string;
  nearbyCountiesSameType: NearbyGeoPageNode;
  nearbyCitiesSameType: NearbyGeoPageNode;
  otherTypesHere: NearbyGeoPageNode;
  otherTypesNearby: NearbyGeoPageNode;
}

interface NearbyGeoPagesLinksConfig {
  enabled?: boolean;
  title?: TitleConfig;
}

export interface NearbyGeoPagesProps {
  options?: {
    enableTrailingSlash?: boolean;
    maxLinks?: string;
    providerSlug?: string;
  };
  title?: TitleConfig;
  content?: ContentConfig;
  bgBorderRadius?: string;
  nearbyCountiesSameType?: NearbyGeoPagesLinksConfig;
  nearbyCitiesSameType?: NearbyGeoPagesLinksConfig;
  otherTypesHere?: NearbyGeoPagesLinksConfig;
  otherTypesNearby?: NearbyGeoPagesLinksConfig;
  data?: NearbyGeoPagesLinks | null;
  className?: string;
  deviceVisibility?: DeviceVisibility;
}

const defaultNearbyGeoPagesLinks: NearbyGeoPagesLinks = {
  encodedId: '',

  nearbyCountiesSameType: {
    heading: '',
    links: [],
  },
  nearbyCitiesSameType: {
    heading: '',
    links: [],
  },
  otherTypesHere: {
    heading: '',
    links: [],
  },
  otherTypesNearby: {
    heading: '',
    links: [],
  },
};

const NearbyGeoPages: React.FC<NearbyGeoPagesProps> = ({
  title,
  content,
  bgBorderRadius,
  nearbyCountiesSameType: nearbyCountiesSameTypeConfig,
  nearbyCitiesSameType: nearbyCitiesSameTypeConfig,
  otherTypesHere: otherTypesHereConfig,
  otherTypesNearby: otherTypesNearbyConfig,
  data,
  className,
  deviceVisibility,
}) => {
  const isHidden = useResponsiveDisplay(deviceVisibility);
  if (isHidden) {
    return <></>;
  }
  const containerBackground = 'gray.50';
  const headingElement = title?.element || 'h2';
  const sectionHeading = title?.content;
  const sectionContent = content?.content;
  const {
    nearbyCountiesSameType,
    nearbyCitiesSameType,
    otherTypesHere,
    otherTypesNearby,
  } = data || defaultNearbyGeoPagesLinks;

  // Determine which sections to show.
  const showHeading = Boolean(sectionHeading) || Boolean(sectionContent);
  const showNearbyCountiesSameType =
    nearbyCountiesSameTypeConfig?.enabled &&
    Boolean(nearbyCountiesSameType?.links?.length);
  const showNearbyCitiesSameType =
    nearbyCitiesSameTypeConfig?.enabled &&
    Boolean(nearbyCitiesSameType?.links?.length);
  const showOtherTypesHere =
    otherTypesHereConfig?.enabled && Boolean(otherTypesHere?.links?.length);
  const showOtherTypesNearby =
    otherTypesNearbyConfig?.enabled && Boolean(otherTypesNearby?.links?.length);

  // Determine the heading for each section.
  const nearbyCountiesSameTypeHeading =
    nearbyCountiesSameTypeConfig?.title?.content ??
    nearbyCountiesSameType?.heading;
  const nearbyCitiesSameTypeHeading =
    nearbyCitiesSameTypeConfig?.title?.content ?? nearbyCitiesSameType?.heading;
  const otherTypesHereHeading =
    otherTypesHereConfig?.title?.content ?? otherTypesHere?.heading;
  const otherTypesNearbyHeading =
    otherTypesNearbyConfig?.title?.content ?? otherTypesNearby?.heading;

  // Only render the component if there is something to show.
  if (
    !showNearbyCountiesSameType &&
    !showNearbyCitiesSameType &&
    !showOtherTypesHere &&
    !showOtherTypesNearby
  ) {
    return <EmptyComponent />;
  }

  return (
    <Container
      bg={containerBackground}
      borderRadius={bgBorderRadius}
      ignoreMaxWidth
      className={className}
    >
      <Container
        as="section"
        display="flex"
        flexDirection="column"
        gap="8"
        py="8"
      >
        {showHeading && (
          <Section
            headingElement={headingElement}
            title={sectionHeading}
            richText={sectionContent}
          />
        )}

        <Grid
          gap="8"
          gridTemplateColumns={{ base: '1fr', xl: 'repeat(3, 1fr)' }}
        >
          {showNearbyCountiesSameType && (
            <CardWithLinks
              heading={nearbyCountiesSameTypeHeading}
              headingElement={nearbyCountiesSameTypeConfig.title?.element}
              links={nearbyCountiesSameType?.links}
            />
          )}

          {showNearbyCitiesSameType && (
            <CardWithLinks
              heading={nearbyCitiesSameTypeHeading}
              headingElement={nearbyCitiesSameTypeConfig.title?.element}
              links={nearbyCitiesSameType?.links}
            />
          )}

          {showOtherTypesHere && (
            <CardWithLinks
              heading={otherTypesHereHeading}
              headingElement={otherTypesHereConfig.title?.element}
              links={otherTypesHere?.links}
            />
          )}

          {showOtherTypesNearby && (
            <CardWithLinks
              heading={otherTypesNearbyHeading}
              headingElement={otherTypesNearbyConfig.title?.element}
              links={otherTypesNearby?.links}
            />
          )}
        </Grid>
      </Container>
    </Container>
  );
};

export default withHydrationOnDemand()(NearbyGeoPages);
