import { experimentViewed } from './eventContracts';
import {
  SessionContextType,
  useSessionContext,
} from '~/contexts/SessionContext';
import segmentEvents from '~/config/segment-events';

interface ExperimentViewedType {
  sessionContext: SessionContextType;
  sessionId;
  pageSessionId;
  experimentId: string;
  experimentName: string;
  variationId: string;
  variationName: string;
}

const experimentView = ({
  sessionContext,
  sessionId,
  pageSessionId,
  experimentId,
  experimentName,
  variationId,
  variationName,
}: ExperimentViewedType) => {
  const experimentViewedContract: experimentViewed = {
    page_session_id: pageSessionId ?? '',
    session_id: sessionId ?? '',
    experimentation: {
      experiment_id: experimentId ?? '',
      experiment_name: experimentName ?? '',
      variation_id: variationId ?? '',
      variation_name: variationName ?? '',
    },
  };
  window.tracking.track(
    segmentEvents.EXPERIMENT_VIEWED,
    experimentViewedContract
  );
};

const useExperimentViewed = () => {
  const sessionContext = useSessionContext();
  return (
    sessionId: string,
    pageSessionId: string,
    experimentId: string,
    experimentName: string,
    variationId: string,
    variationName: string
  ) => {
    return experimentView({
      sessionContext,
      sessionId,
      pageSessionId,
      experimentId,
      experimentName,
      variationId,
      variationName,
    });
  };
};

export default useExperimentViewed;
