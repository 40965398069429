import withHydrationOnDemand from 'react-hydration-on-demand';
import Container from '@components/LayoutStructure/Container';
import Heading from '@components/Heading';
import { Box, UnorderedList } from '@chakra-ui/layout';
import { useEffect, useState } from 'react';
import WhyCaringToggleButton from '@components/WhyCaring/WhyCaringToggleButton';
import { motion } from 'framer-motion';
import WhyCaringListItem, {
  IWhyCaringItem,
} from '@components/WhyCaring/WhyCaringListItem';
import { createID } from '@utils/strings';
import CookieStorage from '@utils/cookieStorage';
import { Color, getColor } from '@utils/getColor';
import {
  DeviceVisibility,
  useResponsiveDisplay,
} from '@hooks/useResponsiveDisplay';

interface Props {
  title: string;
  bg: Color;
  itemsBg: Color;
  iconsColor: Color;
  iconsBg: Color;
  deviceVisibility?: DeviceVisibility;
  firstItem: IWhyCaringItem;
  secondItem: IWhyCaringItem;
  thirdItem: IWhyCaringItem;
  fourthItem: IWhyCaringItem;
}

const WHY_CARING_COOKIE_NAME = 'why-caring-seen-already';

const WhyCaring = ({
  title,
  bg = { color: 'secondary', range: '50' },
  itemsBg = { color: 'white', range: '50' },
  iconsColor = { color: 'accent', range: '400' },
  iconsBg = { color: 'accent', range: '50' },
  deviceVisibility,
  firstItem,
  secondItem,
  thirdItem,
  fourthItem,
}: Partial<Props>) => {
  const referrer = typeof document !== 'undefined' ? document.referrer : '';
  const referrerDoesNotContainHost = referrer
    ? new URL(referrer).hostname !== window.location.hostname
    : true;

  useEffect(() => {
    if (CookieStorage.enabled) {
      CookieStorage.set(WHY_CARING_COOKIE_NAME, true);
    }
  }, []);

  const isFirstVisit = CookieStorage.enabled
    ? !CookieStorage.get(WHY_CARING_COOKIE_NAME)
    : true;

  const initialExpanded = isFirstVisit || referrerDoesNotContainHost;

  const [expanded, setExpanded] = useState(initialExpanded);

  const isHidden = useResponsiveDisplay(deviceVisibility);
  if (isHidden) {
    return <></>;
  }

  const bgText = getColor(bg.color, bg.range);
  const itemsBgText = getColor(itemsBg.color, itemsBg.range);
  const iconsColorText = getColor(iconsColor.color, itemsBg.range);
  const iconsBgText = getColor(iconsBg.color, iconsBg.range);

  const items = [firstItem, secondItem, thirdItem, fourthItem].filter(
    (item) => item?.title && item?.description
  ) as IWhyCaringItem[];

  return (
    <Box bgColor={bgText}>
      <Container
        as="section"
        py={8}
        aria-labelledby={title ? createID(title) : undefined}
      >
        {title && (
          <Box
            display="flex"
            flexDirection={{
              base: 'column',
              sm: 'row',
            }}
            alignItems={{
              base: 'start',
              sm: 'center',
            }}
            mb={expanded ? 8 : 0}
            transition="margin-bottom 0.4s ease-in-out, opacity 0.4s ease-in-out"
          >
            <Heading
              headingElement="h2"
              title={title}
              withContainer={false}
              size={{ base: 'sm', sm: 'md' }}
              mr={8}
            />
            <WhyCaringToggleButton
              expanded={expanded}
              onClick={() => setExpanded((open) => !open)}
              aria-controls="why-caring-list"
              aria-expanded={expanded}
            />
          </Box>
        )}
        <motion.div
          initial={{
            opacity: expanded ? 1 : 0,
            height: expanded ? 'auto' : 0,
          }}
          animate={{
            opacity: expanded ? 1 : 0,
            height: expanded ? 'auto' : 0,
          }}
          transition={{ duration: 0.4, ease: 'easeInOut' }}
          style={{ overflow: 'hidden' }}
        >
          <UnorderedList
            id="why-caring-list"
            listStyleType="none"
            m={0}
            display="grid"
            gridGap={8}
            gridTemplateColumns={{ md: 'repeat(auto-fit, minmax(250px, 1fr))' }}
          >
            {items.map((item, index) => (
              <WhyCaringListItem
                key={index}
                {...item}
                itemsBgText={itemsBgText}
                iconsColorText={iconsColorText}
                iconsBgText={iconsBgText}
              />
            ))}
          </UnorderedList>
        </motion.div>
      </Container>
    </Box>
  );
};

export default withHydrationOnDemand({
  on: ['idle', 'visible'],
  initialVisible: true,
})(WhyCaring);
