import { Card } from '@chakra-ui/card';
import Image from 'next/image';
import { Stack, HStack, Text, Link, Flex, Grid, Box } from '@chakra-ui/layout';
import { ParameterizeRatings } from '@components/ReviewSummary/ParameterizeRatings';
import dynamic from 'next/dynamic';
import { useContext } from 'react';
import { Award, ParametricReviewData } from '~/contexts/Provider';
import SiteContext from '~/contexts/SiteContext';
import { eoc, line, poc, hcp_cert } from '~/assets/badges/homecare-pulse';
import { Metadata } from '~/types/Magnolia';
const CaringStarsMultiYearBadge = dynamic(
  () => import('@components/CaringStars/CaringStarsMultiYearBadge')
);
const ReviewProgress = dynamic(
  () => import('@components/ReviewSummary/ReviewProgress')
);
const ReviewWidget = dynamic(() => import('@components/ReviewWidget'));

const ProviderReviewsHeaderContainer = ({ domain, children }) => {
  switch (domain) {
    case 'caring.com':
      return <HStack marginBottom="auto">{children}</HStack>;
    default:
      return <Stack spacing="4">{children}</Stack>;
  }
};

const ProviderReviewsHeaderStack = ({ domain, children }) => {
  switch (domain) {
    case 'caring.com':
      return (
        <Flex direction="column" alignItems="stretch" width="100%">
          {children}
        </Flex>
      );
    default:
      return <Stack>{children}</Stack>;
  }
};

const gridTemplateColumns = (domain) => {
  switch (domain) {
    case 'caring.com':
      return '1';
    default:
      return { base: '1', lg: 'repeat(4, 1fr)' };
  }
};

const progressBarColor = (domain) => {
  switch (domain) {
    case 'caring.com':
      return 'tertiary';
    default:
      return 'red';
  }
};

const homeCarePulseBadgeMapping = (badgeName) => {
  switch (badgeName) {
    case 'Provider of Choice':
      return poc;
    case 'Employer of Choice':
      return eoc;
    case 'Leader in Experience':
      return line;
    default:
      return '';
  }
};

type homeCarePulseAwards = {
  homeCarePulseCertified: Award | undefined;
  homeCarePulseAwards: Award[];
};
interface Props {
  caringStars: Award[];
  homeCarePulse: Award[];
  reviewCount: number;
  averageRating: string;
  description?: string;
  includeAggregateReview?: boolean;
  includeCountOfReview?: boolean;
  displayBadges?: boolean;
  displayHomeCarePulseBadges?: boolean;
  includeFallbackIfNoReviewAvailable?: boolean;
  includeParametricReview?: boolean;
  ratingColor?: string;
  ratingColorRange?: string;
  parametricReviewColor?: string;
  parametricReviewData: ParametricReviewData;
  providerName: string;
  reviewGuidelinesURL?: string;
  contactUsURL?: string;
  defaultOpenModal?: boolean;
  metadata: Pick<Metadata, '@id'>;
}

const ProviderReviewsHeader: React.FC<Props> = ({
  description = '',
  includeAggregateReview = true,
  includeCountOfReview = true,
  includeFallbackIfNoReviewAvailable = true,
  providerName,
  caringStars,
  homeCarePulse,
  displayBadges = false,
  displayHomeCarePulseBadges = false,
  reviewCount,
  averageRating,
  includeParametricReview = true,
  ratingColor,
  ratingColorRange,
  parametricReviewColor,
  parametricReviewData,
  reviewGuidelinesURL,
  contactUsURL,
  defaultOpenModal = false,
  metadata,
}) => {
  const siteContext = useContext(SiteContext);
  const domain = siteContext.site?.path || '';
  const hasBadges = displayBadges && caringStars.length > 0;

  const hasHomeCarePulseBadges =
    displayHomeCarePulseBadges &&
    homeCarePulse.length > 0 &&
    homeCarePulse.find((badge) => badge.name === 'Home Care Pulse Certified');

  const { homeCarePulseCertified, homeCarePulseAwards } = homeCarePulse.reduce(
    (acc: homeCarePulseAwards, badge) => {
      if (badge.name === 'Home Care Pulse Certified') {
        acc.homeCarePulseCertified = badge;
      } else {
        acc.homeCarePulseAwards.push(badge);
      }
      return acc;
    },
    { homeCarePulseCertified: undefined, homeCarePulseAwards: [] }
  );

  return (
    <ProviderReviewsHeaderContainer domain={domain}>
      <Text fontSize="lg">{description}</Text>
      <ProviderReviewsHeaderStack domain={domain}>
        <ReviewWidget
          includeAggregateReview={includeAggregateReview}
          includeCountOfReview={includeCountOfReview}
          includeFallbackIfNoReviewAvailable={
            includeFallbackIfNoReviewAvailable
          }
          reviewCount={reviewCount}
          averageRating={averageRating}
          provider={providerName}
          reviewGuidelinesURL={reviewGuidelinesURL}
          contactUsURL={contactUsURL}
          defaultOpenModal={defaultOpenModal}
          ratingColor={ratingColor}
          ratingColorRange={ratingColorRange}
          metadata={metadata}
        />
        {includeParametricReview && parametricReviewData && (
          <ReviewProgress
            parametrizedRatings={ParameterizeRatings(parametricReviewData)}
            templateColumns={gridTemplateColumns(domain)}
            paddingTop={0}
            paddingBottom={{ base: 0, lg: 8 }}
            rowGap={3}
            color={parametricReviewColor ?? progressBarColor(domain)}
          />
        )}
        {hasBadges && (
          <CaringStarsMultiYearBadge
            years={caringStars}
            height="100"
            alignment="flex-start"
            yearAlignment="left"
            inSidebar={true}
          />
        )}
        {hasHomeCarePulseBadges && (
          <>
            <Box mt={{ base: 6, lg: 9 }}>
              <Card p={0} variant="elevated">
                <Grid
                  gridTemplateColumns="repeat(5, 1fr)"
                  width="100%"
                  padding={2}
                  justifyItems="center"
                >
                  {homeCarePulseAwards.map((award) => (
                    <Link
                      key={`${award.name}-${award.year}`}
                      target="_blank"
                      href="/senior-care/home-care-pulse-awards/"
                      _hover={{ textDecoration: 'none' }}
                    >
                      <Image
                        src={homeCarePulseBadgeMapping(award.name)}
                        alt={`${award.name} ${award.year}`}
                        height={50}
                      />
                    </Link>
                  ))}
                </Grid>
              </Card>
            </Box>

            <Box mt={{ base: 6, lg: 9 }}>
              <Link
                display="block"
                width="fit-content"
                target="_blank"
                href="/senior-care/home-care-pulse-awards/"
                _hover={{ textDecoration: 'none' }}
              >
                <Image
                  src={hcp_cert}
                  alt={`${homeCarePulseCertified?.name} ${homeCarePulseCertified?.year}`}
                  height={48}
                />
              </Link>
            </Box>
          </>
        )}
      </ProviderReviewsHeaderStack>
    </ProviderReviewsHeaderContainer>
  );
};

export default ProviderReviewsHeader;
