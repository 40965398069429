import { PARAMETRIZED_RATING_LABELS } from '~/constants';
import { ParametricReviewData } from '~/contexts/Provider';

export interface ParametrizedRatings {
  label: string;
  count: string;
  rating: number;
  min: number;
  max: number;
}

export const ParameterizeRatings = (
  props: ParametricReviewData
): ParametrizedRatings[] => {
  return Object.entries(PARAMETRIZED_RATING_LABELS)
    .map(([key, value]) => {
      return {
        label: props[`${key}Name`] || value,
        count: props[`${key}Count`] || 0,
        rating: props[`${key}RatingValue`] || 0,
        min: props[`${key}MinimumRating`] || 0,
        max: props[`${key}MaximumRating`] || 5,
      };
    })
    .filter(({ count }) => count > 0);
};
