import { Box, ListItem, Text } from '@chakra-ui/layout';
import { Icon } from '@chakra-ui/react';
import { STRING_TO_ICON_CLASS } from '@components/RenderIcon';
import Heading from '@components/Heading';

export interface IWhyCaringItem {
  title: string;
  description: string;
  icon: string;
}
interface Props extends IWhyCaringItem {
  iconsBgText: string;
  iconsColorText: string;
  itemsBgText: string;
}

const WhyCaringListItem = ({
  itemsBgText,
  iconsColorText,
  iconsBgText,
  title,
  description,
  icon,
}: Props) => {
  return (
    <ListItem as="li" p={4} bgColor={itemsBgText} display="flex" gap={4}>
      <Box
        bgColor={iconsBgText}
        color={iconsColorText}
        display="flex"
        alignItems="center"
        justifyContent="center"
        w={12}
        h={12}
      >
        <Icon
          w={6}
          h={6}
          color={iconsColorText}
          as={STRING_TO_ICON_CLASS[icon]}
          aria-hidden={true}
          focusable={false}
        />
      </Box>
      <Box flex={1}>
        <Heading
          headingElement="h3"
          title={title}
          withContainer={false}
          size="sm"
          mb={2}
        />
        <Text as="p" fontSize="xs">
          {description}
        </Text>
      </Box>
    </ListItem>
  );
};

export default WhyCaringListItem;
