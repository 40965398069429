import { Heading } from '@chakra-ui/react';
import { MagnoliaTextWithSizeAndStyle } from './types';
import { getTextElementProps } from './MultiStepForm.utils';

const HeadingWithSizeAndStyle: React.FC<MagnoliaTextWithSizeAndStyle> = ({
  text,
  textFormatting,
}) => {
  // Heading.fontSize is not the same as Text.fontSize, we need to use Heading.size instead
  const { fontSize, ...otherProps } = getTextElementProps(textFormatting);

  return text ? (
    <Heading size={fontSize} {...otherProps}>
      {text}
    </Heading>
  ) : null;
};

export default HeadingWithSizeAndStyle;
