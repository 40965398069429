import { z } from 'zod';

const BOOLEAN_STRINGS = {
  TRUE: 'true',
  FALSE: 'false',
} as const;

export const coerceBoolean = <T extends z.ZodTypeAny>(schema: T) =>
  z.preprocess((val) => {
    const result = z
      .union([
        z
          .nativeEnum(BOOLEAN_STRINGS)
          .transform((val) => val === 'true')
          .pipe(z.boolean()),
        z.boolean(),
      ])
      .safeParse(val);
    if (result.success) {
      return result.data;
    } else {
      return null;
    }
  }, schema);

export const coerceNumber = <T extends z.ZodTypeAny>(schema: T) =>
  z.preprocess((val) => {
    const result = z
      .union([
        z
          .string()
          .transform((val) => Number(val))
          .pipe(z.number()),
        z.number(),
      ])
      .safeParse(val);
    if (result.success) {
      return result.data;
    } else {
      return null;
    }
  }, schema);

export const coerceNumberArray = <T extends z.ZodTypeAny>(schema: T) =>
  z.preprocess((val) => {
    const result = z
      .union([
        z
          .string()
          .transform((val) => val.split(',').filter(Boolean).map(Number))
          .pipe(z.array(z.number())),
        z.array(z.number()),
      ])
      .safeParse(val);
    if (result.success) {
      return result.data;
    } else {
      return null;
    }
  }, schema);

export const coerceString = <T extends z.ZodTypeAny>(schema: T) =>
  z.preprocess((val) => {
    const result = z.string().safeParse(val);
    if (result.success) {
      return result.data;
    } else {
      return null;
    }
  }, schema);

export const coerceStringArray = <T extends z.ZodTypeAny>(schema: T) =>
  z.preprocess((val) => {
    const result = z
      .union([
        z
          .string()
          .transform((val) => val.split(',').filter(Boolean))
          .pipe(z.array(z.string())),
        z.array(z.string()),
      ])
      .safeParse(val);
    if (result.success) {
      return result.data;
    } else {
      return null;
    }
  }, schema);
