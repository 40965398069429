import useInquiryFormSubmitted from '@hooks/use-inquiry-form-submitted';
import parse from 'html-react-parser';
import {
  Card,
  Box,
  Heading,
  Icon,
  Stack,
  VStack,
  Text,
  Divider,
  HStack,
  Link,
} from '@chakra-ui/react';
import SearchResultImage from '@components/Search/SearchResultImage';
import useElementClicked, {
  ElementActions,
  ElementNames,
  ElementTypes,
} from '@components/Analytics/events/ElementClicked';
import Button from '@components/Button';
import { getColor } from '@utils/getColor';
import { MdVerified } from 'react-icons/md';
import { Provider } from '~/contexts/Provider';
import CookieStorage from 'utils/cookieStorage';
import { formatNumberLocale, formatRating } from '@utils/number';
import { useModal } from '~/contexts/ModalContext';
import { CaringStars } from '@components/CaringStars';
import { getFeaturedAmenities } from '@utils/amenities';
import { Rating } from '@components/Search/SearchResult';
import FeaturedAmenities from '@components/ProviderCard/FeaturedAmenities';
import { createID } from '@utils/strings';
import { useContext } from 'react';
import { RawPageContext } from '@components/LayoutStructure/EditablePage';
import { replaceVariables } from '@utils/providers';
import { findDataByTemplate } from '@utils/magnolia';
import { Page } from '~/types/Magnolia';

export interface CommunityComparisonCardProps {
  provider: Provider;
  id: string;
  url?: string;
  title: string;
  price: number;
  images: Array<string>;
  listId: string;
  address: string;
  queryId: string;
  height?: string;
  modalId?: string;
  displayBadges: boolean;
  currentProvider: boolean;
  enhancedListing?: boolean;
  accommodations?: Array<string | undefined>;
  displayRequestInfoButton?: boolean;
  displayViewCommunityButton?: boolean;
  displayCurrentProvider?: boolean;
  viewCommunityButtonText?: string;
  ratingStarsColor?: string;
  ratingStarsColorRange?: string;
  providerTitleColor?: string;
  providerTitleColorRange?: string;
  viewCommunityButtonColorScheme?: string;
}

const CommunityComparisonCard = ({
  id,
  url,
  title,
  price,
  images,
  listId,
  address,
  queryId,
  provider,
  modalId = '',
  height = '100%',
  accommodations,
  currentProvider,
  providerTitleColor,
  displayBadges = true,
  enhancedListing = true,
  providerTitleColorRange,
  displayCurrentProvider = true,
  ratingStarsColor = 'tertiary',
  ratingStarsColorRange = '500',
  displayRequestInfoButton = true,
  displayViewCommunityButton = true,
  viewCommunityButtonText = 'View Community',
  viewCommunityButtonColorScheme = 'secondary',
}: CommunityComparisonCardProps): JSX.Element => {
  const elementClicked = useElementClicked();
  const showPrice = useInquiryFormSubmitted();
  const rawPage = useContext(RawPageContext);
  const amenityNode = findDataByTemplate(
    rawPage as Page,
    'spa-lm:components/offeringListing'
  );
  const reviewsNode = findDataByTemplate(
    rawPage as Page,
    'spa-lm:components/reviews'
  );

  const { show: showInquiryForm } = useModal(modalId);

  const openInquiryForm = ({ url, id }) => {
    const selectedProvider = { url, id };

    if (CookieStorage.enabled) {
      CookieStorage.set('provider', JSON.stringify(selectedProvider));
    }

    showInquiryForm();
  };

  return (
    <Card
      key={id}
      h={height}
      padding={0}
      margin="5px"
      background="white"
      borderRadius="md"
      boxShadow={
        currentProvider && displayCurrentProvider
          ? '0px 10px 15px -3px #0000001A'
          : 'none'
      }
    >
      <VStack gap={0} h={height}>
        {displayCurrentProvider && (
          <Text
            gap="10px"
            width="full"
            height="36px"
            color="white"
            display="flex"
            fontWeight={700}
            alignItems="center"
            justifyContent="center"
            borderRadius="12px 12px 0 0"
            backgroundColor={currentProvider ? 'primary.600' : 'transparent'}
          >
            {currentProvider && 'Currently Viewing'}
          </Text>
        )}

        <VStack
          p="10px"
          bg="white"
          rounded="md"
          width="full"
          height="100%"
          alignItems={'left'}
          justifyContent="space-between"
          minHeight={{ base: 'max-content', sm: 'xs', md: '360px' }}
        >
          <VStack alignItems="left">
            <Box rounded="md">
              <Box
                height="155"
                rounded="md"
                bg="gray.200"
                overflow="hidden"
                position="relative"
              >
                <Link
                  href={currentProvider ? '' : url}
                  _hover={{ textDecoration: 'none' }}
                  onClick={(e) => {
                    if (currentProvider) {
                      e.preventDefault();
                      window.scroll(0, 0);
                    }
                    elementClicked({
                      element: {
                        type: ElementTypes.LINK,
                        action: ElementActions.INTERNAL_LINK,
                        name: ElementNames.COMPARISON_PROVIDER_IMAGE,
                        text: '',
                        color: '',
                        textColor: '',
                      },
                      destinationUrl: '/bestseniorliving/',
                    });
                  }}
                >
                  <SearchResultImage images={images} title={title} />
                </Link>
              </Box>
            </Box>
            <HStack
              pb={2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Link
                href={currentProvider ? '' : url}
                _hover={{ textDecoration: 'none' }}
                onClick={(e) => {
                  if (currentProvider) {
                    e.preventDefault();
                    window.scroll(0, 0);
                  }
                  elementClicked({
                    element: {
                      type: ElementTypes.LINK,
                      action: ElementActions.INTERNAL_LINK,
                      name: ElementNames.COMPARISON_PROVIDER_TITLE,
                      text: title,
                      color: '',
                      textColor: getColor(
                        providerTitleColor,
                        providerTitleColorRange
                      ),
                    },
                    destinationUrl: '/bestseniorliving/',
                  });
                }}
              >
                <Heading
                  className="texto-icono-centrados"
                  size="md"
                  as={'span'}
                  color={getColor(providerTitleColor, providerTitleColorRange)}
                  display="inline-block"
                  width="100%"
                >
                  {title}
                  {enhancedListing && (
                    <Icon
                      as={MdVerified}
                      w="24px"
                      h="24px"
                      role="presentation"
                      color="primary.700"
                      verticalAlign="top"
                      ml="5px"
                      float={{ base: 'right', md: 'none' }}
                    />
                  )}
                </Heading>
              </Link>
              {displayBadges &&
                provider.awards &&
                provider.awards.length > 0 && (
                  <Link
                    width={55}
                    target="_blank"
                    href="/bestseniorliving/"
                    _hover={{ textDecoration: 'none' }}
                    onClick={() => {
                      elementClicked({
                        element: {
                          type: ElementTypes.LINK,
                          action: ElementActions.INTERNAL_LINK,
                          name: ElementNames.CARING_STARS_BADGE,
                          text: '',
                          color: '',
                          textColor: '',
                        },
                        destinationUrl: '/bestseniorliving/',
                      });
                    }}
                  >
                    <CaringStars
                      displayYears={false}
                      caringStars={provider.awards}
                    />
                  </Link>
                )}
            </HStack>
            <Text size="xs" color="gray.700" m={0}>
              {parse(address)}
            </Text>
            {displayRequestInfoButton && (
              <Box gap={10} w="full">
                <Button
                  my={2}
                  w="full"
                  size="md"
                  minH={10}
                  colorScheme="secondary"
                  onClick={() => openInquiryForm({ url, id: provider.id })}
                  elementType={ElementTypes.BUTTON}
                  elementAction={ElementActions.OPEN_MODAL}
                  elementName={ElementNames.INFO_REQUEST_SECTION}
                  destinationUrl={url}
                  query={{
                    locationId: id ?? '',
                    queryId: queryId,
                    listId: listId,
                  }}
                >
                  {showPrice ? 'Get Information' : 'Get Pricing'}
                </Button>
              </Box>
            )}
          </VStack>
          <Divider borderColor="gray.300" height="2px" gap="14px" />
          <Stack direction="row" alignItems="center">
            <Link
              w="full"
              _hover={{ textDecoration: 'none' }}
              href={`${url}${
                reviewsNode
                  ? '#' +
                    createID(
                      replaceVariables(reviewsNode?.title, {
                        provider: provider,
                      })
                    )
                  : ''
              }`}
              onClick={() => {
                elementClicked({
                  element: {
                    type: ElementTypes.LINK,
                    action: ElementActions.INTERNAL_LINK,
                    name: ElementNames.COMPARISON_PROVIDER_RATING,
                    text: '',
                    color: '',
                    textColor: '',
                  },
                  destinationUrl: `${url}${
                    reviewsNode
                      ? '#' +
                        createID(
                          replaceVariables(reviewsNode?.title, {
                            provider: provider,
                          })
                        )
                      : ''
                  }`,
                });
              }}
            >
              <Rating
                iconSize={8}
                countFontSize="30px"
                reviewsFontSize="md"
                reviewsTextDecoration="underline"
                reviewCount={provider.reviewCount}
                ratingStarsColor={ratingStarsColor}
                rating={formatRating(provider.averageRating)}
                ratingStarsColorRange={ratingStarsColorRange}
              />
            </Link>
          </Stack>
          <Divider borderColor="gray.300" height="2px" gap="14px" />
          <VStack alignItems="flex-start">
            <Heading as="p" size="sm" fontWeight={400}>
              Bedroom Type
            </Heading>
            <Text
              as="p"
              minH="24px"
              size="sm"
              fontWeight={700}
              textTransform="capitalize"
            >
              {accommodations &&
                accommodations?.length > 0 &&
                accommodations?.join(', ')}
            </Text>
          </VStack>
          <Divider borderColor="gray.300" height="2px" gap="14px" />
          <VStack alignItems="flex-start">
            <Heading as="p" size="sm" fontWeight={400}>
              Starting Price
            </Heading>
            {price > 0 ? (
              <Text as="p" fontWeight={700} size={{ base: 'sm', md: 'md' }}>
                $
                <Text
                  as="span"
                  fontWeight={700}
                  textTransform="capitalize"
                  size={{ base: 'sm', md: 'md' }}
                  filter={showPrice ? '' : 'blur(10px)'}
                >
                  {formatNumberLocale(price / 100)}/mo
                </Text>
              </Text>
            ) : (
              <Text color="gray.600" filter={showPrice ? '' : 'blur(10px)'}>
                Pricing not available
              </Text>
            )}
          </VStack>
          <Divider borderColor="gray.300" height="2px" gap="14px" />
          <VStack alignItems="flex-start">
            <Link
              href={`${url}${
                amenityNode
                  ? '#' +
                    createID(
                      replaceVariables(amenityNode?.title, {
                        provider: provider,
                      })
                    )
                  : ''
              }`}
              _hover={{ textDecoration: 'none' }}
              onClick={() => {
                elementClicked({
                  element: {
                    type: ElementTypes.LINK,
                    action: ElementActions.INTERNAL_LINK,
                    name: ElementNames.COMPARISON_PROVIDER_AMMENITIES,
                    text: 'Featured Amenities',
                    color: '',
                    textColor: '',
                  },
                  destinationUrl: `${url}${
                    amenityNode
                      ? '#' +
                        createID(
                          replaceVariables(amenityNode?.title, {
                            provider: provider,
                          })
                        )
                      : ''
                  }`,
                });
              }}
            >
              <Heading as="p" size="sm" fontWeight={400}>
                Featured Amenities
              </Heading>
              <Box h="24px" mt={2}>
                <FeaturedAmenities
                  amenities={getFeaturedAmenities(provider.amenities || {})}
                  hasJumpLink={currentProvider ? true : false}
                />
              </Box>
            </Link>
          </VStack>
          <Divider borderColor="gray.300" height="2px" gap="14px" />
          <VStack alignItems="flex-start">
            <Heading as="p" size="sm" fontWeight={400}>
              Care Types
            </Heading>
            <Text as="p" size="sm" fontWeight={700} textTransform="capitalize">
              {provider?.services?.map((item) => item.category.name).join(', ')}
            </Text>
          </VStack>
          {displayViewCommunityButton && (
            <Button
              as="a"
              size="md"
              minH={10}
              href={url}
              variant="outline"
              onClick={() => {
                elementClicked({
                  element: {
                    type: ElementTypes.LINK,
                    action: ElementActions.INTERNAL_LINK,
                    name: ElementNames.PROVIDER_CARD,
                    text: title,
                    color: 'white',
                    textColor:
                      getColor(providerTitleColor, providerTitleColorRange) ||
                      '',
                  },
                  destinationUrl: url,
                  query: {
                    locationId: id,
                    queryId: queryId,
                    listId: listId,
                  },
                });
              }}
              elementType={ElementTypes.BUTTON}
              elementAction={ElementActions.OPEN_MODAL}
              elementName={ElementNames.INFO_REQUEST_SECTION}
              destinationUrl={url}
              colorScheme={viewCommunityButtonColorScheme}
              query={{
                locationId: id ?? '',
                queryId: queryId,
                listId: listId,
              }}
            >
              {viewCommunityButtonText}
            </Button>
          )}
        </VStack>
      </VStack>
    </Card>
  );
};

export default CommunityComparisonCard;
