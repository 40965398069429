import {
  stepContent,
  stepSubmission,
} from '@components/Analytics/events/eventContracts';
import useSearchStepSubmission from '@components/Analytics/events/SearchStepSubmission';
import useSearchSubmission from '@components/Analytics/events/SearchSubmission';
import { useCallback } from 'react';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';
import { SearchFormData } from './SearchForm';

const createStepContent = ({
  promptId,
  promptIndex,
  promptValue,
  responseValue,
}: {
  promptId: string;
  promptIndex: number;
  promptValue: string;
  responseValue?: string;
}) => ({
  prompt_id: promptId,
  prompt_type: 'text',
  prompt_instance_id: uuidv4(),
  prompt_index: promptIndex,
  prompt_value: promptValue,
  response_array: [
    {
      response_value: responseValue || '',
      response_id: uuidv4(),
    },
  ],
});

const createStepSubmission = ({
  stepId,
  stepIndex,
  stepInstanceId,
}: {
  stepId: string;
  stepIndex: number;
  stepInstanceId: string;
}) => ({
  step_id: stepId,
  step_instance_id: stepInstanceId,
  step_index: stepIndex,
});

const useSearchFormSubmission = ({
  searchBarId,
  careTypeSelect,
  locationInput,
}: {
  searchBarId?: string;
  careTypeSelect: {
    placeholder: string;
    visible: boolean;
  };
  locationInput: {
    placeholder: string;
    visible: boolean;
  };
}) => {
  const searchStepSubmission = useSearchStepSubmission();
  const searchSubmission = useSearchSubmission();

  const trackFormSubmission = useCallback(
    (data: SearchFormData) => {
      const careTypeInstanceId = uuidv4();
      const locationStepInstanceId = uuidv4();
      const searchInstanceId = uuidv4();
      const searchTemplateId = searchBarId || uuidv4();
      const careTypeStepId = uuidv5(
        careTypeSelect.placeholder,
        searchTemplateId
      );
      const locationStepId = uuidv5(
        locationInput.placeholder,
        searchTemplateId
      );
      const stepContentArray: stepContent[] = [];
      const stepSubmissionArray: stepSubmission[] = [];

      if (locationInput.visible) {
        stepContentArray.push(
          createStepContent({
            promptId: uuidv5(locationInput.placeholder, searchTemplateId),
            promptValue: locationInput.placeholder,
            responseValue: data.keyword,
            promptIndex: 1,
          })
        );
        stepSubmissionArray.push(
          createStepSubmission({
            stepId: locationStepId,
            stepInstanceId: locationStepInstanceId,
            stepIndex: 1,
          })
        );
      }

      if (careTypeSelect.visible) {
        stepContentArray.push(
          createStepContent({
            promptId: uuidv5(careTypeSelect.placeholder, searchTemplateId),
            promptIndex: 2,
            promptValue: careTypeSelect.placeholder,
            responseValue: data.careType,
          })
        );
        stepSubmissionArray.push(
          createStepSubmission({
            stepId: careTypeStepId,
            stepIndex: 2,
            stepInstanceId: careTypeInstanceId,
          })
        );
      }

      searchSubmission({
        search_template_id: searchTemplateId,
        search_instance_id: searchInstanceId,
        step_submissions: stepSubmissionArray,
      });

      searchStepSubmission({
        search_template_id: searchTemplateId,
        search_instance_id: searchInstanceId,
        step_id: locationStepId,
        step_instance_id: locationStepInstanceId,
        step_index: 1,
        step_content: stepContentArray,
      });
    },
    [
      careTypeSelect.placeholder,
      careTypeSelect.visible,
      locationInput.placeholder,
      locationInput.visible,
      searchBarId,
      searchStepSubmission,
      searchSubmission,
    ]
  );

  return trackFormSubmission;
};

export default useSearchFormSubmission;
