import { coerceNumber } from '@utils/coercion';
import { strip } from '@utils/parser';
import kebabCase from 'lodash/kebabCase';
import { z } from 'zod';
import {
  ALIGNMENT,
  AMENITY_CATEGORY,
  BOX_SHADOW,
  DEFAULT_VALUES,
  DISPLAY_MODE,
  HEADING_ELEMENT,
  HEADING_SIZE,
  SEARCH_OPTION,
} from './constants';

export const amenityCategory = z
  .nativeEnum(AMENITY_CATEGORY)
  .nullable()
  .catch(null);

export const border = z.enum(['1px', 'none']).nullable().catch(null);

export const boxShadow = z.nativeEnum(BOX_SHADOW).nullable().catch(null);

export const careType = z
  .union([
    z.string().transform((str) => kebabCase(strip(str))),
    z.object({ name: z.string() }).transform((obj) => obj.name),
  ])
  .nullable()
  .catch(null);

export const color = z
  .object({ color: z.string(), range: z.string() })
  .nullable()
  .catch(null);

export const displayMode = z.nativeEnum(DISPLAY_MODE).catch(DISPLAY_MODE.LIST);

export const facetedSearchOptions = z
  .array(z.nativeEnum(SEARCH_OPTION))
  .catch([]);

export const headingAlignment = z.nativeEnum(ALIGNMENT).nullable().catch(null);

export const headingElement = z
  .nativeEnum(HEADING_ELEMENT)
  .nullable()
  .catch(null);

export const headingSize = z
  .object({
    base: z.nativeEnum(HEADING_SIZE),
    desktop: z.nativeEnum(HEADING_SIZE),
  })
  .nullable()
  .catch(null);

export const itemsPerPage = coerceNumber(
  z.number().int().positive().catch(DEFAULT_VALUES.ITEMS_PER_PAGE)
);

export const itemsPerRow = coerceNumber(
  z.number().int().positive().catch(DEFAULT_VALUES.ITEMS_PER_ROW)
);

export const latitude = coerceNumber(z.number().min(-90).max(90).catch(0));

export const longitude = coerceNumber(z.number().min(-180).max(180).catch(0));

export const magnoliaNode = z
  .record(z.string(), z.unknown())
  .nullable()
  .catch(null);

export const nullableBoolean = z.boolean().nullable().catch(null);

export const nullableNumber = z.number().nullable().catch(null);

export const nullableString = z.string().nullable().catch(null);

export const phoneNumber = z
  .object({ label: z.string(), number: z.string() })
  .nullable()
  .catch(null);

export const providerPhoneNumber = z
  .union([
    z.object({ field: z.enum(['providersDatabase']) }).passthrough(),
    z
      .object({
        field: z.enum(['globalCatalog']),
        providerPhoneNumber: z.string(),
      })
      .passthrough(),
  ])
  .nullable()
  .catch(null);

export const radiusForSearch = z
  .number()
  .int()
  .positive()
  .catch(DEFAULT_VALUES.RADIUS_FOR_SEARCH_IN_MILES);

export const readMoreBehavior = z
  .enum(['expanding_content', 'redirect_to_provider_page'])
  .nullable()
  .catch(null);
