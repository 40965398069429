// import { Button } from '@chakra-ui/button';
import Button from '@components/Button';
import { Icon } from '@chakra-ui/icon';
import { Box, Link } from '@chakra-ui/layout';
import { AmenityCategoryNode } from '@utils/faceted-search';
import clamp from 'lodash/clamp';
import { useEffect, useRef, useState } from 'react';
import { LuSearch, LuX } from 'react-icons/lu';
import ArrowButton from './FacetedSearchBar/ArrowButton';
import { ScrollDirection } from './FacetedSearchBar/constants';
import {
  ElementActions,
  ElementNames,
  ElementTypes,
} from '@components/Analytics/events/ElementClicked';
interface FacetedSearchBarProps {
  initialValue?: string;
  basePath?: string;
  links: AmenityCategoryNode[];
  numberOfResults?: number;
  onChange?: (value: string | null) => void;
}

const appendPath = (basePath: string, path: string) => {
  let cleanedPath = path.startsWith('/') ? path.slice(1) : path;
  if (basePath.endsWith('/')) {
    return `${basePath}${cleanedPath}`;
  }
  return `${basePath}/${cleanedPath}`;
};

const FacetedSearchBar: React.FC<FacetedSearchBarProps> = ({
  initialValue,
  basePath,
  links,
  numberOfResults,
  onChange = () => {},
}) => {
  const initialIndex = links.findIndex((link) => link.value === initialValue);
  const initialSelectedButton = initialIndex > -1 ? initialIndex : null;
  const containerRef = useRef<HTMLDivElement>(null);
  const hasReachedEnd = useRef(false);
  const isAtStart = useRef(false);
  const visibleTabs = useRef<Set<number>>(new Set());
  const [selectedButton, setSelectedButton] = useState<number | null>(
    initialSelectedButton
  );
  const [showNextButton, setShowNextButton] = useState(false);
  const [showPrevButton, setShowPrevButton] = useState(false);
  const pathname = basePath || '';

  useEffect(() => {
    const buttons = getButtons();

    if (!buttons) {
      return;
    }

    const observers: IntersectionObserver[] = [];
    const observerOptions = {
      root: containerRef.current,
      rootMargin: '1px',
      threshold: [0, 1],
    };

    for (let i = 0; i < buttons.length; i++) {
      const buttonNode = buttons[i];

      observers[i] = new IntersectionObserver((entries) => {
        const [entry] = entries;
        const isFirstNode = i === 0;
        const isLastNode = i === buttons.length - 1;

        if (entry.isIntersecting) {
          visibleTabs.current.add(i);
        } else {
          visibleTabs.current.delete(i);
        }

        if (isFirstNode) {
          if (entry.intersectionRatio === 1) {
            isAtStart.current = true;
          } else {
            isAtStart.current = false;
          }
        }

        if (isLastNode) {
          if (entry.intersectionRatio === 1) {
            hasReachedEnd.current = true;
          } else {
            hasReachedEnd.current = false;
          }
        }

        if (isAtStart.current) {
          setShowPrevButton(false);
        } else {
          setShowPrevButton(true);
        }

        if (hasReachedEnd.current) {
          setShowNextButton(false);
        } else {
          setShowNextButton(true);
        }
      }, observerOptions);

      observers[i].observe(buttonNode);
    }

    return () => {
      observers.forEach((observer) => observer.disconnect());
    };
  }, []);

  const getButtons = () => {
    if (!containerRef.current) {
      return;
    }

    const listNode = containerRef.current;
    const buttons: NodeListOf<HTMLAnchorElement | HTMLButtonElement> =
      listNode.querySelectorAll('a, button');
    return buttons;
  };

  const getButtonNode = (index: number) => {
    const buttons = getButtons();

    if (!buttons) {
      return;
    }

    return buttons[index];
  };

  const scroll = (direction: ScrollDirection) => {
    // Get the index of the first/last visible button
    const index =
      direction === ScrollDirection.Left
        ? Math.min(...visibleTabs.current)
        : Math.max(...visibleTabs.current);
    const offset = direction === ScrollDirection.Left ? -1 : 1;
    const shouldIncludeOffset = visibleTabs.current.size === 1;
    const target = shouldIncludeOffset ? index + offset : index;

    // Get index of the previous/next button
    const indexToScrollTo = clamp(target, 0, links.length - 1);

    // Get the previous/next button node
    const button = getButtonNode(indexToScrollTo);

    if (!button) {
      return;
    }

    button.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    });
  };

  const updateSelectedButtonState = (index: number) => {
    const shouldDeselectButton = selectedButton === index;
    const newSelectedButton = shouldDeselectButton ? null : index;
    const newSelectedValue = shouldDeselectButton ? null : links[index].value;

    setSelectedButton(newSelectedButton);
    onChange(newSelectedValue);
  };

  return (
    <Box display="flex" alignItems="center" gap={4} position="relative">
      <Box
        ref={containerRef}
        display="flex"
        gap={4}
        // To show the outline of the buttons when they are focused
        p="3px"
        m="-3px"
        // Scroll
        overflowX="scroll"
        sx={{
          scrollbarWidth: 'none',
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {links.map(({ name, value }, index) => {
          const url = pathname
            ? selectedButton === index
              ? pathname
              : appendPath(pathname, value)
            : undefined;
          return (
            <Button
              key={value}
              as={pathname ? Link : undefined}
              isDisabled={!pathname || !value}
              href={url}
              // Button props
              colorScheme="white"
              flexShrink={0}
              iconSpacing={1}
              isActive={selectedButton === index}
              leftIcon={
                <Icon
                  as={selectedButton === index ? LuX : LuSearch}
                  boxSize={3.5}
                />
              }
              opacity={
                selectedButton !== null && selectedButton !== index ? 0.5 : 1
              }
              size="sm"
              variant="outline"
              elementAction={ElementActions.INTERNAL_LINK}
              elementName={ElementNames.FILTER_CHIP}
              elementType={ElementTypes.LINK}
              destinationUrl={url}
            >
              {`${name}${
                numberOfResults && selectedButton === index
                  ? ` (${numberOfResults})`
                  : ''
              }`}
            </Button>
          );
        })}
      </Box>

      {showPrevButton && (
        <ArrowButton
          direction={ScrollDirection.Left}
          onClick={() => scroll(ScrollDirection.Left)}
        />
      )}
      {showNextButton && (
        <ArrowButton
          direction={ScrollDirection.Right}
          onClick={() => scroll(ScrollDirection.Right)}
        />
      )}
    </Box>
  );
};

export default FacetedSearchBar;
