import { searchSubmission, searchSubmissionFields } from './eventContracts';
import {
  SessionContextType,
  useSessionContext,
} from '~/contexts/SessionContext';
import segmentEvents from '~/config/segment-events';

interface SearchSubmissionType {
  sessionContext: SessionContextType;
  searchSubmissionData: searchSubmissionFields;
}

const searchSubmit = ({
  sessionContext,
  searchSubmissionData,
}: SearchSubmissionType) => {
  const searchSubmissionContract: searchSubmission = {
    search_submission_json: JSON.stringify({
      search_submission: [searchSubmissionData],
    }),
    page_session_id: sessionContext.pageSessionId,
    session_id: sessionContext.sessionId,
    form_type: searchSubmissionData.search_type ?? '',
  };
  window.tracking.track(
    segmentEvents.SEARCH_SUBMITTED,
    searchSubmissionContract
  );
};

const useSearchSubmission = () => {
  const sessionContext = useSessionContext();
  return (searchSubmissionData: searchSubmissionFields) => {
    return searchSubmit({ sessionContext, searchSubmissionData });
  };
};

export default useSearchSubmission;
