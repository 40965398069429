import { Text } from '@chakra-ui/react';
import { MagnoliaTextWithSizeAndStyle } from './types';
import { getTextElementProps } from './MultiStepForm.utils';

const TextWithSizeAndStyle: React.FC<MagnoliaTextWithSizeAndStyle> = ({
  text,
  textFormatting,
}) => {
  return text ? (
    <Text className="magnolia-text" {...getTextElementProps(textFormatting)}>
      {text}
    </Text>
  ) : null;
};

export default TextWithSizeAndStyle;
