import { Flex } from '@chakra-ui/layout';
import {
  MdWifi,
  MdPets,
  MdDirectionsBus,
  MdWheelchairPickup,
} from 'react-icons/md';
import { PageContext } from '@components/LayoutStructure/EditablePage';
import SiteContext from '~/contexts/SiteContext';
import { useContext } from 'react';
import { createID } from '@utils/strings';
import { As } from '@chakra-ui/system';

export const getOfferingListingNode = (page) => {
  const offeringListingNode = page?.main['@nodes']?.find(
    (value) =>
      page.main[value]['mgnl:template'] === 'spa-lm:components/offeringListing'
  );
  return offeringListingNode !== undefined
    ? page.main[offeringListingNode]
    : -1;
};

const FeaturedAmenities: React.FC<{
  amenities: {
    pets: boolean;
    internet: boolean;
    wheelchair: boolean;
    transportation: boolean;
  };
  hasJumpLink?: boolean;
}> = ({ amenities, hasJumpLink = false }) => {
  const siteProps = useContext(SiteContext);
  const domain = siteProps.site?.path ?? '';
  const page = useContext(PageContext)?.page;
  const amenityNode = getOfferingListingNode(page);

  const amenityJumpLink = createID(amenityNode?.title);

  if (
    (!amenities.pets &&
      !amenities.internet &&
      !amenities.wheelchair &&
      !amenities.transportation) ||
    domain !== 'caring.com'
  ) {
    return null;
  }
  const isJumpLink = hasJumpLink
    ? { as: 'a' as As, href: `#${amenityJumpLink}` }
    : {};
  return (
    <Flex fontSize="2xl" color="gray.700" gap="10px" {...isJumpLink}>
      {amenities.internet && <MdWifi data-testid="wifi-icon" />}
      {amenities.pets && <MdPets data-testid="pets-icon" />}
      {amenities.wheelchair && (
        <MdWheelchairPickup data-testid="wheelchair-icon" />
      )}
      {amenities.transportation && (
        <MdDirectionsBus data-testid="transportation-icon" />
      )}
    </Flex>
  );
};

export default FeaturedAmenities;
